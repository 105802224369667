import pro1 from '../../../images/proven-track-record05.png'
import pro2 from '../../../images/numerous-app-maintenance-aervices.png'
import pro3 from '../../../images/party-app-maintenance01.png'
import pro4 from '../../../images/web-app-monitoring078.png'
import pro5 from '../../../images/performance-optimization04.png'
import pro6 from '../../../images/high-quality-solution03.png'
import pro7 from '../../../images/total-transparency06.png'
import pro8 from '../../../images/flexible-cost02.png'

const SupportData = [
    {
        title:"Flow Architecture",
        
       imgsrc: pro1,  
       
    },
    {
        title:"Visual Designing",
       
       imgsrc: pro2,  
     
     },
     {
        title:"Prototype Building",
       
       imgsrc: pro3,  
     
     },
     {
        title:"Testing & QA",
        
       imgsrc: pro4,  
      
     },
     {
        title:"Research",
        
       imgsrc: pro5,  
    
     },
     {
        title:"User Audit",
       
       imgsrc: pro6,
     
     },
     {
        title:"UX Design",
        
       imgsrc: pro7,  
       
     },
     {
        title:"Customer Demand",
       
       imgsrc: pro8,  
     
     },
   
     
     
    
]

export default SupportData ;