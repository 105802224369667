import React from 'react'
import {Link} from 'react-router-dom'
import pic from "../../src/images/jaiipr.png"
import pic1 from "../../src/images/contact-call-icon.png"
import pic2 from "../../src/images/mbai.png"
import pic3 from "../../src/images/pune.png"
import pic4 from "../../src/images/simple-mail.png"
import pic5 from "../../src/images/skype-icon.png"
import pic6 from "../../src/images/facebook.svg"
import pic7 from "../../src/images/linkedin.svg"
import pic8 from "../../src/images/twiiter.svg"
import pic9 from "../../src/images/instagram.svg"
import pic10 from "../../src/images/whatsapp.svg"
import pic11 from "../../src/images/youtube.svg"

export const Contact = () => {
  return (
    <section className="contact-page-section same-section">
    <div className="container">
      <div className="contact-inner-content">
        <h3>Let’s Create The Next Best <span>Together</span></h3>
        <p>
          Uptimise IT helps you grow and reach your business goal with
          innovative and value-adding digital mobile app/web solutions.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="contact-page-middle">
            <div className="contact-united-page">
              <figure>
                <img src={pic} alt="uptimise it" style={{height: '40px', width: '40px'}} />
              </figure>
              <h3>Jaipur</h3>
            </div>
            <div className="contact-page-numbers">
              <div className="contact-page-detial">
                <Link to="tel:+91 141 358 2178">
                  <span>
                    <img src={pic1} alt="uptimise it " />
                  </span>
                  +91 141 358 2178
                </Link>
                <p>
                  Narendra Nagar, Jaipur
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="contact-page-middle">
            <div className="contact-united-page">
              <figure>
                <img src={pic2} alt="uptimise it" style={{height: '40px', width: '40px'}} />
              </figure>
              <h3>Mumbai</h3>
            </div>
            <div className="contact-page-numbers">
              <div className="contact-page-detial">
                <Link to="tel:+917020134662">
                  <span>
                    <img src={pic1} alt="uptimise it" />
                  </span>
                  +91 7020134662
                </Link>
                <p>Mumbai</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="contact-page-middle">
            <div className="contact-united-page">
              <figure>
                <img src={pic3} alt="uptimise it" style={{height: '40px', width: '40px'}} />
              </figure>
              <h3>Pune</h3>
            </div>
            <div className="contact-page-numbers">
              <div className="contact-page-detial">
                <Link to="tel:+917020134662">
                  <span>
                    <img src={pic1} alt="uptimise it" />
                  </span>
                  +91 7020134662
                </Link>
                <p>Pune</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-bottom-parent">
        <div className="row">
          <div className="col-lg-6">
            <div className="contact-bottom-inner">
              <h3>Contact Us:</h3>
              <div className="contact-list">
                <ul>
                  <li>
                    <span><img src={pic4} alt="uptimise it" /></span><Link to="mailto:sales@uptimiseit.com">sales@uptimiseit.com</Link>
                  </li>
                  <li>
                    <span><img src={pic5} alt="uptimise it" /></span><Link to="#">Uptimise IT</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-social-list contact-bottom-inner">
              <h3>Follow Us on:</h3>
              <ul>
                <li>
                  <Link to="https://www.facebook.com/uptimiseit/" target="_blank"><img src={pic6} alt="uptimise it" /></Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/company/uptimise-it/" target="_blank"><img src={pic7} alt="uptimise it" /></Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/company/uptimise-it/" target="_blank"><img src={pic8} alt="uptimise it" /></Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/uptimiseit/" target="_blank"><img src={pic9} alt="uptimise it" /></Link>
                </li>
                <li>
                  <Link to="https://wa.me/917020134662"><img src={pic10} alt="uptimise it" /></Link>
                </li>
                <li>
                  <Link to="https://www.youtube.com/googlecode" target="_blank"><img src={pic11} alt="uptimise it" /></Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Contact
