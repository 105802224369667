
import React from 'react';
import './App.css';
import Home from './pages/Home';
import AndriodApp from './pages/AndriodApp';
import ArtificialIntelligence from './pages/ArtificialIntelligence';
import Automotive from './pages/Automotive';
import Cryptocurrency from './pages/Cryptocurrency';
import Ecommerce from './pages/Ecommerce';
import Dafri from './pages/Dafri';
import Contact from './pages/Contact';
import Education from './pages/Education';
import Error from './pages/Error';
import Ewallet from './pages/Ewallet';
import Event from './pages/Event';
import Fintech from './pages/Fintech';
import FoodDelivery from './pages/FoodDelivery';
import Healthcare from './pages/Healthcare';
import HireAndroid from './pages/HireAndroid';
import HireAngular from './pages/HireAngular';
import HireIphone from './pages/HireIphone';
import HireLaravel from './pages/HireLaravel';
import HireMagento from './pages/HireMagento';
import Nodejs from './pages/Nodejs';
import HirePhp from './pages/HirePhp';
import HirePython from './pages/HirePython';
import HireReact from './pages/HireReact';
import HireWeb from './pages/HireWeb';
import {
  Routes,
  Route,
} from "react-router-dom";

import Topnav from './components/topnav/Topnav';
import Footer from './components/footer/Footer';
import Ourwork from './pages/Ourwork';
import About from './pages/About';
import Industry from './pages/Industry';
import IosApp from './pages/IosApp';
import LoanLending from './pages/LoanLending';
import Logistic from './pages/Logistic';
import Maintenance from './pages/Maintenance';
import MarketPlace from './pages/MarketPlace';
import Medu4 from './pages/Medu4';
import MobileBanking from './pages/MobileBanking';
import OnDemand from './pages/OnDemand';
import PaybyCheck from './pages/PaybyCheck';
import Recruitment from './pages/Recruitment';
import SaaS from './pages/Saas';
import UiUx from './pages/UiUX';
import NotFound from './components/NotFound';
import Web from './pages/Web';



function App() {

 
  return (
    <div>
      <Topnav/>
    
      <Routes>
       
        <Route exact path='/' element={<Home />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path='/ourwork' element={<Ourwork />} />
        <Route exact path='/android-app-development' element={<AndriodApp />} />
        <Route exact path='/artificial-intelligence-application-development' element={<ArtificialIntelligence />} />
        <Route exact path='/automotive-app-development' element={<Automotive />} />
        <Route exact path='/cryptocurrency-app-development' element={<Cryptocurrency />} />
        <Route exact path='/dafri-bank-case-study' element={<Dafri />} />
        <Route exact path='/ecommerce-app-development' element={<Ecommerce />} />
        <Route exact path='/education-app-development' element={<Education />} />
        <Route exact path='/error' element={<Error />} />
        <Route exact path='/event-app-development' element={<Event />} />
        <Route exact path='/ewallet-app-development' element={<Ewallet />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/food-delivery-app-development' element={<FoodDelivery />} />
        <Route exact path='/fintech-app-development' element={<Fintech />} />
        <Route exact path='/healthcare-app-development' element={<Healthcare />} />
        <Route exact path='/hire-android-app-developers'  element={<HireAndroid />} />
        <Route exact path='/hire-angularjs-developers'  element={<HireAngular />}/>
        <Route exact path='/hire-iphone-app-developers'  element={<HireIphone />} />
        <Route exact path='/hire-laravel-developers'  element={<HireLaravel />} />
        <Route exact path='/hire-magento-developers'  element={<HireMagento />} />
        <Route exact path='/hire-nodejs-developers'  element={<Nodejs />} />
        <Route exact path='/hire-php-developers'  element={<HirePhp />} />
        <Route exact path='/hire-python-developers'  element={<HirePython />} />
        <Route exact path='/hire-react-native-developer'  element={<HireReact />} />
        <Route exact path='/hire-web-developers'  element={<HireWeb />} />
        <Route exact path='/industry'  element={<Industry />} />
        <Route exact path='/ios-app-development'  element={<IosApp />} />
        <Route exact path='/loan-lending-app-development'  element={<LoanLending />} />
        <Route exact path='/logistics-app-development'  element={<Logistic />} />
        <Route exact path='/maintenance-support'  element={<Maintenance />} />
        <Route exact path='/marketplace-development'  element={<MarketPlace />} />
        <Route exact path='/medu4-case-study'  element={<Medu4 />} />
        <Route exact path='/mobile-banking-app-development'  element={<MobileBanking />} />
        <Route exact path='/on-demand-app-development'  element={<OnDemand />} />
        <Route exact path='/pay-by-check-case-study'  element={<PaybyCheck />} />
        <Route exact path='/recruitment-app-development'  element={<Recruitment />} />
        <Route exact path='/saas-and-enterprise-solutions'  element={<SaaS />} />
        <Route exact path='/ui-ux-design-development'  element={<UiUx />} />
        <Route exact path='/web-development-company'  element={<Web />} />
        <Route path="*" element={<NotFound />} />
       
      </Routes>

<Footer/>
    </div>
  );
}

export default App;






