import pro from '../../images/custom-event-management-app-development-events.png'
import pro1 from '../../images/mobile-app-maintenance-support-services-events.png'
import pro2 from '../../images/e-ticketing-web-portal-development-events.png'
import pro3 from '../../images/event-booking-mobile-app-development-events.png'
import pro4 from '../../images/event-app-migration-solution-events.png'
import pro5 from '../../images/cross-platform-event-app-development-events.png'
import pro6 from '../../images/conference-app-development-solution-events.png'
import pro7 from '../../images/mobile-app-maintenance-support-services-events.png'


const EventData = [
    {
        title:"Custom Event Management App Development",
        text:     <p>Uptimise IT being top <strong>mobile app developer</strong> offers unparalleled custom event management app development which you can avail yourself at affordable costs.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Event Schedule App Development Solution",
        text:  <p>Avail of the globally renowned <strong>Event Schedule App Development</strong> solution and outshine the competition. Uptimise IT is here to deliver the same. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"E-Ticketing Web Portal Development",
        text:  <p>We have a team of experienced <strong>Web developers</strong> who have the skills needed to deliver a robust e-ticketing web portal with all the required API integrations.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Event Booking Mobile App Development",
        text:   <p>With the event booking mobile app, users can book ticketing to their favorite shows, concerts, and events easily through the mobile app itself.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"EventApp Migration Solution",
        text:  <p>Worried about your current system becoming a legacy? Uptimise IT offers event app migration services helping you move to the better version.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Cross-Platform Event App Development",
        text:   <p>If you can’t choose whether you want an iOS app or an Android app and even if you want both of them,  Uptimise IT offers <strong>Cross-Platform App Development.</strong> </p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Conference App Development Solution",
        text:   <p>As a leading <strong>Conference App Development Company,</strong> Uptimise IT offer amazing app development solutions that help you grow. </p>,
       imgsrc: pro6,
     
     },
     {
        title:"Mobile App Maintenance & Support Services",
        text: <p>Uptimise IT in addition to stellar development, also offers amazing <strong>mobile app maintenance and support services.</strong></p>,
       imgsrc: pro7,  
       
     },
  
   
     
     
    
]

export default EventData ;