import React from 'react'
import pic from "../images/support-banner-img.png"
import pic1 from "../images/500-Planning.png"
import pic2 from "../images/8665237_code_development_icon.png"
import pic3 from "../images/6619436_a_ab_abtest_b_seo_icon.png"
import pic4 from "../images/6619436_a_ab_abtest_b_seo_icon.png"
import pic5 from "../images/control_x2C__management.png"
import pic6 from "../images/clock-icon01.svg"
import pic7 from "../images/clock-icon02.svg"
import pic8 from "../images/clock-icon03.svg"
import pic9 from "../images/iphone-app-developer.png"
import DevHead from './development/andHeader/DevHead'

import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Supprot from './development/designSupprot/Supprot'
import Maintain from './development/maintain/Maintain'
import ItSupport from './development/itSupport/ItSupport'
import Industry from "./industry/Industry"
import Success from "./success/Success"
export default function Maintenance() {
    return (
        <div id="content">
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Web/Application Maintenance And Support Services,</strong> You Can Rely On</h1>
              <p>Why do most of the new apps fail in the market? Because like everything else, mobile apps require maintenance and support. So, if you want to make sure your app outshines and outlasts the competition,Uptimise IT’s <strong>application maintenance and support services</strong> can help you.</p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section steler-design-section">
          <div className="container">
            <div className="demaond-content same-heading text-center">
              <span>Maintenance and Support</span>
              <h4>Why Choose Uptimise IT’s Maintenance and Support Services?</h4>
              <p className="text-center">Why choose our <strong>application development &amp; maintenance support?</strong> Being one of the best application support companies, our services stand out from the rest. With our expertise in IT support and maintenance, we can help you reach the top of the market.</p>
            </div>
            <div className="row">
              <Supprot />
            </div>
          </div>
        </section>
        <section className="same-section bg-color software-section">
          <div className="container">
            <div className="same-heading text-center">
              <h4>Mobile Application Maintenance Services That Fit Your Needs</h4>
              <p className="text-center">Our <strong>application support services &amp; maintenance</strong> services help clients manage their apps in the best ways possible. Some of our highlighting maintenance support services are, as mentioned below:</p>
            </div>
            <div className="row">
             <Maintain />
            </div>
          </div>
        </section>
        <section className="same-section technology-section steller-prototype">
          <div className="container-fluid">
            <div className="same-heading text-center">
              <span>ITSupport and Maintenance Services</span>
              <div className="mobiles_head">
                <h4>The Web/Application Support &amp;Maintenance Services You Need </h4>
                <p>Uptimise IT understands the importance of web/application maintenance &amp; support services in ensuring the success of an app. So, if you maintain the amazing services of your app, our range of solutions can help you:</p>
              </div>
            </div>
            <div className="row">
             <ItSupport />
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Our Portfolio</h2>
                  <p>Look at the Amazing Work Our Team at Uptimise IT.</p>
                  <Link to="#" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section process-parent webdesign-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>How Mobile App Development and Maintenance Services Work?</h4>
                <p>As a leading mobile app development and Maintenance Company, Uptimise IT follows a flexible development process. This process is, as mentioned below:</p>
              </div>
            </div>
            <div className="row process_work">
              <div className="col-lg-5">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic1} />
                    <h3>1</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Analysis &amp; Planning</h3>
                    <p>The very first step of developing an amazing mobile app is analyzing customer needs and planning a strategy accordingly. Once this is done, we can move to the next step.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3 className="text-right">UI/UX Design</h3>
                    <p>User Interface and User experience are the most important component when it comes to making an engaging and attractive. Our <strong>UI/UX designers</strong> are experienced and know how to deliver amazing solutions.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>2</h3>
                    <img src={pic2} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic3} />
                    <h3>3</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Development Process</h3>
                    <p>After the design is finished, our development of <strong>Mobile App Developers</strong> starts the coding process, joining the different components and creating your idea into a working mobile application. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Testing</h3>
                    <p>The testing process is just as important as the development process. As here the Quality Analysis team will be checking the solution thoroughly for bugs and other shortcomings. Necessary changes are made and the app is deployed. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>4</h3>
                    <img src={pic4} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic5} />
                    <h3>5</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Maintenance &amp; Support</h3>
                    <p>Uptimise IT provides the best application maintenance and support services in the market. This step helps apps improve on their shortcoming, identify &amp; eliminate bugs, adapt to the market, and many more things. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pic9} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Improve With Application Support Services </h3>
                  <p>With amazing application support services from Uptimise IT, you can improve your mobile app, adapt to the changing market, and outlast the aging competition. Some of the other benefits of maintenance and support services are, as mentioned below:</p>
                  <ul>
                    <li>Adapt to changing market </li>
                    <li>Identify and remove bugs</li>
                    <li>Add new features </li>
                    <li>Migrate the web app </li>
                    <li>Reduce Downtime </li>
                    <li>Improve Traffic </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section pt-0 watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Maintenance &amp; Support Services Model</h4>
                <p className="text-center">Uptimise IT offers various Android &amp; iOS app maintenance service models. So, you can choose the one that fits your needs the best. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic6} alt="uptimise it" />
                      </figure>
                      <h3>Annual Maintenance Solution</h3>
                      <p>We offer an annual maintenance solution, where we take care of bugs, problem fixing, upgradation, and so on.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic7} alt="uptimise it" />
                      </figure>
                      <h3>Part Time / Full Time Support</h3>
                      <p>You can hire our team of part-time and full time bases for application development &amp; maintenance services based on your needs.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic8} alt="uptimise it" />
                      </figure>
                      <h3>Emergency Maintenance</h3>
                      <p>Something suddenly went wrong and it's damaging the reputation of your app? Our emergency maintenance service can help you withthat.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>The Best Application Maintenance and Support Services</h3>
            <p>If you are looking for amazing application development and maintenance services, Uptimise IT is the perfect partner for you. </p>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
             <Industry />
            </div>
          </div>
        </section>
      
        <section className="same-section bg-color work-inner-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
              <Success />
            </div>
          </div>
        </section>
   <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some of the most frequently asked questions about application maintenance and support services.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          What is application development and maintenance?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Mobile and web application development and maintenance services refer to the process of creating a mobile application or a web app like PWA. In addition to this, it also includes application maintenance services. It includes app up-gradation, migration, bug identification and removal, and so on. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How much does it cost to develop and maintain a mobile app?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The cost to develop and maintain a mobile app highly depends on the nature of the app, its platform, its type, size, etc. Therefore, it is not possible to accurately predicate the cost. But you say that mobile app development and maintenance cost is between $20,000 and $100,000. If you are looking for a closer estimate, you can contact us with the project detail. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          What does include your app development and maintenance services?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Uptimise IT is a leading application maintenance and support service, provider. As such, our top services include:
                          <ul>
                            <li>App Maintenance &amp; Support</li>
                            <li>Mobile App Upgradation</li>
                            <li>Software/App Migration</li>
                            <li>Post Deployment Support</li>
                            <li>Bug Inspection </li>
                            <li>System Audit &amp; Maintenance</li>
                            <li>Code Revamping</li>
                            <li>Web App Maintenance</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Why should I opt for app development and maintenance services?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          There are various reasons to hire app development and maintenance services from Uptimise IT. To name a few, these are, as mentioned below:
                          <ul>
                            <li>Adapt to changing market </li>
                            <li>Identify and remove bugs </li>
                            <li>Add new features </li>
                            <li>Migrate the web app </li>
                            <li>Reduce Downtime </li>
                            <li>Improve Traffic </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          How to choose the best app development and Maintenance Company?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">When it comes to choosing an app development and maintenance company, Uptimise IT rises to the occasion. As a leading application support &amp; maintenance company, we are more than capable of providing amazing development services. Our development prowessis well-known in the USA and recognized by platforms like Clutch.co. </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
        
      </div>

   
    )
}