import React from 'react'

import {Link} from 'react-router-dom'
export default function PaybyCheck() {
    return (
      

        <div>
        <div id="content">
          <section className="paycheck-bg payback-change-image">
            <img src="asset/images/pBC.png" alt="uptimise it" />
            <div className="change-section-image">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="same-heading">
                      <span className="text-center">Case Study</span>
                      <div className="mobiles_head">
                        <h1>Pay By Check</h1>
                        <h4>Multi-Currency E-wallet Mobile App </h4>
                        <p>Pay by Check is a popular e-Wallet mobile app in the United States of America. It allows users to transfer, pay, or even exchange currency.</p>
                      </div>
                      <div className="pay_btn">
                        <a className="btn btn-primaryx">Launch Date : Comming Soon</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section platform-section bg-color">
            <div className="container">
              <div className="same-heading text-center">
                <h4>Platform</h4>
                <p>Pay By Check is an innovative multi-currency eWallet platform that is available on the Web, Android, as well as Web. Throughout the different platforms, the quality and offering of platforms remain consistent. </p>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="platform-box">
                    <Link to>
                      <div className="platform-inner-box">
                        <span>
                          <img src="asset/images/platform-android-icon.svg" alt="uptimise it" />
                        </span>
                        <h3>Android</h3>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="platform-box">
                    <Link to>
                      <div className="platform-inner-box">
                        <span>
                          <img src="asset/images/platform-apple-icon.svg" alt="uptimise it" />
                        </span>
                        <h3>Ios</h3>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="platform-box">
                    <Link to>
                      <div className="platform-inner-box">
                        <span>
                          <img src="asset/images/platform-web-icon.svg" alt="uptimise it" />
                        </span>
                        <h3>Website</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="pay_parent">
                <div className="prev_circle">
                  <span>
                    <img src="asset/images/Icon%20material-navigate-prev.png" />
                  </span>
                  <Link to><h3>Medu4</h3></Link>
                </div>
                <div className="next_circle">
                  <h3>DafriBank</h3>
                  <span>
                    <img src="asset/images/Icon%20material-navigate-next.png" />
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section">
            <div className="container">
              <div className="same-heading">
                <span>Technology</span>
                <div className="mobiles_head">
                  <h4>The Technology Stack</h4>
                  <p>As a leading web/mobile app development company, Uptimise IT uses the latest technologies and tools to ensure high-quality development solutions.</p>
                </div>
              </div>
              <div className="tecnologies-main-parent">
                <ul>
                  <li>
                    <div className="tecnho-top-box">
                      01
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/adobe-XD-icon.png" alt="uptimise it" />
                      </span>
                      <h3>Adobe XD</h3>
                    </div>
                  </li>
                  <li>
                    <div className="tecnho-top-box">
                      02
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/laravel-icon.png" />
                      </span>
                      <h3>Laravel</h3>
                    </div>
                  </li>
                  <li>
                    <div className="tecnho-top-box">
                      03
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/swift-icon.png" />
                      </span>
                      <h3>Swift 4</h3>
                    </div>
                  </li>
                  <li>
                    <div className="tecnho-top-box">
                      04
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/html-icon.png" />
                      </span>
                      <h3>Html 5 </h3>
                    </div>
                  </li>
                  <li>
                    <div className="tecnho-top-box">
                      05
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/my-sql-icon.png" />
                      </span>
                      <h3>My SQL </h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="same-section request-section">
            <div className="container">
              <div className="request-content">
                <h3>Let’s Talk </h3>
                <p>Bring your app idea to us and we will convert it into reality with our magic.</p>
                <Link to className="btn btn-primaryx">Get Free Consultation Now</Link>
              </div>
            </div>
          </section>
          <section className="same-section">
            <div className="container">
              <div className="same-heading text-center">
                <span>Pay By Check </span>
                <div className="mobiles_head">
                  <h4>Feature Set</h4>
                  <p className="text-center">Pay By Check - Multi-Currency E-wallet Mobile Apphas amazing features which allow the users to experience e-wallet advantages seamlessly.</p>
                </div>
              </div>
              <div className="row feature-row-parent">
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Seamless Onboarding</h3>
                      <p>During the onboarding process, the users are required to verify their phone numbers via OTP. Although the users can access the app after this, they’re prompted to complete the KYC process, which they can skip until later.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Add Funds to App Wallet</h3>
                      <p>The app uses ACH and EFT methods to transfer funds from the user’s bank or credit card. The users would be able to add and store multiple bank payment options and cards for adding funds to the wallet.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Invite Friend</h3>
                      <p>Using this function, the users can invite their friends and known contacts to download and use Pay By Check. This will allow them to earn some points or wallet cash.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Commission for App Owner</h3>
                      <p>This feature helps the app administrator to set the commission on every transaction. There is an option where the admin can set the commission percentage, which will then be applied to every transaction</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Amount Limit Management</h3>
                      <p>The administrator of the application is able to set limits for unverified bank and credit/debit cards. Therefore users are able to upload funds up to the assigned limit, but they cannot cross the assigned fund limit until they have verified their card using the IPV or the MDV process.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section same_apps p-0">
            <img src="asset/images/pay-by-check-portfoilio-img.png" width="100%" />
            <div className="container">
              <div className="same-app-content">
                <div className="row">
                  <div className="app-parent">
                    <div className="app_interface">
                      <h3><span>APP</span> INTERFACE</h3>
                    </div>
                  </div>
                  <div className="app-inner-list medu-app-inner-list">
                    <ul>
                      <li><img src="asset/images/Icon%20ionic-ios-done-all.png" />Fluid and Interactive Design </li>
                      <li><img src="asset/images/Icon%20ionic-ios-done-all.png" />Aesthetic visual </li>
                      <li><img src="asset/images/Icon%20ionic-ios-done-all.png" />Easy to navigate </li>
                      <li><img src="asset/images/Icon%20ionic-ios-done-all.png" />Unmatched User Experience </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="education_slider">
                    <div className="owl-carousel owl-theme">
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Curvaceous</h3>
                                <p>Curvaceous fashion is a fashion app available on both iOS and Android which offers unparalleled user experience along with value to customers.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/curvaceous-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>CUT</h3>
                                <p>CUT  isan e-wallet mobile application that available in China and Myanmar. It works well with both RMB  and MMK currencies.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/cut-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>DafriBank </h3>
                                <p>DafriBank is a leading banking portal that also allows users to trade cryptocurrencies and also provides e-wallet advantages.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/DafriBank-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>medu4</h3>
                                <p>A leading online medical educational platform in Japanese market, Medu4 is a perfect example of our educational solutions.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/medu4-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>MM Arcade</h3>
                                <p>MM Arcade is a unique mix of gaming and social networking mobile app solutions that also offer prices up to $1000 per week.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/MM-arcade-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Nujaxx</h3>
                                <p>If you are feeling too cluttered or coming through something complex, decluttering experts can help you simplify life.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Nujaxx-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Pay By Check</h3>
                                <p>Pay by Check is a popular e-Wallet mobile app in the United States of America. It allows users to transfer, pay, or even exchange currency.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Pay-by-check-1-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Pearl Cab</h3>
                                <p>Pearl Cab is a ride-sharing company that provides on-demand taxi services to users globally. The app offers a wide range of vehicles like CNG auto, luxury cars, or 4x4 SUV that users can choose for taking rides.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Pearl-Cab-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>On-Demand Declutter App United Kingdom </h3>
                                <p>If you are feeling too cluttered or coming through something complex, decluttering experts can help you simplify life.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/PT-you-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>SatBorsa</h3>
                                <p>SatBorsa is one of the kind currency exchange platform that is available on both platforms, iOS, and android.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Sat-Borsa-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>On-Demand Declutter App United Kingdom </h3>
                                <p>If you are feeling too cluttered or coming through something complex, decluttering experts can help you simplify life.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/SatPay-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>On-Demand Declutter App United Kingdom </h3>
                                <p>If you are feeling too cluttered or coming through something complex, decluttering experts can help you simplify life.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Student-castle-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Tawlety</h3>
                                <p>Are you a food lover who loves to taste a new recipe every day, then this app is definitely for you? Tawlety is basically a table reservation app. Tawlety allows the users to book a table at their favourite restaurant just with the help of a few taps.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Tawlety-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>On-Demand Declutter App United Kingdom </h3>
                                <p>If you are feeling too cluttered or coming through something complex, decluttering experts can help you simplify life.</p>
                              </div>  
                              <div className="case-btn">
                                <Link to className="btn btn-primaryx">Case Study</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/ysily-slider.png" alt="uptimise it" />
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section></div>
        <section className="same-section testimonial-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Testimonial</span>
              <div className="mobiles_head">
                <h4>Customers? Satisfied</h4>
                <p>Uptimise IT is all about delivering results that satisfy our clients and deliver success. Here are some words from our happy clients.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="testimonal-parent">
                  <h3>Help us improve our productivity</h3>
                  <div className="testimonal_child">
                    <div className="testimonal_img">
                      <Link to>
                        <img src="asset/images/army.png" />
                      </Link>
                    </div>
                  </div>
                  <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="testimonal-parent">
                  <h3>Help us improve our productivity</h3>
                  <div className="testimonal_child">
                    <div className="testimonal_img">
                      <Link to>
                        <img src="asset/images/army.png" />
                      </Link>
                    </div>
                  </div>
                  <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some common frequently asked questions about web and mobile app development:</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item active">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                          Why Choose Uptimise IT? 
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="accordionFlushExample" style={{}}>
                        <div className="accordion-body">
                          Uptimise IT is a globally recognized mobile app development company. We have worked with 800+ and delivered satisfactory &amp; innovative web/mobile app solutions. Our work has been realized by top tech reviewers like Clutch.co. <br />
                          Moreover, our team of developers is highly experienced and we have all the required resources along with the technical know-how needed to deliver the solution you seek. Uptimise IT can deliver mobile apps that can help your business generate millions in the market. <br />
                          So, if you are looking for this success, choose the best, choose Uptimise IT.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How Long Does It Take to Develop an App From Scratch?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="accordionFlushExample">
                        <div className="accordion-body">
                          Custom Mobile app development services create an app from scratch that is as per the preferences and needs of the client. As far as the time to market is concerned, mobile app duration highly depends on various factors like platform, tech stack, app size, and so on. <br />
                          Nevertheless, you can do anything between 2 weeks and 12 weeks for app development. If you want a closer estimate time, you can reach out to the team at Uptimise IT who can give you a closer development time based on project specifications. 
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it Cost to Develop an App?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="accordionFlushExample">
                        <div className="accordion-body">Just like the development time, the mobile app development cost highly depends on several factors like the type of app, platform, tech stack, features, and so on. So, if you want an estimated cost, you can contact our team with the details of the project. Nevertheless, you can expect anything between <strong>$15,000</strong> and <strong>$100,000.</strong> </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Do you Sign NDA?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="accordionFlushExample">
                        <div className="accordion-body">At Uptimise IT we highly care for our clients. So, to protect their unique idea before, after, and during project development, we sign an NDA or nondisclosure agreement with the client ensuring their data’s safety and full privacy.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          How Can I Hire Mobile App Developers at Uptimise IT?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="accordionFlushExample">
                        <div className="accordion-body">You can easily hire mobile app developers at Uptimise IT. All you need to do is contact us with your project needs. And we will be providing you with a dedicated developer or development team in 48 hours.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
   
    )
}