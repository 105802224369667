import React from 'react'
import AngularBigCardData from './AngularBigCardData'
import AngularBigCardWork from './AngularBigCardWork'

const AngularBigCard = () => {
  return (
    <>
    {AngularBigCardData.map((val,ind) => {
              return (
                <AngularBigCardWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )}  
  </>
  )
}

export default AngularBigCard
