import React from 'react'
import AutomationWork from './AutomationWork'
import AutomationData from './AutomationData'

const Automation = () => {
  return (
    <>
    {AutomationData.map((val,ind) => {
        return (
          <AutomationWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
       </>
  )
}

export default Automation
