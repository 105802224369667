import pro from '../images/affordable-cost-food.png'
import pro1 from '../images/fast-time-to-market-food.png'
import pro2 from '../images/agile-development-food.png'
import pro3 from '../images/latest-technology-tools-food.png'
import pro4 from '../images/transparent-process-food.png'
import pro5 from '../images/agile-development-food.png'



const FoodDelData = [
    {
        title:"Affordable Cost",
        text:   <p>Uptimise IT keeps the <strong>food delivery app development cost</strong> as low as possible. </p>,
       imgsrc: pro,  
       
    },
    {
        title:"Fast Time to Market",
        text:   <p>With our experienced development team, Uptimise IT offers fast time to market. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Scalable Food Delivery Solution",
        text:   <p>Our Food delivery app development solutions are scalable as per your needs.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Latest Technology & Tools",
        text: <p><strong>Mobile app developers</strong> at Uptimise IT only use the latest tech and tools.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Transparent Process",
        text:  <p>We believe in building trust that is why our development process is always transparent. </p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Agile Development",
        text: <p>With our agile approach to food delivery app development, we ensure timely &amp; high-quality delivery.</p>,
       imgsrc: pro5,  
    
     },

]

export default FoodDelData ;