import pro from '../../../images/ai-solutions-development-for-marketing-solutions.png'
import pro1 from '../../../images/ai-for-customer-services.png'
import pro2 from '../../../images/ai-mobile-app-development.png'
import pro3 from '../../../images/ai-chatbot-development.png'
import pro4 from '../../../images/machine-learning-solutions-development.png'
import pro5 from '../../../images/natural-language-processing.png'



const GrowthData = [
    {
        title:"AI Solutions development for Marketing Solutions",
        text:  <p>AI helps companies to make better marketing decisions by providing detailed insights into the business. With our AI-powered intelligent marketing solutions, you can understand your customers in real-time.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"AI for Customer Services",
        text:  <p>With our AI Ml development services, you can easily enhance the personalization of customer services. Moreover, it can also help you to quickly analyze the customer data for providing better services.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"AI mobile App Development",
        text: <p>This is why entrepreneurs look forward to implementing artificial intelligence in mobile app development. If you are also one of them and looking for the ins and outs of <strong > AI for app development</strong>, then read this blog.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"AI Chatbot Development",
        text: <p>As a renowned AI chatbot development company, we offer extraordinary AI chatbot development solutions. This can help your business automate different aspects of your business and improve overall productiveness.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Machine Learning Solutions Development",
        text: <p>The machine learning solutions development helps business enterprises in searching the hidden insights into their business. Our AI solutions are integrated with seamless machine learning tools and automated engineering technologies for better and faster decision-making.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Natural Language Processing(NLP)",
        text: <p>We provide AI solutions integrated with NLP technology for improved business insight extraction. NLP enhances the interaction and communications between humans and machines. Our AI solutions are integrated with NLP for the automatic extraction of critical business insights from a large amount of structured content.</p>,
       imgsrc: pro5,  
    
     },
  
   
     
     
    
]

export default GrowthData ;
