import pro from '../../images/magento-eCommerce-development-services-industries-ecommerce.png'
import pro1 from '../../images/bigcommerce-development-solutions-industries-ecommerce.png'
import pro2 from '../../images/wooCommerce-development-services-industries-ecommerce.png'
import pro3 from '../../images/eCommerce-mobile-app-solutions-industries-ecommerce.png'
import pro4 from '../../images/web-mobile-app-maintenance-service-industries-ecommerce.png'
import pro5 from '../../images/shopify-eCommerce-solution-industries-ecommerce.png'
import pro6 from '../../images/migration-and-upgradation-services-industries-ecommerce.png'
import pro7 from '../../images/magento-eCommerce-development-services-industries-ecommerce.png'


const CommerceData = [
    {
        title:"Custom eCommerce Development Services ",
        text:    <p>Uptimise IT has the right experience and technical expertise needed to deliver custom eCommerce development services to our clients. </p>,
       imgsrc: pro,  
       
    },
    {
        title:"Bigcommerce Development Solutions",
        text:   <p>Big commerce is one of the best eCommerce platforms. And being a Commerce-leading <strong>big commerce development company</strong> we offer innovative services.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"WooCommerce Development Services",
        text:  <p>Just like Bigcommerce, woocommerce is one of the most in-demand platforms. Uptimise IT offers stellar <strong>Woocommerce Development Service</strong> at affordable rates. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"eCommerce Mobile App Solutions",
        text: <p>Our team of dedicated mobile app developers are offer eCommerce mobile app development solutions that deliver value to your business and inspire growth.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Web/Mobile App Maintenance Service",
        text:  <p>In addition to a web app and mobile app development services, Uptimise IT also provides web and mobile app <strong>maintenance and support services.</strong></p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Shopify eCommerce Solution",
        text:    <p>Do you want to move your shop online with the Shopify platform? Uptimise IT offers amazing Shopify eCommerce development solutions.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Migration and Upgradation Services",
        text: <p>Don’t suffer from a legacy system, move to a better version or upgrade your current one with our web &amp; mobile app migration and upgradation services.</p>,
       imgsrc: pro6,
     
     },
     {
        title:"Magento eCommerce Development Services",
        text: <p>Uptimise IT is a leading <strong>Magento web development company.</strong> We have the much-valued experience along to deliver Commerce-leading eCommerce solutions. </p>,
       imgsrc: pro7,  
       
     },
  
   
     
     
    
]

export default CommerceData ;