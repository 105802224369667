import React from 'react'
import ReactBigCardData from './ReactBigCardData'
import ReactBigCardWork from './ReactBigCardWork'

const ReactBigCard = () => {
  return (
    <>
    {ReactBigCardData.map((val,ind) => {
              return (
                <ReactBigCardWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )}  
  </>
  )
}

export default ReactBigCard
