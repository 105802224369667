import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Success from './success/Success'
import Industry from './industry/Industry'
import Process from "./process/Process"

import pic from "../images/react-native-developers-banner-img.webp"
import pic1 from "../images/react-native-developers-small-img.webp"
import DevHead from './development/andHeader/DevHead'
import ReactBigCard from './hiredevelopers/react/reactbig/ReactBigCard'

import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"



export default function HireReact() {
    return (
      
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong>React Native Developers</strong></h1>
                  <p>Jaw-dropping user interface using React Native. Now, you can <strong>hire react native developers</strong> who are experienced and creative, with Uptimise IT.</p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>React Native Developers</span>
                  <h3>Dedicated React Native Developers For Hire</h3>
                  <p>Uptimise IT is the leading <strong>react native development company</strong> that also provides dedicated <strong>react native developers for hire.</strong> Our team of developers has years of experience where we have delivered market-leading solutions.</p>
                  <p>We use the agility and features of React Native to create the most beautiful and fluid user interface. So, if you are looking for react native UI designers who can deliver amazing solutions, you are in right place.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Developer</span>
              <div className="mobiles_head">
                <h4>The Expertise of Our React Native App Developer</h4>
                <p>When it comes to creating beautiful UI, our react native developer skills are vetted. We offer a number of react native development services, these are, as mentioned below:</p>
              </div>
            </div>
            <div className="row app-developers-parent">
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>React Native Android App Development</h3>
                  <p>Our team of react native developers offers amazing android app development and designing services. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>React Native iOS Development </h3>
                  <p>Uptimise IT also offers react native iOS and <strong>iPhone app development services</strong> that you can take advantage of.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Progressive Web App Development </h3>
                  <p>We use react native to develop and design visually engaging <strong>progressive web applications.</strong></p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>React Native Testing Services </h3>
                  <p>Testing is just as important as development and we offer stellar react native testing services.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>React Native Maintenance and Support Services </h3>
                  <p>Worried about maintenance of your react native solution? Uptimise IT offers amazing <strong>maintenance and support services.</strong></p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Custom React Native Design Services </h3>
                  <p>For those who want to develop personalized react native UI, our custom react native development can be something you want.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Choose Us</span>
              <div className="mobiles_head">
                <h4>Why Hire React Native App Developers At Uptimise IT?</h4>
                <p><strong>React native developers</strong> at Uptimise IT are known for their creativity and hold the relevant experience needed to deliver amazing UI designs. Some other reasons to hire react native developers are:</p>
              </div>
            </div>
            <div className="row">
             <ReactBigCard />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Where to Find React Native Developers?</h3>
              <p>“I am looking for React Native Developers near Me”. Your search is over. Now, you can hire react native app developers.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">There are various ways to hire react native developers with Uptimise IT. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="model-box-parent">
                  <div className="model-box-inner">
                    <figure>
                      <img src={pro} alt="uptimise it" />
                    </figure>
                    <h3>Full Time</h3>
                    <p>You can hire dedicated react native developers at Uptimise IT on full-time.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="model-box-parent change-bg-img">
                  <div className="model-box-inner">
                    <figure>
                      <img src={pro1} alt="uptimise it" />
                    </figure>
                    <h3>Part Time</h3>
                    <p>If full-time isn’t the model for you, you can also hire react native app developers on part time.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="model-box-parent">
                  <div className="model-box-inner">
                    <figure>
                      <img src={pro2} alt="uptimise it" />
                    </figure>
                    <h3>Hourly</h3>
                    <p>Hiring react native app developers on hourly bases is yet another popular option available at Uptimise IT.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Benefits of React Native Developers for Hire </h3>
                  <p>Hiring react native app developers doesn’t come without benefits. Our team helps you create a design that attracts and engage user, helping your business grow. These are just a few of the benefits you get with hiring our react native developers. Others are, as mentioned below:</p>
                  <ul>
                    <li>Affordable React Native App Development</li>
                    <li>Experienced React native developers. </li>
                    <li>Agile Development process </li>
                    <li>Integrity and Transparency</li>
                    <li>Latest tools and technology </li>
                    <li>Dedicated React Native Development </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
             <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color work-inner-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
              <Success />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion analysis-change-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Agile, Quality, Successful Digital Solutions</h4>
                <p>As a globally renowned app Development Company, Uptimise IT follows a mobile app development process that is agile. We never compromise with the quality and provide high time to market to our clients. Uptimise IT knows how to deliver Success.</p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>Industries We Serve</h3>
            <p>Uptimise IT brings your ideas to reality, we create success. Here are the industries we serve.</p>
            <Link to="#" className="btn btn-primaryx">Explore More</Link>
          </div>
        </section>
       <HomeIn />
        <section className="same-section faq-border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are some frequently asked questions about react native app developers for hire.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          How do I hire React Native app developers or team?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">If you want to want hire react native app developers or team, all you need to do is reach out to us. Uptimise IT as a leading <strong>mobile app/webdevelopment service</strong> responds to request fast and we will assign you dedicated react native developers within 42 hours. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          What our React Native developers can do for you?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Our reach native developers have years of experience and creative prowess, this allows them to deliver market-leading solutions. Some of our top solutions are, as mentioned below:
                          <ul>
                            <li>React Native UI design services </li>
                            <li>React native android development </li>
                            <li>React Native iOS development </li>
                            <li>PWA React native development services.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Can I hire a developer for hourly or project-based tasks?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT let you hire react native developers on hourly, part-time, and full-time bases. If you want, you can also hire dedicated react native developers on project-based tasks. All you need to do is reach out to us and we will assign a development team to you in 42 hours.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it cost to hire a React Native developer?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The cost to hire react native developers on hourly bases can cost anywhere between $40 and $120. If you want to know the exact cost based on your project, you can reach out to us.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Why hire dedicated developers?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          When you hire dedicated react native developers with Uptimise IT gives you many benefits. Some of these are, as mentioned below:
                          <ul>
                            <li>Affordable </li>
                            <li>Resourceful </li>
                            <li>Better results </li>
                            <li>Better control </li>
                            <li>Fast time to market.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
    )
}