import React from 'react'

import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Industry from './industry/Industry'
import Success from './success/Success'

import pic from "../images/laravel-developers-banner-img.webp"
import pic1 from "../images/laravel-developers-small-img.webp"
import DevHead from './development/andHeader/DevHead'
import LaravelBigCard from './hiredevelopers/laravel/laravelbig/LaravelBigCard'

import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"

export default function HireLaravel() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong> Laravel Developer </strong></h1>
                  <p>Now, you can <strong>Hire Laravel Developer</strong> with the right know-how and latest technology with Uptimise IT.</p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>Hire Laravel Developers </span>
                  <h3>Hire Dedicated Laravel Developers</h3>
                  <p>Hire Dedicated Laravel Developers at the most affordable cost for your next market-leading solutions. Our team of developers hasyears worth of experience and the latest resources at their disposal. This combined with the out-of-the-box thinking allows Uptimise IT.</p>
                  <p>So, if you are looking to develop unparalleled laravel development solutions, we are here to help you. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Laravel Developers</span>
              <div className="mobiles_head">
                <h4>Vetted Laravel developer HireThe Best</h4>
                <p>We have the best <strong>laravel developer hire</strong> them to develop SEO-optimized,  web solutions that outperform the competition. We offer a range of Laravel development services, these are, as mentioned below:</p>
              </div>
            </div>
            <div className="row app-developers-parent">
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Custom Laravel Development </h3>
                  <p>Hire laravel developers for custom development solutions that are built ground up for you.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>eCommerce Laravel Solutions </h3>
                  <p>with help of expert laravel developers you can develop market leading and attractive ecommerce platforms. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Laravel Web App Development </h3>
                  <p>Develop never-seen-before web applications based on excellent laravel technology. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Maintenance and Support Services </h3>
                  <p>Uptimise IT as a leading mobile app/ web development company, also provides maintenance and support services. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Laravel Migration &amp; Upgradation </h3>
                  <p>If you want to move from or upgrade your legacy system, Uptimise IT provides migration and upgradation services. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Laravel Web Development Services </h3>
                  <p>Hire laravel developers for unmatched laravel web development services in USA with Uptimise IT. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Data-Driven Laravel Services </h3>
              <p>Uptimise IT as a leading laravel app development company where you can hire vetted developers.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Laravel Experts</span>
              <div className="mobiles_head">
                <h4>Hire Laravel Experts From Uptimise IT </h4>
                <p>If you are looking for nothing but the best, you can <strong>hire laravel expert</strong> who are renowned throughout the market for their unique solutions. If you want more reasons to hire laravel developers are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
            <LaravelBigCard />
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Hire Laravel Developer Services, The Benefit </h3>
                  <p>When you <strong>hire laravel developer services,</strong> you open door to many exciting benefits. As we are a web, android, and <strong>iOS app development company,</strong> that cares for customers, you don’t have to worry about anything.</p>
                  <ul>
                    <li>Cost-Effective laravel Development</li>
                    <li>Resourceful Development Team</li>
                    <li>Dedicated Developers </li>
                    <li>Agile and transparent process </li>
                    <li>State-of-the-art development tools and technology</li>
                    <li>100% Customer Satisfaction</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section pt-0 watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">There are various ways to avail <strong>Laravel website development services.</strong> Popular modes of hiring them are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>You can <strong>hire Laravel developers</strong> on full time bases. </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro1} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>If full time is not for you, you can hire them on part time bases. </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro2} alt="uptimise it" />
                      </figure>
                      <h3>Hourly</h3>
                      <p>Hiring Laravel Developers on hourly bases is yet another popular option among clients. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section work-inner-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
             <Success />
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
              <Industry />
              <div className="view-btn">
                <Link to="#" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>Industries We Serve</h3>
            <p>Uptimise IT brings your ideas to reality, we create success. Here are the industries we serve.</p>
            <Link to="#" className="btn btn-primaryx">Explore More</Link>
          </div>
        </section>
     <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some frequently asked questions about laravel developers for hire.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Can I migrate from Existing PHP to Laravel PHP? 
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">With our migration and upgradation service, you can move from your legacy PHP solution to a better laravel PHP based solutions.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          Where are your Laravel developers located?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      </div>
                      <div className="accordion-body">Uptimise IT AppGene is a globally removed laravel development company that is based in United States of America, Untied Kingdom as well as City-state of Singapore.</div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it cost for a Laravel development application at Uptimise IT?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The cost to develop a laravel application depends on a lot of different factors. This includes: 
                          <ul>
                            <li>Location of Developer</li>
                            <li>Complexity of solution </li>
                            <li>Type of web app </li>
                            <li>Size of app</li>
                            <li>Features and UI/UX </li>
                          </ul>
                          These are some of the factors which affect the cost of laravel app development. As such, you can expect anything between <strong>$30,000  -$ 120,000.</strong> <br />
                          If you need a more precious cost estimation, you can directly contact us and we will give you a quote based on the project and your needs.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Using Laravel is FREE or requires any license for the same?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Laravel, the PHP-based platform is open-sourced meaning you don’t have to worry about anything.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much time will it take to develop the website in Laravel?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Just like the development cost, the development time depends on a lot of various factors. However, you can expect anything between 2 months to 12 months based on your project specifics.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
   
    )
}