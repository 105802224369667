import pro1 from '../images/development-icon01.svg'
import pro2 from '../images/development-icon02.svg'
import pro3 from '../images/development-icon03.png'
import pro4 from '../images/development-icon04.png'
import pro5 from '../images/development-icon05.png'
import pro6 from '../images/development-icon06.png'

const CardData = [
    {
       imgsrc: pro1, 
       title: "E-Wallet App",
       text: "Uptimise It provides unmatched e-wallet app development  services. We have developed and delivered successful  eWallet apps to clients across the globe.",
       view: "Explore Now"
    },
    {
        imgsrc: pro2, 
        title: "On-Demand App",
        text: " We are masters of on-demand mobile app development. Whether it is a food delivery app or a demand logistics mobile app, we can do it all.",
        view: "Explore Now"
     },
     {
        imgsrc: pro3, 
        title: "Fintech App",
        text: "The Fintech Mobile app industry is booming. With our mobile app development services, you can develop the next market-leading fintech app.",
        view: "Explore Now"
     },
     {
        imgsrc: pro4, 
        title: "Education/E-learning App",
        text: " Educational apps are the future of education. And with our e-learning app development services, you can become a part of tomorrow’s best.",
        view: "Explore Now"
     },
     {
         imgsrc: pro5, 
         title: "Healthcare App",
         text: "Uptimise It offers amazing healthcare app development services. If you want to develop a healthcare app of your own, we can help you.",
         view: "Explore Now"
      },
      {
         imgsrc: pro6, 
         title: "Recruitment App",
         text: "With Uptimise It recruitment app development, you can help thousands of people looking for a job.",
         view: "Explore Now"
      }
]

export default CardData;