import pro from '../../../images/best-development-approach-job.png'
import pro1 from '../../../images/enabling-ai-in-recruitment-job.png'
import pro2 from '../../../images/make-use-of-the-best-monetization-model-job.png'
import pro3 from '../../../images/faster-than-light-job.png'
import pro4 from '../../../images/security-is-the-key-job.png'
import pro5 from '../../../images/scalable-solutions-job.png'


import { Link } from 'react-router-dom'

const Weata = [
    {
        title:"Best Development Approach",
        text:  <p>We use a mix of Agile and Waterfall development methodologies. For projects that have clear, fixed requirements at the initiation stage, we prefer to use Waterfall, whereas projects that don’t have precise requirements require an Agile approach.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Enabling AI in Recruitment",
        text:  <p>We implement artificial intelligence and machine learning with our recruitment app development process to help recruiters automate recurring manual tasks, such as procuring candidates, screening CVs, and communicating to the candidates.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Make Use of the Best Monetization Model",
        text: <p>Starting from membership packages to job events ticketing, booking, and third-party advertising, we’re experienced in several monetization models for recruitment app development.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Faster than Light",
        text: <p> Our recruitment portal solutions for web and mobile apps are made using the latest technology and also tested through the same. Thus, we make sure that they are fast and offer a quick response time.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Security is the key",
        text: <p>As a leading recruitment app development company, Uptimise IT embeds the best security measures in recruitment app development solutions.</p>,
       imgsrc: pro4,  
     
     },
     {
        title:"Scalable Solutions",
        text: <p>Our job portal platform solutions are scalable as per the needs of clients.</p>,
       imgsrc: pro5,  
     
     },
     
  
   
     
     
    
]

export default Weata ;