import React from 'react'
import BankingWork from './BankingWork'
import BankingData from './BankingData'

const Banking = () => {
  return (
    <>
    {BankingData.map((val,ind) => {
        return (
          <BankingWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
       </>
  )
}

export default Banking
