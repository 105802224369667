import pro from "../../../../images/login-sign-up-job.png"
import pro1 from '../../../../images/managing-job-profile.png'
import pro2 from '../../../../images/job-search-job.png'
import pro3 from '../../../../images/job-alerts-job.png'
import pro4 from '../../../../images/uploading-jobs-job.png'
import pro5 from '../../../../images/filtering-resume-job.png'
import pro6 from '../../../../images/suggested-job-matches.png'
import pro7 from '../../../../images/job-track-job.png'






import { Link } from 'react-router-dom'


const FrstCardData = [
    {
        title:"Login/Sign Up",
        text: "In order to use the app, users or recruiters have to create an account. They can register using their email id, and phone number, or they can also use social media credentials.",
       imgsrc: pro,  
       
    },
    {
        title:"Managing Job Profile",
        text: "Here, users can add job preferences and set their current employment details such as their job type, industry, salary, and location. Users can also share their resumes on the app, or they can create a resume directly from the app.",
       imgsrc: pro1,  
       
    },
    {
        title:"Job Search",
        text: "According to the user’s preferences and settings, users can filter for available jobs by criteria, including seniority level, job role expectations, area of expertise, or location of the job role. Users can also save or bookmark jobs that they may want to apply for in the future.",
       imgsrc: pro2,  
     
     },
     {
        title:"Job Alerts",
        text: "Users can send their resumes via the app or by mail to various companies that have available job opportunities. On the employer side, recruiters can be given the ability to send job alerts to eligible candidates.",
       imgsrc: pro3,  
     
     },
     {
        title:"Uploading Jobs",
        text: "Recruiters can easily upload new available job positions onto the job portal mobile apps that have become vacant. Moreover, recruiters can also send eligible candidates invitations to apply for specific roles, and then can even use the app to schedule an interview with the candidate.",
       imgsrc: pro4,  
      
     },  
     {
        title:"Filtering Resume",
        text: "One of the most important and useful features of a recruitment app is to allow recruiters to search for specific resumes and also filter within a resume for particular skillsets or experience. Recruiters can also be enabled to download the resume of selected candidates for interviews.",
       imgsrc: pro5,  
      
     }, 
     {
        title:"Suggested Job Matches",
        text: "The job matching app can use the preferences set by the user, such as interests and skills, to automatically suggest the user job roles that fit their profile and career ambitions.",
       imgsrc: pro6,  
      
     }, 
     {
        title:"Job Track",
        text: "Users can easily keep a track of all the jobs that they have applied for within the app. Users can also manage their interview invitations and offer letters that they have received from various companies.",
       imgsrc: pro7,  
      
     }, 
   
]

export default FrstCardData ;