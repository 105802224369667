
import pro1 from '../../../images/vulnerability-management.png'
import pro2 from '../../../images/burp-suite.png'
import pro3 from '../../../images/we-use-isms.png'
import pro4 from '../../../images/rest-api-protocol03.png'


import { Link } from 'react-router-dom'


const ProtocolData = [
    {
        title:"Vulnerability Management",
        text: "Before launching your application to the public, we undertake vulnerability management to make sure the Ewallet app does not have any weak points.",
       imgsrc: pro1,  
       
    },
    {
        title:"Burp Suite",
        text: "We use Burp Suite to qualify the application security. We strive to make sure the Ewallet application is completely secure to use for all users.",
       imgsrc: pro2,  
     
     },
     {
        title:"We use ISMS",
        text: "We use ISMS (Information Security Management System) to secure the confidential and sensitive data of the app users.",
       imgsrc: pro3,  
     
     },
     {
        title:"Rest API Protocol",
        text: "To enhance the speed and security of the Ewallet application, we use the Rest protocol for the backend coding.",
       imgsrc: pro4,  
      
     },  
   
]

export default ProtocolData ;