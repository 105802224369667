import React from 'react'
import WeWork from './WeWork'
import WeData from './WeData'

const We = () => {
  return (
    <>
    {WeData.map((val,ind) => {
        return (
          <WeWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
       </>
  )
}

export default We
