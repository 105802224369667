import React from 'react'

const DevFutureWork = (props) => {
  return (
    <div className="col-lg-3">
    <div className="company-box blur-box-parent">
      <div className="company-content content-blur-box">
        <img src={props.imgsrc} alt="uptimise it" />
        <h3>{props.title}</h3>
      </div>
      <p>{props.text}</p>
    </div>
  </div>
  )
}

export default DevFutureWork
