import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Process from './process/Process'
import Service from './service/Service'
import Values from './values/Values'

import pic1 from "../images/healthcare-app-small-img.png"
import pic from "../images/healthcare-app-banner-img.png"
import pic2 from "../images/affordable-solutions-healthcare.png"
import pic3 from "../images/client-focused-solutions-healthcare.png"
import pic4 from "../images/fast-time-to-market-healthcare.png"
import pic5 from "../images/innovative-future-proof-healthcare.png"
import pic6 from "../images/safe-secure-healthcare.png"
import DevHead from "./development/andHeader/DevHead"
import Health from './trending-solutions/health/Health'
import Type from './trending-solutions/type/Type'


export default function Healthcare() {
    return (
        <div id="content">
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1>
                <strong>Innovative Healthcare App </strong>Development Company
              </h1>
              <p>
                Uptimise IT as a top
                <strong>healthcare mobile app development company</strong> is
                defined by an out-of-the-box creative, technical sound, and
                innovative approach to Healthcare development solutions.
              </p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
              <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <h3>Impactful Healthcare App Development Solutions</h3>
                  <p>
                    Hospitals, clinic, medical institutes, and the entire
                    healthcare industry plays an important role in keeping us
                    healthy and lively. But everything has changed quite a bit
                    since Covid-19.
                  </p>
                  <p>
                    Healthcare Mobile apps and software helped many people receive
                    important healthcare services from the comfort of their homes.
                    This is why; Uptimise IT is dedicated to bringing innovative
                    and practical solutions to
                    <strong>healthcare app development</strong> solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section idustries-on-demand">
          <div className="container">
            <div className="same-heading text-center">
              <span>Healthcare</span>
              <div className="mobiles_head">
                <h4>Our Healthcare App Development Services</h4>
                <p>
                  Our <strong>healthcare app development services</strong> are
                  backed by our years’ worth of experience and state-of-the-art
                  technology. This allows us to deliver services that help your
                  business grow and reach the desired level. Our services are, as
                  mentioned below:
                </p>
              </div>
            </div>
            <div className="row">
             <Health />
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Our Portfolio</h2>
                  <p>
                    Do you want more information about the innovative mobile app
                    solutions we have developed?
                  </p>
                  <Link to="#" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section technical-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Future-Proof Healthcare App Development</h4>
                <p>
                  Our team of <strong>healthcare app developers</strong> makes use
                  of the latest development technology and Industry 4.0 solutions
                  to deliver healthcare apps that are innovative. Our development
                  methods are, as mentioned below:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>High-Quality Source Code</h3>
                    <div className="panel-list">
                      <ul>
                        <li>Maintainable Code</li>
                        <li>High-quality Source Code</li>
                        <li>Full Ownership transfer</li>
                        <li>NDA</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Data-Driven Development Process</h3>
                    <div className="panel-list">
                      <ul>
                        <li>Highly Transparent Development Process</li>
                        <li>Data-Driven Healthcare Development</li>
                        <li>Big Data Integration</li>
                        <li>Top-Notch QA</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Planned Out Architecture</h3>
                    <div className="panel-list">
                      <ul>
                        <li>Strategized Architecture</li>
                        <li>Secure and Fast Solution</li>
                        <li>Monolithicvs microservices Architecture</li>
                        <li>Target–Oriented Architecture</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>High-Grade Security</h3>
                    <div className="panel-list">
                      <ul>
                        <li>High-Grade Security Solutions</li>
                        <li>Breach Protection Technology</li>
                        <li>Security first model</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section web-development-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Medical Solutions</span>
              <div className="mobiles_head">
                <h4>Types of Medical Mobile Application Development Solutions</h4>
                <p>
                  As a leading
                  <strong>healthcare application development company,</strong>
                  Uptimise IT offers a wide range of medical app development
                  services. So, you can choose the solutions that fit your
                  business needs and help you grow. Moving on, these are, as
                  mentioned below:
                </p>
              </div>
            </div>
            <div className="row">
              <Type />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Medical Apps</span>
              <div className="mobiles_head">
                <h4>Why Choose Uptimise IT?</h4>
                <p>
                  Uptimise IT is a top healthcare app development company that
                  offers top-notch healthcare app development services. Our team
                  of medical app developers offers many benefits.
                </p>
              </div>
            </div>
            <div className="industry-icon-parent">
              <ul>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Affordable Solutions</h3>
                      <p>
                        Uptimise IT doesn’t only bring innovation and creativity
                        to healthcare solutions, but also offer affordable
                        <strong>healthcare app development cost.</strong>
                      </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic2} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Client-Focused Solutions</h3>
                      <p>
                        Our solutions are not good, but the best. This is because
                        our development services are based on clients’ needs and
                        market demands.
                      </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic3} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Fast Time to Market</h3>
                      <p>
                        We follow a flexible and agile development process which
                        allows us to deliver healthcare mobile app solutions well
                        within the deadline.
                      </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic4} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Innovative &amp; Future Proof</h3>
                      <p>
                        Uptimise IT as a leading healthcare app development
                        company is dedicated to bringing innovation and making our
                        solutions future-proof.
                      </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic5} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Safe &amp; Secure</h3>
                      <p>
                        The development solutions we deliver in the healthcare
                        industry are top-notch with a high-level of safety and
                        security.
                      </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic6} alt="uptimise it" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Services</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>
                        Being the best mobile app development agency, Uptimise IT
                        offers a range of website and app development services.
                        These are, as mentioned below:
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Service />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>How We Work</h4>
                <p>
                  Our team of expert and experienced medical app developers
                  follows a well-planned out development process. This is as
                  mentioned below:
                </p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section bg-color company-box-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>
                  Uptimise IT is a mobile app development company driven by a
                  mission and working towards a vision. We have built ourselves
                  around the values which enable us to deliver unremarkable
                  results.
                </p>
              </div>
            </div>
            <div className="row">
              <Values />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Leading Healthcare App Development Company</h3>
              <p>
                As a leading healthcare app development firm, we offer affordable
                and innovative healthcare solutions.
              </p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
     <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>
                    These are answers to some of the most frequently asked
                    questions about Healthcare app development.
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          How do you build a healthcare mobile app?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Uptimise IT follows a planned out healthcare mobile app
                          development process. This allows us to deliver results
                          with amazing quality that comply with market standards.
                          Nevertheless, the process is, as mentioned below:
                          <ul>
                            <li>Planning and Analysis</li>
                            <li>UI/UX Design</li>
                            <li>Development Process</li>
                            <li>Testing &amp; Deployment</li>
                            <li>Mobile App maintenance</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How much does it cost to develop a healthcare mobile
                          app?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The cost to develop a healthcare mobile application
                          depends on several factors including the type of app,
                          its feature, design, size, and developer’s location.
                          Consequently, estimating an accurate cost isn’t
                          possible. Nevertheless, you can expect the cost to
                          develop a healthcare mobile app to range from
                          <strong>$20,000</strong> to <strong>$25,000</strong>.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          What is the use of mobile app development services in
                          the healthcare industry?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          There are several applications of mobile application
                          development services in the healthcare industry. This
                          includes:
                          <ul>
                            <li>Appointment booking app</li>
                            <li>On-demand healthcare app</li>
                            <li>Online appointment app</li>
                            <li>Prescription app</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          How long does it take to develop a health app?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Just like the development cost, the time taken by mobile
                          app developers to create a health app depends on a lot
                          of factors. But you can expect it to be anywhere between
                          4 to 12 weeks.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

   
    )
}