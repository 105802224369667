import React from "react";
import {Link} from 'react-router-dom'
import pic from "../../src/images/portfolio-main-banner-image.png"
import pic1 from "../../src/images/dafri.webp"
import pic2 from "../../src/images/medu4-portfolio-tabs-img.ca.delayed"
import pic3 from "../../src/images/pay-by-check-portfoilio-img.cc.delayed"
import pic4 from "../../src/images/satpay-portfolio-img.png"
import pic5 from "../../src/images/student-castle-portfolio-img.png"
import pic6 from "../../src/images/ysily.png"
import pic7 from "../../src/images/Nujaxx.png"
import pic8 from "../../src/images/MM-arcade.png"
import pic9 from "../../src/images/CF.png"
import pic10 from "../../src/images/Tawlety.png"
import pic11 from "../../src/images/Pearl-Cab.png"
import pic12 from "../../src/images/PT-you.png"
import pic13 from "../../src/images/Sat-Borsa.png"
import pic14 from "../../src/images/Cut.png"
import pic15 from "../images/500-Planning.png"
import pic16 from "../images/8665237_code_development_icon.png"
import pic17 from "../images/6619436_a_ab_abtest_b_seo_icon.png"
import pic19 from "../images/control_x2C__management.png"
import pic20 from "../images/app-mintenance01.png"
import Success from "./success/Success";
import Testimonial from "../components/testimonial/Testimonial";
import Reviews from "../components/reviews/Reviews";

class Ourwork extends React.Component{
    render(){
        return(
            <>
          <div id="content">
        {/* page-heading */}
        <section className="inner-section portfolio-banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="blog-inner-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-banner-heading portfolio-heads same-heading">
                    <h1 className="pb-3"><strong> Portfolio</strong></h1>
                    <p>
                      As a leading web/mobile app development company, <br />
                      Uptimise IT has developed some quite impressive solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tabs_section same-section">
          <div className="container">
            <div className="tabs_parent">
              <div className="row">
                <div className="col-lg-12">
                  <nav className="naving_tabs">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <Link className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" to="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Technologies</Link>
                      <Link className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" to="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Region</Link>
                      <Link className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" to="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Industries</Link>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      <div className="content-technologies">
                        <Link to="#" className="btn btn-primary">PHP</Link>
                        <Link to="#" className="btn btn-primary">Python</Link>
                        <Link to="#" className="btn btn-primary">Magento</Link>
                        <Link to="#" className="btn btn-primary">CRM</Link>
                        <Link to="#" className="btn btn-primary">Node.js</Link>
                        <Link to="#" className="btn btn-primary">React.js</Link>
                        <Link to="#" className="btn btn-primary">Mysql</Link>
                        <Link to="#" className="btn btn-primary">Android</Link>
                        <Link to="#" className="btn btn-primary">iOS </Link>
                        <Link to="#" className="btn btn-primary">Ionic</Link>
                        <Link to="#" className="btn btn-primary">React Native</Link>
                        <Link to="#" className="btn btn-primary">Flutter</Link>
                      </div>
                      <div className="technologies-parent">
                        <div className="container">
                          <div className="same-heading text-center">
                            <span>Portfolio</span>
                            <div className="mobiles_head">
                              <h4>We Create Success</h4>
                              <p>
                                We have worked with more than 800 clients and
                                delivered some of the best market-leading
                                solutions. Here, some of these amazing solutions
                                are shown.
                              </p>
                            </div>
                          </div>
                          <div className="port-inner">
                            <div className="row">
                              <div className="col-lg-5 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="show-portfolio-content">
                                      <div className="portfoilo-imgage-parent">
                                        <img src={pic1} alt="uptimise it" />
                                      </div>
                                      <div className="portfoilo-imgage-content">
                                        <h2>DafriBank</h2>
                                        <p>Banking with no Border!</p>
                                      </div>
                                    </div>
                                    <div className="portfolio-content-inner">
                                      <div className="country-list">
                                        <ul>
                                          <li>South Africa</li>
                                          <li>Nigeria</li>
                                        </ul>
                                      </div>
                                      <h3>
                                        DafriBank
                                        <span>Banking with no Border! </span>
                                      </h3>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>South Africa</li>
                                      <li>Nigeria</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-7 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="add-content-portfoilo">
                                      <h2>medu4</h2>
                                      <h4>Medu4 Medical Education Platform</h4>
                                      <p>
                                        A leading online medical educational
                                        platform in Japanese market, Medu4 is a
                                        perfect example of our educational
                                        solutions.
                                      </p>
                                      <Link to="#" className="btn btn-default">Case Study</Link>
                                    </div>
                                    <img src={pic2} alt="uptimise it" />
                                    <div className="portfolio-content-inner">
                                      <div className="portfolio-inner-content">
                                        <div className="country-list">
                                          <ul>
                                            <li>Japan</li>
                                            <li>Malaysia</li>
                                          </ul>
                                        </div>
                                        <h3>
                                          Medu4
                                          <span>Medu4 Medical Educational Platform
                                          </span>
                                        </h3>
                                        <Link to className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>Japan</li>
                                      <li>Malaysia</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-7 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="add-content-portfoilo">
                                      <h2>Pay By Check</h2>
                                      <h4>Multi-Currency E-wallet Mobile App</h4>
                                      <p>
                                        Pay by Check is a popular e-Wallet mobile
                                        app in the United States of America. It
                                        allows users to transfer, pay, or even
                                        exchange currency.
                                      </p>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                    <img src={pic3} />
                                    <div className="portfolio-content-inner">
                                      <div className="portfolio-inner-content">
                                        <div className="country-list">
                                          <ul>
                                            <li>USA</li>
                                            <li>Canada</li>
                                          </ul>
                                        </div>
                                        <h3>
                                          Pay By Check
                                          <span>Multi-Currency E-wallet Mobile App
                                          </span>
                                        </h3>
                                        <Link to="/pay-by-check-case-study.html" className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>USA</li>
                                      <li>Canada</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="show-portfolio-content">
                                      <div className="portfoilo-imgage-parent">
                                        <img src={pic4} />
                                      </div>
                                      <div className="portfoilo-imgage-content">
                                        <h2>SatPay</h2>
                                        <p>Ewallet Platform</p>
                                      </div>
                                    </div>
                                    <div className="portfolio-content-inner">
                                      <div className="country-list">
                                        <ul>
                                          <li>Iraq</li>
                                        </ul>
                                      </div>
                                      <h3>
                                        SatPay <span>Ewallet Platform </span>
                                      </h3>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>Iraq</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="show-portfolio-content">
                                      <div className="portfoilo-imgage-parent">
                                        <img src={pic5} />
                                      </div>
                                      <div className="portfoilo-imgage-content">
                                        <h2>Student Castle</h2>
                                        <p>Accommodation mobile app</p>
                                      </div>
                                    </div>
                                    <div className="portfolio-content-inner">
                                      <div className="country-list">
                                        <ul>
                                          <li>UK</li>
                                          <li>Singapore</li>
                                        </ul>
                                      </div>
                                      <h3>
                                        Student Castle
                                        <span>Accommodation mobile app </span>
                                      </h3>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>UK</li>
                                      <li>Singapore</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-7 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="add-content-portfoilo">
                                      <h2>Ysily</h2>
                                      <h4>Online Messaging App - UK</h4>
                                      <p>
                                        The New way of connecting to special
                                        people, Ysily. This is an online messaging
                                        mobile app in the UK that is connecting
                                        people in a better way.
                                      </p>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                    <img src={pic6} />
                                    <div className="portfolio-content-inner">
                                      <div className="portfolio-inner-content">
                                        <div className="country-list">
                                          <ul>
                                            <li>UK</li>
                                          </ul>
                                        </div>
                                        <h3>
                                          Ysily<span>Online Messaging App - UK
                                          </span>
                                        </h3>
                                        <Link to className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>UK</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-7 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="add-content-portfoilo">
                                      <h2>Nujaxx</h2>
                                      <h4>Music Streaming Platform in the UK</h4>
                                      <p>
                                        Nujax is the new way of enjoying music.
                                        This mobile app is revolutionizing the way
                                        people listen to their favourite music
                                        with a twist.
                                      </p>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                    <img src={pic7} />
                                    <div className="portfolio-content-inner">
                                      <div className="portfolio-inner-content">
                                        <div className="country-list">
                                          <ul>
                                            <li>UK</li>
                                          </ul>
                                        </div>
                                        <h3>
                                          Nujaxx
                                          <span>Music Streaming Platform in the UK
                                          </span>
                                        </h3>
                                        <Link to className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>UK</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="show-portfolio-content">
                                      <div className="portfoilo-imgage-parent">
                                        <img src={pic8} />
                                      </div>
                                      <div className="portfoilo-imgage-content">
                                        <h2>MM Arcade</h2>
                                        <p>Social Mobile App</p>
                                      </div>
                                    </div>
                                    <div className="portfolio-content-inner">
                                      <div className="country-list">
                                        <ul>
                                          <li>UK</li>
                                          <li>Ghana</li>
                                        </ul>
                                      </div>
                                      <h3>
                                        MM Arcade <span>Social Mobile App </span>
                                      </h3>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>UK</li>
                                      <li>Ghana</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="show-portfolio-content">
                                      <div className="portfoilo-imgage-parent">
                                        <img src={pic9} />
                                      </div>
                                      <div className="portfoilo-imgage-content">
                                        <h2>Curvaceous</h2>
                                        <p>Fashion Mobile App</p>
                                      </div>
                                    </div>
                                    <div className="portfolio-content-inner">
                                      <div className="country-list">
                                        <ul>
                                          <li>UK</li>
                                        </ul>
                                      </div>
                                      <h3>
                                        Curvaceous
                                        <span>Fashion Mobile App </span>
                                      </h3>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>UK</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-7 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="add-content-portfoilo">
                                      <h2>Twalety</h2>
                                      <h4>On-Demand Table Booking App</h4>
                                      <p>
                                        Are you a food lover who loves to taste a
                                        new recipe every day, then this app is
                                        definitely for you? Tawlety is basically a
                                        table reservation app.
                                      </p>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                    <img src={pic10} />
                                    <div className="portfolio-content-inner">
                                      <div className="portfolio-inner-content">
                                        <div className="country-list">
                                          <ul>
                                            <li>Oman</li>
                                          </ul>
                                        </div>
                                        <h3>
                                          Twalety
                                          <span>On-Demand Table Booking App
                                          </span>
                                        </h3>
                                        <Link to className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>Oman</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-7 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items change-portfolio-direction">
                                    <div className="add-content-portfoilo color-white-portfolio">
                                      <h2>Pearl Cab</h2>
                                      <h4>On-Demand Taxi Mobile App</h4>
                                      <p>
                                        Pearl Cab is a ride-sharing company that
                                        provides on-demand taxi services to users
                                        globally.
                                      </p>
                                      <Link to="#" className="btn btn-default">Case Study</Link>
                                    </div>
                                    <img src={pic11} />
                                    <div className="portfolio-content-inner">
                                      <div className="portfolio-inner-content">
                                        <div className="country-list">
                                          <ul>
                                            <li>Qatar</li>
                                          </ul>
                                        </div>
                                        <h3>
                                          Pearl Cab
                                          <span>On-Demand Taxi Mobile App </span>
                                        </h3>
                                        <Link to className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>Qatar</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="show-portfolio-content">
                                      <div className="portfoilo-imgage-parent">
                                        <img src={pic12} />
                                      </div>
                                      <div className="portfoilo-imgage-content">
                                        <h2>PT You</h2>
                                        <p>
                                          Healthcare and Fitness iOS mobile app
                                        </p>
                                      </div>
                                    </div>
                                    <div className="portfolio-content-inner">
                                      <div className="country-list">
                                        <ul>
                                          <li>UK</li>
                                        </ul>
                                      </div>
                                      <h3>
                                        PT You
                                        <span>Healthcare and Fitness iOS mobile app
                                        </span>
                                      </h3>
                                      <Link to="l" className="btn btn-default">Case Study</Link>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>UK</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items">
                                    <div className="show-portfolio-content">
                                      <div className="portfoilo-imgage-parent">
                                        <img src={pic13} />
                                      </div>
                                      <div className="portfoilo-imgage-content">
                                        <h2>SatBorsa</h2>
                                        <p>Currency Exchange Fintech Platform</p>
                                      </div>
                                    </div>
                                    <div className="portfolio-content-inner">
                                      <div className="country-list">
                                        <ul>
                                          <li>Iraq</li>
                                        </ul>
                                      </div>
                                      <h3>
                                        SatBorsa
                                        <span>Currency Exchange Fintech Platform
                                        </span>
                                      </h3>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>Iraq</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-7 mb-3">
                                <div className="portfolio_imges">
                                  <div className="portfolio-inner-wrapper-items change-portfolio-direction">
                                    <div className="add-content-portfoilo">
                                      <h2>CUT</h2>
                                      <h4>E-wallet Mobile App</h4>
                                      <p>
                                        CUT isan e-wallet mobile application that
                                        available in China and Myanmar. It works
                                        well with both RMB and MMK currencies.
                                      </p>
                                      <Link to className="btn btn-default">Case Study</Link>
                                    </div>
                                    <img src={pic14} />
                                    <div className="portfolio-content-inner">
                                      <div className="portfolio-inner-content">
                                        <div className="country-list">
                                          <ul>
                                            <li>Myanmar</li>
                                            <li>Thailand</li>
                                          </ul>
                                        </div>
                                        <h3>
                                          CUT <span>E-wallet Mobile App </span>
                                        </h3>
                                        <Link to className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="porfolio-details">
                                    <ul>
                                      <li>Myanmar</li>
                                      <li>Thailand</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section portfolio-cta">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="cta_heading">
                  <h3>Let’s Discuss The Next Big Idea?</h3>
                  <p>
                    Bring your Idea to us, we can create it into a working mobile
                    app.
                  </p>
                </div>
                <div className="portfolio_cta_btn">
                  <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Our Web/App Development Process</h4>
                <p>
                  As a leading Mobile App development company in USA and UK,
                  Uptimise IT follows a well-planned development process for the
                  angular process. Our development process is, as mentioned below:
                </p>
              </div>
            </div>
            <div className="row process_work">
              <div className="col-lg-5">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic15} />
                    <h3>1</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Analysis &amp; Planning</h3>
                    <p>
                      The first step in developing ground-breaking solutions is
                      planning and analysis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3 className="text-right">UI/UX Design</h3>
                    <p>
                      Our team of designers creates an interactive and
                      aesthetically pleasing UI/UX design.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>2</h3>
                    <img src={pic16} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic17} />
                    <h3>3</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Development Process</h3>
                    <p>
                      Our team of developers uses the latest technologies, and
                      technical know-how to create your idea into reality.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Testing</h3>
                    <p>
                      Once the app is developed, it is tested by our QA team to
                      make sure it is up to the mark.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>4</h3>
                    <img src={pic20} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic19} />
                    <h3>5</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Maintenance &amp; Support</h3>
                    <p>
                      Uptimise IT along with web app development services also
                      provides maintenance and support services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color work-inner-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>
                  Choose Uptimise IT because you deserve nothing but the best. As
                  the leading mobile app development agency, we have years of
                  experience and a proven track record to back it.
                </p>
              </div>
            </div>
            <div className="row">
             <Success />
            </div>
          </div>
        </section>
        <section className="same-section testimonial-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Testimonial</span>
              <div className="mobiles_head">
                <h4>Customers? Satisfied</h4>
                <p>
                  Uptimise IT is all about delivering results that satisfy our
                  clients and deliver success. Here are some words from our happy
                  clients.
                </p>
              </div>
            </div>
            <div className="row">
            <Testimonial />
            </div>
          </div>
        </section>
        <section className="same-section bg-color blank-wrapper-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Uptimise IT’s Reviews</span>
              <div className="mobiles_head">
                <h4>Deemed The Best App Developers</h4>
                <p>
                  Uptimise IT has been deemed the top mobile app development
                  company by well-known critics and tech reviewers.
                </p>
              </div>
            </div>
            <div className="row">
              <Reviews />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>
                    Here are answers to some common frequently asked questions
                    about web and mobile app development:
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Why Choose Uptimise IT?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Uptimise IT is a globally recognized mobile app
                          development company. We have worked with 800+ and
                          delivered satisfactory &amp; innovative web/mobile app
                          solutions. Our work has been realized by top tech
                          reviewers like Clutch.co. <br />
                          Moreover, our team of developers is highly experienced
                          and we have all the required resources along with the
                          technical know-how needed to deliver the solution you
                          seek. Uptimise IT can deliver mobile apps that can help
                          your business generate millions in the market.
                          <br />
                          So, if you are looking for this success, choose the
                          best, choose Uptimise IT.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How Long Does It Take to Develop an App From Scratch?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Custom Mobile app development services create an app
                          from scratch that is as per the preferences and needs of
                          the client. As far as the time to market is concerned,
                          mobile app duration highly depends on various factors
                          like platform, tech stack, app size, and so on. <br />
                          Nevertheless, you can do anything between 2 weeks and 12
                          weeks for app development. If you want a closer estimate
                          time, you can reach out to the team at Uptimise IT who
                          can give you a closer development time based on project
                          specifications.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it Cost to Develop an App?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Just like the development time, the mobile app
                          development cost highly depends on several factors like
                          the type of app, platform, tech stack, features, and so
                          on. So, if you want an estimated cost, you can contact
                          our team with the details of the project. Nevertheless,
                          you can expect anything between
                          <strong>$15,000</strong> and <strong>$100,000.</strong>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Do you Sign NDA?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          At Uptimise IT we highly care for our clients. So, to
                          protect their unique idea before, after, and during
                          project development, we sign an NDA or nondisclosure
                          agreement with the client ensuring their data’s safety
                          and full privacy.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          How Can I Hire Mobile App Developers at Uptimise IT?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          You can easily hire mobile app developers at Uptimise
                          IT. All you need to do is contact us with your project
                          needs. And we will be providing you with a dedicated
                          developer or development team in 48 hours.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
        </>
        );
    }
}


export  default  Ourwork;