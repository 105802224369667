import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import DevHead from './development/andHeader/DevHead'
import Industry from './industry/Industry'
import Success from './success/Success'

import pic from "../images/php-developers-banner-img.webp"
import pic1 from "../images/php-developers-small-img.webp"
import PhpBigCard from './hiredevelopers/php/phpbig/PhpBigCard'

import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"

export default function HirePhp() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong>PHP Developers</strong></h1>
                  <p><strong>Hire PHP Developer</strong> to develop the market-leading website with aesthetically pleasing design and fluid functionality.</p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>PHP Developers</span>
                  <h3>Hire Dedicated PHP Developers</h3>
                  <p>When it comes to web and <strong>web app development,</strong> PHP snatches the top spot. More than 90% of the websites on the internet are built using some form of PHP programming language.</p>
                  <p>Now, if you want to <strong>create a website</strong> of your own that helps your business outperform the competition. With Uptimise IT, a leading <strong>PHP development company,</strong> you can <strong>hire dedicated PHP developers</strong> who can deliver just that. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire PHP Developers</span>
              <div className="mobiles_head">
                <h4>Experienced PHP Developers For Hire</h4>
                <p>Uptimise IT offers <strong>PHP developers for hire</strong> with right experience and creative approach. You can avail a range of PHP development solutions that fits your needs:</p>
              </div>
            </div>
            <div className="row app-developers-parent">
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>PHP Web Development Service</h3>
                  <p>Uptimise IT offers amazing PHP web development services through which you can bring your idea to reality.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>PHP-Based CMS Development</h3>
                  <p>Develop a compound content management system with PHP for your website and improve customer experience.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Custom PHP Website Development</h3>
                  <p>You can <strong>hire PHP coders</strong> for custom PHP website development services and create market-leading websites.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>PHP Support and Maintenance Serviecs</h3>
                  <p>Uptimise IT also offer amazing web maintenance and support services to ensure consistent performance.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>E-Commerce Development</h3>
                  <p>With <strong>CakePHP development services</strong> you can develop an eCommerce website solution that helps you grow.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>PHP Upgradation and Migration</h3>
                  <p>If you want to move to a <strong>Codelgniter development solution</strong> or upgrade, we also provide upgradation and migration services.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>PHP Programmers</span>
              <div className="mobiles_head">
                <h4>Hire PHP Programmers, Hire Uptimise IT</h4>
                <p>Uptimise IT has a proven track record and always goes the extra mile to deliver value to clients. If you need more reasons to hire the best PHP developers, a few are mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <PhpBigCard />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Market Leading Web Development Services</h3>
              <p>Uptimise IT provide amazing web and web app development services. Explore them now.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">As a leading <strong>PHP web app development company,</strong> we offer several different methods when it comes to hiring developers. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>With Uptimise IT, you can <strong>hire full time PHP developer.</strong></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro1} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>If full time is not for you, you can hire them on part time bases.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro2} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>Hiring PHP Developers on hourly bases is yet another popular option among clients.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color work-inner-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
              <Success />
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
              <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>Industries We Serve</h3>
            <p>Uptimise IT brings your ideas to reality, we create success. Here are the industries we serve.</p>
            <Link to="#" className="btn btn-primaryx">Explore More</Link>
          </div>
        </section>
        <section className="same-section i-phone-banner p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Benefits of PHP Programmer For Hire</h3>
                  <p>Choosing the right <strong>PHP programmers for hire</strong> is quite important to ensure the success of your mobile app. This is where Uptimise IT comes in. We deliver value and success driving solutions for your business.</p>
                  <ul>
                    <li>Cost-Effective PHP Development</li>
                    <li>Resourceful Development Team </li>
                    <li>Dedicated Developers </li>
                    <li>Agile and transparent process </li>
                    <li>State-of-the-art development tools and technology</li>
                    <li>100% Customer Satisfaction</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
       <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some frequently asked questions for people look to <strong>hire PHP programmers.</strong></p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Will I have complete control over the hired PHP developers?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">With a dedicated PHP development team, you have complete control over the team you hire. Plus, they will be giving you insight and a report of the work they are carrying on.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          What is your pricing and hiring engagement model for the PHP project?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          You can hire PHP developers on various modes which include:
                          <ul>
                            <li>Hire Full Time PHP Developers</li>
                            <li>Hire Part Time PHP Developers</li>
                            <li>Hire Hourly PHP Developers</li>
                          </ul>
                          As far as the price to hire PHP Developers is concerned, you can contact us with your project estimate and we will provide you a quote.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          What would be the estimated cost for hiring PHP developers?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The cost to develop PHP solutions depends on various different factors. This may include: 
                          <ul>
                            <li>Location of the developers</li>
                            <li>Tech Stack </li>
                            <li>Type of Solution</li>
                            <li>Design </li>
                            <li>Complexity</li>
                          </ul>
                          While it is not possible to predict the actual price, we can say that the cost for hiring PHP developers can be anywhere between $20,000 and $300,000. 
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          What are the types of software applications that can be developed with PHP?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Uptimise IT as a leading <strong>mobile app development</strong> companyoffers a range of PHP solutions. This includes:
                          <ul>
                            <li>PHP Upgradation and Migration</li>
                            <li>E-Commerce Development</li>
                            <li>PHP Support and Maintenance Services </li>
                            <li>Custom PHP Website Development </li>
                            <li>PHP-Based CMS Development</li>
                            <li>PHP Web Development Service</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
   
    )
}