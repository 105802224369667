import React from 'react'

const SuccessWork = (props) => {
  return (
    <div className="col-lg-3">
                  <div className="work_parent">
                    <div className="work_icon">
                      <img src={props.imgsrc} alt="image" />
                    </div>
                    <div className="work-content">
                      <p>{props.text}</p>
                      <h3>{props.percent}</h3>
                    </div>
                  </div>
                </div>
  )
}

export default SuccessWork;
