import React from 'react'
import { NavLink } from 'react-router-dom'


const Cardpath = (props) => {
  return (
   
       <div className="col-lg-4">
                      <div className="Specialities-content-parent">
                        <div className="right-arrow">
                          <i className="fa fa-arrow-right" aria-hidden="true" />
                        </div>
                        <figure>
                          <img src={props.imgsrc} alt="image" />
                        </figure>
                        <h3>{props.title}</h3>
                        <p>
                         {props.text}
                        </p>
                        <NavLink to="/" className="btn btn-primaryx" style={{color: 'white'}}>{props.view}</NavLink>
                      </div>
                    
    </div>

  )
}

export default Cardpath
