import React from 'react'
import LogiData from './LogiData'
import LogiWork from "./LogiWork"

const Logi = () => {
  return (
    <>
    {LogiData.map((val,ind) => {
      return (
        <LogiWork
        key={ind}
        title={val.title}
        imgsrc={val.imgsrc}
        text={val.text}
        />
      )
     } )}  
  </>
  )
}

export default Logi
