import pro1 from '../../images/company-icon01.png'
import pro2 from '../../images/company-icon02.png'
import pro3 from '../../images/company-icon03.png'
import pro4 from '../../images/company-icon04.png'

const ValuesData = [
    {
        title: "mission",
       imgsrc: pro1,  
       text: "  Uptimise It aims to improve the financial sector with innovative fintech apps, faster transactions, and bettersecurity."
    },
    {
        title: "Vision",
        imgsrc: pro2,    
        text: "  Our Vision is to contribute to the digital economy while delivering outstanding mobile app/website solutions."
     },
     {
        title: "Goal",
        imgsrc: pro3, 
        text: " Our goal in the year 2022 is to deliver better than ever innovative digital solutions to over clients."
     },
     {
        title: "Success",
        imgsrc: pro4,   
        text: "  For Uptimise It, Success means client satisfaction and growth driving solutions that help you reach your goal."
     },
    
]

export default ValuesData;