import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import DevHead from './development/andHeader/DevHead'
import Industry from './industry/Industry'

import pic from "../images/python-developers-banner-img.webp"
import pic1 from "../images/python-developers-samll-img.webp"
import PythonBigCard from './hiredevelopers/python/pythonbig/PythonBigCard'
import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"

export default function HirePython() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong>Python Developers</strong></h1>
                  <p>Now, you can <strong>Hire Python Developers</strong> and develop your own python based web solution that attracts customers while generating revenue.</p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>Python Developers</span>
                  <h3>Experienced Python Programmers for Hire</h3>
                  <p>Python is one of the most popular high-level programming languages with huge potential. It is used to develop websites, web apps, AI &amp; ML solutions, and many more.</p>
                  <p>So, if you want to develop a market-leading application, at Uptimise IT you can find <strong>Python programmers for hire.</strong> Our team of developers is highly experienced with right tools. So, we can deliver the python solution that generates value for your business.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Dedicated Developers</span>
              <div className="mobiles_head">
                <h4>Data-Driven Solutions from Best Python Coders</h4>
                <p>Every business have different needs and that is why we deliver various web development solutionsbased on Python. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row app-developers-parent">
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Python Web App Development</h3>
                  <p>You can hire python developers for developing web application that fit your business needs.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Custom Python Development solutions </h3>
                  <p>With help of our experienced team, you can avail custom python web development solutions.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Python based AI &amp; ML solutions </h3>
                  <p>We also provide python backed Machine learning <strong>Artificial Intelligence app development solutions.</strong></p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Maintenance and Support services </h3>
                  <p>Uptimise IT offer market leading mobile app maintenance and support services.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Python Migration and Upgradation </h3>
                  <p>If you want to upgrade from your legacy system or migrate from the same, we can help you.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>SaaS Development Solutions </h3>
                  <p>Uptimise IT also provides amazing python <strong>SaaS development solutions.</strong></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Python Programmers</span>
              <div className="mobiles_head">
                <h4>Why Hire Dedicated Python Developers?</h4>
                <p>Uptimise IT is all about delivering value to the customer. And if you are looking for reasons to hire dedicated python developers, some are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <PythonBigCard />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Data-Driven Python Services</h3>
              <p>Uptimise IT as a leading Python development company where you can hire vetted developers.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">As a leading <strong>python development company,</strong> we offer several different methods when it comes to hiring developers. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="model-box-parent">
                  <div className="model-box-inner">
                    <figure>
                      <img src={pro} alt="uptimise it" />
                    </figure>
                    <h3>Full Time</h3>
                    <p>You can hire pythondeveloper on full time bases.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="model-box-parent change-bg-img">
                  <div className="model-box-inner">
                    <figure>
                      <img src={pro1} alt="uptimise it" />
                    </figure>
                    <h3>Part Time</h3>
                    <p>If full time is not for you, you can hire them on part time bases.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="model-box-parent">
                  <div className="model-box-inner">
                    <figure>
                      <img src={pro2} alt="uptimise it" />
                    </figure>
                    <h3>Hourly</h3>
                    <p>Hiring Python on hourly bases is yet another popular option among clients.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Benefits of Python Developers For Hire</h3>
                  <p>Our team of <strong>python developers for hire</strong> comes with many benefits. For us, you are the center of focus and this is why we go far and wide to deliver value to the customer. So, other benefits that you get with us are, as mentioned below:</p>
                  <ul>
                    <li>Cost-Effective Python Development</li>
                    <li>Resourceful Development Team </li>
                    <li>Dedicated Developers </li>
                    <li>Agile and transparent process </li>
                    <li>State-of-the-art development tools and technology</li>
                    <li>100% Customer Satisfaction</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
              <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>

        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>Industries We Serve</h3>
            <p>Uptimise IT brings your ideas to reality, we create success. Here are the industries we serve.</p>
            <Link to="#" className="btn btn-primaryx">Explore More</Link>
          </div>
        </section>
       <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here, you can find the answer to some frequently asked questions about top <strong>python developers.</strong> </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Can you help me build my website using Python?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT is always here to help you. Now, you can <strong>hire python coder</strong> with us, who has years of experience developing and deploying amazing solutions in the market.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How much will it cost to build a python based app?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The average cost to develop a python app can differ based on the various factors involved. Nevertheless, you can expect anything between <strong>$30,000 </strong> and <strong>$300,000.</strong> </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Will I have complete control over the hired Python developers?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Each project receives a dedicated team of python developers. So, you have complete control over hired python coders.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Can I develop mobile apps using Python?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">If you want to develop mobile apps using python, all you need to do is reach out to hire. At Uptimise IT, you can <strong>hire python programmer</strong> that will do it for you.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
     </div>
   
    )
}