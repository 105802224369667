import React from 'react'
import TouchWork from './TouchWork'
import TouchData from './TouchData'


const Touch = () => {
  return (
  <>
{TouchData.map((val,ind) => {
                return (
                  <TouchWork
                  key={ind}
                  text={val.text}
                  num={val.num}
                  />
                )
               } )}  
  </>
  )
}

export default Touch
