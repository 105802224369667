import pro from '../../../images/innovative-solutions-automotives.png'
import pro1 from '../../../images/timely-delivery-automotives.png'
import pro2 from '../../../images/experienced-team-automotives.png'
import pro3 from '../../../images/proven-performance-automotives.png'
import pro4 from '../../../images/development-maintenance-automotives.png'
import pro5 from '../../../images/affordable-automotives.png'



const SoftwareData = [
    {
        title:"Innovative Solutions",
        text:  <p>We imbed our robust automotive software with innovation that drives success.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Timely Delivery",
        text:  <p>Your time is important, that’s why our solutions are delivered within the deadline.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Experienced Team",
        text:  <p>Uptimise IT has a team of experienced software and <strong>web developers.</strong> </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Proven Performance",
        text: <p>We have delivered more than 800 successful projects to clients across the globe.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Development & Maintenance",
        text:  <p>Uptimise IT is with you in development and we are with you after development. </p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Affordable",
        text:  <p>Our top-notch automotive software arenot only innovative but also affordable.</p>,
       imgsrc: pro5,  
    
     },

]

export default SoftwareData ;