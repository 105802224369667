import pro1 from '../../../../images/agile-development-proces-python.webp'
import pro2 from '../../../../images/complete-transparency-python.webp'
import pro3 from '../../../../images/dedicated-python-developers-python.webp'
import pro4 from '../../../../images/latest-tools-technologies-python.webp'
import pro5 from '../../../../images/experince-expertise-python.webp'
import pro6 from '../../../../images/proven-work-python.webp'


const PythonBigCardData = [
    {
        title:"Agile Development Proces",
        text:  <p>Uptimise IT has the <strong>best python coders</strong> who follow the agile development process. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Complete transparency",
        text:   <p>Our team of Python developers maintains complete transparency throughout the development process. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Dedicated Python Developers",
        text:    <p>When you hire python developers, you get a team of dedicated developers working on your project</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Latest Tools & Technologies",
        text: <p>To make sure our solutions are upto mark, we only use the best and latest technologies.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Experince & Expertise",
        text:    <p>Uptimise IT AppGeince provider experienced <strong>python developers for hire</strong> who are experts in their field. </p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Proven Work",
        text:   <p>Our work is proven and recognized by top tech reviewers like Clutch.co. So, you don’t need to worry about anything.</p>,
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default PythonBigCardData ;