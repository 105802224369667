import React from 'react'
import MaintainWork from './MaintainWork'
import MaintainData from './MaintainData'

const Maintain = () => {
  return (
    <>
       {MaintainData.map((val,ind) => {
                return (
                  <MaintainWork
                  key={ind}
                  title={val.title}
                  imgsrc={val.imgsrc}
                  text={val.text}
                  />
                )
               } )}  
    </>
  )
}

export default Maintain
