import React from 'react'
import PhpBigCardData from './PhpBigCardData'
import PhpBigCardWork from './PhpBigCardWork'

const PhpBigCard = () => {
  return (
    <>
    {PhpBigCardData.map((val,ind) => {
              return (
                <PhpBigCardWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )}  
  </>
  )
}

export default PhpBigCard
