import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import DevHead from './development/andHeader/DevHead'
import Industry from './industry/Industry'
import Success from './success/Success'

import pic from "../images/meganto-developers-banner-img.webp"
import pic1 from "../images/meganto-developers-small-img.webp"
import MagentoBigCard from './hiredevelopers/magento/Magentobig/MagentoBigCard'

import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"



export default function HireMagento() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong> Magento Developer</strong></h1>
                  <p><strong>Hire Magento Developer</strong> who will help you develop market-leading solution and deliver value to your business.</p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
              <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>Mangento Developers</span>
                  <h3>Experinced Magento Developers for Hire</h3>
                  <p>Magento is a leading eCommerce platform that host a large number of web stores. And in this age of eCommerce, you can highly benefit from eCommerce solutions.</p>
                  <p>Thus, if you are looking to <strong>hire a magento developer</strong> who can help you take your business to next level, you have come to the right place. Uptimise IT is a market-leading <strong>Magento development company</strong> with years of experience and the right expertise. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Dedicated Developers </span>
              <div className="mobiles_head">
                <h4>Expert Magento Developer For Hire </h4>
                <p>Uptimise IT houses vetted magento developers for hire. Our team along with the right experience and know how are also highly creative and out-of-the-box thinker. Moving on, our top Magento developers are, as mentioned below:</p>
              </div>
            </div>
            <div className="row app-developers-parent">
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Magento Web Development</h3>
                  <p>Our team of developer deliversgroundbreaking web development solutions using Magento. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Custom Magento Development </h3>
                  <p>If you want a personalized magento solution, our custom development service is for you.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Magento Support and Maintenance Services </h3>
                  <p>Uptimise IT has <strong>best Magento Developers</strong> who deliver amazing support and maintenance services.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Magento eCommerce Development Solutions </h3>
                  <p>We provide excellent <strong>eCommerce development services</strong> using remarkable tech Magento. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Legacy System Migration &amp; Upgradation </h3>
                  <p>We can help you move to the better version or upgrade the existing legacy system. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Magento Extension Development </h3>
                  <p>You can <strong>hire magento 2 developers</strong> who will deliver extensions that fulfill your needs.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Dedicated Developers</span>
              <div className="mobiles_head">
                <h4>Hire Magento Developers Who Deliver Success </h4>
                <p>As a leading mobile and <strong>web app development company,</strong> Uptimise IT offers amazing Magento development solutions. If you are wondering why you should choose us, here are some reasons. </p>
              </div>
            </div>
            <div className="row">
              <MagentoBigCard />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Data-Driven Magento Development Company</h3>
              <p>Uptimise IT is a globally recognizedMagento development company that delivers scalable, futuristic, and innovative solutions.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">There are various ways to avail <strong>Magento website development services.</strong> Popular modes of hiring them are, as mentioned below: </p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>You can hire Magento developers on full time bases.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro1} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>If full time is not for you, you can hire them on part time bases.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro2} alt="uptimise it" />
                      </figure>
                      <h3>Hourly</h3>
                      <p>Hiring Magento Developers on hourly bases is yet another popular option among clients</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Here is How to Hire Magento Developer</h3>
                  <p>Wondering <strong>How to Hire Magento Developer?</strong> All you need to do is reach out to us and we will take care of everything else. A team of dedicated Magento developers will be assigned to you for 42 hours. There are more benefits you get when you work with us:</p>
                  <ul>
                    <li>Cost-Effective Magento Development</li>
                    <li>Resourceful Development Team </li>
                    <li>Dedicated Developers </li>
                    <li>Agile and transparent process </li>
                    <li>State-of-the-art development tools and technology</li>
                    <li>100% Customer Satisfaction</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
             <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section work-inner-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
              <Success />
            </div>
          </div>
        </section>
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>Industries We Serve</h3>
            <p>Uptimise IT brings your ideas to reality, we create success. Here are the industries we serve.</p>
            <Link to="#" className="btn btn-primaryx">Explore More</Link>
          </div>
        </section>
   <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some of the most frequently asked questions for people looking to hire magento designers. </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          What is your Hiring Process for Magento Developers?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The hiring process for magento developer is quite simple. All you need to do is reach out to us, and we will guide you through the process. A team of dedicated magento developers will be assigned to your developer with 42 hours. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          Where can I find certified Magento eCommerce programmers?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">If you are looking for certified magento eCommerce progammers, you have come to the right place. Uptimise IT is a leading eCommerce development companythat houses a team of highly knowledgeable and certified Magento eCommerce developers. So, if this is what you are looking for, Uptimise IT is here to help you.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it cost for a Magento project?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The cost for Magento project depends on several different factors. Nevertheless, you can expect anything between <strong>$50,000</strong> to <strong>$500,000</strong> dollar based on specifications and your preferences. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Is Magento a great e-commerce platform for businesses?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Magento is one of the best options when it comes to choosing an eCommerce platform. In fact, this is a great alternative to the likes of WordPress and Sportify. So, if you are looking for an eCommerce development platform, Magento can be a good choice. </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>

   
    )
}