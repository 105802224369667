import pro1 from '../../images/agile-development-process-industries-ecommerce.png'
import pro2 from '../../images/code-revamping13.png'
import pro3 from '../../images/app-testing002.png'
import pro4 from '../../images/gain-control-over-the-assets-logistic.png'
import pro5 from '../../images/android-app-testing.webp'


const ProcessData = [
    {
         imgsrc: pro1,
         text: "Everything Starts with an Idea and a Plan.:",
         title:"Analysis and Planning",
         num: " 01",
    },
    {
        imgsrc: pro2,
        text: " Our creative designers create interactive and beautiful UI/UX.",
        title: "UI/UX Design",
        num: "02"
     },
     {
        imgsrc: pro3,
        text: "Our app developers convert your creative idea into a working app",
        title:"Development Process",
        num:"03"
     },
     {
        imgsrc: pro4,
        text: " We test the app for any possible bugs and errors before it is deployed.",
        title:"Testing",
        num: "04"
     },
     {
        imgsrc: pro5,
        text: "Uptimise It offers mobile app maintenance and Support to ensure the app’s good performance.",
        title:"Maintenance and Support",
        num: "05"
     },
    
]

export default ProcessData ;