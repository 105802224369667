import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import pic from "../images/ui-banner-img.png"
import pic1 from "../images/UIUX-design-Company.png"
import pic2 from "../images/front-end-developmentuxui.png"
import pic3 from "../images/interface-designuxui.png"
import pic4 from "../images/user-experience-designuxui.png"
import pic5 from "../images/custom-web-designuxui.png"
import pic6 from "../images/responsive-layoutuxui.png"
import pic7 from "../images/mobileuxui.png"
import pic8 from "../images/iphone-app-developer.png"
import pic9 from "../images/clock-icon01.svg"
import pic10 from "../images/clock-icon02.svg"
import pic11 from "../images/clock-icon03.svg"
import pic12 from "../images/500-Planning.png"
import pic13 from "../images/8665237_code_development_icon.png"
import pic14 from "../images/6619436_a_ab_abtest_b_seo_icon.png"
import pic15 from "../images/6619436_a_ab_abtest_b_seo_icon.png"
import pic16 from "../images/control_x2C__management.png"
import Industry from "./industry/Industry"
import Success from "./success/Success"
import DevHead from "./development/andHeader/DevHead"
import Design from './development/design/Design'

export default function UiUx() {
    return (
        <div id="content">
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Humane UI/UX Design Company</strong></h1>
              <p>A UI/UX Design Company that creates aesthetic, responsive, captivating designs for people.Uptimise IT, the best “UI/UX Design Company near me”.</p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>UI/UX Agency</span>
                  <h3>UI/UX Design Studio That Creates Success</h3>
                  <p>Uptimise IT is a UX <strong>UI design Firm</strong> driven by stellar creativity and some of the best minds in the fields. Our team works to create designs that attract and engage a potential customer base. As a UI <strong>UX Design Company</strong> that has been to the best place inthe market, we ensure designs that are second to none. Our UI/UX design solutions help you reach your business goal while being aesthetically pleasing and responsive.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color steler-design-section">
          <div className="container">
            <div className="demaond-content same-heading text-center">
              <span>Design</span>
              <h3>Our UI/UX Design Solutions</h3>
              <p className="text-center">As a UI <strong>UX development company</strong> with years of experience and always asking “What If”, Uptimise IT is all ready to help climb the ladder. We offer a number of services which are, as mentioned below:</p>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="steller-box-parent">
                  <figure>
                    <img src={pic2} alt="uptimise it" />
                  </figure>
                  <div className="steller-content">
                    <h3>Front-end <br /> Development </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="steller-box-parent">
                  <figure>
                    <img src={pic3} alt="uptimise it" />
                  </figure>
                  <div className="steller-content">
                    <h3>Interface <br /> Design (UI) </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="steller-box-parent">
                  <figure>
                    <img src={pic4} alt="uptimise it" />
                  </figure>
                  <div className="steller-content">
                    <h3>User Experience <br /> Design (UX) </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="steller-box-parent">
                  <figure>
                    <img src={pic5} alt="uptimise it" />
                  </figure>
                  <div className="steller-content">
                    <h3>Custom Web <br /> Design</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="steller-box-parent">
                  <figure>
                    <img src={pic6} alt="uptimise it" />
                  </figure>
                  <div className="steller-content">
                    <h3>Responsive <br /> Layout</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="steller-box-parent">
                  <figure>
                    <img src={pic7} alt="uptimise it" />
                  </figure>
                  <div className="steller-content">
                    <h3>Mobile UI/UX</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section technology-section steller-prototype">
          <div className="container-fluid">
            <div className="same-heading text-center">
              <span>UI/UX Design Company</span>
              <div className="mobiles_head">
                <h4>How We Create Aesthetic Designs</h4>
                <p>Our team of designers is driven by creativity backed by their extensive knowledge of the field. And this is how they create the best UI/UX designs:</p>
              </div>
            </div>
            <div className="row">
              <Design />
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pic8} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Benefits of Hiring UX/UI Design Firm </h3>
                  <p>When you work with the best UI/UX design company, Uptimise IT, you open door to many amazing benefits. As we believe in creating relationships, Uptimise IT providesthe best possible solutions to clients.</p>
                  <ul>
                    <li>Cost-Effective UI/UX design</li>
                    <li>Aesthetic Design </li>
                    <li>Integrity and Transparency</li>
                    <li>Best Resource Management</li>
                    <li>Optimized Development Cycle</li>
                    <li>State-Of-The-Art Latest Tools</li>
                    <li>Dedicated UI/UX Designer Team</li>
                    <li>100% Client Satisfaction</li>
                    <li>Customer-Centric Services</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section pt-0 watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">Uptimise IT being a leading UI/UX design company offers several flexible options to our designers. These are, as mentioned below: </p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic9} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>With Uptimise IT, you can hire best UI/UX designer on full time bases.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic10} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>If you don’t want to hire developers on full time bases, there is also an option for part time.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic11} alt="uptimise it" />
                      </figure>
                      <h3>Hourly </h3>
                      <p>Now, you can Hire UI/UX Designers of hourly bases, a popular option among clients.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Work With The Best UI/UX design company	</h2>
                  <p>Now, you can hire the best user experience design company . </p>
                  <Link to="#" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section process-parent webdesign-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Our UI/UX Design Process </h4>
                <p>As one of the best UI/UX design companies, Uptimise IT follows a well-planned out process ensuring quality and consistency in the solution. The process is, as mentioned below:</p>              </div>
            </div>
            <div className="row process_work">
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic12} alt="uptimise it"/>
                    <h3>1</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Define Product </h3>
                    <p>As a leading UI UX agency, the first thing we do is define a product that we are working on. This enables us to understand what we are working on and what customer wants. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3 className="text-right">Research</h3>
                    <p>Once we have defined the product, we start conducting in-depth research that gives us the valuable insight required to design attractive and engaging designs. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>2</h3>
                    <img src={pic13} alt="uptimise it"/>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic14} alt="uptimise it"/>
                    <h3>3</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Analysis</h3>
                    <p>The analysis part involves creating a user persona, making user stories, storyboards, and a lot of things to make the design humane and interesting.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>UI/UX Design </h3>
                    <p>Once everything is done, it's time to put together different components and create the final User interface and user experience design.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>4</h3>
                    <img src={pic15} alt="uptimise it"/>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic16} alt="uptimise it"/>
                    <h3>5</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Testing</h3>
                    <p>Once the design is finished it is tested by our QA team to make sure it is up to your expectations and the market standards.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>A Design that Represents Your Business</h3>
            <p>Uptimise IT as a leading UI design firm creates a design that represents your goals and business spirit.</p>
            <Link to="#" className="btn btn-primaryx">Explore More</Link>
          </div>
        </section>
       
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
             <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
      <HomeIn />
        <section className="same-section bg-color work-inner-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
              <Success />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>These are answers to some frequently asked questions about UX/UX Design Services:</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          What kind of UI/UX design services do you offer?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          As a leading UI/UX design company, we offer a number of services. This includes :
                          <ul>
                            <li>Front-end Development</li>
                            <li>Interface Design (UI)</li>
                            <li>User Experience Design (UX)</li>
                            <li>Custom WebDesign</li>
                            <li>Responsive Layout</li>
                            <li>Mobile UI/UX</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          What's the difference between UI and UX design?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">UI primarily means user interface or the design that you see on the website. This includes the color schemes, animations on the website, different sections, pictures, icons, and so on. On the other hand, UX stands for user experience. This part deals with creating paths across the website using links and buttons for users to navigate through. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much do you charge for UI/UX design?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The cost of UI/UX design services is something that cannot be defined without knowing the specifications of the project. So, if you want an accurate estimate of the cost, it is recommended that you consult the best UI/UX design company, Uptimise IT.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          What is your design approach?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Being a renowned user experience design company, we follow a flexible design approach. This is, as mentioned below:
                          <ul>
                            <li>Define Product </li>
                            <li>Research </li>
                            <li>Analysis </li>
                            <li>UI/UX Design </li>
                            <li>Testing </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Which tools and technologies do you use for UI/UX design?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          At Uptimise IT we use the latest tools and technologies to ensure that our UI/UX design solutions are upto the mark. Some of these technologies are, as mentioned below:
                          <ul>
                            <li>Sketch. </li>
                            <li>InVision Studio. </li>
                            <li>Axure. </li>
                            <li>Craft. </li>
                            <li>Proto.io. </li>
                            <li>Adobe XD.</li>
                            <li>Marvel. </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
    
      </div>
    )
}