
const TouchData = [
    {
        num: "91",
      
       text: "(+91) India"
    },
    {
        num: "82",
      
        text: "(+25) India"
     },
   
     
    
]

export default TouchData;