import React from 'react'
import SuccessWork from './SuccessWork'
import SuccessData from './SuccessData'

const Success = () => {
  return (
    <>
      {
        SuccessData.map((val,ind) => {
            return (
              <SuccessWork
              key={ind}
              text={val.text}
              imgsrc={val.imgsrc}
              percent={val.percent}
              />
            )
           } )
      }
    </>
  )
}

export default Success;
