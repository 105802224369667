import React from 'react'
import PythonBigCardData from './PythonBigCardData'
import PythonBigCardWork from './PythonBigCardWork'

const PythonBigCard = () => {
  return (
    <>
    {PythonBigCardData.map((val,ind) => {
              return (
                <PythonBigCardWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )}  
  </>
  )
}

export default PythonBigCard
