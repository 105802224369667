import pro from '../../../images/warehouse-management-system.png'
import pro1 from '../../../images/fleet-management-system.png'
import pro2 from '../../../images/transportation-app-development.png'
import pro3 from '../../../images/order-management-solutions.png'
import pro4 from '../../../images/logistics-supply-chain-management-solution.png'
import pro5 from '../../../images/custom-logistics-mobile-app-development.png'
import { Link } from 'react-router-dom'


const LogiAutoData = [
    {
        title:"Warehouse Management System",
        text:  <p>We develop software for companies who want to mitigate the inventory handling issues commonly faced by logistics service providers. We can equip warehouse management apps with barcode scanning, RFID tags, and IoT sensors.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Fleet Management System",
        text:  <p>Fleet Management Applications are designed to streamline the tracking and deployment of your company vehicles. These mobile apps allow drivers to quickly identify available fleets, and for companies to ensure that appropriate fleets are used for delivery assignments.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Order Management Solutions",
        text: <p>We craft solutions that are equipped with precise geo-locating tracking so that you can leverage a transparent delivery system. An integrated Driver App enables your team to view the order details, update the delivery status and use GPS location and <strong><Link to="/geofencing-a-must-have-feature-for-on-demand-apps/index">Geofencing</Link></strong> features.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Logistics & Supply Chain Management Solution",
        text: <p>Manage the entire logistics &amp; supply chain system from one end to the other easily and effectively with our solutions. Our team of logistics app developers enables the business to streamline its operations and gain valuable insight into logistics and supply chain management solutions.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Automotive Migration & Upgradation",
        text: <p>Now, you can move from your legacy system to a better version or update the existing one with help of our software migration and up-gradation services.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Custom Logistics Mobile App Development",
        text: <p>Do you have an idea for a logistics app that has never been seen before? Now, you can create mobile apps from most out-of-the-box creative concepts using our custom logistics mobile app development services. Our team has the required resources and experience needed to deliver the solutions you need.</p>,
       imgsrc: pro5,  
    
     },
    
  
   
     
     
    
]

export default LogiAutoData ;