import React from 'react'
import IosData from './IosData'
import IosWork from './IosWork'

const Ios = () => {
  return (
    <>
    {IosData.map((val,ind) => {
     return (
       <IosWork 
       key={ind}
       imgsrc={val.imgsrc}
       text={val.text}
       />
     )
    } )}    
         
       </>
  )
}

export default Ios
