import pro from '../../../images/dashboard05.png'
import pro1 from '../../../images/new-offers05.png'
import pro2 from '../../../images/user-management05.png'
import pro3 from '../../../images/reporting-analytics05.png'
import pro4 from '../../../images/static-page-management-funds.png'
import pro5 from '../../../images/merchant-management05.png'
import pro6 from '../../../images/track-payments05.png'
import pro7 from '../../../images/live-support05.png'


const AdminData = [
    {
        title:"Dashboard",
        text:  <p>The admin dashboard is very personalized to give the administrator an overview of the entire online payment business.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"New Offers",
        text:  <p>The admin would be able to introduce new offers to the app users. Also please note that they can notify users about the new offers using push notifications and SMS.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"User Management",
        text: <p>The admin is able to see the list of users using the application, and also can restrict them from specific features and services.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Reporting / Analytics",
        text: <p>To make reporting simpler for the admin to interpret, the admin panel provides advanced analytics and reporting.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Static Page Management Funds",
        text: <p>Manage pages such as privacy policy, terms &amp; conditions, about us, etc. This is a core feature of the <strong>eWallet app for android</strong> and iOS.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Merchant Management",
        text: <p>The administrator can introduce new merchants and services. The panel allows the admin to integrate special offers to the merchant services.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Track Payments",
        text: <p>To ensure payments are transferred successfully, admins can see the transactions overview holistically.</p>,
       imgsrc: pro6,
     
     },
     {
        title:"Live Support",
        text: <p>The admin panel is also provided with an instant support system, where they can monitor and answer user queries, requests, and complaints.</p>,
       imgsrc: pro7,  
       
     },
  
   
     
     
    
]

export default AdminData ;