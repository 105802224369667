import React from 'react'
import FinAppData from './FinAppData'
import FinAppWork from './FinAppWork'

const FinApp = () => {
  return (
    <>
       {FinAppData.map((val,ind) => {
        return (
          <FinAppWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
    </>
  )
}

export default FinApp
