import React from 'react'
import TrackingWork from './TrackingWork'
import TrackingData from './TrackingData'

const Tracking = () => {
  return (
    <>
     {TrackingData.map((val,ind) => {
        return (
          <TrackingWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default Tracking
