import pro1 from '../../../images/check-icon.svg'
import pro2 from '../../../images/check-icon.svg'
import pro3 from '../../../images/check-icon.svg'
import pro4 from '../../../images/check-icon.svg'
import pro5 from '../../../images/check-icon.svg'
import pro6 from '../../../images/check-icon.svg'
import pro7 from '../../../images/check-icon.svg'
import pro8 from '../../../images/check-icon.svg'

const ExtraData = [
    {
       imgsrc: pro1, 
       
       text: "Loan lending mobile app Development",
    
    },
    {
        imgsrc: pro2, 
     
        text: "Custom fintech app Development",
       
     },
     {
        imgsrc: pro3, 
     
        text: "Digital Banking app Development",
      
     },
     {
        imgsrc: pro4, 
      
        text: "Personal Finance mobile app Development",
   
     },
     {
         imgsrc: pro5, 
    
         text: "Investment app Development",
     
      },
      {
         imgsrc: pro6, 
       
         text: "Tax filing and management app Development",
   
      },
      {
        imgsrc: pro7, 
      
        text: "Payment processing app Development",
  
     },
     {
        imgsrc: pro8, 
      
        text: "Insurance Mobile AppDevelopment",
  
     }
]

export default ExtraData;