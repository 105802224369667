import React from 'react'

const ReviewsWork = (props) => {
  return (
    <div className="col">
    <div className="blank-box">
      <img src={props.imgsrc} alt="uptimise it" />
    </div>
  </div>
  )
}

export default ReviewsWork
