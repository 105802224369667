import pro from '../../images/custom-marketplace-development-online.png'
import pro1 from '../../images/custom-marketplace-development-online.png'
import pro2 from '../../images/marketplace-iOS-development-online.png'
import pro3 from '../../images/marketplace-android-development-online.png'
import pro4 from '../../images/cross-platform-marketplace-development-online.png'
import pro5 from '../../images/maintenance-and-support-services-online.png'
import pro6 from '../../images/marketplace-migration-and-upgradation-service-online.png'
import pro7 from '../../images/consultancy-services-online.png'


const MarketData = [
    {
        title:"Custom Marketplace Development",
        text:   <p>Uptimise IT offers custom marketplace development services that help you convert your unique idea into a real-life working solution, fulfilling your business needs.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Marketplace Website Development",
        text:  <p>If you want to choose the web as your platform of choice, Uptimise IT also offers amazing marketplace website development services for you.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Marketplace iOS Development",
        text:  <p>You can choose iOS App Development services for marketplace mobile app development and get robust, creative, as well as feature-filled iPhone applications.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Marketplace Android Development",
        text:  <p><strong>Hire android developers</strong> with a decade’s worth of experience in android marketplace development, who can deliver exactly what you are looking for.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Cross-Platform Marketplace Development",
        text:  <p>If you can’t choose between iOS or Android, there is a third option known as cross-platform mobile app developmentthat gives you the best of both worlds.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:" Maintenance and Support Services",
        text:  <p>In addition to amazing development services, Uptimise IT also offers amazing mobile app <strong>maintenance and support services</strong> helping you remain consistent.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Marketplace Migration and Upgradation Service",
        text: <p>If you are suffering from a legacy marketplace solution, we are here to help you. With our migration and up-gradation services, you can move to a better version.</p>,
       imgsrc: pro6,
     
     },
     {
        title:"Consultancy Services",
        text: <p>Uptimise IT has the right experience along with the creative approach required to deliver stellar IT consultancy services that guide in right direction.</p>,
       imgsrc: pro7,  
       
     },
  
   
     
     
    
]

export default MarketData ;