import React from 'react'
import ValuesWork from './ValuesWork'
import ValuesData from './ValuesData'

const Values = () => {
  return (
    
      <  >
               {ValuesData.map((val,ind) => {
                return (
                  <ValuesWork
                  key={ind}
                  title={val.title}
                  imgsrc={val.imgsrc}
                  text={val.text}
                  />
                )
               } )}    
                    
                  </>

  )
}

export default Values;