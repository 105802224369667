import React from "react";
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn';
import Card from "./Card";
import Industry from "./industry/Industry";
import Service from "./service/Service";
import Values from "./values/Values";
import Success from "./success/Success";
import Process from "./process/Process";
import { TabScrollButton } from "@mui/material";
import prop from "../images/banner-img.png"
import prop1 from "../images/curvaceous-logo-gray.png"
import prop2 from "../images/medu-logo-gray.png"
import prop3 from "../images/student-castle-logo-gray.png"
import prop4 from "../images/drt-logo-gray.png"
import prop5 from "../images/dafri-logo-gray.png"
import prop6 from "../images/banner-bg.png"


class Home extends React.Component{
    render(){
        return(
          <div>
          <section className="banner-section landing-banner">
            <img src={prop6} alt="banner-bg-image" />
            <div className="dot-lg free-dots rellax" />
            <div className="landing-page-content">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="banner-content">
                      <h1><strong id="spin" /> App Development Company</h1>
                      <p>
                        Uptimise It is a leading mobile app development company
                        creating the next-gen mobile apps/websites. Designing,
                        Building, and Delivering the best.
                      </p>
                      <a href="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">
                        Talk to Consultant</a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner-img">
                      <img src={prop} alt="banner-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="logo-section">
            <div className="container-fluid">
              <div className="logo-parent">
                <ul>
                  <li>
                    <Link to=""><img src={prop1} alt="uptimise it" />
                    </Link>
                  </li>
                  <li>
                    <Link to=""><img src={prop2} alt="uptimise it" />
                    </Link>
                  </li>
                  <li>
                    <Link to=""><img src={prop3} alt="uptimise it" />
                    </Link>
                  </li>
                  <li>
                    <Link to=""><img src={prop4} alt="uptimise it" />
                    </Link>
                  </li>
                  <li>
                    <Link to=""><img src={prop5} alt="uptimise it" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="same-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="same-heading text-center">
                    <span>Our Expertise</span>
                    <div className="mobiles_head">
                      <h4>Data-Driven Mobile App Development Service</h4>
                      <p>
                        Uptimise It as a leading mobile app development company
                        provides various outstanding services. With our team of
                        experienced mobile app developers, we enable companies to
                        achieve their business goals. Our specialties are, as
                        mentioned below:
                      </p>
                    </div>
                  </div>
                  <div className="row Specialities-inner">
                  <Card />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section portfolio-section">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="protfolio-content text-center">
                    <h2>Our Portfolio</h2>
                    <p>
                      Uptimise It doesn’t just make claims, Our work speaks for
                      itself. Checkout our portfolio:
                    </p>
                    <Link to="/ourwork" className="btn btn-default">Discover Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section service-section">
            <div className="container">
              <div className="same-heading text-center">
                <span>Our Services</span>
                <div className="mobiles_head">
                  <h4>Creating The Next Best</h4>
                  <p>
                    Being the best mobile app development agency, Uptimise It offers
                    a range of website and app development services. These are, as
                    mentioned below:
                  </p>
                </div>
              </div>
              <div className="row align-items-center">  
              <Service />
                
              </div>
            </div>
          </section>
          <section className="same-section bg-color company-box-wrapper">
            <div className="container">
              <div className="same-heading text-center">
                <span>Our Values</span>
                <div className="mobiles_head">
                  <h4>What Makes Us, Uptimise It</h4>
                  <p>
                    Uptimise It is a mobile app development company driven by a
                    mission and working towards a vision. We have built ourselves
                    around the values which enable us to deliver unremarkable
                    results.
                  </p>
                </div>
              </div>
              <div className="row">
               <Values />
              </div>
            </div>
          </section>
          <section className="same-section slider-wrapper">
            <div className="container">
              <div className="same-heading text-center">
                <span>Industries</span>
                <div className="mobiles_head">
                  <h4>
                    Innovative Mobile App Development Services Across Industries
                  </h4>
                  <p>
                    Our mobile app development services are spread across
                    industries. While we are a well-renowned FinTech application
                    development company, we also deliver our amazing services in
                    other industries. Let’s have a look:
                  </p>
                </div>
              </div>
              <div className="row">
                <Industry />
              
                <div className="view-btn">
                  <Link to="/industry" className="btn btn-primaryx">View All</Link>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section bg-color work-inner-parent">
            <div className="container">
              <div className="same-heading text-center">
                <span>Why Uptimise It?</span>
                <div className="mobiles_head">
                  <h4>Missing Piece To Your Success Formula</h4>
                  <p>
                    Choose Uptimise It AppGenie because you deserve nothing but the
                    best. As the leading mobile app development agency, we have
                    years of experience and a proven track record to back it.
                  </p>
                </div>
              </div>
              <div className="row">
               <Success />
              </div>
            </div>
          </section>
          <section className="same-section analysis-setion">
            <div className="container">
              <div className="same-heading text-center">
                <span>Process</span>
                <div className="mobiles_head">
                  <h4>Agile, Quality, Successful Digital Solutions</h4>
                  <p>
                    As a globally renowned app Development Company, Uptimise It
                    follows a mobile app development process that is agile. We never
                    compromise with the quality and provide high time to market to
                    our clients. Uptimise Itknows how to deliver Success.
                  </p>
                </div>
              </div>
              <div className="row">
               <Process />
              </div>
            </div>
          </section>
          <section className="same-section request-section">
            <div className="container">
              <div className="request-content">
                <h3>Let’s Discuss The Next Big Idea?</h3>
                <p>
                  Bring your Idea to us, we can create it into a working mobile app.
                </p>
                <Link to="/contact" className="btn btn-primaryx">Get Free Consultation Now</Link>
              </div>
            </div>
          </section>

<HomeIn />
         
          <section className="same-section pt-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="faq-heading">
                    <h3>FAQ</h3>
                    <p>
                      Here are answers to some of the most frequently asked
                      questions about web and mobile app development company:
                    </p>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="faq-parent">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            How can you ensure the confidentiality of my app idea?
                          </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">
                            At Uptimise It, your ideas and their security are highly
                            valued. Therefore, in order to make sure your unique app
                            idea is not stolen or copied, we maintain full
                            transparency throughout every process and sign an NDA or
                            non-disclosure agreement making sure information related
                            to your project is fully secure.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            How do I go about the development process?
                          </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">
                            The dedicated mobile app developers at Uptimise It
                            follow a well-planned agile development process. This
                            process is, as mentioned below:
                            <ul>
                              <li>Analysis and Planning</li>
                              <li>UI/UX Design</li>
                              <li>Development Process</li>
                              <li>Testing</li>
                              <li>Maintenance and Support</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Do you have flexible hiring models?
                          </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">
                            Uptimise It is a well-known web and mobile app
                            development company, thus, we offer flexible hiring
                            models to our clients. You can now hire developers on
                            either a full-time, part-time, or hourly bases.
                          </div>
                        </div>
                      </div>toggle
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingfour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                            Do you provide documentation for the solution?
                          </button>
                        </h2>
                        <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">
                            Uptimise It is a professional mobile app development
                            that follows full procedure with all the required
                            documentation. Therefore, we do provide you with
                            documentation for the concerned solutions.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingfive">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                            Will you sign NDA?
                          </button>
                        </h2>
                        <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">
                            As a leading mobile app development company, Uptimise It
                            values your confidentiality above anything else. This is
                            why we always sign an NDA or non-disclosure agreement
                            with the client. Thus, ensuring the confidentiality of
                            your unique mobile app idea.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingsix">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapseThree">
                            Who is the most successful mobile app development
                            company today?
                          </button>
                        </h2>
                        <div id="flush-collapsesix" className="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">
                            Uptimise It is the best mobile app development company
                            you can find in the USA. We have years’ worth of
                            experience along with the right tools and creative
                            approach required to deliver market-leading solutions.
                            So, if you want to develop a mobile app of your own, we
                            can help you with it.
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        );
    }
}


export  default  Home;