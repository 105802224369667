import pro from '../../images/scalable-robust-solutions-eventes.png'
import pro1 from '../../images/timely-delivery-eventes.png'
import pro2 from '../../images/flexible-hiring-model-eventes.png'
import pro3 from '../../images/technology-tools-eventes.png'
import pro4 from '../../images/dedicated-developers-eventes.png'
import pro5 from '../../images/customer-centric-services-eventes.png'



const TimeData = [
    {
        title:"Scalable, Robust Solutions",
        text:  <p>Uptimise IT provides scalable solutions which are robust, helping you grow.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Timely Delivery",
        text:  <p>Being a top event app development company, we always deliver a solution within the deadline.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Flexible Hiring Model",
        text:  <p>Uptimise IT provides flexible hiring options that you can choose from as per preference.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Technology & Tools",
        text: <p>We use the latest technologies and tools for developing your solution, ensuring quality.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Dedicated Developers",
        text:  <p>A team of dedicated mobile app and web developers is assigned to your project.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Customer Centric Services",
        text:  <p>All of our event app development services are based on your needs and that of the market.</p>,
       imgsrc: pro5,  
    
     },

]

export default TimeData ;