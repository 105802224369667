import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Values from './values/Values'
import Process from './process/Process'
import Service from './service/Service'
import DevHead from "./development/andHeader/DevHead"

import pic from "../images/mobile-banking-banner-img.png"
import Mobile from './ai/mobile/Mobile'
import Banking from './ai/banking/Banking'
import LoanApp from "./auto/loanapp/LoanApp"
import LoanAdvance from "./auto/loanadvance/LoanAdvance"
import LoanSecure from "./auto/loansecure/LoanSecure"

export default function MobileBanking() {
    return (
        <div id="content">
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Mobile Banking App Development</strong></h1>
              <p>Helping banking institutions achieve digital transformation without compromising security. We’re among the best <strong>banking app development company</strong> in the USA.</p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
              <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section web-development-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Mobile Banking App</span>
              <div className="mobiles_head">
                <h4>We implement disruptive mobile technologies to enhance CX</h4>
                <p>Deloitte believes that in 2022, North American banks will spend half of their entire IT budget on emerging technologies, whereas European banks are said to spend one-third. The predicted share is much higher than the current at 27%, meaning that digitalbanking is the future.</p>
              </div>
            </div>
            <div className="row">
             <Mobile />
            </div>
          </div>
        </section>
        <section className="same-section opportunities-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Banking app Development</span>
              <div className="mobiles_head">
                <h4>We Help You Achieve Digital Transformation</h4>
                <p>We’re a mobile banking application development company that is specialized in developing secure banking portals facing the endcustomers. Our exceptionally skilled team has delivered several banking applications that are helping banks offer innovative services at lower costs. We concentrate on building software solutions that provide value to the end-user.</p>
              </div>
            </div>
            <div className="row">
             <Banking />
            </div>
          </div>
        </section>
        <section className="same-section technical-section automotive-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Our Work That Shines</h4>
                <p>Uptimise IT as a leading mobile banking app development company, has worked with top clients from across the world and delivered outstanding results. Some of our best work is, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>The Digital Bank of Africa</h3>
                    <div className="panel-list">
                      <ul>
                        <li> The digital Bank of Africa, aka DafriBank, is a borderless digital bank built specifically for entrepreneurs and merchants to act as an electronic payment gateway system. The bank caters to the banking needs of the growing digital entrepreneurial class in the African region.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>CUT E-Wallet</h3>
                    <div className="panel-list">
                      <ul>
                        <li>CUT mobile wallet allows users to make payments and transfer money in Myanmar and China. The app supports MMK and RMB and allows instant fund transfer via QR code, phone number, or an account ID.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Scalable and Robust Architecture</h3>
                    <div className="panel-list">
                      <ul>
                        <li>The banking app we’ll develop for you will be built on very secure and scalable software architecture. Digital banking security is among the biggest concerns for the industry and hence it is compulsory for banks to ensure data safety. The scalability of your banking apps is also an important element you should focus on.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Cost-Effective Solutions</h3>
                    <div className="panel-list">
                      <ul>
                        <li>We’re not here to sell technologies, we aim to help our customers by crafting technology solutions that can solve specific business problems. It means that our clients get the best mobile banking app development services that are built to drive faster ROI while helping banking institutions achieve the desired efficiency.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Want to ensure faster ROI for your mobile banking app development?</h2>
                  <Link to="#" className="btn btn-default">Hire Banking App Developers</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section web-development-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Mobile Banking App</span>
              <div className="mobiles_head">
                <h4>Mobile Banking App Features</h4>
                <p>Many mobile banking application development companies will overwhelm you by offering dozens of features that might be unnecessary for your app. We suggest you the right set of features that your banking app should have. You can pick among the following features.</p>
              </div>
            </div>
            <div className="custom-tab-parent">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false">Basic Feature</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Advance Features</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Security Procedures</button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className="row">
                    
                    <LoanApp />
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="row">
                    <LoanAdvance />
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div className="row">
                    <LoanSecure />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Solutions</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                    </div>
                  </div>
                </div>
              </div>
           <Service />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Our eLearning Mobile App Development Process </h4>
                <p>As a top-notch banking app development company, Uptimise IT follows a flexible process than allows us to deliver solutions that you can look forward to. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section company-box-wrapper bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
              </div>
            </div>
            <div className="row">
              <Values />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Mobile Banking Software Development Services</h3>
              <p>Uptimise IT is a leading Mobile banking software development company in USA. Our team of developers has years’ worth of experience and technical prowess needed to deliver growth-driven mobile banking app solutions.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
       <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>These are answers to some of the most frequently asked questions about mobile banking app development.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          How can I create a mobile banking app?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">All you need to do is reach out to Uptimise IT a leading mobile banking app development company in USA. Our mobile banking app solutions have a proven real-world performance along with much-needed innovation. So, if you want to develop the next best mobile banking app in the industry, Uptimise IT can help you do just that.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How much does it cost to create a banking app?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Mobile Banking app development costs can be anywhere between $15,000 and $30,000. However, there are various factors that can affect the cost. Nevertheless, if you want a closer estimate of the cost you can contact our team and share the project details, our team will give you a better estimate of the development cost.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How long does it take to develop a banking app?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Mobile Banking app development time depends on several factors. But you can expect anything between 2 weeks to 10 weeks.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          What are the types of mobile banking?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Uptimise IT is a leading mobile banking app development that offers a range of services. Some of the popular types of mobile banking apps are, as mentioned below:
                          <ul>
                            <li>Mobile Banking over SMS (also known as SMS Banking)</li>
                            <li>Mobile Banking over Wireless Application Protocol (WAP)</li>
                            <li>Mobile Banking over Unstructured Supplementary Service Data (USSD)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Will you sign NDA?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">As a leading mobile banking app development company, Uptimise IT values your confidentiality above anything else. This is why we always sign an NDA or non-disclosure agreement with the client. Thus, ensuring the confidentiality of your unique mobile app idea.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingsix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapseThree">
                          What are the technologies required for mobile banking?
                        </button>
                      </h2>
                      <div id="flush-collapsesix" className="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          As a visionarytechnologiesapp development company,Uptimise IT uses only the most advanced tools and technologies ensuring high-quality solutions. Some of the technologies we use for iPhone app development are, as mentioned below:
                          <ul>
                            <li>Android SDK Emulator</li>
                            <li>Android Sketch paper</li>
                            <li>MobiOne,</li>
                            <li>RhoMobile</li>
                            <li>Appcelerator</li>
                            <li>Xamarin</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
   
    )
}