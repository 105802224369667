import React from 'react'
import GrowthData from './GrowthData'
import GrowthWork from './GrowthWork'

const Growth = () => {
  return (
    <>
       {GrowthData.map((val,ind) => {
        return (
          <GrowthWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
    </>
  )
}

export default Growth
