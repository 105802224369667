import React from 'react'
import SoftwareData from './SoftwareData'
import SoftwareWork from './SoftwareWork'

const Software = () => {
  return (
    <>
       {SoftwareData.map((val,ind) => {
        return (
          <SoftwareWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
    </>
  )
}

export default Software
