import React from 'react'

const ExtraWork = (props) => {
  return (
    <div className="col-lg-3">
                  <div className="range-bottom-content">
                    <span><img src={props.imgsrc} alt="image" /></span>
                    <p>{props.text}</p>
                  </div>
                </div>
  )
}

export default ExtraWork
