import React from 'react'
import DashWork from './DashWork'
import DashData from './DashData'

const Dash = () => {
  return (
    <>
    {DashData.map((val,ind) => {
        return (
          <DashWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
       </>
  )
}

export default Dash
