import pro from '../../../images/cryptocurrency-wallet-development.png'
import pro1 from '../../../images/cryptocurrency-exchange-software-development.png'
import pro2 from '../../../images/mobile-cryptocurrency-exchange-development.png'
import pro3 from '../../../images/scalable-and-robust-architecture.png'
import pro4 from '../../../images/cost-effective-development-process01.png'
import pro5 from '../../../images/future-ready-blockchain-wallet-app-development.png'



const CryptoAppData = [
    {
        title:"Cryptocurrency Wallet Development",
        text:  <p>We use best practices for cryptocurrency software development, which allow us to create easy, secure, and regulation-compliant crypto wallets, that include payment integrations and transaction capabilities.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Cryptocurrency Exchange Software Development",
        text:  <p>If you wish to have your own Cryptocurrency Exchange Platform, you need the best cryptocurrency development company to help you with it. We have experienced developers onboard that are excellent in cryptocurrency application development.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Mobile Cryptocurrency Exchange Development",
        text:  <p>The majority of cryptocurrency exchanges are web platforms, designed for only PC use. The world is becoming more mobile-focused with each passing day. We enable the cryptocurrency world to keep up with this trend, by building mobile cryptocurrency exchange apps that are seamless and secure.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Scalable and Robust Architecture",
        text: <p>The cryptocurrency app that we’ll develop for you will utilize a very scalable and secure application architecture. Security is one of the biggest concerns for the crypto industry. Hence it is compulsory for cryptocurrency providers to ensure data and coin safety. The scalability of your cryptocurrency apps is also an important element that you should focus on.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Cost-Effective Development Process",
        text: <p>We’re not here to sell technologies, we aim to help our customers by building technology solutions that help them tap into an opportunity or eliminate specific problems. It means that our clients get the best cryptocurrency app development services, driving faster ROI while helping them achieve the desired results.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Future Ready Blockchain Wallet App Development",
        text: <p>Uptimise IT is a leading cryptocurrency app development companythat delivers future-ready blockchain wallet app development work. So, if this is something you are looking for, our team of mobile app developers can help you.</p>,
       imgsrc: pro5,  
    
     },
  
   
     
     
    
]

export default CryptoAppData ;
