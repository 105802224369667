import pro1 from '../../../../images/fast-time-to-market-laravel.webp'
import pro2 from '../../../../images/dedicated-developers-laravel.webp'
import pro3 from '../../../../images/experinced-laravel-team-laravel.webp'
import pro4 from '../../../../images/latest-tools-and-technologies-laravel.webp'
import pro5 from '../../../../images/secure-solutions-laravel.webp'
import pro6 from '../../../../images/customer-centric-services-laravel.webp'


const LaravelBigCardData = [
    {
        title:"Fast Time to Market ",
        text:  <p>You get lightning-fast time to market, without compromising quality when you hire laravel developers. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Dedicated Developers",
        text:  <p>You can <strong>PHP laravel developers</strong> who will be completely dedicated to deliver your project in time. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Experinced Laravel Team",
        text:  <p>Our team of laravel experts have years worth of experience and the right know-how required to deliver market-leading solution.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Latest Tools and Technologies",
        text: "We use latest tools and technologies to ensure our laravel solutions are upto the mark.",
       imgsrc: pro4,  
      
     },
     {
        title:"Secure Solutions ",
        text: "Our team of developers imbedded the solution with technology to make it secure and safe for users.",
       imgsrc: pro5,  
    
     },
     {
        title:"FlexibCustomer Centric Services lity",
        text: "All of the various laravel services offered by Uptimise IT are customer centric.",
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default LaravelBigCardData ;