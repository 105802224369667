import React from 'react'
import DesignData from './DesignData'
import DesignWork from './DesignWork'

const Design = () => {
  return (
    <>
      {DesignData.map((val,ind) => {
              return (
                <DesignWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )} 
    </>
  )
}

export default Design
