import pro from '../../../images/P2P-loan-ending-app-development.png'
import pro1 from '../../../images/bank-loan-app-development-loan.png'
import pro2 from '../../../images/credit-union-loan-app-loan.png'
import pro3 from '../../../images/scalable-and-robust-architecture-loan.png'
import pro4 from '../../../images/cost-efficient-solutions-loan.png'
import pro5 from '../../../images/custom-loan-lending-app-development-loan.png'



const LoanData = [
    {
        title:"P2P Loan Lending App Development",
        text:  <p>P2P lenders are the real disruptors in the industry. If you’re one of the companies that bring investors and borrowers together, Uptimise IT is the right choice for your P2P lending software development.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Bank Loan App Development",
        text:  <p>In this tech-savvy world, it is necessary for banks to provide a mobile lending app, as most loans are long-term arrangements, and are therefore harder to manually monitor. As loans are the main product for many banks, various loan features have been added to some mobile banking apps.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Credit Union Loan App",
        text: <p>Loan lending apps for credit unions are very similar to bank lending apps. Credit union lending apps also require forums and in-app chat features to allow union members to connect. We understand the right set of features that a credit union loan app will need.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Scalable and Robust Architecture",
        text: <p>We’re among the best loan app developers in the USA. As security is among the foremost concerns for FinTech businesses, it is compulsory to build solutions that are end-to-end encrypted and follow the highest security standards. As your userbase grows, your app will have to accommodate all of these users, which requires the app to be developed with highscalability constantly in consideration. Worry not, we’ll do this for you.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Cost-Efficient Solutions",
        text: <p>Unlike many other software development companies, we aim to craft solutions that are powered by top-notch technologies. The technology doesn’t have to be expensive to suit your lending app, but it has to be safe enough to ensure compliance. We make sure that the solutions are built with the least possible effort (cost) while keeping the standards high so that the app drives faster ROI.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Custom Loan Lending App Development",
        text: <p>As a leading money lending app development agency, one of our most renowned services is for custom loan lending app development. Our team of dedicated mobile app developers hasthe required experience and technical know-how needed to deliver market-leading solutions.</p>,
       imgsrc: pro5,  
    
     },
  
   
     
     
    
]

export default LoanData ;
