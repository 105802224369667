import React from 'react'
import TimeData from './TimeData'
import TimeWork from './TimeWork'

const Time = () => {
  return (
    <>
    {TimeData.map((val,ind) => {
        return (
          <TimeWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
       </>
  )
}

export default Time
