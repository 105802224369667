import React from 'react'
import EDevelopData from './EDevelopData'
import EDevelopWork from './EDvelopWork'

const EDevelop = () => {
  return (
    <>
    {EDevelopData.map((val,ind) => {
        return (
          <EDevelopWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
       </>
  )
}

export default EDevelop
