import React from 'react'
import ProcessWork from './ProcessWork'
import ProcessData from './ProcessData'

const Process = () => {
  return (
    
    <>
    {
      ProcessData.map((val,ind) => {
          return (
            <ProcessWork
            key={ind}
            text={val.text}
            title={val.title}
            num={val.num}
            imgsrc={val.imgsrc}
            />
          )
         } )
    }
  </>
    
  )
}

export default Process;
