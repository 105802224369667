import pro from '../../../../images/tech-stack01.png'
import pro1 from '../../../../images/Design01.png'
import pro2 from '../../../../images/app-platform01.png'
import pro3 from '../../../../images/features-of-the-app01.png'
import pro4 from '../../../../images/app-type01.png'
import pro5 from '../../../../images/size-of-the-app01.png'
import pro6 from '../../../../images/Security01.png'
import pro7 from '../../../../images/app-mintenance01.png'



const FinCostData = [
    {
        title:"TTech Stack",
        text:  <p>Tech stack refers to the framework, programming languages, toolkits, and other technologies used in Fintech mobile app development. Based on the tech stack, development costs can be affected.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Design",
        text:  <p>There are various things that go into making a good design. Now, if you are looking at a more complex design with a lot of animations, the cost will be different when compared to a simple design.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"App Platform",
        text: <p>Two of the popular app development platforms are android and iOS. For those who want the benefits of both, Hybrid mobile app development is a good option. Depending on the platform, development costs can differ.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Features of the App",
        text: <p>The features of mobile appslike Fintech are designed to attract and engage customers. Based on the features and their complexity, you can expect differing development costs of the FIntch mobile app.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"App Type",
        text: <p>Fintech is a broad industry and there are various different types of apps you can develop. Based on the type of the fintech app, the cost of development can also highly differ.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Size of the App",
        text: <p>Depending on the type and complexity of the mobile app, it can either be large in size or average. Nevertheless, the size of the fintech mobile application also affects its final cost of development.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Security",
        text: <p>Speaking of Fintech mobile applications, security is a big concern since the platform deals with important data. There are many technologies that go behind making an app secure thus affecting the total cost.</p>,
       imgsrc: pro6,  
    
     },
     {
        title:"App Maintenance",
        text: <p><strong>Mobile app maintenance and support</strong> servicesplay an important role in keeping the app performing well in the market.</p>,
       imgsrc: pro7,  
    
     },
  
   
     
     
    
]

export default FinCostData ;
