
import React from 'react'
import CommerceData from './CommerceData'
import CommerceWork from './CommerceWork'

const Commerce = () => {
  return (
    <>
       {CommerceData.map((val,ind) => {
                return (
                  <CommerceWork
                  key={ind}
                  title={val.title}
                  imgsrc={val.imgsrc}
                  text={val.text}
                  />
                )
               } )}   
    </>
  )
}

export default Commerce
