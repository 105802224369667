import pro from '../../../images/learning-analytics-student-management.png'
import pro1 from '../../../images/gamification.png'
import pro2 from '../../../images/enhanced-video-rich-media.png'
import pro3 from '../../../images/future-of-education.png'

import { Link } from 'react-router-dom'

const EduData = [
    {
        title:"Learning Analytics & Student Management",
        text:  <p>We deploy data analytics in our education app development, to give you more accurate learning analytics about your students. Also, we provide teachers with the ability to identify the weak points of their student’s knowledge, which enables the teacher to be significantly more effective through E-Learning Apps.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Gamification",
        text:  <p>Our Elearning&amp; education app development includes gamification and AR/VR implementation in trending <Link to="#">educational technology</Link> apps. These functionalities help learners to stay motivated. It also helps students to immerse themselves into learning, when dealing with difficult subjects such as history, physical science, medicine or language learning.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Enhanced Video & Rich Media",
        text: <p>We’re an Elearning& education app development company that powers learning apps with advanced video authoring tools. We integrate video plugins that allow learners to interact while learning. Furthermore, we use rich media technologies to make the digital content effective and intuitive for learners in educational mobile apps.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Future of Education",
        text: <p>e-learning mobile apps are the future of modern education. Online education solutions aren’t only innovative but also save a lot of time, and money providing better attention to students, higher quality of education, and whatnot. This is the reason why you should hire eLearning app developers and develop your own educational app.</p>,
       imgsrc: pro3,  
     
     },
     
  
   
     
     
    
]

export default EduData ;