import React from 'react'
import FinCostData from './FinCostData'
import FinCostWork from './FinCostWork'

const FinCost = () => {
  return (
    <>
       {FinCostData.map((val,ind) => {
        return (
          <FinCostWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
    </>
  )
}

export default FinCost;
