import React from 'react'
import {Link} from 'react-router-dom'

export default function Error() {
    return (
        <div id="content">
        <div className="error-4">
          <div className="main" style={{textAlign: 'center'}}>
            <img src="asset/images/404.png" alt="uptimise it"/>
            <p>It looks like you're lost. Don't worry....<br /></p>
            <Link to="/">Go Back to Homepage</Link>
          </div>
        </div>
      </div>

   
    )
}