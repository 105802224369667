import pro1 from '../../images/clutch.png'
import pro2 from '../../images/blank-box-icon01.png'
import pro6 from '../../images/blank-box-icon02.png'
import pro4 from '../../images/blank-box-icon03.png'
import pro5 from '../../images/blank-box-icon04.png'

const ReviewsData = [
    {
       
       imgsrc: pro1,  
       
    },
    {
      
        imgsrc: pro2,    
       
     },
     {
      
        imgsrc: pro6, 
       
     },
     {
      
        imgsrc: pro4, 
       
     },
     {
      
        imgsrc: pro5, 
       
     },
     
    
]

export default ReviewsData;