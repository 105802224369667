import pro from '../../../images/channel-agnostic-cx-banking.png'
import pro1 from '../../../images/transformation-on-the-go-banking.png'
import pro2 from '../../../images/performance-oriented-microservice-architecture-banking.png'
import pro3 from '../../../images/channel-agnostic-cx-banking.png'



const BankingData = [
    {
        title:"Channel Agnostic CX",
        text:  <p>A transformation commenced on a desktop can be carried to mobile devices, and vice-versa. We help you build mobile banking strategies that augment core banking platforms with the “Agile Edge”. This allows you to be Uptimise IT and act on changing customer demands within months instead of years.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Transformation On the Go",
        text:  <p>Big industries on which several other industries rely face a slow pace of transformation due to strict regulations. However, the end customer-facing applications can be taken to the market much faster. We use Agile methodologies that support lean requirements and deliver results to your banking business in less than six months.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Performance-oriented microservice architecture",
        text: <p>By leveraging an intermediary messaging layer and abstracting away from a batch-oriented core platform model, banks can speed up service delivery. We mostly recommend a microservice architecture that scales and personalize itself. We’ve extensive experience in integrating into most of the common banking platforms, such as Finserv, FIS, etc.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"End-to-End Encryption",
        text: <p>Digital banking transactions are the hotspot for cybercriminals. In June 2020, the FBI warned that financial cybercrimes may rise due to the rise of digital banking. To help banks overcome the security challenges, we implement end-to-end data encryption in the banking software that we develop. We recommend SSL chain verification to ensure the encrypted connection between the Upload Filer and the web server.</p>,
       imgsrc: pro3,  
     
     },
   
     
     
    
]

export default BankingData ;