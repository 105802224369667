import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import DevHead from './development/andHeader/DevHead'
import Industry from './industry/Industry'

import pic from "../images/angular-developers-banner-img.webp"
import pic1 from "../images/angular-developers-small-img.webp"
import AngularBigCard from './hiredevelopers/angular/angularbig/AngularBigCard'

import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"

export default function HireAngular() {
    return (
     
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong> AngularJS Developers</strong></h1>
                  <p>Uptimise IT offers the best AngularJS developers who you can hire for your project. As a leading mobile app and web development company,our AngularJS developers have proven performance and years’ worth of experience.</p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
              <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>Hire AngularJS Developer</span>
                  <h3>The best dedicated<br /> AngularJS developers</h3>
                  <p>Our team of dedicated AngularJS developers is known throughout the industry for their out-of-the-box thinking and amazing digital solutions. Now, you can hire dedicated AngularJS developers and take advantage of all of this.Uptimise IT being the top web development company provides amazing <strong>AngularJS development services.</strong> </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Hire Dedicated AngularJS Developers</h3>
              <p>Looking for dedicated AngularJS developers to work on your next project? Uptimise IT offer the best ones in the market.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Dedicated Developer</span>
              <div className="mobiles_head">
                <h4>Innovative Services From AngularJS Experts</h4>
                <p>Dedicated Angular developers at Uptimise IT in addition to being highly experienced also have the right know-how needed to deliver solutions that fulfill business needs. Some of our top solutions are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <AngularBigCard />
            </div>
          </div>
        </section>
        <section className="same-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Developer</span>
              <div className="mobiles_head">
                <h4>Why Hire AngularJS Developers From Uptimise IT? </h4>
                <p>You deserve nothing but the best that is why you should hire AngularJS developers from Uptimise IT. With our technical know-how and creative approach to Angular development, we offer the best solutions in the market. More reasons to hire angular developers are:</p>
              </div>
            </div>
            <div className="row app-developers-parent">
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Dedicated Angular developers </h3>
                  <p>Our team of developers are always dedicated and committed to delivering nothing but the best solutions to our clients.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Experienced Developers </h3>
                  <p>AngularJS developers at Uptimise IT have the required experience to deliver angular solutions that fulfill market needs.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Development Agility </h3>
                  <p>Our flexible strategy for Angular development enables us to overcome challenges and deliver uncompromised results.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Affordable Prices </h3>
                  <p>While Uptimise IT is one of the best AngularJS development company, our services won't hurt your pocket.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Fast Time to Market </h3>
                  <p>Uptimise IT never fail to deliver the solutions within the deadlines. Our team offers amazing time to market.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Maintenance and Support </h3>
                  <p>You can hire AngularJS developers for the maintenance and support of your web app or mobile applications. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Angular Developers Hiring Options</h4>
                <p className="text-center">Every business has different needs. That’s why to cater to your development needs, we provide services that are suitable to you. Now, you can hire AngularJS Developers as full-time, part-time, or hourly development partners.</p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>Full-time dedicated developers that are totally dedicated to your own and report to you at the end of the day.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro1} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>Don’t have enough work for full-time developers, now you can hire dedicated developers part-time.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro2} alt="uptimise it" />
                      </figure>
                      <h3>Hourly</h3>
                      <p>Hiring Angular experts on hourly bases is a popular option among clients. Now, you can do it with Uptimise IT.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Advantages of Hiring AngularJS Experts</h3>
                  <p> Uptimise IT offer many advantages to those who hire AngularJS expert. Our team isn’t only highly talented but also keeps exploring new possibilities. This is what allows us to deliver solutions that fulfill your business needs and help your business reach success.</p>
                  <ul>
                    <li>nteractive UI</li>
                    <li>ata-Driven Angular Development </li>
                    <li>ustom Angular Solutions </li>
                    <li>DA</li>
                    <li>ighly Secure and safe</li>
                    <li>ptimized results</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
              <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
      
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>Looking to Hire dedicated Angular Developers?</h3>
            <p>Hire dedicated Angular developers in USA and UK with Uptimise IT.</p>
            <Link to="/contact" className="btn btn-primaryx">Let’s Talk</Link>
          </div>
        </section>
      <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are some answers to the most frequently asked questions about AngularJS developers.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Why should I Choose Uptimise IT?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT is the best mobile app development company you can find in the USA. We have years’ worth of experience along with the right tools and creative approach required to deliver market-leading solutions. So, if you want to develop a mobile app of your own, we can help you with it.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          What is your AngularJS team strength?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Our team of AngularJS developers has years’ worth of experience and the right technical knowledge needed to deliver market-leading solutions. We follow an agile development process and always deliver the solution within the deadline.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Are you willing to sign a scanned copy Non-Disclosure and confidentiality agreement and have it mailed to you?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Yes, Uptimise IT as a leading AngularJS development company always cares about customers and their interests. So, we have no problem signing a non-disclosure and confidentiality agreement with the client and sharing it with you.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Is testing one of the components included within the pricing structure?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Our development process facilitated by expert angular developers covers everything starting from development to testing and deployment. So, yes, the testing process is included in the pricing structure.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Is hiring more employees at a later stage allowed?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">If you feel like the current team of AngularJS developers isn’t doing justice to the development process, we are happy to deliver scalability and flexibility to the same and expand the team as per your project needs.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>

   
    )
}