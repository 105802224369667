import React from 'react'
import AdvanceData from './AdvanceData'
import AdvanceWork from './AdvanceWork'

const Advance = () => {
  return (
    <>
       {AdvanceData.map((val,ind) => {
        return (
          <AdvanceWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default Advance;
