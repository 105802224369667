import React from 'react'

import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Industry from './industry/Industry'
import Success from './success/Success'

import pic from "../images/developers-banner-img.png"
import pic1 from "../images/android-developers-small-img.webp"
import DevHead from './development/andHeader/DevHead'
import AndroidBigCard from './hiredevelopers/androd/androidbig/AndroidBigCard'
import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"


export default function HireAndroid() {
    return (
      <div id="content">
      {/* page-heading */} 
      <section className="developers-banner-section">
        <div className="container"> 
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-content">
                <h1>Hire <strong>Android App Developers </strong></h1>
                <p>Hire android developers from Uptimise IT for outstanding services and top-notch results. As a leading mobile app development company, we offer a team of the best mobile app developers with high experience and a creative approach.</p>
                <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="developers-banner-img">
                <img src={pic} alt="uptimise it" />
                <div className="developer-box">
                  <ul>
                    <li>Web Developer</li>
                    <li className="change-bg">PHP Developer</li>
                    <li className="change-bg">iOS Developer</li>
                    <li>Android Development</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logo-section">
        <div className="container-fluid">
          <div className="logo-parent">
            <DevHead />
          </div>
        </div>
      </section>
      <section className="same-section">
        <div className="container">
          <div className="row align-items-center demand-wrapper">
            <div className="col-lg-6">
              <div className="demand-img">
                <img src={pic1} alt="uptimise it" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="demaond-content same-heading">
                <span>Hire Android Developer</span>
                <h3>Dedicated Android Application Developers</h3>
                <p>Uptimise IT offers the best android app developers in USA and UK. Our team of developers has years of experience and out-of-the-box thinking that enables them to deliver some of the best android mobile apps. If you want to achieve your business goals and smash the competition, Hire a team of android application developers from us.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="same-section industries-service-banner">
        <div className="container">
          <div className="request-content">
            <h3>The Best Android App Developers in USA &amp; UK</h3>
            <p>Want to convert your million-dollar idea into an android app? Uptimise IT has a team of Android app experts, who you can hire for your next project.</p>
            <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
          </div>
        </div>
      </section>
      <section className="same-section technology-section">
        <div className="container">
          <div className="same-heading text-center">
            <span>Dedicated Mobile App Developer</span>
            <div className="mobiles_head">
              <h4>Impactful Android app development Solutions</h4>
              <p>Uptimise IT let you hire dedicated android application developers with years’ worth of experience and out-of-the-box thinking. So, if you are looking for android app experts to help you with your project, you have come to the right place.</p>
            </div>
          </div>
          <div className="row">
            <AndroidBigCard />
          </div>
        </div>
      </section>
      <section className="same-section bg-color">
        <div className="container">
          <div className="same-heading text-center">
            <span>Hire Developer</span>
            <div className="mobiles_head">
              <h4>Why Hire Android App Developers From Uptimise IT?</h4>
              <p>Hire mobile app developers from Uptimise IT because you deserve the best android app development services in the market. And our team of dedicated app developers can deliver that to you.</p>
            </div>
          </div>
          <div className="row app-developers-parent">
            <div className="col-lg-4">
              <div className="app-developer-box">
                <h3>Dedicated Android App Experts</h3>
                <p>Our team of android application developers is dedicated and committed to serving you the best.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="app-developer-box">
                <h3>Years of Experience</h3>
                <p>In addition to creativity, the developer at Uptimise IT also has years' worth of valuable experience.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="app-developer-box">
                <h3>Flexible Development Approach</h3>
                <p>We take a flexible development approach which allows us to tackle any setback that comes our way.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="app-developer-box">
                <h3>Cost-Effective</h3>
                <p>Uptimise IT's android app developers for hire aren’t only the best, but also comes at an affordable cost.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="app-developer-box">
                <h3>On-Time Delivery</h3>
                <p>We always deliver the android mobile app before the deadline, offering an amazing time to market.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="app-developer-box">
                <h3>Maintenance and Support</h3>
                <p>Uptimise IT offers amazing android app maintenance and support services at amazing prices.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="same-section i-phone-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="iphobe-app-img">
                <img src={pro3} alt="uptimise it" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="developers-content">
                <h3>Advantages of Hiring Dedicated Android App Experts</h3>
                <p>There are many advantages of hiring android app developers from Uptimise IT. With top-level technical prowess and a creative approach, you also get years of experience and know-how. Hire our team of android app developers in UK &amp; USA to get all of these benefits.</p>
                <ul>
                  <li>Cost-Effective Android App Development</li>
                  <li>In-Depth knowledge of Android Programming</li>
                  <li>Fine Database Integration</li>
                  <li>Integrity and Transparency</li>
                  <li>Best Resource Management</li>
                  <li>Optimized Development Cycle</li>
                  <li>App Development Via Latest Tools</li>
                  <li>Dedicated Android Development Team</li>
                  <li>100% Client Satisfaction</li>
                  <li>Well-Managed Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="same-section pt-0 watch-box">
        <div className="container">
          <div className="same-heading text-center mb-5">
            <div className="mobiles_head">
              <h4 className="mb-0">Android App Developers Hiring Options</h4>
              <p className="text-center">Hire android app developers in a mode that suits you. Uptimise IT as a leading mobile app development company offers full time, part time, and hourly bases</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="model-box-parent">
                <div className="model-box-inner">
                  <figure>
                    <img src={pro} alt="uptimise it" />
                  </figure>
                  <h3>Full Time</h3>
                  <p>Uptimise IT offer android app developer for hire for full time. The developer will dedicate their time to your work as per your requirements.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="model-box-parent change-bg-img">
                <div className="model-box-inner">
                  <figure>
                    <img src={pro1} alt="uptimise it" />
                  </figure>
                  <h3>Part Time</h3>
                  <p>If you don’t want to hire developers on a full time, you can hire an android app developer for part time work at Uptimise IT.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="model-box-parent">
                <div className="model-box-inner">
                  <figure>
                    <img src={pro2} alt="uptimise it" />
                  </figure>
                  <h3>Hourly</h3>
                  <p>For those who have specific work, now, you can hire dedicated app developers on hourly bases.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="same-section work-inner-parent bg-color">
        <div className="container">
          <div className="same-heading text-center">
            <span>Why Uptimise IT?</span>
            <div className="mobiles_head">
              <h4>Missing Piece To Your Success Formula</h4>
              <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
            </div>
          </div>
          <div className="row">
           <Success />
          </div>
        </div>
      </section>
      <section className="same-section slider-wrapper">
        <div className="container">
          <div className="same-heading text-center">
            <span>Industries</span>
            <div className="mobiles_head">
              <h4>Innovative Mobile App Development Services Across Industries</h4>
              <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
            </div>
          </div>
          <div className="row">
           <Industry />
            <div className="view-btn">
              <Link to="/industry" className="btn btn-primaryx">View All</Link>
            </div>
          </div>
        </div>
      </section>
     <HomeIn />
      <section className="same-section we-serve p-0">
        <img src="asset/images/serve-bg.png" alt="images" />
        <div className="serve-content-parent">
          <h3>Looking to Hire Android App Developers?</h3>
          <p>Hire dedicated android app developers in USA and UK with Uptimise IT.</p>
          <Link to="#" className="btn btn-primaryx">Let’s Talk</Link>
        </div>
      </section>
      <section className="same-section analysis-setion analysis-change-setion">
        <div className="container">
          <div className="same-heading text-center">
            <span>Process</span>
            <div className="mobiles_head">
              <h4>Agile, Quality, Successful Digital Solutions</h4>
              <p>As a globally renowned app Development Company, Uptimise IT follows a mobile app development process that is agile. We never compromise with the quality and provide high time to market to our clients. Uptimise IT knows how to deliver Success.</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="analysis-parent">
                <div className="big-process-content">01</div>
                <div className="animate-border" />
                <div className="bg-icon" />
                <h4>Analysis &amp; Planning</h4>
                <p>Everything Starts with an Idea and a Plan.</p>
              </div>
            </div>
            <div className="col">
              <div className="analysis-parent">
                <div className="big-process-content">02</div>
                <div className="animate-border" />
                <div className="bg-icon bg-icon01" />
                <h4>UI/UX Design</h4>
                <p>Our creative designers create interactive and beautiful UI/UX.</p>
              </div>
            </div>
            <div className="col">
              <div className="analysis-parent">
                <div className="big-process-content">03</div>
                <div className="animate-border" />
                <div className="bg-icon bg-icon02" />
                <h4>Development Process</h4>
                <p>Our app developers convert your creative idea into a working app</p>
              </div>
            </div>
            <div className="col">
              <div className="analysis-parent">
                <div className="big-process-content">04</div>
                <div className="animate-border" />
                <div className="bg-icon bg-icon03" />
                <h4>Testing</h4>
                <p>We test the app for any possible bugs and errors before it is deployed.</p>
              </div>
            </div>
            <div className="col">
              <div className="analysis-parent main">
                <div className="big-process-content">05</div>
                <div className="inner" />
                <div className="bg-icon bg-icon04" />
                <h4>Maintenance &amp; Support</h4>
                <p>Uptimise IT offers mobile app maintenance &amp; Support to ensure the app’s good performance.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="same-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="faq-heading">
                <h3>FAQ</h3>
                <p>Here are some answers to the most frequently asked questions about AndroidJS developers.</p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="faq-parent">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        How can you ensure the confidentiality of my app idea?
                      </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">At Uptimise IT, your ideas and their security are highly valued. Therefore, in order to make sure your unique app idea is not stolen or copied, we maintain full transparency throughout every process and sign an NDA or non-disclosure agreement making sure information related to your project is fully secure.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Are your applications compatible with all versions of the OS?
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">We have an experienced team of android app developers. We make sure all of our android app solutions are compatible with all the versions of OS that are in use. Moreover, through our mobile app maintenance and support services, you can make sure the app remains market relevant inthe coming years and retain its position as the leading android app.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        Will you help me launch my application as well?
                      </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">The team of android app developers for hire at Uptimise IT takes the project through the full cycle. Meaning we will take your mobile app from ideation to deployment. We also provide amazing mobile app maintenance and support. So, the answer is yes, Uptimise IT will help you with the application launch.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingfour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                        How do I go about the development process?
                      </button>
                    </h2>
                    <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        The dedicated Android app developers at Uptimise IT follow a well-planned agile development process. This process is, as mentioned below:
                        <ul>
                          <li>Maintenance &amp; Support</li>
                          <li>Testing</li>
                          <li>Development Process</li>
                          <li>UI/UX Design</li>
                          <li>Analysis &amp; Planning</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingfive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                        Is android app development worth the hype?
                      </button>
                    </h2>
                    <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">Android app development is worth every bit of hype it gets from the people. As of today, the majority of the app market is dominated by android and the same goes for the customer base. Currently, there are 2,620,593 android apps in the market and these numbers are always growing.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </section>
      </div>
   
    )
}