

const AbtValuesData = [
    {
        title: "mission",
     
       text: "  Uptimise It aims to improve the financial sector with innovative fintech apps, faster transactions, and bettersecurity."
    },
    {
        title: "Vision",
        
        text: "  Our Vision is to contribute to the digital economy while delivering outstanding mobile app/website solutions."
     },
     {
        title: "Goal",
   
        text: " Our goal in the year 2022 is to deliver better than ever innovative digital solutions to over clients."
     },
     {
        title: "Success",
       
        text: "  For Uptimise It, Success means client satisfaction and growth driving solutions that help you reach your goal."
     },
    
]

export default AbtValuesData;