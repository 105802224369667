import pro1 from '../../../images/curvaceous-logo-gray.png'
import pro2 from '../../../images/dafri-logo-gray.png'
import pro3 from '../../../images/medu-logo-gray.png'
import pro4 from '../../../images/student-castle-logo-gray.png'
import pro5 from '../../../images/drt-logo-gray.png'
import pro6 from '../../../images/dafri-logo-gray.png'

const DevHeadData = [
    {
        
       imgsrc1: pro1,  
       imgsrc2: pro2, 
       imgsrc3: pro3, 
       imgsrc4: pro4, 
       imgsrc5: pro5, 
       imgsrc6: pro6, 
     
       
    },
   
     
     
    
]

export default DevHeadData ;