import pro1 from '../../../images/check-icon.svg'
import pro2 from '../../../images/check-icon.svg'
import pro3 from '../../../images/check-icon.svg'
import pro4 from '../../../images/check-icon.svg'
import pro5 from '../../../images/check-icon.svg'
import pro6 from '../../../images/check-icon.svg'
import pro7 from '../../../images/check-icon.svg'
import pro8 from '../../../images/check-icon.svg'

const CoreData = [
    {
       imgsrc: pro1, 
       
       text: "Mobile Wallet App Development",
    
    },
    {
        imgsrc: pro2, 
     
        text: " Cryptocurrency Wallet Development",
       
     },
     {
        imgsrc: pro3, 
     
        text: "Custom Ewallet App Development",
      
     },
     {
        imgsrc: pro4, 
      
        text: "Mobile Wallet Integration",
   
     },
     {
         imgsrc: pro5, 
    
         text: "Money Management App Solutions",
     
      },
      {
         imgsrc: pro6, 
       
         text: "POS Payment Solution Development",
   
      },
      {
        imgsrc: pro7, 
      
        text: "P2P Money Transfer App Development",
  
     },
     {
        imgsrc: pro8, 
      
        text: "International Money Transfer App",
  
     }
]

export default CoreData;