import pro1 from '../../../../images/custom-ios-app-development-services-ios.webp'
import pro2 from '../../../../images/ios-app-update-and-migration-ios.webp'
import pro3 from '../../../../images/ios-support-and-maintenance-ios.webp'
import pro4 from '../../../../images/custom-ios-app-development-services-ios.webp'
import pro5 from '../../../../images/ios-consultancy-ios.webp'
import pro6 from '../../../../images/ipad-mobile-app-development-ios.webp'


const IphoneBigData = [
    {
        title:"Custom iOS App Development Services  ",
        text: <p>With Uptimise IT, you can hire remote iOS developersfor custom iOS app development. With a proven track record in iPhone development work, our team is more than capable of creating a feature-rich </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"iOSApp Update and Migration ",
        text: <p>Uptimise IT allows you to hire iPhone applications, and developers, for iOS app upgradation and migration. Our team of the developer can help you improve your iPhone apps all around and add more value to the same.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"iOS Support and Maintenance",
        text: <p>iPhone App developers for hire at Uptimise IT provide amazing iOS support and maintenance services for your iPhone mobile apps. Our team can help you maintain the app while improving it.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Apple Watch Wearable App Development",
        text:  <p>Our iOS development also provides services for Apple Watch app development. As of today, wearable mobile apps are popular as the Apple Watch is an important part of the Apple ecosystem.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"iOS consultancy  ",
        text: <p>Our iOS development team has years of experience and the right know-how needed to provide top-level iOS consultancy services to you. If this is what you are looking for Uptimise IT is here for you.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"iPad Mobile App Development ",
        text: <p>Just like Apple Watch, the iPad is an important part of Apple’s ecosystem. IOS mobile app developers at Uptimise IT know how to develop and deploy market-leading iPad applications for you and we can bring your idea to reality. </p>,
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default IphoneBigData ;