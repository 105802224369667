import React from 'react'

import {Link} from 'react-router-dom'
export default function Industry() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
              <ul>
                <li> <Link to="#"><img src="asset/images/curvaceous-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="#"><img src="asset/images/dafri-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="#"><img src="asset/images/medu-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="#"><img src="asset/images/student-castle-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="#"><img src="asset/images/drt-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="#"><img src="asset/images/dafri-logo-gray.png" alt="uptimise it" /> </Link> </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section indeustries-main-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovation Across Industries </h4>
                <p>As a mobile app development company with a vision, Uptimise IT is dedicated to delivering innovative solutions across various industries. Some of the top industries we serve are:</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="indystry-img">
                  <img src="asset/images/logistic-industries.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="industry-main-content-parent">
                  <div className="industry-content">
                    <h3>Logistics App Development </h3>
                    <p>Our logistics app development services are known for delivering innovation and data-driven solutions.</p>
                  </div>
                  <div className="industry-read">
                    <Link to="/logistics-app-development">Read More <span><i className="fa fa-angle-right" /></span></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="industry-main-content-parent">
                  <div className="industry-content travel_industries">
                    <h3>Banking, Finance &amp; Insurance App Development</h3>
                    <p>Banking Mobile apps, eWallet mobile apps, etc are just a few of our leading banking, finance, and insurance apps insurance.</p>
                  </div>
                  <div className="industry-read">
                    <Link to="/mobile-banking-app-development">Read More <span><i className="fa fa-angle-right" /></span></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="indystry-img">
                  <img src="asset/images/banking-industries.png" alt="uptimise it" />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="indystry-img">
                  <img src="asset/images/loan-landin-industries.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="industry-main-content-parent">
                  <div className="industry-content">
                    <h3>Loan Lending App Development </h3>
                    <p>If you want to develop a  loan lending mobile application of your own, Uptimise IT is here to help.</p>
                  </div>
                  <div className="industry-read">
                    <Link to="/loan-lending-app-development">Read More <span><i className="fa fa-angle-right" /></span></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="industry-main-content-parent">
                  <div className="industry-content travel_industries">
                    <h3>Cryptocurrency App </h3>
                    <p>If you want to enter the multi-billion dollar rapidly growing market of crypto, our cryptocurrency app development is for you.</p>
                  </div>
                  <div className="industry-read">
                    <Link to="/cryptocurrency-app-development">Read More <span><i className="fa fa-angle-right" /></span></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="indystry-img">
                  <img src="asset/images/currency-industries.png" alt="uptimise it" />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="indystry-img">
                  <img src="asset/images/ai-development-induistries.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="industry-main-content-parent">
                  <div className="industry-content">
                    <h3>AI App Development </h3>
                    <p>Integrating Artificial intelligence into a mobile application to deliver the best of both worlds.</p>
                  </div>
                  <div className="industry-read">
                    <Link to="/artificial-intelligence-application-development">Read More <span><i className="fa fa-angle-right" /></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section request-section">
          <div className="container">
            <div className="request-content">
              <h3>Request a Quote</h3>
              <p>If you have an App Idea in mind, share it with us to turn your idea into a profitable plan or
                business.</p>
              <Link to="#" className="btn btn-primaryx">Get Free Consultation Now</Link>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Work</span>
              <div className="mobiles_head">
                <h4>Our Proud Portfolio</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                  ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
              </div>
            </div>
            <div className="row portfoli-head">
              <div className="col-lg-6">
                <div className="portfolio-parent">
                  <div className="portfolio-inner-wrapper">
                    <img src="asset/images/portfolio-bg01.png" />
                    <div className="portfolio-content-parent">
                      <div className="country-list">
                        <ul>
                          <li>Japan</li>
                          <li>Malaysia</li>
                        </ul>
                      </div>
                      <h3>DafriBank <span>Digital Bank Of Africa
                        </span></h3>
                      <Link to="/dafri-bank-case-study" className="btn btn-default">Case Study</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="portfolio-parent">
                  <div className="portfolio-inner-wrapper">
                    <img src="asset/images/portfolio-bg02.png" />
                    <div className="project-content">
                      <h3>Medu4 <span>Medical Education Platform</span></h3>
                    </div>
                    <div className="portfolio-content-parent">
                      <div className="country-list">
                        <ul>
                          <li>Japan</li>
                          <li>Malaysia</li>
                        </ul>
                      </div>
                      <h3>DafriBank <span>Digital Bank Of Africa
                        </span></h3>
                      <Link to="/medu4-case-study" className="btn btn-default">Case Study</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-parent">
                  <div className="portfolio-inner-wrapper">
                    <img src="asset/images/portfolio-bg03.png" />
                    <div className="project-content">
                      <h3>Medu4 <span>Medical Education Platform</span></h3>
                    </div>
                    <div className="portfolio-content-parent">
                      <div className="country-list">
                        <ul>
                          <li>Japan</li>
                          <li>Malaysia</li>
                        </ul>
                      </div>
                      <h3>DafriBank <span>Digital Bank Of Africa
                        </span></h3>
                      <Link to="/dafri-bank-case-study" className="btn btn-default">Case Study</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-bottom-btn text-center">
              <Link to="/ourwork" className="btn btn-primaryx">View Portfolio</Link>
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Our Portfolio</h2>
                  <p>Do you want more information about the innovative mobile app solutions we have developed?</p>
                  <Link to="#" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section testimonial-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Testimonial</span>
              <div className="mobiles_head">
                <h4>What Our Customers Are Saying</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                  ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="testimonal-parent">
                  <Link to="#">
                    <h3>Help us improve our productivity</h3>
                  </Link>
                  <div className="testimonal_child">
                    <div className="testimonal_img">
                      <Link to="#"><img src="asset/images/army.png" /></Link>
                    </div>
                    <div className="testimonal-icon">
                      <Link to="#"><img src /></Link>
                    </div>
                  </div>
                  <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                    invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="testimonal-parent">
                  <Link to="#">
                    <h3>Help us improve our productivity</h3>
                  </Link>
                  <div className="testimonal_child">
                    <div className="testimonal_img">
                      <img src="asset/images/african-american-business-woman-working-computer-bar.png" />
                    </div>
                    <div className="testimonal-icon">
                      <Link to="#"><img src /></Link>
                    </div>
                  </div>
                  <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                    invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color blank-wrapper-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Uptimise IT Reviews</span>
              <div className="mobiles_head">
                <h4>How We Work</h4>
                <p className="text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon01.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon02.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon03.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon04.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon04.png" alt="uptimise it" />
                </div>
              </div>
            </div>
          </div>
        </section>  
      </div>
   
    )
}