import React from 'react'
import TechnoData from './TechnoData'
import TechnoWork from './TechnoWork'
const Techno = () => {
  return (
    <>
    {TechnoData.map((val,ind) => {
      return (
        <TechnoWork
        key={ind}
        title={val.title}
        imgsrc={val.imgsrc}
        text={val.text}
        />
      )
     } )}  
  </>
  )
}

export default Techno
