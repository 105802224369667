import React from 'react'
import AbtValuesData from './AbtValuesData'
import AbtValuesWork from './AbtValuesWork'

const AbtValues = () => {
  return (
    
      <  >
               {AbtValuesData.map((val,ind) => {
                return (
                  <AbtValuesWork
                  key={ind}
                  title={val.title}
                  imgsrc={val.imgsrc}
                  text={val.text}
                  />
                )
               } )}    
                    
                  </>

  )
}

export default AbtValues;