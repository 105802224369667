import pro1 from '../../../../images/robust-fluid-solutions-website.webp'
import pro2 from '../../../../images/fast-time-to-market-website.webp'
import pro3 from '../../../../images/after-release-support-website.webp'
import pro4 from '../../../../images/future-proof-solutions-website.webp'
import pro5 from '../../../../images/dedicated-developers-website.webp'
import pro6 from '../../../../images/scalable-solutions-website.webp'


const WebBigCardData = [
    {
        title:<h3>Robust &amp; Fluid Solutions </h3>,
        text:  <p>Uptimise IT offer secure, fast, fluid, and robust website development solution that fit your needs.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:<h3>Fast Time To Market </h3>,
        text:   <p>In today’s age of technology, time is money, this is why we offer fast time to market, minimizing development costs. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"After Release Support ",
        text:     <p>We are with you in development and after development,  so you don’t have to worry about anything.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Future Proof Solutions ",
        text:  <p>We use the latest technologies and tools to create website development solutions that are future-proof. </p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Dedicated Developers",
        text:    <p>A team of dedicated website developers is assigned to your team who only work on your project.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Scalable Solutions",
        text:    <p>Our web development solutions aren’t just visually aesthetic but also highly scalable as per your needs.</p>,
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default WebBigCardData ;