import pro1 from '../../../images/analysis-icon.svg'
import pro2 from '../../../images/ux-ui-icon.svg'
import pro3 from '../../../images/maintainence-icon.svg'
import pro4 from '../../../images/development-icon.svg'
import pro5 from '../../../images/testing-icon.svg'

const AbtSuccessData = [
    {
        title:"1",
        text: "Everything Starts with an Idea and a Plan.",
       imgsrc: pro1,  
       head: " Analysis & Planning"
    },
    {
        title:"2",
        text: "Our creative designers create interactive and beautiful UI/UX.",
       imgsrc: pro2,  
       head: "UI/UX Design"
     },
     {
        title:"3",
        text: "Our app developers convert your creative idea into a working app",
       imgsrc: pro3,  
       head: "Development Process"
     },
     {
        title:"4",
        text: "We test the app for any possible bugs and errors before it is deployed.",
       imgsrc: pro4,  
       head: "Testing"
     },
     {
        title:"5",
        text: "Uptimise IT offers mobile app maintenance &amp; Support to ensure the app’s good performance.",
       imgsrc: pro5,  
       head: "Maintenance & Support"
     },
     
    
]

export default AbtSuccessData ;