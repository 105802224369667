import React from 'react'
import LoanAppData from './LoanAppData'
import LoanAppWork from './LoanAppWork'

const LoanApp = () => {
  return (
    <>
       {LoanAppData.map((val,ind) => {
        return (
          <LoanAppWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default LoanApp;
