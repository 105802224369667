import React from 'react'
import { Link } from 'react-router-dom'

const IndustryWork = (props) => {
  return (
    
      <div className="col-lg-4">
                  <div className="slider-box">
                    <figure>
                      <img src={props.imgsrc} alt="uptimise it" />
                    </figure>
                    <div className="slider-content-box">
                      <h3>
                        <Link to="/mobile-banking-app-development" >{props.title}</Link>
                      </h3>
                      <p className="show">
                        {props.text}
                      </p>
                      <Link to="/mobile-banking-app-development" className="readmore-btn">Read More
                        <i className="fa fa-angle-right" aria-hidden="true" /></Link>
                    </div>
                  </div>
                </div>
    
  )
}

export default IndustryWork
