import pro1 from '../../images/army.png'
import pro2 from '../../images/army.png'


const TestimonialData = [
    {
        title: "Help us improve our productivity",
       imgsrc: pro1,  
       text: " “Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
    },
    {
        title: "Help us improve our productivity",
        imgsrc: pro2,    
        text: " “Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
     },
   
     
    
]

export default TestimonialData;