import pro1 from '../../../images/real-time-location-tracking-logistic.png'
import pro2 from '../../../images/customizable-alerts-logistic.png'
import pro3 from '../../../images/sensor-technology-logistic.png'
import pro4 from '../../../images/vehicle-utilization-reports-logistic.png'


const LogiData = [
    {
        title:"Real-Time Location Tracking",
        text: "Our logistics software development includes real-time cargo GPS tracking, so updates can be provided to managers and your customers.",
       imgsrc: pro1,  
       
    },
    {
        title:"Customizable Alerts",
        text: "Not every fleet manager wants to receive the same type of notifications, as their priorities may differ. This feature allows the fleet managers to choose whether they want to receive notifications about driver behavior, vehicle conditions, route anomalies, or any other parameters.",
       imgsrc: pro2,  
       
    },
    {
        title:"Sensor Technology",
        text: "The logistics software solution can be coupled with IoT sensors, which will help in optimizing warehouse operations and deliveries. These sensors also help with security as abnormal cargo movement or applied pressure can be identified.",
       imgsrc: pro3,  
       
    },
    {
        title:"Vehicle Utilization Reports",
        text: "Knowing which vehicles have been deployed and how frequently they are used can be very beneficial for transportation and logistics companies. Doing it manually or in an excel spreadsheet is very inefficient. We can incorporate this feature into your logistics app, so you can visualize this data.",
       imgsrc: pro4,  
       
    },
   
    
       
]

export default LogiData ;