import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import DevHead from './development/andHeader/DevHead'

import pic from "../images/nodejs-developers-banner-img.webp"
import pic1 from "../images/nodejs-developers-small-img.webp"
import pic2 from "../images/500-Planning.png"
import pic3 from "../images/8665237_code_development_icon.png"
import pic4 from "../images/6619436_a_ab_abtest_b_seo_icon.png"
import pic5 from "../images/500-Planning.png"
import pic6 from "../images/control_x2C__management.png"
import Industry from "./industry/Industry"
import Success from "./success/Success"
import NodeCard from './hiredevelopers/node/NodeCard'

import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"

export default function Nodejs() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong> NodeJS Developer</strong></h1>
                  <p><strong>Hire NodeJS Developers</strong> who help you design and deploy rebost, data-driven, and scalable solutions. With our help, you can take over the market and generate groundbreaking revenue.</p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
              <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>NodeJS Developers </span>
                  <h3>Experienced NodeJS Developers for Hire </h3>
                  <p>NodeJS is popular throughout the industry for its front-end <strong>UI/UX design</strong> capacities. When done right, you can design an aesthetically pleasing, fluid, and easy-to-navigate website that attracts&amp; engage a potential customer base.</p>
                  <p>You can <strong>Hire NodeJS Programmers</strong> with Uptimise IT. Our team follows a flexible approach and only makes use of the latest technologies. This is how we help you generate millions of dollars in revenue.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section opportunities-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire NodeJS Developers</span>
              <div className="mobiles_head">
                <h4>Hire Dedicated Node JS Developer Who Deliver Data-Driven Solutions </h4>
                <p>Uptimise IT provides vettednodeJS developers for hire who deliver robust development solutions. Some of our top NodeJS services are, as mentioned below: </p>
              </div>
            </div>
            <div className="row">
              <NodeCard />
            </div>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">As a leading <strong>Node JS app development company,</strong> we offer several different methods when it comes to hiring developers. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>With Uptimise IT, you can hire node.js developerson full time bases. </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro1} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>If full time is not for you, you can hire them on part time bases. </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro2} alt="uptimise it" />
                      </figure>
                      <h3>Hourly</h3>
                      <p>Hiring node.js developers on hourly bases are yet another popular option among clients.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Why Hire NodeJS Developers?</h3>
                  <p>There are various reasons to <strong>hire JS Developers.</strong> With this robust technology, you can develop, deploy, and manage stellar NodeJS apps. In addition, Node-based solutions are quite compatible with third party technologies. Much of the credit for amazing performancegoes to multithreading capacity.</p>
                  <ul>
                    <li>Cost-Effective nodeJS Development</li>
                    <li>Resourceful Development Team </li>
                    <li>Dedicated Developers </li>
                    <li>Agile and transparent process </li>
                    <li>State-of-the-art development tools and technology</li>
                    <li>100% Customer Satisfaction</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
             <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section request-section">
          <div className="container">
            <div className="request-content">
              <h3>Let’s Discuss The Next Big Idea?</h3>
              <p>Bring your Idea to us, we can create it into a working mobile app.</p>
              <Link to="#" className="btn btn-primaryx">Get Free Consultation Now</Link>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>PHP Development Process</h4>
                <p>The top <strong>NodeJS developers</strong> at Uptimise IT follow a well-planned out process that allows us to ensure high quality and satisfactory solutions.</p>
              </div>
            </div>
            <div className="row process_work">
              <div className="col-lg-5">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic2} />
                    <h3>1</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Analysis &amp; Planning</h3>
                    <p>PHP web application development starts with analyzing and planning the entire development in great detail.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3 className="text-right">UI/UX Design</h3>
                    <p>You can <strong>hire NodeJS experts</strong> at, Uptimise IT to create the best user interface and user experience design</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>2</h3>
                    <img src={pic3} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic4} />
                    <h3>3</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Development Process</h3>
                    <p>It is in this process that our team of developer create your idea into the next best <strong>magneto eCommerce store.</strong></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Testing</h3>
                    <p>After development, the second step of PHP development services is testing the solution.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>4</h3>
                    <img src={pic5} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic6} />
                    <h3>5</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Maintenance &amp; Support</h3>
                    <p>Even after deployment, we provide amazing <strong>maintenance and support</strong> services that ensure the quality of your solutions in the long run.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color work-inner-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
              <Success />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="education-parent">
                  <h3>Medical Education App Development</h3>
                  <p>We developed an Elearning app platform for the Japanese market. This app provides users with educationalvideos and lectures related to the medical sector.</p>
                </div>  
                <div className="case-btn">
                  <Link to="#" className="btn btn-primaryx">Case Study</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="education_slider">
                  <div className="owl-carousel owl-theme">
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some frequently asked questions for people looking to <strong>hire node developers.</strong></p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          How do I hire node js developer?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">If you want to hire a NodeJS developer, you have come to the right place. Uptimise IT is a market-leading nodejs development company with years of experience and expertise in the field. Our work is proven and platforms like Clutch.co rank us among the top development company. Here, you can hire the top Node JS developers.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          Is node js developer in demand?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Node JS developers are in high demand in the market due to the affordability of the solutions and amazing results. This javascript-based framework delivers amazing UI/UX designs. So, if you are looking to hire a NodeJS developer, Uptimise IT is here to help you.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it cost to build a node?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          As per the market statistics, the average cost to build a node solution may range from $40,000 to $400,000.<br />
                          If you are looking for a closer estimate, you can contact us and we will give a quote based on your project specifications. 
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Which is better React Native or NodeJS? 
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Both <strong>React native development services</strong> and NodeJS services are perfect their own right. Depending on your project needs and preferences, both react native and Node can deliver satisfactory results.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Do you Sign NDA? 
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT is always willing to go an extra mile for clients. This is why we always sign an NDA or non disclosure agreement to protect the privacy and idea originality of the client.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
   
    )
}