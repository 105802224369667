import pro1 from '../../../images/flow-architectureuxui.png'
import pro2 from '../../../images/visual-designinguxui.png'
import pro3 from '../../../images/prototype-buildinguxui.png'
import pro4 from '../../../images/testing-qauxui.png'
import pro5 from '../../../images/researchuxui.png'
import pro6 from '../../../images/user-audituxui.png'
import pro7 from '../../../images/ux-design-development.png'
import pro8 from '../../../images/customer-demanduxui.png'

const DesignData = [
    {
        title:"Flow Architecture",
        text: "We design UX architecture and flow that providesan amazing user experience combing design and function.",
       imgsrc: pro1,  
       
    },
    {
        title:"Visual Designing",
        text: "Uptimise IT looks at the design from the users’ perspective and combines usability &amp; aesthetics accordingly.",
       imgsrc: pro2,  
     
     },
     {
        title:"Prototype Building",
        text: "Our designers create prototypes of Design and user experience ensuring the best quality solution.",
       imgsrc: pro3,  
     
     },
     {
        title:"Testing & QA",
        text: "We use Concurrent Think Aloud (CTA) Concurrent Probing (CP), Retrospective Think Aloud (RTA),etc, for testing.",
       imgsrc: pro4,  
      
     },
     {
        title:"Research",
        text: "Our UI/UX design process is based on research about the topic and deep knowledge of the niche.",
       imgsrc: pro5,  
    
     },
     {
        title:"User Audit",
        text: "In order to understand the user base better and deliver to their demand, we conduct a user audit.",
       imgsrc: pro6,
     
     },
     {
        title:"UX Design",
        text: "To create a stellar User experience we use our boundless creativity and technical skills. Delivering magical UX.",
       imgsrc: pro7,  
       
     },
     {
        title:"Customer Demand",
        text: "We create UI/UX design that doesn’t only look and feel good but also caters to the customer demand.",
       imgsrc: pro8,  
     
     },
   
     
     
    
]

export default DesignData ;