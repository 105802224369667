import React from 'react'
import pic from "../images/online-market-place-banner-img.png"
import pic1 from "../images/profile-registration-online.png"
import pic2 from "../images/secure-payment-gateway-online.png"
import pic3 from "../images/api-integration-online.png"
import pic4 from "../images/client-support-online.png"
import pic5 from "../images/offers-discounts-online.png"
import pic6 from "../images/market-place-user.png"
import pic7 from "../images/market-place-admin.png"
import pic8 from "../images/market-place-vendor.png"

import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Process from './process/Process'
import Service from './service/Service'
import Values from "./values/Values"
import Market from './market/Market'
import Best from './auto/best/Best'
export default function MarketPlace() {
    return (
        <div id="content">
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Online Marketplace <br /> App Development </strong> Company </h1>
              <p>Uptimise IT is the best marketplace app development company, helping businesses grow online.</p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Online Marketplace</span>
              <div className="mobiles_head">
                <h4>Top Features of Marketplace App Development</h4>
                <p>A web app or app is only as good as its features in it. That’s why Uptimise IT as a leading mobile app development company imbeds solutions with the best features.</p>
              </div>
            </div>
            <div className="industry-icon-parent">
              <ul>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Profile Registration </h3>
                      <p>The user can register their profile via a different medium. This includes e-mail, phone number, or social media like Facebook, Instagram, etc. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic1} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Secure Payment Gateway</h3>
                      <p>Our solutions make it easier for the users to pay for their purchases via the app itself through secure payment gateway integration. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic2} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>API Integration</h3>
                      <p>By integrating third-party API into our online marketplace app or web app, we make our solution much more interactive and functional. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic3} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>24/7 Client Support </h3>
                      <p>24/7 client support functionality allows the vendors to respond to and solve the problems of customers throughout the day and night. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic4} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Offers &amp; Discounts</h3>
                      <p>Another top feature we add as an online marketplace app development company is of creating and availing offers, coupons, discounts, cashback, etc.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic5} alt="uptimise it" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section opportunities-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Online Marketplace</span>
              <div className="mobiles_head">
                <h4>Build a Marketplace, Grow Your Business</h4>
                <p>Every business has their own needs and there is no one size fits all. This is why Uptimise IT as a leading marketplace app development company offers a range of services. Our top services are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
             
            <Market />
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Our Portfolio</h2>
                  <p>Uptimise IT have developed and delivered hundreds of successful projects. Here are some of our success stories. </p>
                  <Link to="contact" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section app-panel">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Online Marketplace Panels</h4>
                <p>As a leading marketplace app development company, Uptimise IT fit the solution with all required functionality. Below you can see the features of all three panels: </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic6} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>User panel</h3>
                  <p>User are the most important part of the app and they can effectively shop, pay, track orders, etc through the user panels.</p>
                  <div className="panel-list">
                    <ul>
                      <li>Easy registration process </li>
                      <li>Cart option </li>
                      <li>Secure payment gateway </li>
                      <li>Advanced search </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic7} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>Admin panel</h3>
                  <p>The admin panel enables the ability to effectively supervise and manage the platform. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Interactive dashboard </li>
                      <li>Delete or Edit profiles </li>
                      <li>Payment management </li>
                      <li>Create offers, discounts, etc. </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic8} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>Vendor </h3>
                  <p>The vendor panel allows the sellers to effectively list and sell their products on the marketplace. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Add products or services to inventory.</li>
                      <li>Receive and manage payment</li>
                      <li>Real-time customer support </li>
                      <li>Manage Inventory </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section web-development-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Marketplace App </span>
              <div className="mobiles_head">
                <h4>Uptimise IT, The Best Marketplace App Development Company </h4>
                <p>If you are looking for a top mobile app development companyto build a marketplace, we are here to help you. Uptimise IT is the best marketplace app development company. Some reasons to choose us are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
             <Best />
            </div>
          </div>
        </section>
        <section className="same-section technical-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Technology To Build Marketplace Solution</h4>
                <p>Uptimise IT uses the latest technologies and tools to develop, test, and deploy at top-notch level. </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Framework </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Ionic </li>
                        <li>Onsen UI </li>
                        <li>PhoneGap</li>
                        <li>Codename One </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Languages </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Java </li>
                        <li>Kotlin</li>
                        <li>JavaScript </li>
                        <li>Swift </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Testing Tools </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Test IO </li>
                        <li>Espresso </li>
                        <li>XCUI Test</li>
                        <li>Appium</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Stacks </h3>
                    <div className="panel-list">
                      <ul>
                        <li>MEAN Stack </li>
                        <li>MERN stack </li>
                        <li>Full Stack </li>
                        <li>ADT</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Solutions</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                    </div>
                  </div>
                </div>
              </div>
              <Service />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Online Marketplace Development Process</h4>
                <p>AS a leading Marketplace development company, Uptimise IT follows a well-planned out agile development process. This helps us ensure the quality of our solution.</p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section bg-color company-box-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
              </div>
            </div>
            <div className="row">
             <Values />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Innovative Mobile App/Web Development Services</h3>
              <p>Uptimise IT is a leading mobile app and web Development Companythat offers amazing services across different industries.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
    <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some of the most frequently asked questions about Service marketplace development.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          What is a service marketplace?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">A service marketplace is a platform where sellers can list their services or products and the users can avail these services. This can be based on different business models including b2b, b2c, c2c, and so on. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How do I create a marketplace service?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">If you want to create a marketplace service in USA, we can help you. Uptimise IT is a market-leading marketplace app Development Company with proven work performance and years’ worthof experience.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          What are the examples of marketplace services?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Some of the popular marketplace services are, as mentioned below:
                          <ul>
                            <li>Consultancy Services </li>
                            <li>Marketplace Migration and Upgradation Service </li>
                            <li>Maintenance and Support Services </li>
                            <li>Cross-Platform Marketplace Development </li>
                            <li>Marketplace Android Development </li>
                            <li>Marketplace iOS Development </li>
                            <li>Marketplace Website Development </li>
                            <li>Custom Marketplace Development </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Why I should Choose you?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT is a market-leading mobile app Development Company with years’ worth of experience and a creative approach to development. As such, we have a proven track record plus we have also worked with hundreds of clients, delivering successful market-leading solutions. Even, clutch.co recognizes us as one of the best web and app development company.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
    )
}