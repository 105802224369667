import React from 'react'
import ReviewsWork from './ReviewsWork'
import ReviewsData from './ReviewsData'

const Reviews = () => {
  return (
    <>
       {ReviewsData.map((val,ind) => {
                return (
                  <ReviewsWork
                  key={ind}
                  imgsrc={val.imgsrc}
                  />
                )
               } )}    
    </>
  )
}

export default Reviews;
