import React from 'react'

const AbtSuccessWork = (props) => {
  return (
    <li>
    <div className="dating-big-content">
      <h2>{props.title}</h2>
    </div>
    <div className="dating-work-content">
      <figure>
        <img src={props.imgsrc} alt="image" />
      </figure>
      <h3>{props.head}</h3>
      <p>{props.text}</p>
    </div>
  </li>
  )
}

export default AbtSuccessWork;
