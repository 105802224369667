import React from 'react'
import CardData from './CardData'
import Cardpath from './Cardpath'



const Card = () => {
  return (
    <  >
               {CardData.map((val,ind) => {
                return (
                  <Cardpath 
                  key={ind}
                  imgsrc={val.imgsrc}
                  title={val.title}
                  text={val.text}
                  view={val.view}
                  />
                )
               } )}    
                    
                  </>
  )
}

export default Card
