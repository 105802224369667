import React from 'react'
import EduWork from './EduWork'
import EduData from './EduData'

const Edu = () => {
  return (
    <>
    {EduData.map((val,ind) => {
        return (
          <EduWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
       </>
  )
}

export default Edu
