import React from 'react'

const TouchWork = (props) => {
  return (
    <>
       <option value={props.num}>{props.text}</option>
    </>
  )
}

export default TouchWork
