import React from 'react'
import ItSupportData from './ItSupportData'
import ItSupportWork from './ItSupportWork'

const ItSupport = () => {
  return (
    <>
      {ItSupportData.map((val,ind) => {
              return (
                <ItSupportWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )}  
    </>
  )
}

export default ItSupport
