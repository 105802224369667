
import pro1 from '../../../images/geo-location02.png'
import pro2 from '../../../images/third-party-integration01.png'
import pro3 from '../../../images/tokenization02.png'
import pro4 from '../../../images/USSD-Payments.png'
import pro5 from '../../../images/app-camera01.png'
import pro6 from '../../../images/artificial-intelligence01.png'

import { Link } from 'react-router-dom'


const AdvanceData = [
    {
        title:"Geo Location",
        text: "The Geo-Location feature can be used for finding nearby utility service providers so that the users don’t have to search for them manually.",
       imgsrc: pro1,  
       
    },
    {
        title:"Third-party Integration",
        text: "The Ewallet can be integrated with the party service providers. It allows users to make instant payments without switching to the mobile payment app.",
       imgsrc: pro2,  
     
     },
     {
        title:"Tokenization",
        text: "The safety of the user’s banking details is the foremost priority for a mobile payment service provider. Therefore we implement the legacy tokenization system into your mobile payment app.",
       imgsrc: pro3,  
     
     },
     {
        title:"USSD Payments",
        text: "For users that don’t have smartphones, we’ve integrated the USSD payment option into our mobile payment solutions.",
       imgsrc: pro4,  
      
     },  
     {
        title:"App Camera",
        text: "An in-app camera can be used for scanning the QR code from a recipient’s app. It can also be used for in-app customer helpline services.",
       imgsrc: pro5,  
      
     }, 
     {
        title:"Artificial Intelligence",
        text:  <p>With Ewallet app development, we implement advanced AI algorithms that help the <strong><Link to="how-to-use-ai-for-developing-automated-mobile-app/index">AI mobile app</Link></strong> to adapt the user behavior and preferences.</p>,
       imgsrc: pro6,  
      
     }, 
   
]

export default AdvanceData ;