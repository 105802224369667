import pro1 from '../../../images/blockchain-logistic.png'
import pro2 from '../../../images/rfdi-logistic.png'
import pro3 from '../../../images/ble-logistic.png'
import pro4 from '../../../images/gpd-logistic.png'


const TechnoData = [
    {
        title:"Blockchain",
        text: "To ensure that the in-transit goods are delivered safely, and in agreement with contract terms, smart contracts via the blockchain are very effective. Blockchain-based logistics solutions can help shippers make faster transactions and provide more efficient location updates.",
       imgsrc: pro1,  
       
    },
    {
        title:"RFID",
        text: "To mitigate the theft risk of stored and in-transit goods, we can make use of RFID tags to help you manage your inventory effectively.",
       imgsrc: pro2,  
       
    },
    {
        title:"BLE",
        text: "We develop logistics apps to integrate Bluetooth Low Energy (BLE) sensors that measure humidity, temperature, and the proximity of the environment where the goods are stored. This can also be used for goods in transit.",
       imgsrc: pro3,  
       
    },
    {
        title:"GPS",
        text: "To get the real-time precise location of your cargo. We can integrate GPS sensors with our transport and logistics software solutions.",
       imgsrc: pro4,  
       
    },
   
    
       
]

export default TechnoData ;