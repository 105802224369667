import React from 'react'
import DevFutureData from './DevFutureData'
import DevFutureWork from './DevFutureWork'

const DevFuture = () => {
  return (
  <>
   {DevFutureData.map((val,ind) => {
                return (
                  <DevFutureWork
                  key={ind}
                  title={val.title}
                  imgsrc={val.imgsrc}
                  text={val.text}
                  />
                )
               } )}   
  </>
  )
}

export default DevFuture
