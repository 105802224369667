import React from 'react'
import EventData from './EventData'
import EventWork from './EventWork'

const Events = () => {
  return (
    <>
      {EventData.map((val,ind) => {
                return (
                  <EventWork
                  key={ind}
                  title={val.title}
                  imgsrc={val.imgsrc}
                  text={val.text}
                  />
                )
               } )}   
    </>
  )
}

export default Events
