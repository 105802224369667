import pro from "../../../../images/eeo-location-job.png"
import pro1 from '../../../../images/in-app-camera-job.png'
import pro2 from '../../../../images/push-notifications-job.png'
import pro3 from '../../../../images/back-up-job.png'
import pro4 from '../../../../images/multilingual-job.png'
import pro5 from '../../../../images/source-code-authorization-job.png'






import { Link } from 'react-router-dom'


const GeoData = [
    {
        title:"Geo Location",
        text: "This feature can be used by job seekers that are searching for jobs from companies nearby.",
       imgsrc: pro,  
       
    },
    {
        title:"In-App Camera",
        text: "Users can upload the image of their resume into the app by using the in-app camera feature.",
       imgsrc: pro1,  
       
    },
    {
        title:"Push Notifications",
        text: "Users can be notified ofthe latest jobs in the market through SMS, email, or push notifications.",
       imgsrc: pro2,  
     
     },
     {
        title:"Back-Up",
        text: "Integrate your recruitment app with an auto-backup feature, so that the user can easily restore all of their previous settings and data.",
       imgsrc: pro3,  
     
     },
     {
        title:"Multilingual",
        text: "The job searching app for Android and iPhone supports multiple languages, which allows the users to navigate through the app in their desired language.",
       imgsrc: pro4,  
      
     },  
     {
        title:"Source Code Authorization",
        text: "The project's source code is delivered to youto ensure that the source code is not disclosed to any other parties.",
       imgsrc: pro5,  
      
     }, 
      
   
]

export default GeoData ;