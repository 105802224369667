import React from 'react'
import LogiAutoData from './LogiAutoData'
import LogiAutoWork from './LogiAutoWork'

const LogiAuto = () => {
  return (
    <>
      {LogiAutoData.map((val,ind) => {
        return (
          <LogiAutoWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
    </>
  )
}

export default LogiAuto;
