import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Service from "./service/Service"
import Process from "./process/Process"
import Values from "./values/Values"

import pic from "../images/event-banner-images.png"
import pic1 from "../images/stay-alert-events.png"
import pic2 from "../images/aesthetic-UI_UX-events.png"
import pic3 from "../images/effortless-guest-management-events.png"
import pic4 from "../images/safe-secure-quick-payment-events.png"
import pic5 from "../images/event-user.png"
import pic6 from "../images/event-admin.png"
import pic7 from "../images/event-organizer-img.png"
import Events from './event/Events'
import Time from './time/Time'


export default function Event() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Event App Development <br /></strong> Company </h1>
              <p>With 250+ Successful Project Delivery Uptimise IT is the right Event App Development Company to Convert Your Idea into Reality. </p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Event App Development </span>
              <div className="mobiles_head">
                <h4>Stellar Event App Development</h4>
                <p>Uptimise IT is a market-leading <strong>Mobile App Development Company</strong> in USA. We add the most useful and unique feature to our <strong>event app development</strong> ensuring humane solutions.</p>
              </div>
            </div>
            <div className="industry-icon-parent">
              <ul>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Stay Alert</h3>
                      <p>The push notification feature of our event app keeps the user alert about different activities.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic1} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Aesthetic UI/UX</h3>
                      <p>In addition to amazing feature, we also built our solutions with a visually pleasing user interface and user experience.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic2} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Effortless Guest Management</h3>
                      <p>The event management mobile app allows you to manage guest list in real time effortlessly. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic2} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Safe, Secure, Quick Payment</h3>
                      <p>Buy tickets through the app itself and pay with multiple fast &amp; secure payment gateways. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic3} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Smooth Check in</h3>
                      <p>Another highlighting feature is that it allows a smooth in app check in process for the guests.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic4} alt="uptimise it" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section opportunities-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Event App Development</span>
              <div className="mobiles_head">
                <h4>Data-Driven Event App Management Services</h4>
                <p>Uptimise IT offers data-driven <strong>Event App Management Services.</strong> Our solutions are based on your needs and help your business grow to the desired level. Our top services are, as mentioned below: </p>
              </div>
            </div>
            <div className="row">
              <Events />
            </div>
          </div>
        </section>
       
        <section className="same-section app-panel">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Panels of Event Application Development</h4>
                <p>Our <strong>event application development</strong> can be divided into three parts to make it easier for the users to do their job and enjoy the services. </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic5} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>User panel</h3>
                  <p>The user panel allows the customers to buy a ticket and enjoy the event effortlessly. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Navigate the different events in area </li>
                      <li>Social Media Integration Synchronization </li>
                      <li>Secure Payment Gateway Methods</li>
                      <li>Buy event tickets or register for event through the app. </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic6} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>Admin panel</h3>
                  <p>This panel allows the admin to effectively manage the event and its various aspects. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Interactive and informative Dashboard </li>
                      <li>Real-Time customer support system </li>
                      <li>Guest and attendee management </li>
                      <li>Managing ticket pricing </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div> 
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic7} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>Event Organizer Panel</h3>
                  <p>The event organizer panel allows for streamlining operations and delivering a smooth event. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Attendees and visitors detail management </li>
                      <li>Live announcements  through the app </li>
                      <li>Event ticket booking and cancellation management </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>            
          </div>
        </section>
        <section className="same-section web-development-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Event Management</span>
              <div className="mobiles_head">
                <h4>Choose The Best Event App Developers </h4>
                <p>Customers are our pole star and we deliver unmatched value to them. Uptimise IT is all about creating fulfilling solutions. Some reasons to choose our event app developers are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <Time />
            </div>
          </div>
        </section>
        <section className="same-section technical-section automotive-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Event Application Development Technologies &amp; Tools</h4>
                <p>Uptimise IT  only uses the latest technologies &amp; tools for developing, designing, and testing stellar solutions. </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Framework </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Ionic </li>
                        <li>Onsen UI </li>
                        <li>PhoneGap</li>
                        <li>Codename One </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Languages </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Java </li>
                        <li>Kotlin</li>
                        <li>JavaScript </li>
                        <li>Swift </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Testing Tools </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Test IO </li>
                        <li>Espresso </li>
                        <li>XCUI Test</li>
                        <li>Appium</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Stacks </h3>
                    <div className="panel-list">
                      <ul>
                        <li>MEAN Stack </li>
                        <li>MERN Stack </li>
                        <li>Full Stack </li>
                        <li>ADT</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Solutions</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                    </div>
                  </div>
                </div>
              </div>
             <Service />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Event App Development Process </h4>
                <p>AS a leading Event App Development Company, Uptimise IT follows a well-planned out agile development process. This helps us ensure the quality of our solution.</p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Our Portfolio</h2>
                  <p>Uptimise IT has developed and delivered hundreds of successful projects. Here are some of our success stories. </p>
                  <Link to="#" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color company-box-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
              </div>
            </div>
            <div className="row">
              <Values />
            </div>
          </div>
        </section>
       
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Innovative Mobile App/Web Development Services</h3>
              <p>Uptimise IT is a leading mobile app and web Development Company that offers amazing services across different industries.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
      <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>These are answers to some of the most frequently asked questions about Event App Development.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          How much does it cost to develop an event planning app?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The cost to develop an event planning mobile app can range from $20,000 to $80,000. However, the cost depends on a number of different factors. These are, as mentioned below: 
                          <ul>
                            <li>Developer Location</li>
                            <li>Complexity </li>
                            <li>Features </li>
                            <li>UI/UX Design </li>
                            <li>Size of App </li>
                          </ul>
                          If you are looking for a closer estimate, you can contact us with your project specification and we will give you a quote. 
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          What are the must-included features in event management app solutions?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Some of the must include the feature in an event management app solution are, as mentioned below:
                          <ul>
                            <li>Push Notification</li>
                            <li>Real-time management </li>
                            <li>Profile Registration </li>
                            <li>Secure Payment Gateway</li>
                            <li>In app communication </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Why should we go for Uptimise IT to have event app development services?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT is the Best Event App Development Company you can find in the USA. We have years’ worth of experience along with the right tools and creative approach required to deliver market-leading solutions. So, if you want to develop a mobile app of your own, we can help you with it.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          How do you build event planning software solutions?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The event planning software development process followed by the event app developers at Uptimise IT is, as mentioned below:
                          <ul>
                            <li>Analysis &amp; Planning</li>
                            <li>UI/UX Design</li>
                            <li>Development Process</li>
                            <li>Testing</li>
                            <li>Maintenance &amp; Support</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much time you will take to develop an event management website?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The time taken to develop an event management website can vary from 1 month to 3 months. If you are looking for a closer estimate, you can contact us with your project specification and we will give you a quote.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>

   
    )
}