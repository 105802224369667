import React from 'react'
import AbtSuccessWork from './AbtSuccessWork'
import AbtSuccessData from './AbtSuccessData'

const AbtSuccess = () => {
  return (
    <>
       {AbtSuccessData.map((val,ind) => {
                return (
                  <AbtSuccessWork
                  key={ind}
                  head={val.head}
                  title={val.title}
                  imgsrc={val.imgsrc}
                  text={val.text}
                  />
                )
               } )}    
    </>
  )
}

export default AbtSuccess
