import pro from '../../../images/nodejs-web-app-development-node.webp'
import pro1 from '../../../images/web-and-mobile-usux-development-node.webp'
import pro2 from '../../../images/custom-nodejs-app-development-node.webp'
import pro3 from '../../../images/nodejs-maintenance-and-support-node.webp'
import pro4 from '../../../images/nodejs-real-time-app-development-node.webp'
import pro5 from '../../../images/nodejs-cms-development-node.webp'
import pro6 from '../../../images/nodejs-migration-and-upgradation-services-node.webp'
import pro7 from '../../../images/hire-dedicated-nodejs-developers-node.webp'
import pro8 from '../../../images/nodejs-consultancy-services-node.webp'



const NodeCardData = [
    {
        title:"NodeJS Web App Development",
        text: <p>As a Leading <strong>NodeJS App Development company,</strong> we know how to develop market-leading web apps.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Web and Mobile UI/UX Development ",
        text:   <p>We create aesthetically pleasing and interactive web//mobile user interface &amp; user experience design.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Custom NodeJS App Development",
        text:   <p>With our custom NodeJS Development Service, you can bring your concept to a reality that is tailor-made.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"NodeJS Maintenance and Support",
        text:  <p>Not only development but we also provide scalable NodeJS <strong>Maintenance and Support services.</strong> </p>,
       imgsrc: pro3,  
     
     },
     {
        title:"NodeJS Real-Time App Development",
        text:  <p>Our team of <strong>web developers</strong> has the right experience needed to develop and deploy a real-time NodeJS App. </p>,
       imgsrc: pro4,  
      
     },
     {
        title:"NodeJS CMS Development",
        text: <p>NodeJs-based content management system that allows you to update and optimize content as per your needs.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"NodeJS Migration And Upgradation Services",
        text:  <p>If you want to move on from legacy system, we provide NodeJS mugration and Upgradation services.</p>,
       imgsrc: pro6,  
    
     },
     {
        title:"Hire Dedicated NodeJS Developers",
        text: <p>With Uptimise IT, you can <strong>hire dedicated NodeJS developers</strong> who deliver robust solutions for you. </p>,
       imgsrc: pro7,  
    
     },
     {
        title:"NodeJS Consultancy Services",
        text: <p>In addition to <strong>development and maintenance services,</strong> we also provide consultancy services for NodeJS.</p>,
       imgsrc: pro8,  
    
     },
  
   
     
     
    
]

export default NodeCardData ;
