import React from 'react'
import { Link } from 'react-router-dom'

const TestimonialWork = (props) => {
  return (
    <div className="col-lg-6">
    <div className="testimonal-parent">
      <Link to="#">
        <h3>{props.title}</h3>
      </Link>
      <div className="testimonal_child">
        <div className="testimonal_img">
          <Link to="#"><img src={props.imgsrc} alt='uptimiseit'/></Link>
        </div>
        
      </div>
      <p>{props.text}</p>
    </div>
  </div>
  )
}

export default TestimonialWork
