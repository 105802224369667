import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn';
import Values from './values/Values';
import Success from './success/Success';
import Industry from './industry/Industry';
import Process from './process/Process';
import pic from "../images/about-us-banner-img.png"
import pic1 from "../images/curvaceous-logo-gray.png"
import pic2 from "../images/dafri-logo-gray.png"
import pic3 from "../images/medu-logo-gray.png"
import pic4 from "../images/student-castle-logo-gray.png"
import pic5 from "../images/drt-logo-gray.png"
import pic6 from "../images/dafri-logo-gray.png"
import pic7 from "../images/about-small-img.png"
import pic8 from "../images/dafri-start.png"
import pic11 from "../images/dating-work-bg.png"
import pic12 from "../images/client-about.png"
import pic13 from "../images/quality-about.png"
import pic14 from "../images/perfomance-about.png"
import pic9 from "../images/slider-img01.png"
import pic10 from "../images/slider-img02.png"
import AbtValues from './about/abt-values/AbtValues';
import AbtSuccess from './about/abt-success/AbtSuccess';



export const About = () => {
  return (
    <div id="content">
    <section className="industries-banner-section banner-section screen">
      <img src={pic} alt="uptimise it" />
      <div className="container">
        <div className="banner-content">
          <h1><strong>Web/Mobile App</strong> Development Company</h1>
          <p>
            Uptimise IT is a leading Mobile App Development Company that is
            driven by futuristic vision and passion.
          </p>
          <div className="industries-btn">
           <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
           <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
          </div>
        </div>
      </div>
    </section>
    <section className="logo-section">
      <div className="container-fluid">
        <div className="logo-parent">
          <ul>
            <li>
             <Link to="#"><img src={pic1} alt="uptimise it" />
              </Link>
            </li>
            <li>
             <Link to="#"><img src={pic2} alt="uptimise it" />
              </Link>
            </li>
            <li>
             <Link to="#"><img src={pic3} alt="uptimise it" />
              </Link>
            </li>
            <li>
             <Link to="#"><img src={pic4} alt="uptimise it" />
              </Link>
            </li>
            <li>
             <Link to="#"><img src={pic5} alt="uptimise it" />
              </Link>
            </li>
            <li>
             <Link to="#"><img src={pic6} alt="uptimise it" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section className="same-section">
      <div className="container">
        <div className="row align-items-center demand-wrapper">
          <div className="col-lg-6">
            <div className="demand-img">
              <img src={pic7} alt="uptimise it" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="demaond-content same-heading">
              <span>Innovation</span>
              <h3>Uptimise IT Stellar Mobile App Development Company</h3>
              <p>
                Uptimise IT is a bespoke
                <strong>Web and Mobile app development company</strong> that
                has been delivering industry-leading solutions since 2015. We
                are not like your other boring development companies, our team
                of excellent developers and designers define new paths in the
                world of IT with out-of-the-box creative and interstellar
                development ability.
              </p>
              <p>
                Uptimise IT is dedicated to “doing better” and helping out
                clients shatter their competition. So, far we have developed
                and deployed a number of market-leading solutions.This is
                Uptimise IT for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="same-section portfolio-section">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="protfolio-content text-center">
              <h2>Our Portfolio</h2>
              <p>Explore Our Extraordinary Work.</p>
             <Link to="/ourworks" className="btn btn-default">Discover Now</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="same-section bg-color startup-bussiness">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="dafri-banner">
              <figure>
                <img src={pic8} alt="uptimise it" />
              </figure>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="same-heading businnes_head">
              <span>Let’s Start-Up</span>
              <div className="mobiles_head">
                <h4>Let’s Create Next Million Dollar App</h4>
                <p>
                  You have an out-of-the-world idea and we have all the
                  necessary resources needed to develop it. So, let’s create
                  the next big app that will make millions of dollars and
                  shake the market.
                </p>
                <div className="businnes-start-btn">
                 <Link to="/contact" className="btn btn-primaryx">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="same-section slider-wrapper">
      <div className="container">
        <div className="same-heading text-center">
          <span>Industries</span>
          <div className="mobiles_head">
            <h4>
              Innovative Mobile App Development Services Across Industries
            </h4>
            <p>
              Our mobile app development services are spread across
              industries. While we are a well-renowned FinTech application
              development company, we also deliver our amazing services in
              other industries. Let’s have a look:
            </p>
          </div>
        </div>
        <div className="row">
          <Industry />
          <div className="view-btn">
           <Link to="/industry" className="btn btn-primaryx">View All</Link>
          </div>
        </div>
      </div>
    </section>
    <section className="same-section bg-color work-inner-parent">
      <div className="container">
        <div className="same-heading text-center">
          <span>Why Uptimise IT?</span>
          <div className="mobiles_head">
            <h4>Missing Piece To Your Success Formula</h4>
            <p>
              Choose Uptimise IT because you deserve nothing but the best. As
              the leading mobile app development agency, we have years of
              experience and a proven track record to back it.
            </p>
          </div>
        </div>
        <div className="row">
       <Success />
        </div>
      </div>
    </section>
    <section className="same-section value-parent-box">
      <div className="container">
        <div className="same-heading text-center">
          <span>Our Values</span>
          <div className="mobiles_head">
            <h4>What Makes Us,</h4>
            <p className="text-center">
              Uptimise IT is a mobile app development company driven by a
              mission and working towards a vision. We have built ourselves
              around the values which enable us to deliver unremarkable
              results.
            </p>
          </div>
        </div>
        <div className="row">
        <div className="col">
            <div className="values-parent">
              <div className="value-inner-content">
                <h3>Mission</h3>
                <p>
                  Uptimise IT aims to improve the financial sector with
                  innovative fintech apps, faster transactions, and better
                  security.
                </p>
              </div>
            </div>
          </div>
          <AbtValues />
        </div>
      </div>
    </section>
    <section className="same-section industries-dating-section bg-color">
      <div className="container">
        <div className="same-heading text-center">
          <div className="mobiles_head">
            <h4>Agile, Quality, Successful Digital Solutions</h4>
            <p>
              As a globally renowned app Development Company, Uptimise IT
              follows a mobile app development process that is agile. We never
              compromise with the quality and provide high time to market to
              our clients. Uptimise IT knows how to deliver Success.
            </p>
          </div>
        </div>
        <div className="dating-wrok-wrapper">
          <img src={pic11} alt="uptimise it" />
          <div className="work-inner-wrapper">
            <ul>
             <AbtSuccess />
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="same-section watch-box">
      <div className="container">
        <div className="same-heading text-center mb-5">
          <div className="mobiles_head">
            <h4 className="mb-0">We Care</h4>
            <p className="text-center">
              Uptimise IT is a mobile app development company driven by
              Values.
            </p>
          </div>
        </div>
        <div className="row about-watch-section">
          <div className="col-lg-4">
            <div className="model-box-parent">
              <div className="model-box-inner">
                <figure>
                  <img src={pic12} alt="uptimise it" />
                </figure>
                <h3>Clients</h3>
                <p>
                  Uptimise IT doesn’t just sell services, we create
                  relationships. Our clients are the most important parts of
                  our work.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="model-box-parent change-bg-img">
              <div className="model-box-inner">
                <figure>
                  <img src={pic13} alt="uptimise it" />
                </figure>
                <h3>Quality</h3>
                <p>
                  Our bespoke mobile app development services are driven by
                  quality and we never cut corners.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="model-box-parent">
              <div className="model-box-inner">
                <figure>
                  <img src={pic14} alt="uptimise it" />
                </figure>
                <h3>Performance</h3>
                <p>
                  We don’t just make our solutions look pretty but also create
                  a solid foundation that leads to amazing real-life
                  performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="same-section industries-service-banner">
      <div className="container">
        <div className="request-content">
          <h3>Hire Mobile App Developers</h3>
          <p>
            With Uptimise IT you can hire the best mobile app developers who
            will deliver solutions that will help you grow.
          </p>
         <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
        </div>
      </div>
    </section>
    <HomeIn />
  </div>
  )
}

export default About
