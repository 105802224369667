import React from 'react'

const AbtValuesWork = (props) => {
  return (
    <div className="col">
    <div className="values-parent">
      <div className="value-inner-content">
        <h3>{props.title}</h3>
        <p>
          {props.text}
        </p>
      </div>
    </div>
  </div>
  )
}

export default AbtValuesWork;
