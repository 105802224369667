import pro1 from '../../../images/account-management-banking.png'
import pro2 from '../../../images/transaction-swiftness-banking.png'
import pro3 from '../../../images/24-7-customer-service-banking.png'




const MobileData = [
    {
        title:"Account Management",
        text: "Your banking apps would provide customers with ease of their account management. The customers can manage account information, and cards, and check account balances and transactions via the app only.",
       imgsrc: pro1,  
       
    },
    {
        title:"Transaction Swiftness",
        text: "Modern customers want their banking mobile apps to allow them to make payments and transfer money. Payments via QR codes, NFC, and UPI have become mainstream in digital payments. However, it is crucial for your app to instantly transfer funds.",
       imgsrc: pro2,  
       
    },
    {
        title:"24/7 Customer Service",
        text: "Conversation technology coupled with AI & ML allows banks to offer 24/7 customer service that doesn't require continuous human efforts. The advent of banking chatbots has made customer service cost-efficient for banks.",
       imgsrc: pro3,  
       
    },
 
   
    
       
]

export default MobileData ;