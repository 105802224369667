
import pro1 from '../../../../images/secure-signup.png'
import pro2 from '../../../../images/instant-money-transfer.png'
import pro3 from "../../../../images/linking-bank-account.png"
import pro4 from '../../../../images/investment-advice.png'
import pro5 from '../../../../images/budget-organization.png'
import pro6 from '../../../../images/voice-recognition.png'
import pro7 from "../../../../images/messaging-chatbot.png"
import pro8 from "../../../../images/linking-bank-account.png"

import { Link } from 'react-router-dom'


const Multiata = [
    {
        title:"Secure Sign-Up",
        text: <p>One of the most basic features of every service app is user registration. Users have to register themselves into the app for accessing further features. The sign-up section can be made more secure by integrating various features like fingerprint recognition, facial identification, OTP, or unique code generation.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Instant Money Transfer",
        text: <p>The feature is proven to be useful for grabbing higher user engagement on the app. With the instant money transfer feature, users can easily transfer money to their relatives with zero commission and no fees, through the FinTech application.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Push Notifications",
        text: <p>For better convenience, users must be updated with the latest features and updates of the app without opening the app. Push or real-time notification feature notifies the users with the details about the total monthly spending, pending bill payments, and many more. </p>,
        imgsrc: pro3,
     
     },
     {
        title:"Investment Advice",
        text:  <p>Apart from notifying the users of the latest features and updates, push notifications can also be sent to suggest investment plans. They can plan their monthly, weekly, or quarterly investment decision according to the suggested apt investment plans.</p>,
       imgsrc: pro4,  
      
     },  
     {
        title:"Budget Organization",
        text: <p>Sometimes the monthly budget goes over the limit which basically affects the next few month’s spending. The budget organization helps them to manage and monitor the monthly budget by providing the details of their spending. Moreover, it also provides alternative ways to reduce expenses.</p>,
       imgsrc: pro5,  
      
     }, 
     {
        title:"Voice Recognition",
        text:  <p>For better convenience, voice features with Artificial Intelligence can be integrated into the FinTech app development. Users can interact with the financial applications through a voice command or speech, as well as the apps can also respond to the queries through a voice command.</p>,
       imgsrc: pro6,  
      
     }, 
     {
        title:"Messaging Chatbot ",
        text:  <p><strong><Link to="#">Chatbots integrated with Artificial Intelligence</Link></strong> provide quick replies to the queries and problems of the user as well as assist them through the whole app usage process. This results in better customer retention and improved customer satisfaction.</p>,
       imgsrc: pro7,  
      
     }, 
     {
        title:"Linking Bank Account",
        text:  <p>In order to make or receive payments directly from the bank, users need to link the bank account with the app. For fast transactions, details like card number, expiry date, and CVV has to be entered into the app. All the information is kept confidential and secure through various encryption and security protocols.</p>,
       imgsrc: pro8,  
      
     }, 
   
]

export default Multiata ;