import pro1 from '../../../../images/dedicated-php-developers-php-developers.webp'
import pro2 from '../../../../images/flexible-development-process-php-developers.webp'
import pro3 from '../../../../images/secure-and-robust-php-developers.webp'
import pro4 from '../../../../images/fast-development-timephp-developers.webp'
import pro5 from '../../../../images/clients-first-php-developers.webp'
import pro6 from '../../../../images/latest-tools-and-technologies-php-developers.webp'


const PhpBigCardData = [
    {
        title:"Dedicated PHP Developers",
        text:  <p>We have a team of dedicated PHP developers who work on your project and your project only.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Flexible Development Process",
        text:  <p>Uptimise IT follows a flexible development process that allows us to deliver high-quality solutions.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Secure and Robust",
        text:  <p>You can <strong>hire a PHP developers</strong> with us, who delivers solutions that are secure and robust. </p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Fast Development Time",
        text:  <p>With our extensive experience and creative approach, we offer quite a fast development time.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Clients First ",
        text:   <p>Uptimise IT iaa company that puts clients before anything else thus delivering value to your business.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Latest Tools and Technologies",
        text:  <p>We only use the latest and best tools &amp; technologies ensuring future-proof PHP solutions.</p>,
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default PhpBigCardData ;