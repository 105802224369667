
import pro1 from '../../../images/multi-factor-authentication-loan.png'
import pro2 from '../../../images/real-time-alerts-loan.png'
import pro3 from '../../../images/TLS-RSA-SSL-loan.png'
import pro4 from '../../../images/biometrics-loan.png'

const LoanSecureData = [
    {
        title:"Multi-factor Authentication",
        text: "Giving access to the loan account with a password will only compromise the data security. We recommend implementing multi-factor authentication for logins.",
       imgsrc: pro1,  
       
    },
    {
        title:"Real-time Alerts",
        text: "By using advanced technologies like AI & ML, we enable the app to alert users about suspicious activities.",
       imgsrc: pro2,  
     
     },
     {
        title:"TLS, RSA SSL",
        text: "We ensure maximum digital security by implementing several encryption algorithms. TLS protocol is among the best for computer network security.",
       imgsrc: pro3,  
     
     },
     {
        title:"Biometrics",
        text: "To ease out the multi-factor authentication for the users, we implement biometrics into the loan lending apps that we create.",
       imgsrc: pro4,  
      
     },  
    
]

export default LoanSecureData ;