import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import DevHead from './development/andHeader/DevHead'
import Industry from './industry/Industry'
import Success from './success/Success'

import pic from "../images/website-developers-banner-img.webp"
import pic1 from "../images/website-developers-small-img.webp"
import WebBigCard from './hiredevelopers/web/webbig/WebBigCard'

import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"

export default function HireWeb() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong> Web Developers </strong></h1>
                  <p>Website is the face of your business. <strong>Hire Web Developers</strong> and create a robust, aesthetic, and functional website that takes you to next level. </p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>Web Developer</span>
                  <h3>Hire Dedicated Web Developers, Create Success</h3>
                  <p>Since the rise of the internet, websites have worked as the face of businesses that help them interact, attract, and engage millions of potential customers, generating groundbreaking revenue.</p>
                  <p>So, if you want to <strong>hire dedicated web developer</strong> who can help you do the same, Uptimise IT is here to help you. Contact us today and start working on your dream idea.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Web Developers</span>
              <div className="mobiles_head">
                <h4>Hire Web Developer, Hire Success </h4>
                <p><strong>Hiring a website designer</strong> with Uptimise IT is easy. We offer a range of website development services that you can choose from based on your needs. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row app-developers-parent">
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Custom Web Developers website</h3>
                  <p>With Uptimise IT you can <strong>hire website developer</strong> who offerscustom web services. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Maintenance And Support Services </h3>
                  <p>It is important to maintain websites, this is why Uptimise IT provides web maintenance &amp; support services.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Hire Ecommerce Developer</h3>
                  <p>Hire eCommerce developers who deliver an online store that helps your business grow.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Website Migration &amp; Upgradation</h3>
                  <p>Suffering with the legacy system? Move to a better version or improve the existing one with our services.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Hire Front End Developer</h3>
                  <p>If you want front end programmers, you can hire front end developers with Uptimise IT. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="app-developer-box">
                  <h3>Hire Backend Developer</h3>
                  <p>We also allow clients to hire backend developers for robust and flexible website solutions. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Web Developers</span>
              <div className="mobiles_head">
                <h4>Hire Web Designers With Uptimise IT </h4>
                <p>Uptimise IT is a globally renowned <strong>PHP development company</strong> that offer amazing services for web solutions. Using technologies like <strong>Cake PHP</strong> and <strong>React Native</strong> we deliver a solution that helps you businesses grow. </p>
                <p>Some more reasons to hire web designers with Uptimise IT are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
             <WebBigCard />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Innovative Mobile App/Web Development Company </h3>
              <p>Uptimise IT is an innovative, data-driven web and mobile app development company. Here are our stellar services that you can take advantage of.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">There are various ways to avail <strong>website development services.</strong> Popular modes of hiring them are, as mentioned below: </p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>With Uptimise IT you can <strong>hire dedicated web developers</strong> on full time</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro1} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>If full time is not for you, you can hire them on part time bases.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro2} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>Hiring websiteDevelopers on hourly bases is yet another popular option among clients</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Why Hire Web Developers? </h3>
                  <p>By hiring a website designer and developer with the right skill set, you can create a web solution that do wonders for your business. And if you are looking for such developers, you can hireweb designer with us.</p>
                  <ul>
                    <li>Cost-Effective Website Development</li>
                    <li>Resourceful Development Team </li>
                    <li>Dedicated Developers </li>
                    <li>Agile and transparent process </li>
                    <li>State-of-the-art development tools and technology</li>
                    <li>100% Customer Satisfaction</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
              <Industry />
            </div>
          </div>
        </section>
        <section className="same-section bg-color work-inner-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
             <Success />
            </div>
          </div>
        </section>
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>Industries We Serve</h3>
            <p>Uptimise IT brings your ideas to reality, we create success. Here are the industries we serve.</p>
            <Link to="#" className="btn btn-primaryx">Explore More</Link>
          </div>
        </section>
       <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some frequently asked questions about front end web developers for hire. </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          What are the qualities I should check while hiring web developers?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Something that you should look out for when hiring web developers are, as mentioned below: 
                          <ul>
                            <li>Communication </li>
                            <li>Expertise</li>
                            <li>Experience</li>
                            <li>Past work </li>
                            <li>Portfolio </li>
                          </ul>
                          If you are looking to hire web developer with best qualities, Uptimise IT is perfect place to do so.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How much does it cost to hire a web developer?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The cost to hire a web developer can be anywhere between $20,000 and $100,000 depending on specification of project and client preferences. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          What are the top benefits of hiring web developers in USA?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Some of the top benefits that you get with hiring a web developer in USA are, as mentioned below: 
                          <ul>
                            <li>Top Notch Web Design</li>
                            <li>Better SEO optimization </li>
                            <li>Engaging Web Design </li>
                            <li>Gain Competitive Advantage </li>
                            <li>Time Saving </li>
                            <li>Reponsive Website</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Why should I choose Uptimise IT? 
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT is a market-leading mobile app and web development company with years of experience in the field. Our work has been recognized by the top reviewer platform like clutch.co. in addition to this, we provide scalable solutions that deliver value to your business. With our web development and designing services we can help you grow.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Do your web developers provide us with access to see the website progress?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body"> Our development process is completely transparent and we maintain good communication with the client. 	Thus, we always share each stpe and progress milestone with the client in real-time to give them insight into the work process.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>

   
    )
}