import pro1 from '../../../../images/dedicated-developers-react-native.webp'
import pro2 from '../../../../images/experienced-team-react-native.webp'
import pro3 from '../../../../images/fast-time-to-market-react-native.webp'
import pro4 from '../../../../images/affordable-services-react-native.webp'
import pro5 from '../../../../images/proven-work-react-native.webp'
import pro6 from '../../../../images/experienced-team-react-native.webp'


const ReactBigCardData = [
    {
        title:<h3>Experienced Team</h3>,
        text:  <p>Our team of react native app developers holds years’ worth of valuable experience that enables them to deliver a satisfactory solution. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:<h3>Fast Time to Market</h3>,
        text:    <p>Time to market is important when you are investing a large amount in a project. Uptimise IT offers a fast time to market. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Affordable Services",
        text: <p>Despite providing market-leading react native services, all of our solutions are offered at an affordable cost. </p>,
       imgsrc: pro3,  
     
     },
     {
        title:<p>Our development and design work is recognized by the top tech reviewers like Clutch.co, who havelabeled us one of the best in the industry.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"State-of-The-Art Technology",
        text: <p>Uptimise IT uses the latest tools and technology to deliver react native solutions is as per the market standard</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Scalable Solutions",
        text:    <p>Our web development solutions aren’t just visually aesthetic but also highly scalable as per your needs.</p>,
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default ReactBigCardData ;