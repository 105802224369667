import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn';
import DevHead from './development/andHeader/DevHead';
import pic from "../images/web-application-development-small-img.png"
import pic1 from "../images/iphone-app-developer.png"
import pic2 from "../images/clock-icon01.svg"
import pic3 from "../images/clock-icon02.svg"
import pic4 from "../images/clock-icon03.svg"
import pic5 from "../images/microsite.png"
import pic6 from "../images/electronic-img.png"
import pic7 from "../images/nicrosite-img.png"
import pic8 from "../images/webportals-img.png"
import pic9 from "../images/software-services.png"
import pic10 from "../images/flexibel-coading.png"
import pic11 from "../images/500-Planning.png"
import pic12 from "../images/8665237_code_development_icon.png"
import pic13 from "../images/6619436_a_ab_abtest_b_seo_icon.png"
import pic14 from "../images/control_x2C__management.png"
import pic15 from "../images/control_x2C__management.png"

import Development from './development/andservices/Development';
import Success from './success/Success';
import Industry from './industry/Industry';

export default function Web() {
    return (
        <div id="content">
        <section className="solution-banner">
          <div className="solution-banner-content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-content">
                    <h1><strong> Impact Web Development<br /> </strong>Company</h1>
                    <p>As a promising <strong>web development company in USA,</strong> Uptimise IT is bringing innovation to the world of websites. Our data-driven, result-oriented services help a business like yours reach the level of success, you always dreamt of.</p>
                    <p>Our team of web developers has required experienced, expertise, and resources needed to deliver impactful &amp; innovative services. If you are looking for web development services that can help you attract and retain an audience, Uptimise IT is here to help you.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="solution-banner-form">
                    <div className="same-heading">
                      <span>Get In Touch</span>
                      <div className="mobiles_head">
                        <h4>I want to Discuss my Project</h4>
                      </div>
                    </div>
                    <form action="https://www.uptimiseit.com/contents/send_enquirys/" method="post" encType="multipart/form-data">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="solution-form-content">
                            <div className="form-group">
                              <label>Your Name:</label>
                              <input type="text" className="form-control" required name="txtName" id="txtName" placeholder="Enter Name.." />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="solution-form-content">
                            <div className="form-group">
                              <label>Your Email:</label>
                              <input type="email" name="txtEmail" id="txtEmail" required className="form-control" placeholder="Enter Email.." />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="solution-form-content">
                            <div className="form-group">
                              <label>I am Interested in</label>
                              <div className="form-dropdown">
                                <select name="interested" id="interested" className="form-control">
                                  <option value="no_option_choose">--Select--</option>
                                  <option value="Enterprise Software Solutions">Enterprise Software Solutions</option>
                                  <option value="Creating a Mobile App">Creating a Mobile App</option>
                                  <option value="Web Development">Web Development</option>
                                  <option value="Dedicated Team Service">Dedicated Team Service</option>
                                  <option value="IT Consulting Services">IT Consulting Services</option>
                                  <option value="Enhancing an Existing Product">Enhancing an Existing Product</option>
                                  <option value="Partnership Opportunities">Partnership Opportunities</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="solution-form-content">
                            <div className="form-group">
                              <div className="request-input-filed">
                                <label htmlFor="exampleFormControlTextarea1">Your Message:</label>
                                <textarea className="form-control" placeholder="Type Message" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="request-popup-btn">
                          <div className="captcha-parent">
                            <div id="google_recaptchal" style={{transform: 'scale(0.77)', WebkitTransform: 'scale(0.77)', transformOrigin: '0 0', WebkitTransformOrigin: '0 0'}} data-theme="light" />
                          </div>
                          <div className="contact-btn">
                            <input type="hidden" name="form_submit" defaultValue="true" />
                            <input type="hidden" name="how_to_find" id="how_to_find" defaultValue="true" />
                            <input type="hidden" name="start" id="start" defaultValue="true" />
                            <input type="submit" name="btnSubmit" className="btn btn-primaryx" defaultValue="Send Message" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>web application development</span>
                  <h3>Bespoke Web Development Company</h3>
                  <p>Being the <strong>best web development company</strong> in the UK, Uptimise IT provides adaptable and seamless website development services for clients globally. We are committed to delivering the finest web solutions that are worthy of your investments. With a motive of “Stand above the crowd and rise above the noise”, we develop eye-catchy and customer-friendly web designs to help elevate your business to unprecedented heights.</p>
                  <p>Now you can hire dedicated Web development experts from Uptimise IT for your next big dream project. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Web Development</span>
              <div className="mobiles_head">
                <h4>Innovative Web Development Services</h4>
                <p>Our website developers have over decade-long expertise in building solutions that meet market needs, empower companies’ brand identity, and encourage business growth and expansion.</p>
              </div>
            </div>
            <div className="row">
              <Development />
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Why Uptimise IT for Web Development?</h3>
                  <p>Uptimise IT knows how to deliver <strong>web development solutions</strong> while helping your business attract more customers and retain them. This is what makes us the <strong>web development company</strong> USA/UK clients are looking for.</p>
                  <ul>
                    <li><strong>User-Friendly Experiences -</strong> We develop websites with an engaging and interesting strategy. This ensures we provide a user-friendly experience for the customers of your business.</li>
                    <li><strong>Targeted Communication -</strong> We use strategic design methodologies to improve your brand presence in the market. We carry out market research, which enables our designs to fit and impress your target market.</li>
                    <li><strong>Traffic Conversion -</strong> Our expert marketers integrate the latest SEO rules and features that can enable you to increase traffic on your website.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section pt-0 watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">As a leading web app development firm, we provide several options to hire web developers. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic2} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>You can hire web app developers with Uptimise IT full-time.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic3} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>If full-time isn’t for you, you can also hire developers part-time.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic4} alt="uptimise it" />
                      </figure>
                      <h3>Hourly</h3>
                      <p>Another option you get is to hire developers on hourly bases.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section request-section">
          <div className="container">
            <div className="request-content">
              <h3>Hire Dedicated Web Developers </h3>
              <p>“I am looking for a web <strong>development company nearme</strong>”. Your search is over, for Uptimise IT is here. Let’s discuss your next million-dollar project.</p>
            </div>
          </div>
        </section>
        <section className="same-section web-application-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Data-Driven Web Development Services</h4>
                <p>With proven experience in the field and a creative web development team, we deliver the best <strong>web development and design services.</strong> Some of our top solutions are, as mentioned below:</p>
              </div>
            </div>
            <div className="row align-items-center align-items-center">
              <div className="col-lg-6">
                <div className="web-application-box">
                  <img src={pic5} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="web-application-content">
                  <div className="same-heading">
                    <span>Minimal &amp; Responsive</span>
                    <div className="mobiles_head">
                      <h4>Online Marketplace </h4>
                      <p>As a leading web application development company, we deliver unmatched online marketplace solutions for our clients.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="web-application-box">
                  <img src={pic6} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="web-application-content">
                  <div className="same-heading">
                    <span>Safe &amp; Scalable</span>
                    <div className="mobiles_head">
                      <h4>eCommerce</h4>
                      <p>eCommerce is a megatrend of 2022, and we deliver eCommerce solutions that attract potential customers and  keep them engaged.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="web-application-box">
                  <img src={pic7} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="web-application-content">
                  <div className="same-heading">
                    <span>Elegant &amp; Branded</span>
                    <div className="mobiles_head">
                      <h4>Engaging Microsites</h4>
                      <p>Uptimise IT has a team of experienced developers that deliver engaging microsites.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="web-application-box">
                  <img src={pic8} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="web-application-content">
                  <div className="same-heading">
                    <span>Fast &amp; intuitive</span>
                    <div className="mobiles_head">
                      <h4>Job PortalsWeb</h4>
                      <p>Our <strong>Job Portal web development services</strong> are popular in the USA and UK industries.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="web-application-box">
                  <img src={pic9} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="web-application-content">
                  <div className="same-heading">
                    <span>Scalable &amp; Secure</span>
                    <div className="mobiles_head">
                      <h4>Software-as-a-Service</h4>
                      <p>Uptimise IT is known as a leading SaaS development company in USA offering impactful and innovative solutions. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="web-application-box">
                  <img src={pic10} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="web-application-content">
                  <div className="same-heading">
                    <span>Support &amp; Fixes</span>
                    <div className="mobiles_head">
                      <h4>Website Maintenance &amp; Support </h4>
                      <p>In addition to website development services, we also offer amazing website <strong>maintenance and support services.</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color work-inner-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
             <Success />
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
              <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="education-parent">
                  <h3>The Best Web Design and Development Company</h3>
                  <p>Now, you can <strong>hire web developer</strong> with Uptimise IT and develop an interactive, engaging website that attracts and engages developers.</p>
                </div>  
                <div className="case-btn">
                  <Link to="#" className="btn btn-primaryx">Case Study</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="education_slider">
                  <div className="owl-carousel owl-theme">
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                    <div className="item">
                      <div className="slider-heads">
                        <h3>Pay By Check</h3>
                      </div>
                      <img src="asset/images/pay-by-check01.png" alt="uptimise it" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Our eWallet App Development Process </h4>
                <p>Uptimise IT being the leading <strong>web application development company,</strong> follow a well planned out development process. It is, as mentioned below:</p>
              </div>
            </div>
            <div className="row process_work">
              <div className="col-lg-5">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic11} alt="uptimise it" />
                    <h3>1</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Analysis &amp; Planning</h3>
                    <p>The first step of website development is analyzing and planning. This is one of the crucial steps.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3 className="text-right">UI/UX Design</h3>
                    <p>Here, our UI/UX designers create a beautiful and interactive front-end design. We have amazing <strong>UI/UX designing services.</strong></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>2</h3>
                    <img src={pic12} alt="uptimise it"/>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic13} alt="uptimise it"/>
                    <h3>3</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Development Process</h3>
                    <p>This is where all the heavy lifting is done, as developers start the coding process. You can hire web developers for your website development work with Uptimise IT.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Testing</h3>
                    <p>The testing process is where QA checks the website for bugs and errors.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <h3>4</h3>
                    <img src={pic14} alt='uptimiseit'/>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio_icons_process">
                  <div className="portfolio_icon">
                    <img src={pic15} alt="uptimise it" />
                    <h3>5</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="portfolio_process_inner">
                  <div className="portfolio_process_content">
                    <h3>Maintenance &amp; Support</h3>
                    <p>We also provide Web maintenance and support services. This step help website remains market-relevant and keep growing.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <HomeIn  />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>These are answers to some of the most frequently asked questions about web development</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Why use custom web development?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Custom web development services give you the web product is looking for. Custom web solutions are built from scratch around the needs and preferences of the customer. This is why custom web development is one of the most popular services at Uptimise IT.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          What are web applications?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">As the name suggests, web applications are software or “application” that run on the web using it as a platform. In contrast native apps only work on their specific platforms like windows, android, or iOS. Whereas, the web can be accessed from any platform and the web application can be utilized.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          What is the best platform to develop web applications?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Some of the top platform used to develop web application is, as mentioned below:
                          <ul>
                            <li>AngularJS</li>
                            <li>ASP.NET</li>
                            <li>Ruby on Rails</li>
                            <li>Symfony</li>
                            <li>Laravel</li>
                            <li>Node.js</li>
                            <li>React.js</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it cost to build a web application?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The <strong>cost of web application development</strong> depends on several different factors. Nevertheless, you can expect anything between <strong>$10,000 and $30,000.</strong> If you are looking for a closer estimate, it is recommended that you approach Uptimise IT a top web app development company.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          How long does it take to build and launch a web application?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The time is taken by the web app developers to create a web application and deploy it actually depends on several factors. Nevertheless, you can expect anything between 3-and 6 weeks.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
    )
}