
import pro1 from '../../../images/telehealth-mobile0-apps-healthcare.png'
import pro2 from '../../../images/online-prescription-mobile-app-healthcare.png'
import pro3 from '../../../images/eHealth-record-app-healthcare.png'
import pro4 from '../../../images/online-pharmacy-app-healthcare.png'
import pro5 from '../../../images/telehealth-mobile0-apps-healthcare.png'
import pro6 from '../../../images/appointment-booking-app-healthcare.png'

import { Link } from 'react-router-dom'


const TypeData = [
    {
        title:"Telehealth Mobile Apps",
        text: "Uptimise IT provides amazing telehealth mobile app development that provides all the required healthcare services to patients.",
       imgsrc: pro1,  
       
    },
    {
        title:"Online Prescription Mobile App",
        text: "Through the online prescription mobile app solutions, patients can get the required prescription as per their health issues without going to a doctor.",
       imgsrc: pro2,  
     
     },
     {
        title:"eHealth Record App",
        text: "The eHealth or electronic health record app allows users to maintain their medical documents easily through an app.",
       imgsrc: pro3,  
     
     },
     {
        title:"Online Pharmacy App",
        text: "The online pharmacy app allows the users to order their prescription through the app and get it delivered to their doorstep.",
       imgsrc: pro4,  
      
     },  
     {
        title:"On-Demand Healthcare App",
        text: "The users can take advantage of On-Demand Healthcare App and get the best healthcare services as per their needs.",
       imgsrc: pro5,  
      
     }, 
     {
        title:"Appointment Booking App",
        text: "The appointment booking app, as the name suggests, allows the patients to book appointments with doctors.",
       imgsrc: pro6,  
      
     }, 
   
]

export default TypeData ;