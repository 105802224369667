import React from 'react'
import HomeIn from '../components/HomeIn'
import {Link} from 'react-router-dom'
import pic from "../images/development-banner.png"
import pic1 from "../images/curvaceous-logo-gray.png"
import pic2 from "../images/dafri-logo-gray.png"
import pic3 from "../images/medu-logo-gray.png"
import pic4 from "../images/student-castle-logo-gray.png"
import pic5 from "../images/drt-logo-gray.png"
import pic6 from "../images/dafri-logo-gray.png"
import pic7 from "../images/android-app-development-company-img.png"
import Development from './development/andservices/Development'
import Industry from './industry/Industry'
import Success from "./success/Success"
import Process from './process/Process'
import pic8 from "../images/serve-bg.png"
import pic9 from "../images/clock-icon01.svg"
import pic10 from "../images/clock-icon02.svg"
import pic11 from "../images/clock-icon03.svg"
import DevHead from './development/andHeader/DevHead'

export default function AndriodApp() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1> <strong>Android App Development</strong> Company</h1>
              <p>Uptimise IT is a leading <strong>Android App Development company</strong> in USA and UK. We offer our renowned <strong>Android App Development Services</strong> that are growth-driven and fulfill your business growth. Our team consists of experienced developers who have all the right tools, resources, and know-how to deliver market-leading Android solutions.</p>
              <div className="industries-btn">
                <a href="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</a>
                <a href="#contact-section" className="btn btn-default">Get a Quote</a>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic7} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>Android Development</span>
                  <h3>Android App development services to Grow Your Business</h3>
                  <p>Research shows that Google’s Android is projected to have approximately an 85% increment in smartphone shipment worldwide. With continuous updates and advancements, <strong>Android development services</strong> have completely changed the scenario of mobile app development. In the last few years, the need for Android in each sector has also increased. Moreover, the gaming and <strong>e-commerce</strong> industries are also on hype due to Android App Development.</p>
                  <p>As a leading <strong>android application development company,</strong> Uptimise IT, is committed to delivering native Android apps with result-oriented solutions. We're the best android app development company in London. With a strong command of Java, Kotlin, C++, and HTML, our team of full-stack developers is expert in building customizable Android apps for tablets, smartphones, Android TV, and wearable devices.</p>
                  <p>Beginning from development to till testing, Uptimise IT provides end-to-end <strong>Android App Development services</strong> for startups as well as settled firms. All of it makes us the best <strong>android development company</strong> in the UK.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Innovative Android App Development Solutions</h2>
                  <p>If you want to develop a leading android mobile app that offers amazing solutions.</p>
                  <Link to="#" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section hire-top-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="same-heading hire-heading ">
                  <span>Hire Developers</span>
                  <div className="mobiles_head">
                    <h4>Why Do You Need Android App Development Services?</h4>
                    <p>Android app development services have been turning around how businesses perform and generate profit for quite a while now. If you are someone who wants amazing ROI while creating skyrocketing revenue, android app development services are for you.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="hire-top-parent">
                      <div className="hire-top-inner">
                        <h3>Powerful Marketing Tool</h3>
                        <p>Usually, mobile phone users spend 80% of their time exploring android apps. Almost for every small work, people depend on these applications. There is no doubt that android can boost your business.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="hire-top-parent">
                      <div className="hire-top-inner">
                        <h3>Highly Customizable</h3>
                        <p>In case of customization and flexibility, android apps are on top. These apps make UI more smooth and also offer extraordinary frameworks. Specific hardware like Bluetooth or a camera can be configured with the help of particular queries.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="hire-top-parent">
                      <div className="hire-top-inner">
                        <h3>Improved Customer Engagement</h3>
                        <p>It doesn’t matter what type of business you are running. In order to generate profit from the business, you need high customer engagement. Android app helps target large audiences to improve customer engagement.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="hire-top-parent">
                      <div className="hire-top-inner">
                        <h3>Flexible engagement</h3>
                        <p>Uptimise IT  is a leading android app development company that delivers innovative solutions. Our solutions aren’t just useful for businesses but they are also built to attract clients while being future-proof.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Android Development</span>
              <div className="mobiles_head">
                <h4>Impactful Android Application Development Services</h4>
                <p>As a leading artificial Intelligence development company, Uptimise IT is committed to delivering growth driving android application development services. Our solutions don’t only offer fast time to market but also help your business reach the market goal and experience sky-rocketing growth.</p>
                <p>Some of our popular android application development services are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <Development />
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
              <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section work-inner-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
              <Success />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Agile, Quality, Successful Digital Solutions</h4>
                <p>As a globally renowned app Development Company, Uptimise IT follows a mobile app development process that is agile. We never compromise with the quality and provide high time to market to our clients. Uptimise IT knows how to deliver Success.</p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section we-serve p-0">
          <img src={pic8} alt="images" />
          <div className="serve-content-parent">
            <h3>Hire Dedicated Android App Developers</h3>
            <p>Now, you can hire the best android app developers in USA with Uptimise IT.</p>
            <Link to="#" className="btn btn-primaryx">Explore More</Link>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">Variety of Hiring Models</h4>
                <p className="text-center">As a leading android app development firm, we provide several options to <strong>hire android app developers.</strong> These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic9} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>You can hire android app developers with Uptimise IT full-time.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic10} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>If full-time isn’t for you, you can also hire developers part-time.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pic11} alt="uptimise it" />
                      </figure>
                      <h3>Hourly</h3>
                      <p>Another option you get is to hire developers on hourly bases.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some of the most frequently asked questions about web and mobile app development company:</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Will you sign an NDA to keep my Android app idea safe?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">By signing an NDA with an Android App development company, you are making sure your app idea is safe and 100% secure. This is one of the best ways to ensure the privacy and originality of your idea remain the same.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How do you allocate the resources for my app development in Android?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          As a leading android app development company, we provide a dedicated team of app developers for your project. And do the following to allocate resources to your android app development: 
                          <ul>
                            <li>high-level planning</li>
                            <li>Risk Assessment </li>
                            <li>Project Audit and related research </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Which tools and technologies your android developers use?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Uptimise IT android app developers make use of the latest tools and technologies to make sure our solutions are high quality and future-ready. Technologies we use are, as mentioned below: 
                          <ul>
                            <li>Android Studio.</li>
                            <li>ADB (Android Debug Bridge)</li>
                            <li>AVD Manager</li>
                            <li>Eclipse</li>
                            <li>Fabric</li>
                            <li>FlowUp</li>
                            <li>GameMaker</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Will you assist me to upload my Android app on the Play Store?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT takes care of your every android app development need. This also includesdeploying the application. So, yes, we will assist you with android app deployment. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Will I be getting any support after project completion?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">In addition to amazing android app development, Uptimise IT also provides amazing mobile app maintenance and support service that you can take advantage of.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
    )
}