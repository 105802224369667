import React from 'react'
import TestimonialWork from './TestimonialWork'
import TestimonialData from './TestimonialData'

const Testimonial = () => {
  return (
    <>
      {TestimonialData.map((val,ind) => {
                return (
                  <TestimonialWork
                  key={ind}
                  text={val.text}
                  imgsrc={val.imgsrc}
                  title={val.title}
                  />
                )
               } )}   
    </>
  )
}

export default Testimonial
