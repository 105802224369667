import pro1 from '../../../images/artificial-intelligence-result.png'
import pro2 from '../../../images/Iot-blockchain-and-chatbots-result.png'
import pro3 from '../../../images/autonomous-it-management-result.png'
import pro4 from '../../../images/horizontal-connection-vertical-depth-result.png'

const DevFutureData = [
    {
        title: "Artificial Intelligence",
       imgsrc: pro1,  
       text: " Artificial Intelligence is the future of modern computing and SaaS services. At Uptimise IT we do our base to apply AI in our SaaS development services and solutions."
    },
    {
        title: "IoT, Blockchains, and Chatbots",
        imgsrc: pro2,    
        text: " Internet of Things, Blockchains, Chatbots, and everything about the megatrends of Industry 4.0. We are dedicated to integrating in our services."
     },
     {
        title: "Autonomous IT Management",
        imgsrc: pro3, 
        text: "Another amazing megatrend from Industry 4.0 is automation. You no longer need people to do everything, as machines are smart enough to the worth themselves. And we know how to make them do it"
     },
     {
        title: "Horizontal Connection & Vertical Depth",
        imgsrc: pro4, 
        text: "Our future-proof SaaS solutions are aimed to form horizontal connections and provide vertical depth tothe clients adding value to their business."
     },
     
    
]

export default DevFutureData;