import React from 'react'
import { Link } from 'react-router-dom'

const DevHeadWork = (props) => {
  return (
    <ul>
    <li> <Link to="#"><img src={props.imgsrc1} alt="uptimise it" /> </Link> </li>
    <li> <Link to="#"><img src={props.imgsrc2} alt="uptimise it" /> </Link> </li>
    <li> <Link to="#"><img src={props.imgsrc3} alt="uptimise it" /> </Link> </li>
    <li> <Link to="#"><img src={props.imgsrc4} alt="uptimise it" /> </Link> </li>
    <li> <Link to="#"><img src={props.imgsrc5} alt="uptimise it" /> </Link> </li>
    <li> <Link to="#"><img src={props.imgsrc6} alt="uptimise it" /> </Link> </li>
  </ul>
  )
}

export default DevHeadWork
