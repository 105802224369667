import React from 'react'
import {Link} from 'react-router-dom'
import Reviews from './reviews/Reviews'
import Testimonial from './testimonial/Testimonial'
import test from "../images/dafri.webp"
import test2 from "../images/portfolio-main-page-medu4-img.png"

export default function HomeIn() {
    return (

    //   Portfolio,Review , testimonial

        <>
        <section className="same-section portfoli-head-wrapper">
        <div className="container">
          <div className="same-heading text-center">
            <span>Portfolio</span>
            <div className="mobiles_head">
              <h4>Our Success Stories</h4>
              <p>
                Uptimise It has worked with many amazing clients and delivered
                successful mobile applications. Check out some of our success
                stories.
              </p>
            </div>
          </div>
          <div className="row portfoli-head">
            <div className="col-lg-6">
              <div className="portfolio-parent">
                <div className="portfolio-inner-wrapper">
                  <img src={test}  alt='uptimiseit'/>
                  <div className="project-content">
                    <h3>DafriBank <span>Digital Bank Of Africa</span></h3>
                  </div>
                  <div className="portfolio-content-parent">
                    <div className="country-list">
                      <ul>
                        <li>South Africa</li>
                        <li>Nigeria</li>
                      </ul>
                    </div>
                    <h3>DafriBank <span>Digital Bank Of Africa</span></h3>
                    <Link to="/dafri-bank-case-study" className="btn btn-default">Case Study</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="portfolio-parent">
                <div className="portfolio-inner-wrapper paybycheck-color">
                  <img src={test} alt='uptimiseit' />
                  <div className="project-content">
                    <h3>
                      Pay By Check
                      <span>Multi-Currency E-wallet Mobile App</span>
                    </h3>
                  </div>
                  <div className="portfolio-content-parent">
                    <div className="country-list">
                      <ul>
                        <li>USA</li>
                        <li>Canada</li>
                      </ul>
                    </div>
                    <h3>
                      Pay By Check
                      <span>Multi-Currency E-wallet Mobile App</span>
                    </h3>
                    <Link to="/pay-by-check-case-study" className="btn btn-default">Case Study</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="portfolio-parent">
                <div className="portfolio-inner-wrapper">
                  <img src={test2} alt='uptimiseit'/>
                  <div className="project-content medu-home-page">
                    <h3>
                      medu4 <span>Medu4 Medical Educational Platform</span>
                    </h3>
                    <p>
                      A leading online medical educational platform in Japanese
                      market, Medu4 is a perfect example of our educational
                      solutions.
                    </p>
                    <Link to="/medu4-case-study" className="btn btn-default">Case Study</Link>
                  </div>
                  <div className="portfolio-content-parent">
                    <div className="country-list">
                      <ul>
                        <li>Japan</li>
                        <li>Malaysia</li>
                      </ul>
                    </div>
                    <h3>
                      medu4 <span>Medu4 Medical Educational Platform</span>
                    </h3>
                    <Link to="/medu4-case-study" className="btn btn-default">Case Study</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-bottom-btn text-center">
            <Link to="/ourwork" className="btn btn-primaryx">View Portfolio</Link>
          </div>
        </div>
      </section>
      <section className="same-section bg-color blank-wrapper-parent">
        <div className="container">
          <div className="same-heading text-center">
            <span>Uptimise It Reviews</span>
            <div className="mobiles_head">
              <h4>Deemed The Best App Developers</h4>
              <p>
                Uptimise It has been deemed the top mobile app development
                company by well-known critics and tech reviewers.
              </p>
            </div>
          </div>
          <div className="row">
           <Reviews/>
          </div>
        </div>
      </section>
      <section className="same-section testimonial-section">
        <div className="container">
          <div className="same-heading text-center">
            <span>Testimonial</span>
            <div className="mobiles_head">
              <h4>Customers? Satisfied</h4>
              <p>
                Uptimise It is all about delivering results that satisfy our
                clients and deliver success. Here are some words from our happy
                clients.
              </p>
            </div>
            
          </div>
          <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                 <Testimonial />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      </>
    )
}