import pro1 from '../../../images/account-management-loan.png'
import pro2 from '../../../images/loan-application-form-loan.png'
import pro3 from '../../../images/payment-log-loan.png'
import pro4 from '../../../images/personalized-alerts-loan.png'


const LoanAppData = [
    {
        title:"Account Management",
        text: "The users should be able to manage their account information along with the capability to block/activate cards.",
       imgsrc: pro1,  
       
    },
    {
        title:"Loan Application Form",
        text: "This is the core feature for your loan lending app development. We enhance the user experience by breaking down the loan application into several steps.",
       imgsrc: pro2,  
     
     },
     {
        title:"Payment Log",
        text: "Once a loan has been funded, the customer would want to know all the completed and pending payments. Knowing the total remaining debt at a glance is also better.",
       imgsrc: pro3,  
     
     },
     {
        title:"Personalized Alerts",
        text: "When users have dozens of applications on their mobile phones, they won’t pay attention to every notification. Hence, you should shoot the least possible notifications to make them valuable.",
       imgsrc: pro4,  
      
     },  
    
]

export default LoanAppData ;