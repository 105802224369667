import pro from '../../../../images/insurance-apps.png'
import pro1 from '../../../../images/forex-apps.png'
import pro2 from '../../../../images/cryptocurrency-apps.png'
import pro3 from '../../../../images/ewallet-apps.png'
import pro4 from '../../../../images/investment-apps.png'
import { Link } from 'react-router-dom'




const FinAppData = [
    {
        title:"Insurance Apps",
        text:  <p>FinTech insurance applications are integrated with the latest functionalities that will provide more adequate ways for users to connect with insurance companies easily.
      </p>,
       imgsrc: pro,  
       
    },
    {
        title:"Forex Apps",
        text:   <p>Top-Notch FinTech Apps for the forex sector will enable the forex industries to provide all the trading features on a single fully-fledged trading platform, to the app users.
      </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Cryptocurrency Apps",
        text:  <p>Get a feature-rich cryptocurrency app and currency exchange app developed by our experts to provide the feature of seamless and secure transfer of multiple currencies from mobile devices.
      </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Ewallet Apps",
        text: <p>Our expertise extends the use of FinTech applications by developing a digital wallet application that will enable the users to transfer money using credit/debit cards, NFC, or mobile numbers.
      </p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Investment Apps",
        text: <p>
        Highly scalable and quality-driven <strong><Link to="create-an-investment-platform/index">FinTech investment applications</Link></strong> that will help the users to monitor their daily expenses, and portfolio values, as well as they, can also invest in mutual funds.
      </p>,
       imgsrc: pro4,  
      
     },
    
  
   
     
     
    
]

export default FinAppData ;
