import React from 'react'
import SolutionData from './SolutionData'
import SolutionWork from './SolutionWork'


const Solution = () => {
  return (
    <>
      {SolutionData.map((val,ind) => {
      return (
        <SolutionWork
        key={ind}
        title={val.title}
        imgsrc={val.imgsrc}
        text={val.text}
        />
      )
     } )}  
    </>
  )
}

export default Solution
