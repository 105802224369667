// import pro1 from '../asset/images/images/service-img01.png'
import pro1 from '../../images/service-img01.png'
import pro2 from '../../images/service-img02.png'
import pro3 from '../../images/service-img03.png'
import pro4 from '../../images/service-img04.png'
import pro5 from '../../images/service-img05.png'

const ServiceData = [
    {
       imgsrc: pro1, 
     
       view: "Web Development"
    },
    {
        imgsrc: pro2, 
        
        view: "UI/UX Design"
     },
     {
        imgsrc: pro3, 
      
        view: "Maintenance and Support"
     },
     {
        imgsrc: pro4, 
       
        view: "Android App Development"
     },
     {
         imgsrc: pro5, 
        
         view: "iOS App Development"
      },
     
]

export default ServiceData;