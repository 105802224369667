import React from 'react'
import NodeCardData from './NodeCardData'
import NodeCardWork from './NodeCardWork'

const NodeCard = () => {
  return (
    <>
       {NodeCardData.map((val,ind) => {
        return (
          <NodeCardWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
    </>
  )
}

export default NodeCard
