import pro1 from '../../images/slider-img01.png'
import pro2 from '../../images/slider-img02.png'
import pro3 from '../../images/slider-img03.png'

const IndustryData = [
    {
        title: "Mobile Banking App Development",
       imgsrc: pro1,  
       text: " Leading Mobile Banking App Development company."
    },
    {
        title: "Loan Lending App Development",
        imgsrc: pro2,    
        text: " Get the best loan lending app developing services."
     },
     {
        title: "Cryptocurrency App Development",
        imgsrc: pro3, 
        text: "Innovative Cryptocurrency App Development solutions."
     },
     
    
]

export default IndustryData;