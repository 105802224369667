import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import ScrollButton from '../buttonTop/ButtonScuoll'
import prop from "../../images/logo.png"
import prop1 from "../../images/request-fixed-icon.png"
import pic from "../../images/show-menu-icon.png"
import pic2 from '../../images/left-popup-image.png'



import Sidebar from './Sidebar'

export default function Topnav() {


  const [active, setActive] = useState(true)
  const [activ, setActiv] = useState(true)
  const [acti, setActi] = useState(true)
  const [act, setAct] = useState(true)
  const [ac, setAc] = useState(true)
 
    return (
<>
      <div>
        <div id="back-top-wrapper" className="visible-desktop">
          <p id="back-top" style={{display: 'initial'}}>
            <a  to="" >
           <ScrollButton  />
            </a>
          </p>
        </div>
        <div className="whatsapp-icon-parent">
          <a className="whats-app-icon" to="https://wa.me/917020134662" target="_blank">
            <i className="fa-brands fa-whatsapp" />
            
          </a>
          
        </div>
        <div className="fixed-top-section">
          <ul>
            <li className="review-parent">
              <a data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                <img src={prop1} alt="uptimise it" />
                <span>Requ</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <header className="header" id="myHeader" >
        <div className="container-fluid" >
          <div className="row align-items-center">
            <div className="col logo">
              <Link to="/"><img src={prop} alt="logo-icon" /></Link>
            </div>
            <div className="col menu">
              <div className="menu-parent">
                <ul className="main-menu" >
                  <li>
                    <Link to="" className="sub_menus" >Company  </Link>
                    <div className="main_menu">
                      <button className="menu-close-btn">
                        <i className="fas fa-times" />
                      </button>
                      <div className="container" >
                        <div className="row">
                          <h5><Link to="">Company</Link></h5>
                          <div className="col-lg-4">
                            <div className="menu_sub" >
                              <ul>
                                <li><Link to="/about">About Us</Link></li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li><Link to="/ourwork">Portfolio</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="/"  className="sub_menus">Services</Link >
                    <div className="main_menu">
                      <button className="menu-close-btn">
                        <i className="fas fa-times" />
                      </button>
                      <div className="container">
                        <div className="row">
                          <h5>
                            <Link to="">Mobile Application Development</Link>
                          </h5>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/android-app-development">Android App Development</Link>
                                </li>
                                <li>
                                  <Link to="/ios-app-development">iPhone App Development</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/ui-ux-design-development">UI/UX Design</Link>
                                </li>
                                <li>
                                  <Link to="/maintenance-support">Maintenance &amp; Support</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <h5>
                            <Link to="">Full Stack Development</Link>
                          </h5>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/web-development-company">Custom Web Development</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <h5>
                            <Link to="">Software Product Development</Link>
                          </h5>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/saas-and-enterprise-solutions">SAAS &amp; Enterprise App Development</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="" className="sub_menus">Industries</Link>
                    <div className="main_menu">
                      <button className="menu-close-btn">
                        <i className="fas fa-times" />
                      </button>
                      <div className="container">
                        <div className="row">
                          <h5><Link to="">Industries</Link></h5>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/automotive-app-development">Automotives</Link>
                                </li>
                                <li>
                                  <Link to="/logistics-app-development">Logistics App</Link>
                                </li>
                                <li>
                                  <Link to="/loan-lending-app-development">Loan Lending App</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/marketplace-development">Service Marketplace</Link>
                                </li>
                                <li>
                                  <Link to="/event-app-development">Event &amp; Tickets</Link>
                                </li>
                                <li>
                                  <Link to="/mobile-banking-app-development">Banking, Finance &amp; Insurance</Link>
                                </li>
                                <li>
                                  <Link to="/artificial-intelligence-application-development">AI App Development</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/ecommerce-app-development">Ecommerce, Retail &amp; B2B</Link>
                                </li>
                                <li>
                                  <Link to="/food-delivery-app-development">Food &amp; Restaurant</Link>
                                </li>
                                <li>
                                  <Link to="/cryptocurrency-app-development">Cryptocurrency App</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="" className="sub_menus">Solutions</Link>
                    <div className="main_menu">
                      <button className="menu-close-btn">
                        <i className="fas fa-times" />
                      </button>
                      <div className="container">
                        <div className="row">
                          <h5>
                            <Link to="">Trending Solutions</Link>
                          </h5>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/ewallet-app-development">eWallet development</Link>
                                </li>
                                <li>
                                  <Link to="/education-app-development">Education and ELearning</Link>
                                </li>
                                <li>
                                  <Link to="/healthcare-app-development">Healthcare App</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/recruitment-app-development">Job Portal App Development</Link>
                                </li>
                                <li>
                                  <Link to="/fintech-app-development">Fintech App Development</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="" className="sub_menus">Hire Developers</Link>
                    <div className="main_menu">
                      <button className="menu-close-btn">
                        <i className="fas fa-times" />
                      </button>
                      <div className="container">
                        <div className="row">
                          <h5>
                            <Link to="">Hire Developers</Link>
                          </h5>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/hire-iphone-app-developers">Hire iPhone App Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-android-app-developers">Hire Android App Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-react-native-developer">Hire React Native Developer</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/hire-laravel-developers">Hire Laravel Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-magento-developers">Hire Magento Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-nodejs-developers">Hire Node JS Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-angularjs-developers">Hire Angular JS Developer</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="menu_sub">
                              <ul>
                                <li>
                                  <Link to="/hire-python-developers">Hire Python Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-php-developers">Hire PHP Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-web-developers">Hire Web Developer</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <div className="header-btn">
                    <a type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn btn-primaryx">Request A Quote</a>
                  </div>
                  
                </ul>
              </div>
              <div className="menu-parent mobile-menu d-xl-none">
                <div className="d-xl-none" id="black-layer" />
                <div className="d-xl-none">
                  <button id="toggle-btn"><i className="fas fa-bars" /></button>
                </div>
                <div className="left-menu-list" id="show-left header-nav">
                  <button id="nav-close-btn" className="d-xl-none">
                    <i className="fas fa-times" />
                  </button>
                  <ul className="mobile-menu-list">
                  
                  <li className="submenu">
                    <Link to="#" className="openmenu" id="openmenu" onClick={() => setActive(!active)}
                      >Company
                      <span
                        ><img src={pic} className={active ? "img " : "img active"} alt="uptimise it"
                      /></span>
                    </Link>
                    <ul className={active ? "summary " : " summary active"} id="summary hide-menu">
                      <h5><a href="javascript:void(0)">Company</a></h5>
                      <li><a href="about-us.html">About Us</a></li>

                      <li><a href="our-work.html">Portfolio</a></li>
                      <li><a href="contact.html">Contact Us</a></li>
                    </ul>
                  </li>
                    <li className="submenu">
                      <Link  to="" className="openmenu" id="openmenu" onClick={() => setActiv(!activ)}>Services 
                        <span><img className={activ ? "img " : "img "} src={pic} alt="uptimise it" /></span>
                      </Link>
                       <ul className={activ ? "summary " : " summary active"}>
                        <h5>
                          <Link to="">Mobile Application Development</Link>
                        </h5>
                        <li>
                          <Link to="/android-app-development">Android App Development</Link>
                        </li>
                        <li>
                          <Link to="/ios-app-development">iPhone App Development</Link>
                        </li>
                        <li>
                          <Link to="/ui-ux-design-development">UI/UX Design</Link>
                        </li>
                        <li>
                          <Link to="/maintenance-support">Maintenance &amp; Support</Link>
                        </li>
                        <h5>
                          <Link to="">Full Stack Development</Link>
                        </h5>
                        <li>
                          <Link to="/web-development-company">Custom Web Development</Link>
                        </li>
                        <h5>
                          <Link to="">Software Product Development</Link>
                        </h5>
                        <li>
                          <Link to="/saas-and-enterprise-solutions">SAAS &amp; Enterprise App Development</Link>
                        </li>
                        <li>
                          <Link to="/saas-and-enterprise-solutions">UI/UX Design</Link>
                        </li>
                        <li>
                          <Link to="/saas-and-enterprise-solutions">Maintenance & Support</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="submenu">
                      <Link className="openmenu" id="openmenu" onClick={() => setActi(!acti)} to="#" >Industries
                        <span><img src={pic} className={acti ? "img " : "img active"} alt="uptimise it" /></span>
                      </Link>
                      <ul className={acti ? "summary" : " summary active"} id="summary hide-menu">
                        <h5><Link to="">Industries</Link></h5>
                        <li>
                          <Link to="/logistics-app-development">Automatives</Link>
                        </li>
                        <li>
                          <Link to="/logistics-app-development">Logistics App</Link>
                        </li>
                        <li>
                          <Link to="/loan-lending-app-development">Loan Lending App</Link>
                        </li>
                        <li>
                          <Link to="/mobile-banking-app-development">Banking, Finance &amp; Insurance</Link>
                        </li>
                        <li>
                          <Link to="/artificial-intelligence-application-development">AI App Development</Link>
                        </li>
                        <li>
                          <Link to="/cryptocurrency-app-development">Cryptocurrency App</Link>
                        </li>
                        <li>
                          <Link to="/marketplace-development">Service Marketplace</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="submenu">
                      <Link className="openmenu" id="openmenu" to="#" onClick={() => setAct(!act)}>Solutions
                        <span><img src={pic} className={active ? "img " : "img active"} alt="uptimise it" /></span>
                      </Link>
                      <ul className={act ? "summary " : " summary active"} id="summary hide-menu">
                        <h5>
                          <Link to="">Trending Solutions</Link>
                        </h5>
                        <li>
                          <Link to="/ewallet-app-development">eWallet development</Link>
                        </li>
                        <li>
                          <Link to="/education-app-development">Education and ELearning</Link>
                        </li>
                        <li>
                          <Link to="/healthcare-app-development">Healthcare App</Link>
                        </li>
                        <li>
                          <Link to="/recruitment-app-development">Job Portal App Development</Link>
                        </li>
                        <li>
                          <Link to="/fintech-app-development">Fintech App Development</Link>
                        </li>
                        <li>
                          <Link to="/on-demand-app-development">On-Demand</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="submenu">
                      <Link className="openmenu" id="openmenu" to="#" onClick={() => setAc(!ac)}>Hire Developers
                        <span><img src={pic} className={ac ? "img " : "img active"} alt="uptimise it" /></span>
                      </Link>
                      <ul className={ac ? "summary " : " summary active"} id="summary hide-menu">
                        <h5>
                          <Link to="">Hire Developers</Link>
                        </h5>
                        <li>
                                  <Link to="/hire-iphone-app-developers">Hire iPhone App Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-android-app-developers">Hire Android App Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-react-native-developer">Hire React Native Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-laravel-developers">Hire Laravel Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-magento-developers">Hire Magento Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-nodejs-developers">Hire Node JS Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-angularjs-developers">Hire Angular JS Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-python-developers">Hire Python Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-php-developers">Hire PHP Developer</Link>
                                </li>
                                <li>
                                  <Link to="/hire-web-developers">Hire Web Developer</Link>
                                </li>
                      </ul>
                     
                      
                    </li>
                    <div className="header-btn">
                      <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn btn-primaryx">Request A Quote</a> 
                    </div>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </header>

      <div>
        <div id="back-top-wrapper" className="visible-desktop">
          <p id="back-top" style={{display: 'none'}}>
            <Link to="" className>
              <img src="asset/images/arrow-top.png" alt="uptimise it" />
            </Link>
          </p>
        </div>
        <div className="whatsapp-icon-parent">
          <a className="whats-app-icon" href="https://wa.me/917020134662" target="_blank">
            <i className="fa-brands fa-whatsapp" />
          </a>
        </div>
        <div className="fixed-top-section">
          <ul>
            <li className="review-parent">
              <a data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                <img src={prop1} alt="uptimise it" />
                <span>Request a Quote</span> 
              </a>
            </li>
          </ul>
        </div>
      </div>



        <div className="modal fade request-form" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <i className="fas fa-times" />
                </button>
              </div>
              <div className="modal-body">
                <div className="request-form-content">
                  <h2>Inquiry Now</h2>
                  <div className="request-form-field">
                    <form action="#" method="post" onsubmit="return chkEnqForm(1);" encType="multipart/form-data">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="request-input-filed">
                              <label>Your Name<span style={{color: 'red'}}>* </span>:</label>
                              <input type="text" className="form-control" required name="txtName" id="txtName" placeholder="Enter Name.." />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="request-input-filed">
                              <label>Your Email<span style={{color: 'red'}}>* </span>:</label>
                              <input type="email" name="txtEmail" id="txtEmail" required className="form-control" placeholder="Enter Email.." />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group input-group">
                            <div className="request-input-filed">
                              <label>Your Phone Number<span style={{color: 'red'}}>* </span>:</label>
                              <div className="country-select-parent">
                                <select name="code" id="ptest">
                                  <option value={91}>(+91) India </option>
                                  <option value={44}>(+44) UK </option>
                                  <option value={1}>(+1) Canada </option>
                                  <option value={61}>(+61) Australia </option>
                                  <option value={43}>(+43) Austria </option>
                                  <option value={880}>(+880) Bangladesh </option>
                                  <option value={20}>(+20) Egypt </option>
                                </select>
                                <input className="form-control phone" name="txtPhone" required type="tel" id="phone" maxLength={15} onkeyup="return formatPhone(this)" placeholder="Enter Phone No..." />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="request-input-filed">
                              <label htmlFor="exampleFormControlTextarea1">Your Message:</label>
                              <textarea name="txtMsg" className="form-control" placeholder="Type Message" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="request-popup-btn">
                        <div className="captcha-parent">
                          <div id="google_recaptchas" style={{transform: 'scale(0.77)', WebkitTransform: 'scale(0.77)', transformOrigin: '0 0', WebkitTransformOrigin: '0 0'}} data-theme="light" />
                        </div>
                        <div className="contact-btn">
                          <input type="hidden" name="form_submit" defaultValue="true" />
                          <input type="hidden" name="how_to_find" id="how_to_find" defaultValue="true" />
                          <input type="hidden" name="start" id="start" defaultValue="true" />
                          <input type="submit" name="btnSubmit" className="btn btn-primaryx" defaultValue="Send Message" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Modal */}
        {/* <div className="modal fade request-form default-page-popup show" id="popup_content_wrap" style={{display: 'none', paddingRight: '17px'}} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" onclick="popup_content('hide')" aria-label="Close">
                  <i className="fas fa-times" />
                </button>
              </div>
              <div className="modal-body">
                <div className="website-main-popup">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="popup-left-image">
                        <img src={pic2} alt="uptimise it" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="request-form-content">
                        <h2>Inquiry Now</h2>
                        <div className="request-form-field">
                          <form action="#" method="post" encType="multipart/form-data" onsubmit="return chkEnqForm(2);">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <div className="request-input-filed">
                                    <label>Your Name<span style={{color: 'red'}}>* </span>:</label>
                                    <input type="text" className="form-control" required name="txtName" id="txtNames" placeholder="Enter Name.." />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <div className="request-input-filed">
                                    <label>Your Email<span style={{color: 'red'}}>* </span>:</label>
                                    <input type="email" name="txtEmail" id="txtEmails" required className="form-control" placeholder="Enter Email.." />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group input-group">
                                  <div className="request-input-filed">
                                    <label>Your Phone Number<span style={{color: 'red'}}>* </span>:</label>
                                    <div className="country-select-parent">
                                      <select name="code" id="ptest">
                                      <select name="code" id="ptest">
                                  <option value={91}>(+91) India </option>
                                  <option value={44}>(+44) UK </option>
                                  <option value={1}>(+1) Canada </option>
                                  <option value={61}>(+61) Australia </option>
                                  <option value={43}>(+43) Austria </option>
                                  <option value={880}>(+880) Bangladesh </option>
                                  <option value={20}>(+20) Egypt </option>
                                </select>
                                      </select>
                                      <input className="form-control phone" name="txtPhone" required type="tel" maxLength={15} onkeyup="return formatPhone(this)" max={15} min={15} id="phone" placeholder="Enter Phone No..." />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <div className="request-input-filed">
                                    <label htmlFor="exampleFormControlTextarea5">Your Message:</label>
                                    <textarea name="txtMsg" className="form-control message" placeholder="Type Message" id="exampleFormControlTextarea5" rows={3} defaultValue={""} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="request-popup-btn">
                              <div className="captcha-parent">
                                <div id="google_recaptchass" style={{transform: 'scale(0.77)', WebkitTransform: 'scale(0.77)', transformOrigin: '0 0', WebkitTransformOrigin: '0 0'}} data-theme="light" />
                              </div>
                              <div className="contact-btn">
                                <input type="hidden" name="form_submit" defaultValue="true" />
                                <input type="hidden" name="how_to_find" id="how_to_find" defaultValue="true" />
                                <input type="hidden" name="start" id="start" defaultValue="true" />
                                <input type="submit" name="btnSubmit" className="btn btn-primaryx" defaultValue="Send Message" />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
   
      </>
    )
}

