
import pro1 from '../../../images/customer-onboarding.png'
import pro2 from '../../../images/add-money-to-mobile-app-ewallet.png'
import pro3 from '../../../images/send-and-receive-funds.png'
import pro4 from '../../../images/bank-account-configuration.png'
import pro5 from '../../../images/pin-fingerprints-and-retina-scans.png'
import pro6 from '../../../images/transaction-history-or-ewallet-passbook.png'
import pro7 from '../../../images/check-ewallet-balance.png'
import pro8 from '../../../images/digital-wallet-to-bank-transfer.png'

const StudentData = [
    {
        title:"Customer Onboarding",
        text: "A digital wallet app requires customer identity information for the KYC process and getting this information during the signup process is quite tricky. With mobile wallet app development, we create a seamlessonboarding experience for your customers to improve retention. We also provide offline ID verification integration so that your customer can verify their identity by physically visiting kiosks.",
       imgsrc: pro1,  
       
    },
    {
        title:"Add Money to Mobile App Ewallet",
        text: "Users can add and store funds to the mobile app digital wallet to use for online services and payments. The reason they credit their Ewallet is that it allows them to send money or pay bills instantly by just entering a PIN, Retina Scan, or Fingerprint Authentication.",
       imgsrc: pro2,  
     
     },
     {
        title:"Send and Receive Funds",
        text: "It allows the users to send and receive money through the application. The users can enter the recipient's phone number or they could scan the barcode of the recipient’s application to send money. During the Ewallet software development, we also integrate QR code, NFC, and beacon technologies to allow users to send funds or pay for services. In the same manner, the user can receive money from other users. Users can also send payment requests to other users for incoming fund transfers. Once the payment is sent, these funds are instantly available in the mobile payment application to use.",
       imgsrc: pro3,  
     
     },
     {
        title:"Bank Account Configuration",
        text: "To leverage the bank to bank fund transfer and load the money into the Ewallet, the users are asked to link their bank account with the Ewallet application. The users can link their bank accounts with the app by providing their bank account details. This information is being kept confidential and is stored in a very secure format using various security compliances. The mobile wallet app allows users to configure more than one bank account to the application.",
       imgsrc: pro4,  
      
     },  
     {
        title:"PIN, Fingerprints, and Retina Scans",
        text: "Users are asked to set a four-digit secured Pin, Fingerprints, or Retina Scan Authentication to enable instant transactions. We utilize the user authentication methods at every crucial stage of the mobile payment app such as profile update, payment authentication, bank account update, etc. We allow users to use any of the authentication options as the primary one.",
       imgsrc: pro5,  
      
     }, 
     {
        title:"Transaction History or Ewallet Passbook",
        text: "The must-have feature for Ewallet app development. With this in place, users can check all of the previous transactions that they have made on the mobile payment application itself. Furthermore, they would be able to see the transaction amount value, the sender or recipient’s details, dates of payments, and any additional comments that were added when the transaction was made. With the USSD payment Ewallets, the users can also access the transaction history via USSD codes on their feature phones.",
       imgsrc: pro6,  
      
     }, 
     {
        title:"Check Ewallet Balance",
        text: "If you incorporate this feature into your Ewallet app development, the users can see the real-time Ewallet balance within the mobile payment app. If the users have linked their bank account with the application, they can also check their bank account balance.",
       imgsrc: pro7,  
      
     }, 
     {
        title:"Digital Wallet to Bank Transfer",
        text: "We integrate the payment gateways seamlessly so that you can allow your mobile payment app users to send the payment app wallet amount to their bank accounts. Do you know what makes us capable of doing it, we're the best Ewallet mobile app development company.",
       imgsrc: pro8,  
      
     }, 
]

export default StudentData ;