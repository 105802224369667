import React from 'react'
import { Link } from 'react-router-dom'

const ServiceWork = (props) => {
  return (
    
       <div className="col-lg-4">
                  <div className="service-box-parent">
                    <div className="service-img">
                      <img src={props.imgsrc} alt="image" />
                    </div>
                    <div className="service-bottom text-center">
                      <Link to="/web-development-company">{props.view}</Link>
                    </div>
                  </div>
                </div>
    
  )
}

export default ServiceWork
