import React from 'react'

const ChooseWork = (props) => {
  return (
    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src={props.imgsrc} alt="image" />
                        </figure>
                        <h3>{props.title}</h3>
                        <p>{props.text}</p>
                      </div>
                    </div>
  )
}

export default ChooseWork;
