import React from 'react'

const ValuesWork = (props) => {
  return (
    <>
       <div className="col-lg-3">
                  <div className="company-box">
                    <div className="company-content">
                      <h3>{props.title}</h3>
                      <img src={props.imgsrc} alt="uptimise it" />
                    </div>
                    <p>
                     {props.text}
                    </p>
                  </div>
                </div>
    </>
  )
}

export default ValuesWork
