
import pro1 from '../../../images/platform-job.png'
import pro2 from '../../../images/UI_UX-design-job.png'
import pro3 from '../../../images/features-job.png'
import pro4 from '../../../images/tech-stack-job.png'
import pro5 from '../../../images/third-party-apis-job.png'
import pro6 from '../../../images/software-app-testing.png'
import pro7 from "../../../images/app-size-type-job.png"
import pro8 from "../../../images/hosting-submission-job.png"

import { Link } from 'react-router-dom'


const JobData = [
    {
        title:"Platform",
        text: <p>You can choose between <strong>iOS app development</strong> or <strong>android app development.</strong> If you can’t choose, go for hybrid app development. Depending on your choice, development costs can differ. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"UI/UX Design",
        text: <p>UI/UX design is an important part of a job portal web app or mobile app. Depending on whether it is a complex or simple one, the cost to develop the same can differ. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Features",
        text: <p>Features are backbone of any recruitment portal, as a recruitment app development company will tell you, the more complex feature you choose, the more expensive they get. </p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Tech Stack",
        text:  <p>Tech stack refers to the technologies used in the recruitment app development process. So, based on which tech stack you choose, the price can differ.</p>,
       imgsrc: pro4,  
      
     },  
     {
        title:"Third-Party APIs",
        text: <p>Third-party APIs can really add to the functionality of the job portal. Now, some of the APIs are free, while the other ones are paid.</p>,
       imgsrc: pro5,  
      
     }, 
     {
        title:"Software/App Testing",
        text:  <p>Job portal Software and mobile app testing process is an important one as it helps figure out the shortcoming of the same. The tools used here are paid.</p>,
       imgsrc: pro6,  
      
     }, 
     {
        title:"App Size & Type ",
        text:  <p>There are different types of recruitment mobile apps and they can be differently sized based on complexity. This factor can highly affect the cost.</p>,
       imgsrc: pro7,  
      
     }, 
     {
        title:"Hosting & Submission",
        text:  <p>Based on the platform you choose, the submission and the hosting charges can be different. This, affect the total development cost. </p>,
       imgsrc: pro8,  
      
     }, 
   
]

export default JobData ;