import React from 'react'
import WebBigCardData from './WebBigCardData'
import WebBigCardWork from './WebBigCardWork'

const WebBigCard = () => {
  return (
    <>
    {WebBigCardData.map((val,ind) => {
              return (
                <WebBigCardWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )}  
  </>
  )
}

export default WebBigCard
