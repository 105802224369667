import React from 'react'
import Service from "./service/Service"
import Process from "./process/Process"
import AbtSuccess from "./about/abt-success/AbtSuccess"

import pic2 from "../images/recruitment-small-image.png"
import pic from "../images/dating-work-bg.png"
import pic1 from "../images/recruitment-banner-image.png"
import pic3 from "../images/channel-agnostic-cx-job.png"
import pic4 from "../images/transformation-on-the-go-job.png"
import pic5 from "../images/performance-oriented-microservice-architecture-job.png"
import pic6 from "../images/we-are-ready-to-incorporate-changes-job.png"
import pic7 from "../images/robust-and-scalable-app-architecture-job.png"
import pic8 from "../images/cost-efficient-solutions-job.png"

import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import DevHead from './development/andHeader/DevHead'
import FrstCard from './trending-solutions/multicard/frstcard/FrstCard'
import Protocol from "./trending-solutions/protocol/Protocol"
import Geo from './trending-solutions/multicard/geo/Geo'
import Dash from './trending-solutions/dash/Dash'
import We from './trending-solutions/we/We'
import Iso from './trending-solutions/ios/Ios'
import Ios from './trending-solutions/ios/Ios'
import Job from './trending-solutions/job/Job'

export default function Recruitment() {
    return (
        <div id="content">
        <section className="industries-banner-section banner-section">
          <img src={pic1} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Recruitment</strong> <br /> App Development Company</h1>
              <p>As a leading <strong>recruitment app development company,</strong> Uptimise IT develops intuitive recruitment applications that make searching for a job a seamless process. Our solutions provide an interactive platform that works as a bridge between job seekers and job providers.</p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
              <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic2} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <h3>Impactful Recruitment App Development Services </h3>
                  <p>As the population grows, the number of job seekers are also increasing. In this world of job seekers and providers, our recruitment platforms are what connects them to each other. Our <strong>recruitment app development</strong> keeps in mind the highly competitive market and growing demand, thus, delivering innovative, easy-to-use, versatile job portal solutions. So, if you want to develop a <strong>recruitment portal</strong> of your own, Uptimise IT can help you do just that.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section web-development-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Job Portal App </span>
              <div className="mobiles_head">
                <h4>Recruitment App Features</h4>
                <p>Uptimise IT is a leading recruitment app development company, that provides cutting-edge job portal software development solutions for <strong>native and cross-platform.</strong> As such, features are what drive software or mobile app, so let’s see what are the features you should include in your job portal.</p>
              </div>
            </div>
            <div className="custom-tab-parent">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Common Features</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Advance Features</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Security Protocols</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-contact-tab5" data-bs-toggle="pill" data-bs-target="#pills-contact5" type="button" role="tab" aria-controls="pills-contact5" aria-selected="false">Recruitment App Admin Panel</button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className="row">
                   <FrstCard />
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="row">
                   <Geo />
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div className="row">
                  <Protocol />
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-contact5" role="tabpanel" aria-labelledby="pills-contact-tab5">
                  <div className="row">
                    <Dash />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section request-section">
          <div className="container">
            <div className="request-content">
              <h3>Request a Quote</h3>
              <p>If you have an App Idea in mind, share it with us to turn your idea into a profitable plan or business.</p>
              <Link to="#" className="btn btn-primaryx">Get Free Consultation Now</Link>
            </div>
          </div>
        </section>
        <section className="same-section dating-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>The best</span>
              <div className="mobiles_head">
                <h4>We’re the Best Recruitment App Development Company in USA</h4>
                <p>Uptimise IT specializes in recruiting app development. We make use of best development practices to provide our clients with best-in-class software solutions for recruitment. Our team of exceptionally skilled developers has helped us deliver several recruitment solutions that are excelling in the market. At Uptimise IT, we focus on building software solutions that provide value to our clients.</p>
              </div>
            </div>
            <div className="industry-icon-parent">
              <ul>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Channel Agnostic CX</h3>
                      <p>It isn’t straightforward to carry the desktop experience to mobile devices or vice-versa. We build you mobile strategies that augment core recruitment portals with the ‘Agile Edge.’ This allows our clients to be Uptimise IT and act on changing recruitment demands.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic3} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Transformation On the Go</h3>
                      <p>The recruitment industry relies on several other industries, hence it faces a fast pace of transformation. This requires your recruitment app development company to be agile and to adapt to the changing business environment. This makes us your best pick for job portal app development.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic4} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Performance-Oriented Microservice Architecture</h3>
                      <p>We leverage an intermediary messaging layer and a batch-oriented core software platform model to speed up software delivery. We prefer using a microservice architecture that is capable of scaling and personalizing itself.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic5} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>We are Ready to Incorporate Changes</h3>
                      <p>As we said, agility is at our core; hence, we can easily incorporate changes in the requirement, even during the development stages. Splitting the recruitment app development into several sprints, allows us to adapt to any changes that occur.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic6} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Robust and Scalable App Architecture</h3>
                      <p> We build the recruitment apps on a very secure and robust technical architecture. Data security is among the key concerns for recruiters and hence, it is necessary for your job portal development company to build the app with security in mind. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic7} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Cost-Efficient Solutions</h3>
                      <p>We don’t sell technology, we make use of technology to bridge the gap between recruiters and job-seekers. We always aim to develop ROI-focused software solutions, so that the recruiters get the most out of their investment.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic8} alt="uptimise it" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section idustries-on-demand bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>We implement disruptive mobile technologies to enhance Customer Experience.</h4>
                <p>Fortune Business Insights’s report states that by 2025, the mobile recruitment market will reach $3 million from $1.7 million in 2017. This shows the huge market potential. So, if you want a piece of this million-dollar industry, you need a recruitment portal of your own. For that, Uptimise IT is the best choice. As a leading <strong>job portal app development company,</strong> we can deliver the best solutions.</p>
              </div>
            </div>
            <div className="row">
             <We />
            </div>
          </div>
        </section>
        <section className="same-section range-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Feature-Filled Job Portal Solutions</h4>
                <p>Our <strong>job portal web application</strong> and mobile apps are filled with the best features. These features don’t only attract and engage users but also fulfill their needs. Moreover, a job portal software or mobile app can be divided into three-part, these are, as mentioned below:</p>
              </div>
            </div>
            <div className="range-box-parent">
              <ul>
                <li className="range-blue">
                  <div className="range-circle"><h2>01</h2></div>
                  <p>App for Job Seeker</p>
                </li>
                <li className="range-blue">
                  <div className="range-circle"><h2>02</h2></div>
                  <p>App for Job Provider </p>
                </li>
                <li className="range-blue">
                  <div className="range-circle"><h2>03</h2></div>
                  <p>Admin Panel</p>
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <li className="range-blue d-xl-none">
                  <div className="range-circle"><h2>01</h2></div>
                  <p>App for Job Seeker</p>
                </li>
                <div className="range-box-content">
                  <p>The users component of the app has features that enable easy job posting exploration and applying for the same. This includes features like login, online Resume, etc. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <li className="range-blue d-xl-none">
                  <div className="range-circle"><h2>02</h2></div>
                  <p>App for Job Provider </p>
                </li>
                <div className="range-box-content">
                  <p>The app for job providers enables the companies or employers to post a job opening, reply to applying seekers, and so on. </p>
                </div>
              </div>
              <div className="col-lg-4">
                <li className="range-blue d-xl-none">
                  <div className="range-circle"><h2>03</h2></div>
                  <p>Admin Panel</p>
                </li>
                <div className="range-box-content">
                  <p>The admin panel of job portal software allows the user to control and supervise the different information as well as activities on the platform.</p>
                </div>
              </div>
            </div>
            <div className="range-bottom-parent">
              <p>Our job portal solutions are compatible with all the different platforms including web, iOS, Android, and so on. And three basic components are included in every app, you can ask for more:</p>
              <div className="row">
               <Ios />
              </div>
              <div className="range-bottom-inner">
                <p>Uptimise IT also delivers custom <strong>job portal software</strong> development services. This enables you to get customize the solutions as per your needs.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Hire recruitment Portal Developers </h2>
                  <p>Want to ensure faster ROI for your recruitment app development?</p>
                  <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-default">Hire Recruitment App Developers</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section web-development-parent">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Job Portal App Development Solutions Cost Estimation</h4>
                <p>The cost of <strong>recruitment portal development</strong> depends on several different factors. So, if you are looking for a closer estimate, you contact Uptimise IT the best recruitment app development company. Nevertheless, these factors are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
             <Job />
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Services</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                    </div>
                  </div>
                </div>
              </div>
              <Service />
            </div>
          </div>
        </section>
        <section className="same-section company-box-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section industries-dating-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Flexible Recruitment App Development Process</h4>
                <p>Our team of <strong>recruitment portal developers</strong> has a flexible and agile development process they follow. This enables them to deliver the solutions within the time while maintaining the high quality.</p>
              </div>
            </div>
            <div className="dating-wrok-wrapper">
              <img src={pic} alt="uptimise it" />
              <div className="work-inner-wrapper">
                <ul>
                  <AbtSuccess />
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner bg-color">
          <div className="container">
            <div className="request-content">
              <h3>Hire the best recruitment portal developers</h3>
              <p>As a leading recruitment app development company, we offer you innovative and growing breaking job portal development services.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
     <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>These are answers to some of the most frequently asked questions about job portal app development: </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          What is job portal app?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Job portal app or recruitment app refers to a software application that allows the job seekers to explore and apply for job opening that are posted by the employers. There are various job portals or recruitment app like LinkedIn, Indeed, and so on. <br /> Therefore, if you want to develop a job portal app of your own, Uptimise IT is here to help.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How do I make a job search app?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">If you want to make a job search app of your own, all you need to do is reach out to us. As a leading job portal app development company in USA, Uptimise IT can deliver market-leading job search app solutions based on your needs and requirements.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          What technologies you use for job portal app development?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The mobile app developers at Uptimise IT use the latest technologies and tools to deliver the best job portal app development services. As such, some of the technologies we use are, as mentioned below:
                          <ul>
                            <li>Android SDK Emulator</li>
                            <li>Android Sketch paper</li>
                            <li>MobiOne,</li>
                            <li>RhoMobile</li>
                            <li>Appcelerator</li>
                            <li>Xamarin</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it cost to development job portal app? 
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The cost of developing a job portal mobile app actually depends on a lot of different factors. Nevertheless, you can expect anything between <strong>$20,000</strong> and <strong>$30,000.</strong></div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          How do you create a job portal?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          At Uptimise IT our team of mobile app developers follows an agile development process that consists of 6 steps. These are, as mentioned below: 
                          <ul>
                            <li>Planning and Analysis </li>
                            <li>UI/UX Design </li>
                            <li>Development Process</li>
                            <li>Testing &amp; Deployment </li>
                            <li>Mobile App maintenance </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
   
    )
}