import React from 'react'
import BeneData from './BeneData'
import BeneWork from './BeneWork'


const Bene = () => {
  return (
    <>
      {BeneData.map((val,ind) => {
      return (
        <BeneWork
        key={ind}
        title={val.title}
        imgsrc={val.imgsrc}
        text={val.text}
        />
      )
     } )}  
    </>
  )
}

export default Bene
