import React from 'react'

const AdminWork = (props) => {
  return (
    <div className="col-lg-6">
    <div className="chalange-box">
      <h3>{props.title}</h3>
      <p>{props.text}</p>
      <div className="chalange-box-icon">
        <img src={props.imgsrc} alt="uptimise it" />
      </div>
    </div>
  </div>
  )
}

export default AdminWork
