import React from 'react'
import {Link} from 'react-router-dom'

export default function Dafri() {
    return (
        <div>
        <div id="content">
          <section className="paycheck-bg payback-change-image">
            <img src="asset/images/Dafri-hero-banner-image.png" alt="images" />
            <div className="change-section-image">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="same-heading">
                      <span className="text-center">Case Study</span>
                      <div className="mobiles_head">
                        <h1>DafriBank </h1>
                        <h4>Digital Bank of Africa</h4>
                        <p>DafriBank is a leading banking portal that also allows users to trade cryptocurrencies and also provides e-wallet advantages.</p>
                      </div>
                      <div className="pay_btn">
                        <a className="btn btn-primaryx">Launch Date: 7 July 2021</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section platform-section bg-color">
            <div className="container">
              <div className="same-heading text-center">
                <h4>Crypto Wallet Integration</h4>
                <p>DafriBank was developed by Uptimise IT with Crypto Wallet Integration. This allows the platform to provide cryptocurrency trading.  Users can buy/sell crypto currencies (BTC, ETH, and DBA), and use them to top up the DafriBank e-wallet.</p>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="platform-box">
                    <Link to="#">
                      <div className="platform-inner-box">
                        <span>
                          <img src="asset/images/platform-web-icon.svg" alt="image" />
                        </span>
                        <h3>Website</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="pay_parent">
                <div className="prev_circle">
                  <span>
                    <img src="asset/images/Icon%20material-navigate-prev.png" />
                  </span>
                  <Link to="#"><h3>Medu4</h3></Link>
                </div>
                <div className="next_circle">
                  <Link to="#"><h3>DafriBank</h3></Link>
                  <span>
                    <img src="asset/images/Icon%20material-navigate-next.png" />
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section">
            <div className="container">
              <div className="same-heading">
                <span>Technology</span>
                <div className="mobiles_head">
                  <h4>The Technology Stack</h4>
                  <p>As a leading web/mobile app development company, Uptimise IT uses the latest technologies and tools to ensure high quality development solutions.</p>
                </div>
              </div>
              <div className="tecnologies-main-parent">
                <ul>
                  <li>
                    <div className="tecnho-top-box">
                      01
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/adobe-XD-icon.png" alt="image" />
                      </span>
                      <h3>Adobe XD</h3>
                    </div>
                  </li>
                  <li>
                    <div className="tecnho-top-box">
                      02
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/laravel-icon.png" />
                      </span>
                      <h3>Laravel</h3>
                    </div>
                  </li>
                  <li>
                    <div className="tecnho-top-box">
                      03
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/Java-icon.png" />
                      </span>
                      <h3>Java</h3>
                    </div>
                  </li>
                  <li>
                    <div className="tecnho-top-box">
                      04
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/html-icon.png" />
                      </span>
                      <h3>Html 5 </h3>
                    </div>
                  </li>
                  <li>
                    <div className="tecnho-top-box">
                      05
                    </div>
                    <div className="tecnho-bottom-box">
                      <span>
                        <img src="asset/images/my-sql-icon.png" />
                      </span>
                      <h3>My SQL </h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="same-section request-section">
            <div className="container">
              <div className="request-content">
                <h3>Let’s Talk</h3>
                <p>Bring your app idea to us and we will convert it into reality with our magic.</p>
                <a className="btn btn-primaryx">Get Free Consultation Now</a>
              </div>
            </div>
          </section>
          <section className="same-section payback-section">
            <div className="container">
              <div className="same-heading text-center">
                <span>DafriBank</span>
                <div className="mobiles_head">
                  <h4>Feature Set </h4>
                  <p className="text-center">DafriBank, The bank of Africa is a crypto-banking portal that has several feature enabling users to enjoy the platform to the fullest.</p>
                </div>
              </div>
              <div className="row feature-row-parent">
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Third-Party Bill Payment</h3>
                      <p>The DafriBank portal allows users to pay different kinds of bills via their online portal. The bank is continuously partnering with several third party companies, hence, the portal will support even more in the future.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Several Types of Accounts</h3>
                      <p>From personal banking to enterprise-level, DafriBank caters to everyone that is in need for digital banking. The portal boasts functionalities like various account types (Silver, Gold, Platinum), which users can avail based on their transaction values.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Cryptocurrency Exchange</h3>
                      <p>In addition to supporting BTC and ETH, DafriBank has its own crypto currency named DBA. The bank portal allows users to buy/sell and store crypto currencies.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Intra-Currency Money T/F</h3>
                      <p>Users are able to make payments in every currency that the portal supports. Currently DafriBank allows payments in NGN, ZAR, EUR, GBP, and USD.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Admin Panel Banking </h3>
                      <p>We empowered the DafriBank admin portal with the ability to manage the entire banking operations online. Role based information access, user account approval, cryptocurrency management and customer support are among the key features of Admin panel.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-parent">
                    <div className="feature-inner">
                      <h3>Banking</h3>
                      <p>DafriBank is a leading platform that provide amazing banking portal services across the Africa. This is one of the many amazing features of this banking portal. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section same_apps p-0">
            <img src="asset/images/dafriapp-middle-image.png" width="100%" />
            <div className="container-fluid">
              <div className="same-app-content satborsa-parent dafribank-list-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="same-app-inner-content">
                      <div className="app-parent">
                        <div className="medu_interface">
                          <h3><span>APP</span> INTERFACE</h3>
                        </div>
                      </div>
                      <div className="medu-app-inner-list">
                        <ul>
                          <li><img src="asset/images/ios-done-all.png" />Improved User Engagement</li>
                          <li><img src="asset/images/ios-done-all.png" />Pleasing Visual Design</li>
                          <li><img src="asset/images/ios-done-all.png" />Top notch User Experience</li>
                          <li><img src="asset/images/ios-done-all.png" />User Friendly</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section testimonial-section">
            <div className="container">
              <div className="same-heading text-center">
                <span>Testimonial</span>
                <div className="mobiles_head">
                  <h4>Customers? Satisfied</h4>
                  <p>Uptimise IT is all about delivering results that satisfy our clients and deliver success. Here are some words from our happy clients.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="testimonal-parent">
                    <h3>Help us improve our productivity</h3>
                    <div className="testimonal_child">
                      <div className="testimonal_img">
                        <Link to="#">
                          <img src="asset/images/army.png" />
                        </Link>
                      </div>
                    </div>
                    <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="testimonal-parent">
                    <h3>Help us improve our productivity</h3>
                    <div className="testimonal_child">
                      <div className="testimonal_img">
                        <Link to="#">
                          <img src="asset/images/army.png" />
                        </Link>
                      </div>
                    </div>
                    <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="same-section">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="education_slider">
                    <div className="owl-carousel owl-theme">
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Curvaceous</h3>
                                <p>Curvaceous fashion is a fashion app available on both iOS and Android which offers unparalleled user experience along with value to customers.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/curvaceous-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>CUT</h3>
                                <p>CUT  isan e-wallet mobile application that available in China and Myanmar. It works well with both RMB  and MMK currencies.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/cut-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>DafriBank </h3>
                                <p>DafriBank is a leading banking portal that also allows users to trade cryptocurrencies and also provides e-wallet advantages.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/DafriBank-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>medu4</h3>
                                <p>A leading online medical educational platform in Japanese market, Medu4 is a perfect example of our educational solutions.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/medu4-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>MM Arcade</h3>
                                <p>MM Arcade is a unique mix of gaming and social networking mobile app solutions that also offer prices up to $1000 per week.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/MM-arcade-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Nujaxx</h3>
                                <p>If you are feeling too cluttered or coming through something complex, decluttering experts can help you simplify life.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Nujaxx-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Pay By Check</h3>
                                <p>Pay by Check is a popular e-Wallet mobile app in the United States of America. It allows users to transfer, pay, or even exchange currency.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Pay-by-check-1-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Pearl Cab</h3>
                                <p>Pearl Cab is a ride-sharing company that provides on-demand taxi services to users globally. The app offers a wide range of vehicles like CNG auto, luxury cars, or 4x4 SUV that users can choose for taking rides.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Pearl-Cab-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>PT You </h3>
                                <p>PT You is a personal training mobile app specially built for iPhone and iPad that help you reach your fitness goal in no time.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/PT-you-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>SatBorsa</h3>
                                <p>SatBorsa is one of the kind currency exchange platform that is available on both platforms, iOS, and android.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Sat-Borsa-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>SatPay</h3>
                                <p>Versatile eWallet Solution that allows users to request, receive, and send payment without hassle.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/SatPay-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Student Castle </h3>
                                <p>Student Castle is a mobile app in USA on iOS platform that help students find accommodation near their college or school.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Student-castle-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Tawlety</h3>
                                <p>Are you a food lover who loves to taste a new recipe every day, then this app is definitely for you? Tawlety is basically a table reservation app. Tawlety allows the users to book a table at their favourite restaurant just with the help of a few taps.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/Tawlety-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="slider-content">
                              <div className="education-parent">
                                <h3>Ysily</h3>
                                <p>The New way of connecting to special people, Ysily. This is an online messaging mobile app in the UK that is connecting people in a better way.</p>
                              </div>  
                              <div className="case-btn">
                                <a className="btn btn-primaryx">Case Study</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="slider-img">
                              <img src="asset/images/ysily-slider.png" alt="image" />
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section></div>
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some common frequently asked questions about web and mobile app development:</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Why Choose Uptimise IT? 
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Uptimise IT is a globally recognized mobile app development company. We have worked with 800+ and delivered satisfactory &amp; innovative web/mobile app solutions. Our work has been realized by top tech reviewers like Clutch.co. <br />
                          Moreover, our team of developers is highly experienced and we have all the required resources along with the technical know-how needed to deliver the solution you seek. Uptimise IT can deliver mobile apps that can help your business generate millions in the market. <br />
                          So, if you are looking for this success, choose the best, choose Uptimise IT.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How Long Does It Take to Develop an App From Scratch?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Custom Mobile app development services create an app from scratch that is as per the preferences and needs of the client. As far as the time to market is concerned, mobile app duration highly depends on various factors like platform, tech stack, app size, and so on. <br />
                          Nevertheless, you can do anything between 2 weeks and 12 weeks for app development. If you want a closer estimate time, you can reach out to the team at Uptimise IT who can give you a closer development time based on project specifications. 
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How much does it Cost to Develop an App?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Just like the development time, the mobile app development cost highly depends on several factors like the type of app, platform, tech stack, features, and so on. So, if you want an estimated cost, you can contact our team with the details of the project. Nevertheless, you can expect anything between <strong>$15,000</strong> and <strong>$100,000.</strong> </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Do you Sign NDA?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">At Uptimise IT we highly care for our clients. So, to protect their unique idea before, after, and during project development, we sign an NDA or nondisclosure agreement with the client ensuring their data’s safety and full privacy.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          How Can I Hire Mobile App Developers at Uptimise IT?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">You can easily hire mobile app developers at Uptimise IT. All you need to do is contact us with your project needs. And we will be providing you with a dedicated developer or development team in 48 hours.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
   
    )
}