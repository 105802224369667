import pro from '../../../images/technology-stack004.png'
import pro1 from '../../../images/UIUX-Components006.png'
import pro2 from '../../../images/app-platform001.png'
import pro3 from '../../../images/features003.png'
import pro4 from '../../../images/storage003.png'
import pro5 from '../../../images/type-of-app005.png'
import pro6 from '../../../images/app-testing002.png'



const CostData = [
    {
        title:"Technology Stack",
        text:  <p>Tech stack refers to the technologies used in development. Different stack of technologies has different total development costs.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"UI/UX Components",
        text:  <p>UI/UX component is an important part of the mobile app. Moreover, the more complex design is, the more expensive it grows.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"App Platform",
        text: <p>Whether you choose android app development, iOS app development, or Hybrid, the development cost will be changing accordingly.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Features",
        text: <p>Features are the driving force of a mobile app. And just like UI/UX design, the more complex and advanced feature you include, the more expensive it is going to be.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Storage",
        text: <p>Larger mobile apps require more storage, in this way, the amount of storage required for the app also affects the total cost.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Type of App",
        text: <p>There are a few different types of e-wallet mobile app development. Depending on the type you choose, the development cost can be different.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"App Testing",
        text: <p>Some of the tools used in in-app testing are quite expensive, so the testing process of the app can affect the total cost.</p>,
       imgsrc: pro6,  
    
     },
  
   
     
     
    
]

export default CostData ;
