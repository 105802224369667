import React from 'react'
import IndustryWork from './IndustryWork'
import IndustryData from './IndustryData'

const Industry = () => {
  return (
    
      <  >
               {IndustryData.map((val,ind) => {
                return (
                  <IndustryWork
                  key={ind}
                  title={val.title}
                  imgsrc={val.imgsrc}
                  text={val.text}
                  />
                )
               } )}    
                    
                  </>

  )
}

export default Industry;