import pro1 from '../../../../images/custom-angularjs-solutions-angular.webp'
import pro2 from '../../../../images/angularjs-front-end-development-angular.webp'
import pro3 from '../../../../images/angularjs-application-development-angular.webp'
import pro4 from '../../../../images/custom-angularjs-solutions-angular.webp'
import pro5 from '../../../../images/angularjs-web-app-development-angular.webp'
import pro6 from '../../../../images/angular-web-development-services-angular.webp'


const AngularBigCardData = [
    {
        title:"Custom AngularJS Solutions ",
        text: "Our developer usesthe Angular framework to deliver feature-rich and appeasing applications as well as web apps that fulfill clients’ needs. This is the reason why custom AngularJS solutions are so popular.",
       imgsrc: pro1,  
       
    },
    {
        title:"AngularJS Front-End Development",
        text: "When it comes to creating interacting and aesthetic websites and apps, AngularJS works quite well. If this is something you are looking for, you can hire dedicated AngularJS developers for your project.",
       imgsrc: pro2,  
     
     },
     {
        title:"AngularJS application Development",
        text: "At Uptimise IT we use AngularJS to create feature-filled and amazing-looking applications that enable skyrocketing business growth and keep users engaged.",
       imgsrc: pro3,  
     
     },
     {
        title:"Cross-platform Mobile App Development",
        text: "Our team of AngularJS developers enables high-end cross-platform mobile application development using AngularJS. Now, you can hire an AngularJS expert to create a hybrid mobile app of your own.",
       imgsrc: pro4,  
      
     },
     {
        title:"AngularJS Web App Development ",
        text: "AngularJS web app development is one of our highlights. Our team of developers has the right technical ability and experience to deliver amazing web applications.",
       imgsrc: pro5,  
    
     },
     {
        title:"Angular Web Development services ",
        text: "Dedicated AngularJS developers at Uptimise IT provide amazing web development services which drive business growth.",
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default AngularBigCardData ;