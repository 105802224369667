import React from 'react'
import { Link } from 'react-router-dom'

const PythonBigCardWork = (props) => {
  return (
    <div className="col-lg-4">
    <div className="technology-box">
      <Link to="#">
        <figure>
          <img src={props.imgsrc} alt="uptimise it" />
        </figure>
        <h3>{props.title}</h3>
        <p>{props.text}</p>
      </Link>
    </div>
  </div>
  )
}

export default PythonBigCardWork
