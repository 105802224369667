import pro from '../../../images/custom-healthcare-app-development-helthcare.png'
import pro1 from '../../../images/hippa-compliant-solutions-helthcare.png'
import pro2 from '../../../images/medical-systems-integration-helthcare.png'
import pro3 from '../../../images/mobile-app-maintenance-support-helthcare.png'
import pro4 from '../../../images/enterprise-mobility-solutions-helthcare.png'
import pro5 from '../../../images/healthcare-app-consultation-helthcare.png'



const HealthData = [
    {
        title:"Our Healthcare App Development Services",
        text:  <p>
        Our <strong>healthcare app development services</strong> are
        backed by our years’ worth of experience and state-of-the-art
        technology. This allows us to deliver services that help your
        business grow and reach the desired level. Our services are, as
        mentioned below:
      </p>,
       imgsrc: pro,  
       
    },
    {
        title:"Custom Healthcare app development",
        text:   <p>
        If you have a healthcare mobile app Idea that you want to
        develop, you have come to the right place. Our team of mobile
        app developers has the right know-how needed to develop custom
        healthcare mobile apps.
      </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"HIPAA Compliant Solutions",
        text:  <p>
        We understand how important it is for Healthcare app solutions
        to follow compliance. That is why, as a top healthcare mobile
        app development company in the USA all of our healthcare
        mobile apps are HIPAA agreement Compliant.
      </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Medical Systems Integration",
        text: <p>
        Whether you are a start-up or a full-fledged corporate,
        NimblUptimise IT can create the medical systems you are
        looking for. Moreover, our team also provides amazing medical
        system integration solutions.
      </p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Mobile App Maintenance & support",
        text: <p>
        In addition to healthcare mobile app development services,
        Uptimise IT also provides amazing
        <strong>mobile app maintenance and support</strong> services
        to our clients. Thus, enabling them to keep their app up and
        running perfectly.
      </p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Enterprise Mobility Solutions",
        text: <p>
        Uptimise IT is known throughout USA and UK for amazing web and
        mobile app development services. In addition, we also provide
        innovative and data-driven
        <strong>enterprise application development services.</strong>
      </p>,
       imgsrc: pro5,  
    
     },
  
   
     
     
    
]

export default HealthData ;
