import React from 'react'
import JobData from './JobData'
import JobWork from './JobWork'

const Job = () => {
  return (
    <>
       {JobData.map((val,ind) => {
        return (
          <JobWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default Job;
