import pro1 from '../../images/customer-rate-icon.png'
import pro2 from '../../images/customers-icon.svg'
import pro3 from '../../images/rating-icon.svg'
import pro4 from '../../images/complete-goal-icon.svg'

const SuccessData = [
    {
        text: "Customer Retention Rate:",
       imgsrc: pro1,  
       percent: " 97%"
    },
    {
        text: "Customers Collaborated with:",
        imgsrc: pro2,    
        percent: "60+"
     },
     {
        text: "Years of Experience:",
        imgsrc: pro3, 
        percent: "5+"
     },
     {
        text: "Completed Projects:",
        imgsrc: pro4, 
        percent: "100+"
     },
     
    
]

export default SuccessData ;