import React from 'react'
import DevHeadWork from './DevHeadWork'
import DevHeadData from './DevHeadData'

const DevHead = () => {
  return (
    <>
       {DevHeadData.map((val,ind) => {
              return (
                <DevHeadWork
                key={ind}
            
                imgsrc1={val.imgsrc1}
                imgsrc2={val.imgsrc2}
                imgsrc3={val.imgsrc3}
                imgsrc4={val.imgsrc4}
                imgsrc5={val.imgsrc5}
                imgsrc6={val.imgsrc6}
                
                />
              )
             } )} 
    </>
  )
}

export default DevHead;
