import React from 'react'
import pic from "../images/automotives-banner-images.webp"
import pic1 from "../images/unmatched-performance-automotives.png"
import pic2 from "../images/feature-filled-automotives.png"
import pic3 from "../images/effective-management-automotives.png"
import pic4 from "../images/future-proof-solution-automotives.png"
import pic5 from "../images/monitoring-automotives.png"
import pic6 from "../images/automotive-user-panel.png"
import pic7 from "../images/automotives-admin-panel.png"



import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Automation from './auto/automation/Automation'
import Software from './auto/software/Software'
import Service from "./service/Service"
import Process from "./process/Process"
import Values from "./values/Values"
export default function Automotive() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="image" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Automotive Software <br />Development </strong> Company </h1>
              <p>Create an automotive ecosystem with an innovative <strong>automotive software development company,</strong> Uptimise IT.</p>
              <div className="industries-btn">
                <Link to="contact.html" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Automotive Development</span>
              <div className="mobiles_head">
                <h4>Stellar Car Software Development</h4>
                <p>Uptimise IT offers market-leading <strong>automotive software development services</strong> delivering value to your business and enabling explosive growth.</p>
              </div>
            </div>
            <div className="industry-icon-parent">
              <ul>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Unmatched Performance</h3>
                      <p>Uptimise IT as a leading automotive software development company, deliver unmatched performance.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic1} alt="image" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Feature Filled</h3>
                      <p>Our autonomous <strong>car software development</strong> is filled with a feature that attracts and engage customers.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic2} alt="image" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Effective Management</h3>
                      <p>Our autonomous car software allows effective management of the vehicles in question. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic3} alt="image" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Future Proof Solution </h3>
                      <p>Our market-leading car software development solutions are future-proof and deliver innovation. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic4} alt="image" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Monitoring</h3>
                      <p>Our autonomous car software development solutions enable easy and effective monitoring.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic5} alt="image" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section opportunities-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Automation</span>
              <div className="mobiles_head">
                <h4>Automotive Software Development Services</h4>
                <p>Uptimise IT is a leading automotive software development company offering a number of appealing automotive software development services that fulfill your business goals. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <Automation />
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Our Portfolio</h2>
                  <p>Uptimise IT have developed and delivered hundreds of successful projects. Here are some of our success stories.</p>
                  <Link to="contact.html" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section app-panel">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Automotive Software Panel</h4>
                <p>Uptimise IT fills the automotive solution with the right features and functionality to enable effective operations &amp;satisfactory results.</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic6} alt="image" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>User panel</h3>
                  <p>The user panel of the automotive software development solution all the customers to easily avail all of the functions and services of the software.</p>
                  <div className="panel-list">
                    <ul>
                      <li>Quick and easy profile registration </li>
                      <li>Explore product specifications </li>
                      <li>Order management </li>
                      <li>Free amenities – blogs, quotes, etc.</li>
                    </ul>
                  </div>
                  <Link to="contact.html" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic7} alt="image" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>Admin panel</h3>
                  <p>The admin panel of this Car Software Development solution effectively enables the management of the platform. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Easy quote assigning </li>
                      <li>Interactive Dashboard </li>
                      <li>Manage automobile </li>
                      <li>Fleet management and monitoring </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>            
          </div>
        </section>
        <section className="same-section web-development-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Automotive Software</span>
              <div className="mobiles_head">
                <h4>Hire The Best Automotive Software Development Solutions </h4>
                <p>Uptimise IT delivers only the best <strong>Automotive Software Development</strong> solutions. Some reasons to choose us are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <Software />
            </div>
          </div>
        </section>
        <section className="same-section technical-section automotive-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Technologies &amp; Tools Used By Our Automotive Software Developers</h4>
                <p>The <strong>automotive software developers</strong> at Uptimise IT use the latest tools and technologies to develop market leading solutions. These are: </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Framework </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Ionic </li>
                        <li>Onsen UI </li>
                        <li>PhoneGap</li>
                        <li>Codename One </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Languages</h3>
                    <div className="panel-list">
                      <ul>
                        <li>Java </li>
                        <li>Kotlin</li>
                        <li>JavaScript </li>
                        <li>Swift </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Testing Tools </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Test IO </li>
                        <li>Espresso </li>
                        <li>XCUI Test</li>
                        <li>Appium</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Solutions</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                    </div>
                  </div>
                </div>
              </div>
             <Service />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Development Process </span>
              <div className="mobiles_head">
                <h4>Automotive Software Development Process</h4>
                <p>AS a leading Automotive Software development company, Uptimise IT follows a well-planned out agile development process. This helps us ensure the quality of our solution.</p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section bg-color company-box-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
              </div>
            </div>
            <div className="row">
              <Values />
            </div>
          </div>
        </section>
      

        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Innovative Mobile App/Web Development Services</h3>
              <p>Uptimise IT is a leading mobile app and web Development Company that offers amazing services across different industries.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>

        <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some of the most frequently asked questions aboutAutomotive Software Development Company</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Which software is used in automobile industry?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT delivers the best automotive software development services that are tailor-made to the needs of your business. So, if this is what you are looking for, we are here to help you.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How Python is used in automotive industry?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Python is a programming language that acts as the driving force behind automation-based solutions. So, in the automotive industry, it is used to deliver automotive software development services. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Cost to develop automobile software?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The cost to develop automobile software can range between 20,000 and 100,000 depending on the specification of the project.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Will you sign NDA?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">As a leading mobile app development company, Uptimise IT values your confidentiality above anything else. This is why we always sign an NDA or non-disclosure agreement with the client. Thus, ensuring the confidentiality of your unique mobile app idea.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Where is your company based?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT is a globally renowned Automotive Software Development Company based in the United States of America and the United Kingdom.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
   
    )
}