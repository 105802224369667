import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Process from './process/Process'
import Service from './service/Service'
import Values from './values/Values'
import Commerce from "../components/e-commerce/Commerce"

import pic from "../images/industry-ecommerce-banner-image.png"
import pic1 from "../images/multiple-language-support-industries-ecommerce.png"
import pic2 from "../images/secure-payment-integration-industries-ecommerce.png"
import pic3 from "../images/wishlist-industries-ecommerce.png"
import pic4 from "../images/coupons-offers-cashback-industries-ecommerce.png"
import pic5 from "../images/delivery-tracking-industries-ecommerce.png"
import pic6 from "../images/ecommerce-user.png"
import pic7 from "../images/ecommerce-admin.png"
import EDevelop from '../components/e-devlop/EDevelop'
export default function Ecommerce() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="image" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Ecommerce Development Company</strong> </h1>
              <p>Develop The Next Amazon With The Best Ecommerce Development Company, Uptimise IT. </p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>E-Commerce</span>
              <div className="mobiles_head">
                <h4>Hire Ecommerce Developers, Generate Success </h4>
                <p><strong>Hire eCommerce Developers</strong> with Uptimise IT who will deliver market-leading, scalable eCommerce platform to you. Grow your business, outshine the competition, </p>
              </div>
            </div>
            <div className="industry-icon-parent">
              <ul>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Multiple Language Support </h3>
                      <p>Your users come from different backgrounds, and with multilingual capacity, you can entertain them all.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic1} alt="image" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Secure Payment Integration </h3>
                      <p>With multiple secure payment gateway integrations, the users can pay for their orders through the app. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic2} alt="image" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Wishlist</h3>
                      <p>Make a wish list of favorite items and check the items as you buy them with eCommerce apps.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic3} alt="image" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Coupons, Offers, Cashback</h3>
                      <p>Every shopper enjoyscashback, offers, and coupons, that’s why we have integrated this into our app.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic4} alt="image" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Delivery Tracking</h3>
                      <p>The joy of waiting for the order is different. With delivery tracking, it will increase many folds.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic5} alt="image" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section opportunities-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>E-Commerce</span>
              <div className="mobiles_head">
                <h4>Data-Driven eCommerce Development Services</h4>
                <p>Uptimise IT as a leading eCommerce development company offers attractive and success-driving <strong>eCommerce development services.</strong> These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
             <Commerce />
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Our Portfolio</h2>
                  <p>Uptimise IT has developed and delivered hundreds of successful projects. Here are some of our success stories. </p>
                  <Link to="#" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section app-panel">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Panels in eCommerce Mobile App Development</h4>
                <p>As a leading <strong>ecommerce development agency,</strong> Uptimise IT providesa unique solution with useful features. The panels of eCommerce development are, as mentioned below:</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic6} alt="image" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>User panel</h3>
                  <p>The user panel allows the customers to effectively shop, pay, and track orders effectively. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Advanced search feature</li>
                      <li>Easy profile registration</li>
                      <li>Multiple payment gateway methods</li>
                      <li>Manage and track orders </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic7} alt="image" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>Admin panel</h3>
                  <p>The admin panel enables effective management of  eCommerce platform.</p>
                  <div className="panel-list">
                    <ul>
                      <li>Interactive dashboard </li>
                      <li>24/7 customer support functionality </li>
                      <li>Order and payment management </li>
                      <li>Inventory Management </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>           
          </div>
        </section>
        <section className="same-section web-development-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>e-commerce Development</span>
              <div className="mobiles_head">
                <h4>Choose The Best Ecommerce Software Development Company</h4>
                <p>Uptimise IT is a leading <strong>Ecommerce Software Development Company</strong> that knows what customer wants and market demands. Some reasons to choose us are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <EDevelop />
            </div>
          </div>
        </section>
        <section className="same-section technical-section automotive-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Innovative Ecommerce Development With Latest Technology &amp; Tools </h4>
                <p>Uptimise IT offers only the latest technologies and tools to ensure high quality <strong>Ecommerce Mobile App And Web Development Services.</strong> </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Framework </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Ionic </li>
                        <li>Onsen UI </li>
                        <li>PhoneGap</li>
                        <li>Codename One </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Languages </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Java</li>
                        <li>Kotlin</li>
                        <li>JavaScript </li>
                        <li>Swift </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Testing Tools </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Test IO </li>
                        <li>Espresso</li>
                        <li>XCUI Test</li>
                        <li>Appium</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Stacks </h3>
                    <div className="panel-list">
                      <ul>
                        <li>MEAN Stack </li>
                        <li>MERN stack </li>
                        <li>Full Stack 	</li>
                        <li>ADT</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Solutions</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                    </div>
                  </div>
                </div>
              </div>
             <Service/>
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Development Process </span>
              <div className="mobiles_head">
                <h4>Ecommerce Development Process</h4>
                <p>AS a leading eCommerce development company, Uptimise IT follows a well-planned out agile development process. This helps us ensure the quality of our solution.</p>
              </div>
            </div>
            <div className="row">
              <Process />
            </div>
          </div>
        </section>
        <section className="same-section bg-color company-box-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
              </div>
            </div>
            <div className="row">
              <Values />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Innovative Mobile App/Web Development Services</h3>
              <p>Uptimise IT is a leading mobile app and web Development Company that offers amazing services across different industries.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
    <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some of the most frequently asked questions about ecommerce development company.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          How Much Does It Cost for Retail and eCommerce App Development?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The cost for retail and eCommerce app development highly depends on several factors. These are:
                          <ul>
                            <li>Developer location</li>
                            <li>Complexity </li>
                            <li>Feature</li>
                            <li>Design </li>
                            <li>Type of app </li>
                          </ul>
                          Nevertheless, you can expect anything between $25,000 and $ 120,000. 
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          What Process Do You Apply for Retail &amp; eCommerce App Development?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The process we follow for retail and eCommerce development is, as mentioned below:
                          <ul>
                            <li>Analysis &amp; Planning</li>
                            <li>UI/UX Design</li>
                            <li>Development Process</li>
                            <li>Testing</li>
                            <li>Maintenance &amp; Support</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          What Are Different Ecommerce App Development Services You Offer?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The different eCommerce app development services Uptimise IT offers are, as mentioned below: 
                          <ul>
                            <li>Custom eCommerce Development Services </li>
                            <li>Bigcommerce Development Solutions </li>
                            <li>WooCommerce Development Services </li>
                            <li>eCommerce Mobile App Solutions </li>
                            <li>Web/Mobile App Maintenance Service </li>
                            <li>Shopify eCommerce Solution </li>
                            <li>Magento eCommerce Development Services </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          Why Choose Uptimise IT for eCommerce Applications Software Development Services?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT is the best eCommerce development company you can find in the USA. We have years’ worth of experience along with the right tools and creative approach required to deliver market-leading solutions. So, if you want to develop a mobile app of your own, we can help you with it. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          I Run a Clothing Store, Should I Obtain for eCommerce Store App Development Services?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Yes. If you are running any kind of store, you can highly benefit from eCommerce store app development services. Now, you can develop your own solution with Uptimise IT a leading eCommerce development company.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
   
    )
}