import pro from '../../../images/learning-management-systems.png'
import pro1 from '../../../images/self-learning-apps.png'
import pro2 from '../../../images/school-infrmation-system.png'


import { Link } from 'react-router-dom'

const ViaData = [
    {
        title:"Learning Management Systems",
        text:  <p>According to your specific business case, we deliver very resourceful, insightful, and highly intuitive LMS (Learning Management Systems) solutions. Also, we align your training needs to build a custom LMS software solution capable of helping you with administration, documentation, tracking, and delivery of training programs, educational courses, and development programs. Our understanding of your company’s specific needs is what sets us apart as an <Link to="#">educational app development service provider.</Link> </p>,
       imgsrc: pro,  
       
    },
    {
        title:"Self-Learning Apps",
        text:  <p>In this modern era, Self-Learning applications are a trending business model. Educational apps like Duolingo, Babbel, Rosetta Stone, and many more help students learn independently. Creating a self-learning app isn’t simple because it requires a lot of research on the user experience (UX) and user journey to ensure learners are motivated. To achieve this within self-learning apps, we often implement features such as competing with friends and other types of gamification.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"School Information System",
        text: <p>We are an <Link to="#">Elearning app development</Link> company that develops school information systems that comprise an administrative panel, apps for teachers, students, and parents/mentors. The most crucial aspect of creating a school management system is keeping the <Link to="#">education mobile app development cost</Link> low while integrating system data security, infrastructure availability, and robustness.</p>,
       imgsrc: pro2,  
     
     },
     
  
   
     
     
    
]

export default ViaData ;