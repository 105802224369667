import pro1 from '../../../images/perfective-and-corrective-maintenance002.png'
import pro2 from '../../../images/adaptive-maintenance001.png'
import pro3 from '../../../images/routine-003maintenance.png'


const MaintainData = [
    {
        title: "Perfective and Corrective Maintenance",
       imgsrc: pro1,  
       text: <p>This <strong>application maintenance service,</strong> help you improve your apps and perfect them to evolving market demand.</p>
    },
    {
        title: "Adaptive Maintenance",
        imgsrc: pro2,    
        text: <p>As the name suggests adaptive IT support and maintenance services help the application adapt to changing customer demand.</p>
     },
     {
        title: "Routine Maintenance",
        imgsrc: pro3, 
        text: <p>Routine <strong>app/software maintenance services</strong> help with timelyup-keeping and care of the application.</p>
     },
    
]

export default MaintainData;