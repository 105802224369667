
import pro1 from '../../../images/fast-time-to-market005.png'
import pro2 from '../../../images/innovative-solutions005.png'
import pro3 from '../../../images/future-ready-development005.png'
import pro4 from '../../../images/dedicated-developers005.png'
import pro5 from '../../../images/proven-work005.png'
import pro6 from '../../../images/client-centric-development-service005.png'

const ChooseData = [
    {
        title:"Fast Time to Market",
        text: "Our agile and flexible trust wallet clone app development process allows us to provide fast time to market to our clients. This is one of our highlighting qualities.",
       imgsrc: pro1,  
       
    },
    {
        title:"Innovative Solutions",
        text: "Uptimise IT is dedicated to delivering highly innovative solutions using the latest development tools and technologies.",
       imgsrc: pro2,  
     
     },
     {
        title:"Future Ready Development",
        text: "Our blockchain wallet app development solutions are future-ready. Meaning you wouldn’t need to upgrade or migrate it every second year.",
       imgsrc: pro3,  
     
     },
     {
        title:"Dedicated Developers",
        text: "With Uptimise IT, you can hire dedicated developers for crypto wallet app development projects.",
       imgsrc: pro4,  
      
     },  
     {
        title:"Proven Work",
        text: "As a leading crypto wallet app development agency, our work is proven and recognized by tech reviewers like Clutch.",
       imgsrc: pro5,  
      
     },  
     {
        title:"Client-Centric Development Service",
        text: "Our innovative cryptocurrency application development solutions are based around the needs of our clients.",
       imgsrc: pro6,  
      
     },  
    
]

export default ChooseData ;