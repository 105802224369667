import pro1 from '../../../images/order-management-logistic.png'
import pro2 from '../../../images/inventory-management-logistic.png'
import pro3 from '../../../images/forecasting-logistic.png'
import pro4 from '../../../images/ecommerce-integration-logistic.png'
import pro5 from '../../../images/real-time-visibility-logistic.png'
import pro6 from '../../../images/data-analytics-logistic.png'
import pro7 from '../../../images/multiple-user-logins-logistic.png'
import pro8 from '../../../images/real-time-alerts-logistic.png'

const TrackingData = [
    {
        title:"Order Management",
        text: "Order and billing management is always one of the key features that logistics management and shipment tracking software required. We can provide you with centralized as well as decentralized order and billing management capabilities with your logistics software solution.",
       imgsrc: pro1,  
       
    },
    {
        title:"Inventory Management",
        text: "Warehouse automation requires software to manage the inventory to ensure safety and cost-efficiency. Our logistics software solutions empower warehouse managers to manage inventory levels.",
       imgsrc: pro2,  
     
     },
     {
        title:"Forecasting",
        text: "Be it available inventory or in-demand inventory, logistics service providers need assistance in forecasting. Our logistics app development includes advanced algorithms. This ensures that you procure what is required and have enough supplies to feed the demand anomalies.",
       imgsrc: pro3,  
     
     },
     {
        title:"E-Commerce Integration",
        text: "We can implement e-commerce services with our logistics mobile app development. We integrate the logistics software with eCommerce platforms, so you can sell and deliver your products.",
       imgsrc: pro4,  
      
     },
     {
        title:"Real-time Visibility",
        text: "It is very critical for a transportation and logistics service provider to have real-time visibility of their assets. Goods are prone to theft and damage. The ability to track cargo movements will ensure the safety of stored/in-transit goods.",
       imgsrc: pro5,  
    
     },
     {
        title:"Data Analytics",
        text: "Getting the right data into your hand is not the end of the job. Data Analysis and Visualization are the techniques that can bring meaning to the data. Our logistics software development includes Advanced Data Analytics capabilities.",
       imgsrc: pro6,
     
     },
     {
        title:"Multiple User Logins",
        text: "In our experience as a logistics app development company, there are various levels of users for logistics delivery management software. For example, in a logistics application, typically the drivers will not have access to the delivery status of other drivers, but the managers will be able to view this information.",
       imgsrc: pro7,  
       
     },
     {
        title:"Real-time Alerts",
        text: "We understand that while deliveries are being made, it may not be convenient for fleet managers to open the app every time they want to check the fleet status. To automate the process, we configure alerts for deliveries, route anomalies, theft, delivery delays, and many more scenarios.",
       imgsrc: pro8,  
     
     },
    
     
     
    
]

export default TrackingData ;