import pro from '../../../images/innovative-solution-online.png'
import pro1 from '../../../images/future-proof-online.png'
import pro2 from '../../../images/dedicated-developer-online.png'
import pro3 from '../../../images/customer-centric-services-online.png'
import pro4 from '../../../images/future-proof-online.png'
import pro5 from '../../../images/transparent-process-online.png'



const BestData = [
    {
        title:"Innovative Solution",
        text:  <p>Uptimise IT is known as one of the forerunners bringing innovation to web and mobile app development.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Future-Proof",
        text:  <p>Our mobile app and web app solutions are future proof that you don’t have to worry about becoming a legacy.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Dedicated Developer",
        text:  <p>With us, you can hire dedicated developers who deliver market-leading solutions</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Customer Centric Services",
        text: <p>All of our marketplace app development services are based on customer needs.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"State-Of-The-Art Technology",
        text:  <p>We only use the latest technologies and tools to ensure high-quality solutions.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Transparent Process",
        text:  <p>Our mobile app development process is entirely transparent and opento customers.</p>,
       imgsrc: pro5,  
    
     },

]

export default BestData ;