

import React from 'react'

const ProcessWork = (props) => {
  return (
    <div className="col">
                  <div className="analysis-parent">
                    <div className="animate-border" />
                    <div className="analysis-before">{props.num}</div>
                    <div   className='dad' > <img src={props.imgsrc}  alt='uptimiseit'/></div>
                    <h4>{props.title}</h4>
                    <p>{props.text}</p>
                  </div>
                </div>
  )
}

export default ProcessWork
