import React from 'react'
import LoanAdvanceData from './LoanAdvanceData'
import LoanAdavanceWork from './LoanAdvanceWork'

const LoanAdavance = () => {
  return (
    <>
       {LoanAdvanceData.map((val,ind) => {
        return (
          <LoanAdavanceWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default LoanAdavance;
