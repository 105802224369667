import React from 'react'

const MaintainWork = (props) => {
  return (
    <div className="col-lg-4">
                <div className="software-box-parent">
                  <figure>
                    <img src={props.imgsrc} alt="uptimise it" />
                  </figure>
                  <h3>{props.title}</h3>
                  <p>{props.text}</p>
                </div>
              </div>
  )
}

export default MaintainWork
