import React from 'react'
import TypeData from './TypeData'
import TypeWork from './TypeWork'

const Type = () => {
  return (
    <>
       {TypeData.map((val,ind) => {
        return (
          <TypeWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default Type;
