import pro1 from '../../../images/kyc-and-eml-compilation.png'
import pro2 from '../../../images/conversational-aI.png'
import pro3 from '../../../images/fraud-prevention.png'
import pro4 from '../../../images/learn-grow.png'
import pro5 from '../../../images/data-management-insight.png'
import pro6 from '../../../images/better-decision-making.png'
import { Link } from 'react-router-dom'


const SolutionData = [
    {
        title:"KYC and AML Compilation",
        text: "By combining the OCR and Computer Vision with the AI software development services can easily process the data from various sources in real-time. AI also helps in enhancing the Due Diligence framework within the organization.",
       imgsrc: pro1,  
       
    },
    {
        title:"Conversational AI",
        text: "In the upcoming decades, conversational UI will be going to be the topmost priority by replacing the web and mobile interfaces. Moreover, it is going to evolve human and machine interactions. As a leading artificial intelligence development company, we can deliver that.",
       imgsrc: pro2,  
       
    },
    {
        title:"Fraud Prevention",
        text: "Traditional fraud detection methods are not efficient enough, due to which several billion are lost per year. With AI algorithms and Big Data technologies, fraud detection can be effectively reduced.",
       imgsrc: pro3,  
       
    },
    {
        title:"Learn & Grow",
        text:  <p>Artificial Intelligence is a technology that feeds on data and grows with time. The older it is, the more smart, efficient, and useful to the fintech business it is going to be.</p>,
       imgsrc: pro4,  
       
    },
    {
        title:"Data Management & Insight",
        text: "Since Artificial Intelligence software can use data and help manage it better. In addition, AI software and apps also provide better data management in fintech.",
       imgsrc: pro5,  
       
    },
    {
        title:"Better Decision Making",
        text: "With better data management using artificial intelligence and improved insight, you can use the information to make better decisions.",
       imgsrc: pro6,  
       
    },
   
    
       
]

export default SolutionData ;