import pro1 from '../../../../images/experinced-developers-magento.webp'
import pro2 from '../../../../images/latest-tech-magento.webp'
import pro3 from '../../../../images/customer-centric-services-magento.webp'
import pro4 from '../../../../images/proven-work-magento.webp'
import pro5 from '../../../../images/transparency-magento.webp'
import pro6 from '../../../../images/scalable-solutions-magento.webp'


const MagentoBigCardData = [
    {
        title:"Experinced Developers  ",
        text:  <p>You can <strong>hire dedicated magento developer</strong> who is highly experienced and equipped with right technology. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Latest Tech",
        text:  <p>Uptimise IT uses nothing but the latest and best technology to ensure high-quality solutions. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Customer Centric Services",
        text:  <p>We totally revolve around customers’ needs and their project requirements allowing us to add a personal touch. </p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Proven Work",
        text: <p>We have delivered hundreds success solutions in the market and are recognized by top tech reviewers like Clutch.co.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Transparency ",
        text:   <p>Our team of developers maintains an open communication channel and total transparency throughout the process. 	</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Scalable Solutions ",
        text:  <p>You can <strong>hire Magento developer USA/UK</strong>  people love, who will deliver amazing scalable solutions for you.</p>,
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default MagentoBigCardData ;