import React from 'react'
import Service from "./service/Service"
import Process from "./process/Process"
import Values from "./values/Values"
import FoodDel from "../food-del/FoodDel"

import pic from "../images/food-delivery-banner-image.png"
import pic1 from "../images/easy-order-process-food.png"
import pic2 from "../images/multiple-secure-payment-options-food.png"
import pic3 from "../images/advanced-restaurant-search-food.png"
import pic4 from "../images/discounts-coupons-offering-food.png"
import pic5 from "../images/live-tracking-food.png"
import pic6 from "../images/delivery-user.png"
import pic7 from "../images/delivery-partner-img.png"
import pic8 from "../images/restaurant-panel-img.png"


import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Delivery from '../components/delivery/Delivery'
export default function FoodDelivery() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="industries-banner-section banner-section">
          <img src={pic} alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Food Delivery<br /> App Development </strong> Company </h1>
              <p>Develop The Next <strong>App Like Uber</strong> for Food Delivery With Uptimise IT, The Best <strong>Food Delivery App Development Company.</strong></p>
              <div className="industries-btn">
                <Link to="javascript:void(Tawk_API.toggle())" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Food Delivery </span>
              <div className="mobiles_head">
                <h4>Humane Food Delivery App Development</h4>
                <p>Uptimise IT adds the best features during <strong>food delivery app development.</strong> Making it easier for users to order mouth-watering food and get it delivered. And allows businesses to generate off-the-chart revenue.</p>
              </div>
            </div>
            <div className="industry-icon-parent">
              <ul>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Easy Order Process </h3>
                      <p>Our food delivery mobile apps allow users to order their favorite food and restaurant to manage them effectively.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic1} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Multiple Secure Payment Options </h3>
                      <p>Finding the right place to order food from can be a hassle. But not anymore, our search feature is here to help.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic2} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Advanced Restaurant Search </h3>
                      <p>Finding the right place to order food from can be a hassle. But not anymore, our search feature is here to help.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic3} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Discounts, Coupons, &amp; Offering</h3>
                      <p>Who doesn’t love when they get an extra discount on their order? Our solution allows the creation and avail of the same.</p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic4} alt="uptimise it" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="industry-icon-box">
                    <div className="industry-content">
                      <h3>Live Tracking </h3>
                      <p>The wait between ordering food and it getting delivered is most intense, Cut it short with live tracking of your order. </p>
                    </div>
                    <div className="industry-icon">
                      <img src={pic5} alt="uptimise it" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section opportunities-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Food Delivery App </span>
              <div className="mobiles_head">
                <h4>Stellar Food Delivery App Development Services</h4>
                <p>Uptimise IT as a market-leading <strong>food delivery app Development</strong> Company, we offer a range of food delivery app development services. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <Delivery />
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Our Portfolio</h2>
                  <p>Uptimise IT have developed and delivered hundreds of successful projects. Here are some of our success stories. </p>
                  <Link to="#" className="btn btn-default">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section app-panel">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Panels of Food Delivery AppsLike Uber</h4>
                <p>Uptimise IT as a <strong>food delivery application development company</strong> is known for a humane food delivery mobile app that allows users to order food and the vendors to receive orders effectively. Different panels of the app are, as mentioned below:</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic6} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>User Panel</h3>
                  <p>User Panel allows the customer to effortlessly order food, pay for it, explore places, and track it in real-time management.</p>
                  <div className="panel-list">
                    <ul>
                      <li>Order food for home delivery in a few easy steps</li>
                      <li>Track the order in real-time with the app</li>
                      <li>Multiple Payment Gateway Methods</li>
                      <li>Contact details of the delivery person </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic7} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>Delivery Partner Panel</h3>
                  <p>The Panel for delivery partners allows them to effectively deliver the order within the time. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Receive, accept or reject order delivery requests. </li>
                      <li>Navigation and route optimization to the delivery location </li>
                      <li>Order details </li>
                      <li>In-App chatting feature </li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div> 
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="app-panel-img">
                  <img src={pic8} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="app-panel-content">
                  <h3>Restaurant Panel</h3>
                  <p>The user panel for the restaurant panel allows them to receivethe order, update the menu, etc. </p>
                  <div className="panel-list">
                    <ul>
                      <li>Add, remove, and edit food items to the menu</li>
                      <li>Accept or Reject the order </li>
                      <li>Update waiting time, order status </li>
                      <li>Receive payment through the app</li>
                    </ul>
                  </div>
                  <Link to="#" className="btn btn-primaryx">Get Started</Link>
                </div>
              </div>
            </div>            
          </div>
        </section>
        <section className="same-section web-development-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Food Delivery </span>
              <div className="mobiles_head">
                <h4>Hire the Best Food Delivery App Developers </h4>
                <p>We care about our customers and go the extra mile to deliver value to you. Here are some reasons why we are the best food delivery app development company. </p>
              </div>
            </div>
            <div className="row">
              <FoodDel />
            </div>
          </div>
        </section>
        <section className="same-section technical-section automotive-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Our Delivery App Development Technologies &amp; Tools</h4>
                <p><strong>Food Delivery app developers</strong> use state-of-the-art technologies and tools for testing &amp; developing to ensure high-quality, robust, scalable solutions that help your business grow. </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Framework</h3>
                    <div className="panel-list">
                      <ul>
                        <li>Ionic </li>
                        <li>Onsen UI </li>
                        <li>PhoneGap</li>
                        <li>Codename One </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Languages </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Java </li>
                        <li>Kotlin</li>
                        <li>JavaScript </li>
                        <li>Swift </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Testing Tools </h3>
                    <div className="panel-list">
                      <ul>
                        <li>Test IO </li>
                        <li>Espresso </li>
                        <li>XCUI Test</li>
                        <li>Appium</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Stacks </h3>
                    <div className="panel-list">
                      <ul>
                        <li>MEAN Stack </li>
                        <li>MERN Stack /li&gt;
                        </li><li>Full Stack </li>
                        <li>ADT</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Solutions</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                    </div>
                  </div>
                </div>
              </div>
             <Service />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Development Process </span>
              <div className="mobiles_head">
                <h4>Food Ordering App Development Process</h4>
                <p>AS a leading <strong>food ordering app development company,</strong> Uptimise IT follows a well-planned out agile development process. This helps us ensure the quality of our solution.</p>
              </div>
            </div>
            <div className="row">
             <Process />
            </div>
          </div>
        </section>
        <section className="same-section bg-color company-box-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
              </div>
            </div>
            <div className="row">
              <Values />
            </div>
          </div>
        </section>
        {/* <section className="same-section portfoli-head-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Portfolio</span>
              <div className="mobiles_head">
                <h4>Our Success Stories</h4>
                <p>Uptimise IT has worked with many amazing clients and delivered successful mobile applications. Check out some of our success stories.</p>
              </div>
            </div>
            <div className="row portfoli-head">
              <div className="col-lg-6">
                <div className="portfolio-parent">
                  <div className="portfolio-inner-wrapper">
                    <img src="asset/images/dafribank-main-page-image.png" alt="uptimise it" />
                    <div className="project-content">
                      <h3>DafriBank <span>Digital Bank Of Africa</span></h3>
                    </div>
                    <div className="portfolio-content-parent">
                      <div className="country-list">
                        <ul>
                          <li>South Africa</li>
                          <li>Nigeria</li>
                        </ul>
                      </div>
                      <h3>DafriBank <span>Digital Bank Of Africa</span></h3>
                      <Link to="/dafri-bank-case-study" className="btn btn-default">Case Study</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="portfolio-parent">
                  <div className="portfolio-inner-wrapper paybycheck-color">
                    <img src="asset/images/pay-by-check-portfoilio-img-01.png" />
                    <div className="project-content">
                      <h3>Pay By Check <span>Multi-Currency E-wallet Mobile App</span></h3>
                    </div>
                    <div className="portfolio-content-parent">
                      <div className="country-list">
                        <ul>
                          <li>USA</li>
                          <li>Canada</li>
                        </ul>
                      </div>
                      <h3>Pay By Check <span>Multi-Currency E-wallet Mobile App</span></h3>
                      <Link to="/pay-by-check-case-study" className="btn btn-default">Case Study</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-parent">
                  <div className="portfolio-inner-wrapper">
                    <img src="asset/images/portfolio-main-page-medu4-img.png" alt="uptimise it" />
                    <div className="project-content medu-home-page">
                      <h3>medu4 <span>Medu4 Medical Educational Platform</span></h3>
                      <p>A leading online medical educational platform in Japanese market, Medu4 is a perfect example of our educational solutions.</p>
                      <Link to="#" className="btn btn-default">Case Study</Link>
                    </div>
                    <div className="portfolio-content-parent">
                      <div className="country-list">
                        <ul>
                          <li>Japan</li>
                          <li>Malaysia</li>
                        </ul>
                      </div>
                      <h3>medu4 <span>Medu4 Medical Educational Platform</span></h3>
                      <Link to="/dafri-bank-case-study" className="btn btn-default">Case Study</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-bottom-btn text-center">
              <Link to="/ourwork" className="btn btn-primaryx">View Portfolio</Link>
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Innovative Mobile App/Web Development Services</h3>
              <p>Uptimise IT is a leading mobile app and web Development Company that offers amazing services across different industries.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section testimonial-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Testimonial</span>
              <div className="mobiles_head">
                <h4>Customers? Satisfied</h4>
                <p>Uptimise IT is all about delivering results that satisfy our clients and deliver success. Here are some words from our happy clients.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="testimonal-parent">
                  <Link to="#"><h3>Help us improve our productivity</h3></Link>
                  <div className="testimonal_child">
                    <div className="testimonal_img">
                      <img src="asset/images/african-american-business-woman-working-computer-bar.png" />
                    </div>
                    <div className="testimonal-icon">
                    </div>
                  </div>
                  <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                    invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="testimonal-parent">
                  <Link to="#"><h3>Help us improve our productivity</h3></Link>
                  <div className="testimonal_child">
                    <div className="testimonal_img">
                      <img src="asset/images/african-american-business-woman-working-computer-bar.png" />
                    </div>
                  </div>
                  <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                    invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color blank-wrapper-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Uptimise IT’s Reviews</span>
              <div className="mobiles_head">
                <h4>Deemed The Best App Developers</h4>
                <p>Uptimise IT has been deemed the top mobile app development company by well-known critics and tech reviewers.</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon01.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon02.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon03.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon04.png" alt="uptimise it" />
                </div>
              </div>
              <div className="col">
                <div className="blank-box">
                  <img src="asset/images/blank-box-icon04.png" alt="uptimise it" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some of the most frequently asked questions about food delivery app Development Company.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          How Much it Costs To Develop A Food Delivery App?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The cost to develop a food delivery app can be anywhere between $ 10,000 and $ 100,000 based on the specification of your project. If you need a more accurate estimation, it is suggested you contact us and we will give a quote based on your project needs.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How long it takes to develop a food delivery app?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The time taken to develop a food delivery mobile app can depend on various different factors. Some of these are, as mentioned below:
                          <ul>
                            <li>The complexity of the app </li>
                            <li>Budget </li>
                            <li>Feature</li>
                            <li>Design </li>
                            <li>Type of app</li>
                            <li>Tech stack </li>
                          </ul>
                          Nevertheless, you can expect the development time to be anywhere from 2 months to 12 months. 
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Do you provide after-sale services?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT as a leading food delivery app development company also provides mobile app maintenance and support services to our clients. For more details, you can contact us. </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          How can I monetize my Food Ordering app?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          There are several monetization strategies that allow you generate revenue through the app. Some of these are, as mentioned below: 
                          <ul>
                            <li>Advertisement </li>
                            <li>Delivery commission </li>
                            <li>Food order commission </li>
                            <li>Promotional services </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Can you develop food delivery apps like Postmates and Zomato?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Uptimise IT have developers with years’ worth of experience and much valued know-how needed to develop and deploy app like postmates and Zomato. If you want to avail our food delivery app development services, you can contact us and we will assign a team of dedicated developers to your project within 42 hours.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>

   
    )
}