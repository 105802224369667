import React from 'react'
import HealthData from './HealthData'
import HealthWork from './HealthWork'

const Health = () => {
  return (
    <>
       {HealthData.map((val,ind) => {
        return (
          <HealthWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )} 
    </>
  )
}

export default Health
