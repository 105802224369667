import React from 'react'
import FrstCardData from './FrstCardData'
import FrstCardWork from './FrstCardWork'

const FrstCard = () => {
  return (
    <>
       {FrstCardData.map((val,ind) => {
        return (
          <FrstCardWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default FrstCard;
