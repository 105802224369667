import React from 'react'
import LoanSecureData from './LoanSecureData'
import LoanSecureWork from './LoanSecureWork'

const LoanSecure = () => {
  return (
    <>
       {LoanSecureData.map((val,ind) => {
        return (
          <LoanSecureWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default LoanSecure;
