import pro1 from '../../../images/android-app-development-icon01.png'
import pro2 from '../../../images/android-app-development-icon02.png'
import pro3 from '../../../images/android-app-development-icon03.png'
import pro4 from '../../../images/android-app-development-icon04.png'
import pro5 from '../../../images/android-app-development-icon05.png'
import pro6 from '../../../images/android-app-development-icon06.png'
import pro7 from '../../../images/android-app-development-icon07.png'
import pro8 from '../../../images/android-app-development-icon08.png'
import pro9 from '../../../images/android-app-development-icon09.png'

const DevelopmentData = [
    {
        title:"Custom Android App Development",
        text: "Providing custom android apps with seamless features to meet all your business needs and requirements. We follow the latest SDKs and integrate advanced features to build a highly-scalable app for your business.",
       imgsrc: pro1,  
       
    },
    {
        title:"Android Web-Apps",
        text: "Want to increase the efficiency of the daily operational activities of your business? Share the requirements of your business with us and get the best Android webapp with the latest features for your business.",
       imgsrc: pro2,  
     
     },
     {
        title:"Porting Android App",
        text: "Thinking about porting your pre-built app from one platform to another, like Android to iOS or iOS to Android? Get in touch with us now and get free consultation for porting your app.",
       imgsrc: pro3,  
     
     },
     {
        title:"Android Wearable Development",
        text: "As a leading android development company, Uptimise IT offers services for Android wearable app development. Today, wearable solutions are an important part of the technology ecosystem.",
       imgsrc: pro4,  
      
     },
     {
        title:"Android TV App Development",
        text: "Android is one of the most versatile platforms which also offers Android TV OS. However, Uptimise IT is as versatile as Android, as we also offer services for Android TV app development.",
       imgsrc: pro5,  
    
     },
     {
        title:"Android Game Development Services",
        text: "Now, you can hire mobile app developers from Uptimise IT to develop android mobile apps. You can develop your concept into an android game and add to millions of games on Play Store.",
       imgsrc: pro6,
     
     },
     {
        title:"Android App Testing and QA",
        text: "Testing and QA are as important to the success of the android mobile app as the development process itself. And our team of android app developers is experienced in providing the best QA and testing services.",
       imgsrc: pro7,  
       
     },
     {
        title:"Enterprise App Solutions",
        text: "Our team of experienced developers also offers enterprise mobile applications based on the android platform. These solutions can help you streamline your business process and generate skyrocketing revenue.",
       imgsrc: pro8,  
     
     },
     {
        title:"Android Mobile App Maintenance and Support",
        text: "Uptimise IT offers mobile app maintenance &amp; Support to ensure the app’s good performance.",
       imgsrc: pro9,  
       
     },
     
     
    
]

export default DevelopmentData ;