import React from 'react'
import AdData from './AdData'
import AdWork from './AdWork'

const Ad = () => {
  return (
    <>
       {AdData.map((val,ind) => {
        return (
          <AdWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default Ad;
