import pro1 from '../../../images/app-maintenance-support11.png'
import pro2 from '../../../images/mobile-app-upgradation14.png'
import pro3 from '../../../images/software-app-migration16.png'
import pro4 from '../../../images/post-deployment-support15.png'
import pro5 from '../../../images/bug-inspection12.png'
import pro6 from '../../../images/system-audit-maintenance17.png'
import pro7 from '../../../images/code-revamping13.png'
import pro8 from '../../../images/web-app-maintenance18.png'


const ItSupportData = [
    {
        title:"App Maintenance & Support",
        text: <p>Uptimise IT uses amazing strategies and technologies to deliver amazing <strong>application development &amp; maintenance services.</strong> This includes iOS app, android apps, web apps, and so on. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Mobile App Upgradation",
        text:  <p>Ever so often, there is a new trend in the market, and you don’t want your app to become a legacy system. You can keep your app “fresh” with our mobile app up-gradation services. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Software/App Migration",
        text: <p>Software and mobile application migration services help you move the concerned program to a newer version itself. This helps it stay relevant to the market, thus continue being successful. </p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Post Deployment Support",
        text: <p>Uptimise IT also provides post-deployment support services for websites, software, and mobile apps. It covers, backup, API integration, Routine maintenance, and so on. </p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Bug Inspection ",
        text: <p>Bugs can really impact the performance of an app. Our bug inspection service provides you with full bug tracking and reporting along with amazing real-time results. </p>,
       imgsrc: pro5,  
    
     },
     {
        title:"System Audit & Maintenance",
        text: <p>With our experienced team and technical know-how, Uptimise IT provides amazing system audit &amp; maintenance. This is one of our best services.</p>,
       imgsrc: pro6,
     
     },
     {
        title:"Code Revamping",
        text: <p>Are you experiencing issues with the existing code? Well, no worries, our team of the experienced developer will write a better, faster, and optimized code for your software, web, or app.</p>,
       imgsrc: pro7,  
       
     },
     {
        title:"Web App Maintenance",
        text: <p>Our <strong>website maintenance and support services</strong> help you with PWA(progressive web app) maintenance and support. This includes migration, database maintenance, improving service, and so on.</p>,
       imgsrc: pro8,  
     
     },
    
     
     
    
]

export default ItSupportData ;