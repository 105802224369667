import pro from '../../../images/dealer-management-solutions-auto.png'
import pro1 from '../../../images/autonomous-car-software-development-auto.png'
import pro2 from '../../../images/custom-automotive-development-auto.png'
import pro3 from '../../../images/automotive-cross-platform-app-development-auto.png'
import pro4 from '../../../images/automotive-migration-upgradation-auto.png'
import pro5 from '../../../images/fleet-management-solutions-auto.png'
import pro6 from '../../../images/web-mobile-spp-maintenance-support-auto.png'
import pro7 from '../../../images/vehicle-nonitoring-software-auto.png'


const AutomationData = [
    {
        title:"Dealer Management Solutions",
        text:  <p>We enable dealerships and dealers alike effectively manage their various operations effectively using our dealer management solution available at an affordable price.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"Autonomous Car Software Development",
        text:  <p>Uptimise IT as a leading automotive software development agency provides amazing <strong>automotive car software development</strong> solutions.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Custom Automotive Development",
        text: <p>If you have a unique idea that you want to develop into an idea, our <strong>mobile app developer</strong> offers custom automotive development services.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Automotive Cross-Platform App Development",
        text: <p>If you can’t choose between <strong>iOS &amp;Android App Development services,</strong> we also provide stellar automotive <strong>cross-platform app development services.</strong></p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Automotive Migration & Upgradation",
        text: <p>Now, you can move from your legacy system to a better version or update the existing one with help of our software migration and up-gradation services.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:" Fleet Management Solutions",
        text: <p>Manage your large fleet tracking and managing them effectively through the <strong>fleet management software solution.</strong> Uptimise IT is here to help you.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Web/ Mobile App Maintenance & Support",
        text: <p>In addition to development services, Uptimise IT also provides web and mobile app maintenance &amp; support services that help you remain consistent.</p>,
       imgsrc: pro6,
     
     },
     {
        title:"Vehicle Monitoring Software",
        text: <p>With our <strong>Car Software Development</strong> solution, you can effectively monitor your vehicle with all the vital information available at your fingertips.</p>,
       imgsrc: pro7,  
       
     },
  
   
     
     
    
]

export default AutomationData ;