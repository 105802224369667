import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Success from "./success/Success"
import Industry from "./industry/Industry"
import pic from "../images/ios-developers-banner-img.webp"
import pic1 from "../images/ios-developers-small-img.webp"
import DevHead from "./development/andHeader/DevHead"
import IphoneBig from './hiredevelopers/iphone/iphonebig/IphoneBig'

import pro from "../images/clock-icon01.svg"
import pro1 from "../images/clock-icon02.svg"
import pro2 from "../images/clock-icon03.svg"
import pro3 from "../images/iphone-app-developer.png"

export default function HireIphone() {
    return (
        <div id="content">
        {/* page-heading */} 
        <section className="developers-banner-section">
          <div className="container"> 
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1>Hire <strong>iPhone App Developers</strong></h1>
                  <p>Hire iPhone app developers who are dedicated and hold years’ worth of experience in the field. Our team of developers can help you turn your idea into a running app and revenue-generating business. </p>
                  <Link to="#" className="btn btn-primaryx">Discuss Your Project</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="developers-banner-img">
                  <img src={pic} alt="uptimise it" />
                  <div className="developer-box">
                    <ul>
                      <li>Web Developer</li>
                      <li className="change-bg">PHP Developer</li>
                      <li className="change-bg">iOS Developer</li>
                      <li>Android Development</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
             <DevHead />
            </div>
          </div>
        </section>
        <section className="same-section">
          <div className="container">
            <div className="row align-items-center demand-wrapper">
              <div className="col-lg-6">
                <div className="demand-img">
                  <img src={pic1} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="demaond-content same-heading">
                  <span>Hire iOS Developer</span>
                  <h3>Dedicated iPhone App Developers</h3>
                  <p>Uptimise IT is a well-renowned iOS app development company in USA and UK. With an aesthetically beautiful interface and high-end features, we help you take your business to next level while generating off-charts revenue.Now, you can hire dedicated iPhone app developers for your business and convert your concepts into running mobile apps. Our solutions will fulfill your business goal while strengthening the market position.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Hire Developer</span>
              <div className="mobiles_head">
                <h4>Why Hire iPhone App Developers From Uptimise IT?</h4>
                <p> Why hire iPhone application developers from Uptimise IT? You should hire mobile app developers with us because we are one of the best iOS app development company. Our services are backed with years of experience and technical capacity. Some more reasons to hire us are, as mentioned below:</p>
              </div>
            </div>
            <div className="row app-developers-parent">
             <div></div>
            </div>
          </div>
        </section>
        <section className="same-section technology-section">
          <div className="container">
            <div className="same-heading text-center">
              <span>Dedicated Developer</span>
              <div className="mobiles_head">
                <h4>Impact Development Services from iPhone Experts</h4>
                <p>Our team of iPhone app developers for hire has years’ worth of relevant experience in the field. This is backed by the top-end resources and development tools used by Uptimise IT to create valuablebusiness solutions.</p>
              </div>
            </div>
            <div className="row">
            <IphoneBig />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Hire iOS App Developers</h3>
              <p>Let’s convert your insane business idea into an interactive, engaging iPhone app.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
        <section className="same-section watch-box">
          <div className="container">
            <div className="same-heading text-center mb-5">
              <div className="mobiles_head">
                <h4 className="mb-0">iOS Developers Hiring Options</h4>
                <p className="text-center">Different clients have different development needs. Uptimise IT makes sure all of these are fulfilled with our various options when it comes to hiring iOS Developers.</p>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro} alt="uptimise it" />
                      </figure>
                      <h3>Full Time</h3>
                      <p>Now, you can hire iOS developers full-time with Uptimise IT who will be working for you and reporting to you.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent change-bg-img">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro1} alt="uptimise it" />
                      </figure>
                      <h3>Part Time</h3>
                      <p>Clients can also hire iOS app developers part-time for their iOS app development work.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="model-box-parent">
                    <div className="model-box-inner">
                      <figure>
                        <img src={pro2} alt="uptimise it" />
                      </figure>
                      <h3>Hourly</h3>
                      <p>Hiring Developers on hourly bases is a popular option among clients, thus, Uptimise IT offer the option too. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section work-inner-parent bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Why Uptimise IT?</span>
              <div className="mobiles_head">
                <h4>Missing Piece To Your Success Formula</h4>
                <p>Choose Uptimise IT because you deserve nothing but the best. As the leading mobile app development agency, we have years of experience and a proven track record to back it.</p>
              </div>
            </div>
            <div className="row">
            <Success />
            </div>
          </div>
        </section>
        <section className="same-section slider-wrapper">
          <div className="container">
            <div className="same-heading text-center">
              <span>Industries</span>
              <div className="mobiles_head">
                <h4>Innovative Mobile App Development Services Across Industries</h4>
                <p>Our mobile app development services are spread across industries. While we are a well-renowned FinTech application development company, we also deliver our amazing services in other industries. Let’s have a look:</p>
              </div>
            </div>
            <div className="row">
             <Industry />
              <div className="view-btn">
                <Link to="/industry" className="btn btn-primaryx">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section i-phone-banner p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="iphobe-app-img">
                  <img src={pro3} alt="uptimise it" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="developers-content">
                  <h3>Benefits of Hire iPhone App Developers</h3>
                  <p>Uptimise IT has one of the best teams of iOS developers. So, when you are working with our development team, there are many benefits that you avail yourself. These are, as mentioned below:</p>
                  <ul>
                    <li>Fluid UI/UX </li>
                    <li>Data-Driven Development </li>
                    <li>Agile Development Process</li>
                    <li>Experienced Developers </li>
                    <li>Transparent Process </li>
                    <li>Secure app development </li>
                    <li>Innovative iOS apps </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        <section className="same-section we-serve p-0">
          <img src="asset/images/serve-bg.png" alt="images" />
          <div className="serve-content-parent">
            <h3>Looking to HireiOS App Developers?</h3>
            <p>Hire iOS app developers in USA and UK with Uptimise IT.</p>
            <Link to="#" className="btn btn-primaryx">Let’s Talk</Link>
          </div>
        </section>
       <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>Here are answers to some of the most frequently asked questions about hiring iphone app developers:</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          What steps do I follow when I need to hire iPhone developers?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">If you want to hire iOS developers, all you need to do is contact the Uptimise IT a leading iOS development company and we will take care of the rest. A team of dedicated developers is assigned to your project within 42 hours.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          Will the developers submit the app to the Apple App Store?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Our experienced team of developers will help you with everything including the deployment of the iOS app into the app store.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Will you provide documentation for my iOS app development? Do I own the code?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">At the end of the development process, the ownership of the code of the iOS app is transferred to the client and no copies of the same are kept with us. So, the answer is yes, you get the complete documentation along with the ownership of the code.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          How long does it take for you to develop an iPhone app?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          The iPhone app development time depends on various different factors. Some of which are, as mentioned below: 
                          <ul>
                            <li>Complexity</li>
                            <li>Feature</li>
                            <li>Design</li>
                            <li>Type of app</li>
                            <li>Size of app</li>
                            <li>Tech stack</li>
                          </ul>
                          These are the factors that affect the cost. Nevertheless, you can expect anything from 2 months to 12 months of development time.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>

   
    )
}