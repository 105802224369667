import React from 'react'
import SuppotWork from './SuppotWork'
import SuccessData from '../../success/SuccessData'
import SupportData from './SupportData'

const Supprot = () => {
  return (
    <>
      {SupportData.map((val,ind) => {
              return (
                <SuppotWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                />
              )
             } )} 
    </>
  )
}

export default Supprot
