import React from 'react'
import MagentoBigCardData from './MagentoBigCardData'
import MagentoBigCardWork from './MagentoBigCardWork'

const MagentoBigCard = () => {
  return (
    <>
    {MagentoBigCardData.map((val,ind) => {
              return (
                <MagentoBigCardWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )}  
  </>
  )
}

export default MagentoBigCard
