import React from 'react'
import MultiData from './MultiData'
import MultiWork from './MultiWork'

const Multi = () => {
  return (
    <>
       {MultiData.map((val,ind) => {
        return (
          <MultiWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default Multi;
