import pro from '../../images/order-management-app-food.png'
import pro1 from '../../images/on-demand-food-delivery-app-development-food.png'
import pro2 from '../../images/food-app-migration-solutions-food.png'
import pro3 from '../../images/mobile-app-maintenance-support-food.png'
import pro4 from '../../images/restaurants-app-development-food.png'
import pro5 from '../../images/custom-food-delivery-app-like-uber-eats-food.png'
import pro6 from '../../images/web-mobile-spp-maintenance-support-auto.png'
import pro7 from '../../images/food-ordering-app-development-food.png'


const DeliveryData = [
    {
        title:"Order Management App",
        text:   <p>During the rush hours, handling all the orders without any errors is a bit of a task. Now, you can streamline all of it with an order management app from Uptimise IT.</p>,
       imgsrc: pro,  
       
    },
    {
        title:"On Demand Food Delivery App Development",
        text:  <p>Order food and get it to deliver to your doorstep within no time with on-demand food delivery app. Our on demand food delivery app development is open to you.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Food App Migration Solutions",
        text: <p>If you are suffering legacy food app, we are here to help you. Uptimise IT offers amazing food app migration solutions that help you move to a better version. </p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Mobile App Maintenance & Support",
        text: <p>In addition to a range of food delivery app development services, we also provide unmatched mobile app maintenance and support services to our clients. </p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Restaurants App Development ",
        text: <p>If you are a restaurant owner who is looking for mobile app developers to create an app for you, as a restaurants app development company we can help you.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Custom Food Delivery App Like Uber Eats",
        text: <p>With unmatched development prowess, Uptimise IT as a Food Delivery app development company offers custom food delivery app services.</p>,
       imgsrc: pro5,  
    
     },
     {
        title:"Reservation App Solutions",
        text: <p>People are often worried about finding their favorite place full. With the table reservation mobile app, users can reserve their spot in the restaurant through the app.</p>,
       imgsrc: pro6,
     
     },
     {
        title:"Food Ordering App Development",
        text:  <p>Uptimise IT can help you develop a food ordering mobile app like uber of your own with our <strong>food ordering app development</strong> data-driven solutions.</p>,
       imgsrc: pro7,  
       
     },
  
   
     
     
    
]

export default DeliveryData ;