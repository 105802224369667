
import pro1 from '../../../../images/aI-personalization.png'
import pro2 from '../../../../images/data-analytics.png'
import pro3 from "../../../../images/investment-advice01.png"
import pro4 from '../../../../images/cross-platform-functionality.png'
import pro5 from '../../../../images/easy-compatibility.png'
import pro6 from '../../../../images/robo-advisor.png'


import { Link } from 'react-router-dom'


const AdData = [
    {
        title:"AI Personalization",
        text: <p>Integration of <strong><Link to="artificial-intelligence-in-digital-payments/index">Machine Learning and Artificial Intelligence</Link></strong> enables the FinTech apps to provide intelligent and smart suggestions and answers to user’s queries.</p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Data Analytics",
        text: <p>The data analytics monitor and manage all the financial data of the user so that users can easily access all the information related to their financial activities.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Investment Advice",
        text: <p>By push notifications, you can easily suggest investment ideas to your users regarding how they can schedule weekly, monthly, or quarterly investments.</p>,
        imgsrc: pro3,
     
     },
     {
        title:"Cross-Platform Functionality",
        text:  <p>Since consumer demands are becoming more diversified, cross-platform functionality increases the usability of the app on various devices.</p>,
       imgsrc: pro4,  
      
     },  
     {
        title:"Easy Compatibility",
        text: <p>FinTech spreads across a wide range of industries around the world, it must be able to easily collaborate with other FinTech applications.</p>,
       imgsrc: pro5,  
      
     }, 
     {
        title:"Robo-Advisor",
        text:  <p>The integration of the Robo-advisor reduces human efforts by providing financial advice and is also useful to start-ups for data and service management.</p>,
       imgsrc: pro6,  
      
     }, 
     
   
]

export default AdData ;