import pro from '../../images/affordable-cost-industries-ecommerce.png'
import pro1 from '../../images/innovation-driven-industries-ecommerce.png'
import pro2 from '../../images/fast-development-speed-industries-ecommerce.png'
import pro3 from '../../images/agile-development-process-industries-ecommerce.png'
import pro4 from '../../images/experienced-team-industries-ecommerce.png'
import pro5 from '../../images/scalability-industries-ecommerce.png'



const EDevelopData = [
    {
        title:"Affordable Cost",
        text:  <p>Uptimise IT provides eCommerce development services at affordable rates. </p>,
       imgsrc: pro,  
       
    },
    {
        title:"Innovation Driven",
        text:   <p>Our customer-centric services are innovation drive, delivering future-proof solutions. </p>,
       imgsrc: pro1,  
       
    },
    {
        title:"Fast Development Speed",
        text:   <p>With our resources and development experience, we develop quick and robust solutions.</p>,
       imgsrc: pro2,  
     
     },
     {
        title:"Agile Development Process",
        text: <p>Our team follows an agile development process allowing us to deliver timely solutions.</p>,
       imgsrc: pro3,  
     
     },
     {
        title:"Experienced Team",
        text:  <p>We have a team of an experienced team of developers who know what success looks like.</p>,
       imgsrc: pro4,  
      
     },
     {
        title:"Scalability",
        text: <p>Uptimise IT as a leading eCommerce development company delivers scalable solutions.</p>,
       imgsrc: pro5,  
    
     },

]

export default EDevelopData ;