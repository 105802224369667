import React from 'react'
import ChooseData from './ChooseData'
import ChooseWork from './ChooseWork'

const Choose = () => {
  return (
    <>
       {ChooseData.map((val,ind) => {
        return (
          <ChooseWork
          key={ind}
          title={val.title}
          imgsrc={val.imgsrc}
          text={val.text}
          />
        )
       } )}  
    </>
  )
}

export default Choose;
