import pro1 from '../../../../images/custom-android-app-a-developers.webp'
import pro2 from '../../../../images/android-wearable-application.webp'
import pro3 from '../../../../images/android-app-testing.webp'
import pro4 from '../../../../images/android-app-update-and-migration-a-developers.webp'
import pro5 from '../../../../images/android-games-development-a-developers.webp'
import pro6 from '../../../../images/android-mobile-app-maintenance-and-support-a-developers.webp'


const AndroidBigCardData = [
    {
        title:"Custom Android App Development ",
        text: "Looking for custom android app development services? Hire our android app experts and create the next best android app on the market.",
       imgsrc: pro1,  
       
    },
    {
        title:"Android Wearable Application",
        text: "Hire android app developers from Uptimise IT and develop the best wearable mobile applications for your business.",
       imgsrc: pro2,  
     
     },
     {
        title:"Android App Testing",
        text: "Our team of android app experts can help you with android app testing and QA. Uptimise IT offers the best Android App developers in USA & UK.",
       imgsrc: pro3,  
     
     },
     {
        title:"Android App Update & Migration",
        text: "Hire our android application developers who will help you with updating and migrating your android mobile app.",
       imgsrc: pro4,  
      
     },
     {
        title:"Android Games Development",
        text: "If you are looking to develop an android game, our team of dedicated android application developers can help you develop the next best android game.",
       imgsrc: pro5,  
    
     },
     {
        title:"Android mobile app maintenance & Support ",
        text: "Our team of android app experts provides excellent mobile app maintenance and support services that you can take advantage of.",
       imgsrc: pro6,
     
     },
    
     
     
    
]

export default AndroidBigCardData ;