import pro1 from '../../../images/track-your-shipments-logistic.png'
import pro2 from '../../../images/enhance-security-logistic.png'
import pro3 from '../../../images/gain-control-over-the-assets-logistic.png'
import pro4 from '../../../images/scalable-and-robust-architecture-logistic.png'
import pro5 from '../../../images/cost-effective-solutions-logistic.png'
import pro6 from '../../../images/get-future-ready-logistic.png'
import { Link } from 'react-router-dom'


const BeneData = [
    {
        title:"Track Your Shipments",
        text: "We can enable your transportation app to include shipment tracking software that lets you and your customers easily identify the delivery status of goods. Your customers will be able to receive the order details and current location of the goods by inputting the tracking number into the logistic delivery application.",
       imgsrc: pro1,  
       
    },
    {
        title:"Enhance Security",
        text: "GPS tracking and monitoring software empowers you to protect your vehicle and goods' security. This is an especially useful feature for those companies transporting valuable goods. If an unexpected event occurs, the real-time vehicle location tracking functionality will help you identify the whereabouts of the vehicle.",
       imgsrc: pro2,  
       
    },
    {
        title:"Gain Control Over the Assets",
        text: "Whilst shipments are on the go, it is crucial for a transporter to know the exact status of the goods carriers. Custom-built GPS tracking software will give you command over your assets from production, all the way to delivery to your customers. This increased control over your supply management will provide you with a competitor advantage.",
       imgsrc: pro3,  
       
    },
    {
        title:"Scalable and Robust Architecture",
        text:  <p>The <strong><Link to="https://uptimiseit.com
        what-is-the-cost-for-custom-logistics-software-development/">custom transportation and logistics management applications</Link></strong> that we create are built on a highly resilient and scalable architecture. This enables us to develop applications, on which your transportation and logistics business can reliably depend. We are aware that the system may have additional features implemented as your business grows. Therefore we will build your logistics app with future further development in mind.</p>,
       imgsrc: pro4,  
       
    },
    {
        title:"Cost-Effective Solutions",
        text: "Rather than sell software, we are a logistics app development company that instead crafts business solutions, which solve the specific pain points that your business faces. Our focus is to identify and then develop the transportation and logistics management software that will drive a faster ROI for your company.",
       imgsrc: pro5,  
       
    },
    {
        title:"Get Future Ready",
        text: "We are in Industry 4.0 or the fourth industrial revolution. And this time around, it’s all about mobile apps, AI, and robotics. So, with help of logistics and transportation management application development, you can be future-ready.",
       imgsrc: pro6,  
       
    },
   
    
       
]

export default BeneData ;