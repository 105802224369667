import React from 'react'
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    const location = useLocation();
  
    React.useEffect(() => {
      navigate('/');
    }, [navigate, location]);
  
    return null; // Optional: You can render a custom error message or design here.
  };

export default NotFound