import pro1 from '../../../images/check-icon.svg'
import pro2 from '../../../images/check-icon.svg'
import pro3 from '../../../images/check-icon.svg'
import pro4 from '../../../images/check-icon.svg'
import pro5 from '../../../images/check-icon.svg'
import pro6 from '../../../images/check-icon.svg'
import pro7 from '../../../images/check-icon.svg'
import pro8 from '../../../images/check-icon.svg'

const IosData = [
    {
       imgsrc: pro1, 
       
       text: "Membership Job Portal App",
    
    },
    {
        imgsrc: pro2, 
     
        text: " Featured Listing Recruitment Portal",
       
     },
     {
        imgsrc: pro3, 
     
        text: "The Job Search app for Users",
      
     },
     {
        imgsrc: pro4, 
      
        text: "Job Search Home panel",
   
     },
     {
         imgsrc: pro5, 
    
         text: "Job Search Login",
     
      },
      {
         imgsrc: pro6, 
       
         text: "Job Portal iOS",
   
      },
      {
        imgsrc: pro7, 
      
        text: "Job portal Andriod",
  
     },
     {
        imgsrc: pro8, 
      
        text: "Cross-platform Job Portal",
  
     }
]

export default IosData;