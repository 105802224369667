import React from 'react'
import DevelopmentData from './DevelopmentData'
import DevelopmentWork from './DevelopmentWork'

const Development = () => {
  return (
    <>
    {DevelopmentData.map((val,ind) => {
              return (
                <DevelopmentWork
                key={ind}
                title={val.title}
                imgsrc={val.imgsrc}
                text={val.text}
                />
              )
             } )}  
  </>
  )
}

export default Development
