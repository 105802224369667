import React from 'react'
import CoreData from './CoreData'
import CoreWork from './CoreWork'

const Core = () => {
  return (
    <>
    {CoreData.map((val,ind) => {
     return (
       <CoreWork 
       key={ind}
       imgsrc={val.imgsrc}
       text={val.text}
       />
     )
    } )}    
         
       </>
  )
}

export default Core
