import React from 'react'

const SuppotWork = (props) => {
  return (
    <div className="col-lg-3">
                <div className="steller-box-parent">
                  <figure>
                    <img src={props.imgsrc} alt="uptimise it" />
                  </figure>
                  <div className="steller-content">
                    <h3>{props.title}</h3>
                  </div>
                </div>
              </div>
  )
}

export default SuppotWork
