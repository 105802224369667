
import pro1 from '../../../images/seamless-customer-onboarding-loan.png'
import pro2 from '../../../images/secure-authentication-loan.png'
import pro3 from '../../../images/credit-score-loan.png'
import pro4 from '../../../images/loan-repayment-loan.png'
import pro5 from '../../../images/chatbots-loan.png'
import pro6 from '../../../images/automating-or-scheduling-payments-loan.png'

const LoanAdvanceData = [
    {
        title:"Seamless Customer Onboarding",
        text: "Due to a lot of financial information requirements, customers often find it hard to associate with a new lending institution, especially when the process is online. We build applications that allow users to get onboard quickly.",
       imgsrc: pro1,  
       
    },
    {
        title:"Secure Authentication",
        text: "Because of the strict industry norms, AML and KYC requirements have become very necessary. Although user authentication is crucial, it can result in users abandoning your app. We create promising user experiences that help avoid such issues.",
       imgsrc: pro2,  
     
     },
     {
        title:"Credit Score",
        text: "Considering the increasing customer expectations, it is essential for your loan lending app to integrate credit score data using open-banking APIs.",
       imgsrc: pro3,  
     
     },
     {
        title:"Loan Repayment",
        text: "When the lending is performed using your app, it can be repaid too. The users should be able to pay via several payment methods such as UPI and e-wallets.",
       imgsrc: pro4,  
      
     },  
     {
        title:"Chatbots",
        text: "AI-powered chatbots allow users to reach out to you for any query or grievances. It makes your customer service effective and cost-efficient. Also, you’ll be available to your customers 24/7 when you have chatbots in place.",
       imgsrc: pro5,  
      
     }, 
     {
        title:"Automating or Scheduling Payments",
        text: "You can go one step forward with loan repayments by enabling users to enrollin autopay. It would require users to add their favorite payment method to the app. Hence, your app should have the functionality.",
       imgsrc: pro6,  
      
     }, 
]

export default LoanAdvanceData ;