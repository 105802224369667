import React from 'react'
import {Link} from 'react-router-dom'
import HomeIn from '../components/HomeIn'
import Service from './service/Service'
import Values from './values/Values'
import Process from "./process/Process"

export default function OnDemand() {
    return (
        <div id="content">
        <section className="industries-banner-section banner-section">
          <img src="asset/images/mobile-banking-banner-img.png" alt="uptimise it" />
          <div className="container">
            <div className="banner-content">
              <h1><strong>Mobile Banking App Development</strong></h1>
              <p>Helping banking institutions achieve digital transformation without compromising security. We’re among the best <strong>banking app development company</strong> in the USA.</p>
              <div className="industries-btn">
                <Link to="contact" className="btn btn-primaryx">Talk to Consultant</Link>
                <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="logo-section">
          <div className="container-fluid">
            <div className="logo-parent">
              <ul>
                <li> <Link to="javascript:void(0);"><img src="asset/images/curvaceous-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="javascript:void(0);"><img src="asset/images/medu-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="javascript:void(0);"><img src="asset/images/student-castle-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="javascript:void(0);"><img src="asset/images/drt-logo-gray.png" alt="uptimise it" /> </Link> </li>
                <li> <Link to="javascript:void(0);"><img src="asset/images/dafri-logo-gray.png" alt="uptimise it" /> </Link> </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="same-section web-development-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Mobile Banking App</span>
              <div className="mobiles_head">
                <h4>We implement disruptive mobile technologies to enhance CX</h4>
                <p>Deloitte believes that in 2022, North American banks will spend half of their entire IT budget on emerging technologies, whereas European banks are said to spend one-third. The predicted share is much higher than the current at 27%, meaning that digitalbanking is the future.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="development-box">
                  <figure>
                    <img src="asset/images/account-management-banking.png" alt="uptimise it" />
                  </figure>
                  <h3>Account Management</h3>
                  <p>Your banking apps would provide customers with ease of their account management. The customers can manage account information, and cards, and check account balances and transactions via the app only.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="development-box">
                  <figure>
                    <img src="asset/images/transaction-swiftness-banking.png" alt="uptimise it" />
                  </figure>
                  <h3>Transaction Swiftness</h3>
                  <p>Modern customers want their banking mobile apps to allow them to make payments and transfer money. Payments via QR codes, NFC, and UPI have become mainstream in digital payments. However, it is crucial for your app to instantly transfer funds.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="development-box">
                  <figure>
                    <img src="asset/images/24-7-customer-service-banking.png" alt="uptimise it" />
                  </figure>
                  <h3>24/7 Customer Service</h3>
                  <p>Conversation technology coupled with AI &amp; ML allows banks to offer 24/7 customer service that doesn't require continuous human efforts. The advent of banking chatbots has made customer service cost-efficient for banks.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section opportunities-section bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Banking app Development</span>
              <div className="mobiles_head">
                <h4>We Help You Achieve Digital Transformation</h4>
                <p>We’re a mobile banking application development company that is specialized in developing secure banking portals facing the endcustomers. Our exceptionally skilled team has delivered several banking applications that are helping banks offer innovative services at lower costs. We concentrate on building software solutions that provide value to the end-user.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="chalange-box">
                  <h3>Channel Agnostic CX</h3>
                  <p>A transformation commenced on a desktop can be carried to mobile devices, and vice-versa. We help you build mobile banking strategies that augment core banking platforms with the “Agile Edge”. This allows you to be Uptimise IT and act on changing customer demands within months instead of years.</p>
                  <div className="chalange-box-icon">
                    <img src="asset/images/channel-agnostic-cx-banking.png" alt="uptimise it" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="chalange-box">
                  <h3>Transformation On the Go</h3>
                  <p>Big industries on which several other industries rely face a slow pace of transformation due to strict regulations. However, the end customer-facing applications can be taken to the market much faster. We use Agile methodologies that support lean requirements and deliver results to your banking business in less than six months.</p>
                  <div className="chalange-box-icon">
                    <img src="asset/images/transformation-on-the-go-banking.png" alt="uptimise it" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="chalange-box">
                  <h3>Performance-oriented microservice architecture</h3>
                  <p>By leveraging an intermediary messaging layer and abstracting away from a batch-oriented core platform model, banks can speed up service delivery. We mostly recommend a microservice architecture that scales and personalize itself. We’ve extensive experience in integrating into most of the common banking platforms, such as Finserv, FIS, etc.</p>
                  <div className="chalange-box-icon">
                    <img src="asset/images/performance-oriented-microservice-architecture-banking.png" alt="uptimise it" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="chalange-box">
                  <h3>End-to-End Encryption</h3>
                  <p>Digital banking transactions are the hotspot for cybercriminals. In June 2020, the FBI warned that financial cybercrimes may rise due to the rise of digital banking. To help banks overcome the security challenges, we implement end-to-end data encryption in the banking software that we develop. We recommend SSL chain verification to ensure the encrypted connection between the Upload Filer and the web server.</p>
                  <div className="chalange-box-icon">
                    <img src="asset/images/end-to-end%20encryption-banking.png" alt="uptimise it" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section technical-section automotive-section">
          <div className="container">
            <div className="same-heading text-center">
              <div className="mobiles_head">
                <h4>Our Work That Shines</h4>
                <p>Uptimise IT as a leading mobile banking app development company, has worked with top clients from across the world and delivered outstanding results. Some of our best work is, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>The Digital Bank of Africa</h3>
                    <div className="panel-list">
                      <ul>
                        <li> The digital Bank of Africa, aka DafriBank, is a borderless digital bank built specifically for entrepreneurs and merchants to act as an electronic payment gateway system. The bank caters to the banking needs of the growing digital entrepreneurial class in the African region.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>CUT E-Wallet</h3>
                    <div className="panel-list">
                      <ul>
                        <li>CUT mobile wallet allows users to make payments and transfer money in Myanmar and China. The app supports MMK and RMB and allows instant fund transfer via QR code, phone number, or an account ID.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Scalable and Robust Architecture</h3>
                    <div className="panel-list">
                      <ul>
                        <li>The banking app we’ll develop for you will be built on very secure and scalable software architecture. Digital banking security is among the biggest concerns for the industry and hence it is compulsory for banks to ensure data safety. The scalability of your banking apps is also an important element you should focus on.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="technical-box">
                  <div className="app-panel-content">
                    <h3>Cost-Effective Solutions</h3>
                    <div className="panel-list">
                      <ul>
                        <li>We’re not here to sell technologies, we aim to help our customers by crafting technology solutions that can solve specific business problems. It means that our clients get the best mobile banking app development services that are built to drive faster ROI while helping banking institutions achieve the desired efficiency.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section portfolio-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="protfolio-content text-center">
                  <h2>Want to ensure faster ROI for your mobile banking app development?</h2>
                  <Link to="#" className="btn btn-default">Hire Banking App Developers</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section web-development-parent">
          <div className="container">
            <div className="same-heading text-center">
              <span>Mobile Banking App</span>
              <div className="mobiles_head">
                <h4>Mobile Banking App Features</h4>
                <p>Many mobile banking application development companies will overwhelm you by offering dozens of features that might be unnecessary for your app. We suggest you the right set of features that your banking app should have. You can pick among the following features.</p>
              </div>
            </div>
            <div className="custom-tab-parent">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false">Basic Feature</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Advance Features</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Security Procedures</button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/account-management-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Account Management</h3>
                        <p>The users should be able to manage their account information along with the capability to block/activate cards.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/scheduling-payments-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Scheduling Payments</h3>
                        <p>Allowing users to schedule automatic payments for future dates or times.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/bill-payments-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Bill Payments</h3>
                        <p>The users would be able to pay their mobile or utility bills via the banking app.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/customizable-alerts-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Customizable Alerts</h3>
                        <p>Self-personalization of alerts increases user retention and reduces app abandonment.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/seamless-customer-onboarding-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Seamless Customer Onboarding</h3>
                        <p>Customers often find it difficult to associate with a new bank. Starting from filling out an application form to KYC, creating a bank account online isn’t so easy. We build applications that enable users to create their bank accounts in minutes.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/secure-authentication-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Secure Authentication</h3>
                        <p>Due to KYC and AML regulations, user authentication has become a crucial step for banking app development. Although user authentication is necessary, it improves the chances of app abandonment. Hence, banking app developers should also keep UX into consideration while implementing user authentication.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/push-notification-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Push Notification</h3>
                        <p>The most crucial feature that helps in marketing the banking services as well as enhancing security. We mostly implement all three types (Transactional, app-based, and promotional) of push notifications in the banking apps.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/mobile-payments-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Mobile payments</h3>
                        <p>We’ll implement QR-code, NFC, and UPI payment technology to allow your users to make instant payments directly from their bank accounts. It can be easily done by integrating your app with the bank's server.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/chatbots-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Chatbots</h3>
                        <p>Having AI-powered chatbots will allow you to be available to customer queries 24/7. The good news is that the advancement of AI and ML has made it possible for banking app development companies like us to implement this feature ata lesser cost.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/atm-or-branch-locator-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>ATM or Branch Locator</h3>
                        <p>Finding your physical bank branch should be easy for your customers. Having the map locations within the app will enable them to easily find the nearby ATM or the bank branch. We integrate Google and Apple maps for this feature.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/multi-factor-authentication-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Multi-factor Authentication</h3>
                        <p>Giving access to the bank account with a single password will only compromise digital-banking security. Therefore we implement multi-factor authentication to ensure security.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/real-time-alerts-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Real-time Alerts</h3>
                        <p>Real-time alerts powered by AI &amp; ML allow you to alert users about any suspicious activities.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/tls-rsa-ssl-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>TLS, RSA SSL</h3>
                        <p>We use several encryption algorithms to ensure maximum security for the banking application. We recommend TLS protocol for computer networks.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="development-box">
                        <figure>
                          <img src="asset/images/biometrics-banking.png" alt="uptimise it" />
                        </figure>
                        <h3>Biometrics</h3>
                        <p>To ease out the authentication and improve security, we prefer using biometrics due to their heavy popularity.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="same-section service-section bg-color">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="industry-extra-space">
                  <div className="same-heading">
                    <span>Our Solutions</span>
                    <div className="mobiles_head">
                      <h4>Creating The Next Best</h4>
                      <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                    </div>
                  </div>
                </div>
              </div>
              <Service />
            </div>
          </div>
        </section>
        <section className="same-section analysis-setion">
          <div className="container">
            <div className="same-heading text-center">
              <span>Process</span>
              <div className="mobiles_head">
                <h4>Our eLearning Mobile App Development Process </h4>
                <p>As a top-notch banking app development company, Uptimise IT follows a flexible process than allows us to deliver solutions that you can look forward to. These are, as mentioned below:</p>
              </div>
            </div>
            <div className="row">
             <Process />
            </div>
          </div>
        </section>
        <section className="same-section company-box-wrapper bg-color">
          <div className="container">
            <div className="same-heading text-center">
              <span>Our Values</span>
              <div className="mobiles_head">
                <h4>What Makes Us, Uptimise IT</h4>
                <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
              </div>
            </div>
            <div className="row">
             <Values />
            </div>
          </div>
        </section>
        <section className="same-section industries-service-banner">
          <div className="container">
            <div className="request-content">
              <h3>Mobile Banking Software Development Services</h3>
              <p>Uptimise IT is a leading Mobile banking software development company in USA. Our team of developers has years’ worth of experience and technical prowess needed to deliver growth-driven mobile banking app solutions.</p>
              <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
            </div>
          </div>
        </section>
       <HomeIn />
        <section className="same-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="faq-heading">
                  <h3>FAQ</h3>
                  <p>These are answers to some of the most frequently asked questions about mobile banking app development.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-parent">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          How can I create a mobile banking app?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">All you need to do is reach out to Uptimise IT a leading mobile banking app development company in USA. Our mobile banking app solutions have a proven real-world performance along with much-needed innovation. So, if you want to develop the next best mobile banking app in the industry, Uptimise IT can help you do just that.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          How much does it cost to create a banking app?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Mobile Banking app development costs can be anywhere between $15,000 and $30,000. However, there are various factors that can affect the cost. Nevertheless, if you want a closer estimate of the cost you can contact our team and share the project details, our team will give you a better estimate of the development cost.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How long does it take to develop a banking app?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Mobile Banking app development time depends on several factors. But you can expect anything between 2 weeks to 10 weeks.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                          What are the types of mobile banking?
                        </button>
                      </h2>
                      <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          Uptimise IT is a leading mobile banking app development that offers a range of services. Some of the popular types of mobile banking apps are, as mentioned below:
                          <ul>
                            <li>Mobile Banking over SMS (also known as SMS Banking)</li>
                            <li>Mobile Banking over Wireless Application Protocol (WAP)</li>
                            <li>Mobile Banking over Unstructured Supplementary Service Data (USSD)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                          Will you sign NDA?
                        </button>
                      </h2>
                      <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">As a leading mobile banking app development company, Uptimise IT values your confidentiality above anything else. This is why we always sign an NDA or non-disclosure agreement with the client. Thus, ensuring the confidentiality of your unique mobile app idea.</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingsix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapseThree">
                          What are the technologies required for mobile banking?
                        </button>
                      </h2>
                      <div id="flush-collapsesix" className="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          As a visionarytechnologiesapp development company,Uptimise IT uses only the most advanced tools and technologies ensuring high-quality solutions. Some of the technologies we use for iPhone app development are, as mentioned below:
                          <ul>
                            <li>Android SDK Emulator</li>
                            <li>Android Sketch paper</li>
                            <li>MobiOne,</li>
                            <li>RhoMobile</li>
                            <li>Appcelerator</li>
                            <li>Xamarin</li>
                          </ul>
                        </div>  <div id="content">
                          <section className="industries-banner-section banner-section">
                            <img src="asset/images/on-demand-banner-images.png" alt="uptimise it" />
                            <div className="container">
                              <div className="banner-content">
                                <h1><strong>On Demand<br /> App Development </strong> Company </h1>
                                <p>As a leading <strong>On-Demand App Development Company,</strong> Uptimise IT offer you proven On-Demand development solutions. With our help, you can step to the next level.</p>
                                <div className="industries-btn">
                                  <Link to="contact" className="btn btn-primaryx">Talk to Consultant</Link>
                                  <Link to="#contact-section" className="btn btn-default">Get a Quote</Link>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="logo-section">
                            <div className="container-fluid">
                              <div className="logo-parent">
                                <ul>
                                  <li> <Link to="javascript:void(0);"><img src="asset/images/curvaceous-logo-gray.png" alt="uptimise it" /> </Link> </li>
                                  <li> <Link to="javascript:void(0);"><img src="asset/images/medu-logo-gray.png" alt="uptimise it" /> </Link> </li>
                                  <li> <Link to="javascript:void(0);"><img src="asset/images/student-castle-logo-gray.png" alt="uptimise it" /> </Link> </li>
                                  <li> <Link to="javascript:void(0);"><img src="asset/images/drt-logo-gray.png" alt="uptimise it" /> </Link> </li>
                                  <li> <Link to="javascript:void(0);"><img src="asset/images/dafri-logo-gray.png" alt="uptimise it" /> </Link> </li>
                                </ul>
                              </div>
                            </div>
                          </section>
                          <section className="same-section">
                            <div className="container">
                              <div className="row align-items-center demand-wrapper">
                                <div className="col-lg-6">
                                  <div className="demand-img">
                                    <img src="asset/images/on-demand-small-images.png" alt="uptimise it" />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="demaond-content same-heading">
                                    <h3>Data-Driven On-Demand App Development Company</h3>
                                    <p>Uptimise IT is a leading On-Demand app Development Company based in USA and UK. Our <strong>On-Demand app development</strong> solutions are backed by years’ worth of experience and our technical ability. As a firm, we have a proven track record and constantly innovative development capacities. So, if you are someone who wants to develop their own On-Demand <strong>app like Uber,</strong> Uptimise IT is here to help you.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section idustries-on-demand">
                            <div className="container">
                              <div className="same-heading text-center">
                                <span>On-Demand</span>
                                <div className="mobiles_head">
                                  <h4>Our On-Demand App Development Services</h4>
                                  <p>On-Demand solutions can be of different types. So, being a top <strong>On-Demand mobile app development</strong> firm, we cater all the different types of On-Demand apps. Some of the popular On-Demand mobile app development services offered by Uptimise IT are, as mentioned below:</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Taxi Service</h3>
                                    <p>Taxi services have been around for a long time. But you no longer need to go outside to book a taxi. With <strong>On-Demand taxi mobile app,</strong> you can book one and get it right in front of your house at the time you want.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-taxi-service.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Food Service</h3>
                                    <p>On-Demand food delivery mobile apps have grown quite popular recently. Food delivery app like Zomato has generated millions of dollars in profit. And we can develop a food app delivery app like uber for you.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/0n-demand-food-service.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>Doctor On-Demand Service</h3>
                                    <p>Medical services are of uttermost importance. With the doctor On-Demand mobile app, you can deliver all of these services to needy patients through an easy-to-use mobile app.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/doctor-on-demand-service.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>Household Services On-Demand</h3>
                                    <p>household services or house cleaning On-Demand mobile app is another popular On-Demand service in USA. If you are looking for such an app, Uptimise IT can deliver the best household service On-Demand apps.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/household-services-on-demand.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>Laundry On-Demand</h3>
                                    <p>Our <strong>On-Demand developer</strong> can deliver high-quality laundry On-Demand mobile app development solutions. This is one of the most popular niches in United States of America when it comes to the On-Demand industry.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/laundry-on-demand.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>Courier On-Demand</h3>
                                    <p>The on-Demand Logistics service app has become an important part of our life since covid 19. This allows us to send couriersOn-Demand through their mobile app and keep a track of the same.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/courier-on-demand-demand.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Grocery Delivery</h3>
                                    <p>We spend a large part of our time getting groceries. And now, you can do all of it through the On-Demand Grocery delivery mobile app developed and deployed by an expert team of the developer at Uptimise IT.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-grocery-delivery.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Car Wash</h3>
                                    <p>On-Demand car wash has grown quite popular in recent years as people hate washing their own cars. Now, users can generate a request for an On-Demand car wash service through their mobile application.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-car-wash-demand.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Tutor App</h3>
                                    <p>Do you want to develop an On-Demand tutor mobile app? Now, You can develop this with help of dedicated On-Demand developers at Uptimise IT and receive world-class On-Demand solutions. </p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-tutor-app.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand House Cleaning App</h3>
                                    <p>In countries like the United States of America and the United Kingdom, On-Demand house cleaning mobile apps are growing more and more popular. Uptimise IT can help you develop just the app you are looking for.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-house-cleaning-app.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Flower Delivery</h3>
                                    <p>With the help of the On-Demand flower delivery mobile app, you can either order to your doorstep or send flowers of your choice to someone else. This is one of the top features of the On-Demand flower delivery mobile app.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-flower-delivery.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Driver App</h3>
                                    <p>The on-Demand driver app is quite a good opportunity for those who are looking to hire drivers. The On-Demand driver app allows users to generate a request to get a driver to drive them from location A to location B.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-driver-app-demand.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Medicine Delivery App</h3>
                                    <p>The on-Demand medicine delivery app is a good example of <strong>On-Demand mobile app development</strong> done right. This app allows the users to get their prescriptions delivered to their doors steps within a small span of time.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-medicine-delivery-app.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Lawyer App</h3>
                                    <p>Uptimise IT has experience in developing and deploying On-Demand lawyer apps like rocket lawyers. So, if you want to develop an On-Demand lawyer mobile app of your own, our team can develop that for you.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-lawyer-app.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="chalange-box">
                                    <h3>On-Demand Car Rent</h3>
                                    <p>Now, you can develop the popular On-Demand car rent mobile app in USA and UK. Uptimise IT as a leading On-Demand app development company can deliver the On-Demand solutions you are looking for.</p>
                                    <div className="chalange-box-icon">
                                      <img src="asset/images/on-demand-car-rent.png" alt="uptimise it" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section portfolio-section">
                            <div className="container-fluid">
                              <div className="row align-items-center">
                                <div className="col-lg-12">
                                  <div className="protfolio-content text-center">
                                    <h2>Experienced On-Demand Developers</h2>
                                    <p>Got an amazing idea for On-Demand mobile app? We can help you bring the concept to reality.</p>
                                    <Link to="#" className="btn btn-default">Lets Talk</Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section web-development-parent">
                            <div className="container">
                              <div className="same-heading text-center">
                                <span>Why Choose Us</span>
                                <div className="mobiles_head">
                                  <h4>Uptimise IT, The Best On-Demand App Development Company </h4>
                                  <p>If you are looking the best <strong>On-Demand software development</strong> services, you have come to the right place. Uptimise IT is a leading On-Demand app Development Company that offer unmatched On-Demand solutions. </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="development-box">
                                    <figure>
                                      <img src="asset/images/client-centric-services-demand.png" alt="uptimise it" />
                                    </figure>
                                    <h3>Client-Centric Services </h3>
                                    <p> The On-Demand app development services offered by Uptimise IT are based on the needs of clients and their needs. </p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="development-box">
                                    <figure>
                                      <img src="asset/images/flexible-development-demand.png" alt="uptimise it" />
                                    </figure>
                                    <h3>Flexible Development </h3>
                                    <p> At Uptimise IT we follow a flexible On-Demand app development process. This enables us to tackle every problem.</p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="development-box">
                                    <figure>
                                      <img src="asset/images/dedicated-on-demand-developers-demand.png" alt="uptimise it" />
                                    </figure>
                                    <h3>Dedicated On-Demand Developers </h3>
                                    <p>We have a team of dedicated On-Demand developers who are experienced and got the require technical know-how to deliver amazing solutions.</p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="development-box">
                                    <figure>
                                      <img src="asset/images/transparency-process-demand.png" alt="uptimise it" />
                                    </figure>
                                    <h3>Transparency Process </h3>
                                    <p>Our high-level development process is transparent and we keep open communication with the client throughout the process.</p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="development-box">
                                    <figure>
                                      <img src="asset/images/future-proof-demand.png" alt="uptimise it" />
                                    </figure>
                                    <h3>Future Proof</h3>
                                    <p>Our solutions are developed using the latest technology in the industry, this is what makes our On-Demand solutions future-proof. </p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="development-box">
                                    <figure>
                                      <img src="asset/images/affordable-services-demand.png" alt="uptimise it" />
                                    </figure>
                                    <h3>Affordable Services </h3>
                                    <p>Despite being one of the leading On-Demand App Development Company, we provide affordable On-Demand solutions to our clients.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section technical-section bg-color">
                            <div className="container">
                              <div className="same-heading text-center">
                                <div className="mobiles_head">
                                  <h4>Innovative On-Demand App Development Standards</h4>
                                  <p>Uptimise IT provides the <strong>On-Demand app development</strong> USA/UK clients are looking for. As such, we embed the latest industry 4.0 in our On-Demand app like uber solutions.</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="technical-box">
                                    <div className="app-panel-content">
                                      <h3>High-Quality Source Code </h3>
                                      <div className="panel-list">
                                        <ul>
                                          <li>Maintainable Code</li>
                                          <li>High-quality Source Code</li>
                                          <li>Full Ownership transfer </li>
                                          <li>NDA</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="technical-box">
                                    <div className="app-panel-content">
                                      <h3>Data-Driven Development Process </h3>
                                      <div className="panel-list">
                                        <ul>
                                          <li>Highly Transparent Development Process </li>
                                          <li>Data-Driven Healthcare Development </li>
                                          <li>Big Data Integration </li>
                                          <li>Top-Notch QA </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="technical-box">
                                    <div className="app-panel-content">
                                      <h3>Planned Out Architecture </h3>
                                      <div className="panel-list">
                                        <ul>
                                          <li>Strategized Architecture </li>
                                          <li>Secure and Fast Solution </li>
                                          <li>Monolithicvs microservices Architecture </li>
                                          <li>Target–Oriented Architecture </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="technical-box">
                                    <div className="app-panel-content">
                                      <h3>High-Grade Security </h3>
                                      <div className="panel-list">
                                        <ul>
                                          <li>High-Grade Security Solutions </li>
                                          <li>Breach Protection Technology </li>
                                          <li>Security first model </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section service-section">
                            <div className="container">
                              <div className="row align-items-center">
                                <div className="col-lg-4">
                                  <div className="industry-extra-space">
                                    <div className="same-heading">
                                      <span>Our Services</span>
                                      <div className="mobiles_head">
                                        <h4>Creating The Next Best</h4>
                                        <p>Being the best mobile app development agency, Uptimise IT offers a range of website and app development services. These are, as mentioned below:</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="service-box-parent">
                                    <div className="service-img">
                                      <img src="asset/images/service-img01.png" alt="uptimise it" />
                                    </div>
                                    <div className="service-bottom text-center">
                                      <Link to="web-development-company">Web Development</Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="service-box-parent">
                                    <div className="service-img">
                                      <img src="asset/images/service-img02.png" alt="uptimise it" />
                                    </div>
                                    <div className="service-bottom text-center">
                                      <Link to="ui-ux-design-development">UI/UX Design</Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="service-box-parent">
                                    <div className="service-img">
                                      <img src="asset/images/service-img03.png" alt="uptimise it" />
                                    </div>
                                    <div className="service-bottom text-center">
                                      <Link to="maintenance-support">Maintenance &amp; Support</Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="service-box-parent">
                                    <div className="service-img">
                                      <img src="asset/images/service-img04.png" alt="uptimise it" />
                                    </div>
                                    <div className="service-bottom text-center">
                                      <Link to="android-app-development">Android App Development</Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="service-box-parent">
                                    <div className="service-img">
                                      <img src="asset/images/service-img05.png" alt="uptimise it" />
                                    </div>
                                    <div className="service-bottom text-center">
                                      <Link to="ios-app-development">iOS App Development</Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section company-box-wrapper bg-color">
                            <div className="container">
                              <div className="same-heading text-center">
                                <span>Our Values</span>
                                <div className="mobiles_head">
                                  <h4>What Makes Us, Uptimise IT</h4>
                                  <p>Uptimise IT is a mobile app development company driven by a mission and working towards a vision. We have built ourselves around the values which enable us to deliver unremarkable results.</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="company-box">
                                    <div className="company-content">
                                      <h3>Mission</h3>
                                      <img src="asset/images/company-icon01.png" alt="uptimise it" />
                                    </div>
                                    <p>Uptimise IT aims to improve the financial sector with innovative fintech apps, faster transactions, and better security.</p>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="company-box">
                                    <div className="company-content">
                                      <h3>Vision</h3>
                                      <img src="asset/images/company-icon02.png" alt="uptimise it" />
                                    </div>
                                    <p>Our Vision is to contribute to the digital economy while delivering outstanding mobile app/website solutions.</p>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="company-box">
                                    <div className="company-content">
                                      <h3>Goal</h3>
                                      <img src="asset/images/company-icon03.png" alt="uptimise it" />
                                    </div>
                                    <p>Our goal in the year 2022 is to deliver better than ever innovative digital solutions to over clients.</p>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="company-box">
                                    <div className="company-content">
                                      <h3>Success</h3>
                                      <img src="asset/images/company-icon04.png" alt="uptimise it" />
                                    </div>
                                    <p>For Uptimise IT, Success means client satisfaction and growth driving solutions that help you reach your goal.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section analysis-setion">
                            <div className="container">
                              <div className="same-heading text-center">
                                <span>Process </span>
                                <div className="mobiles_head">
                                  <h4>Our On-Demand App Development Process </h4>
                                  <p>All of our <strong>on-demand app development services</strong> follow a well-planned process. This is what enables to deliver consistent yet ever-improving results. The process followed by Uptimise IT as the best On-Demand App Development Company is, as mentioned below: </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="analysis-parent">
                                    <div className="animate-border" />
                                    <div className="analysis-before">01</div>
                                    <div className="bg-icon" />
                                    <h4>Analysis &amp; Planning</h4>
                                    <p>Everything Starts with an Idea and a Plan. </p>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="analysis-parent">
                                    <div className="animate-border" />
                                    <div className="analysis-before">02</div>
                                    <div className="bg-icon bg-icon01" />
                                    <h4>UI/UX Design</h4>
                                    <p>Our creative designers create interactive and beautiful UI/UX.</p>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="analysis-parent">
                                    <div className="animate-border" />
                                    <div className="analysis-before">03</div>
                                    <div className="bg-icon bg-icon02" />
                                    <h4>Development Process</h4>
                                    <p>Our app developers convert your creative idea into a working app</p>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="analysis-parent">
                                    <div className="animate-border" />
                                    <div className="analysis-before">04</div>
                                    <div className="bg-icon bg-icon03" />
                                    <h4>Testing</h4>
                                    <p>We test the app for any possible bugs and errors before it is deployed.</p>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="analysis-parent main">
                                    <div className="inner" />
                                    <div className="analysis-before">05</div>
                                    <div className="bg-icon bg-icon04" />
                                    <h4>Maintenance &amp; Support</h4>
                                    <p>Uptimise IT offers mobile app maintenance &amp; Support to ensure the app’s good performance.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section portfoli-head-wrapper bg-color">
                            <div className="container">
                              <div className="same-heading text-center">
                                <span>Portfolio</span>
                                <div className="mobiles_head">
                                  <h4>Our Success Stories</h4>
                                  <p>Uptimise IT has worked with many amazing clients and delivered successful mobile applications. Check out some of our success stories.</p>
                                </div>
                              </div>
                              <div className="row portfoli-head">
                                <div className="col-lg-6">
                                  <div className="portfolio-parent">
                                    <div className="portfolio-inner-wrapper">
                                      <img src="asset/images/portfolio-bg01.png" />
                                      <div className="portfolio-content-parent">
                                        <div className="country-list">
                                          <ul>
                                            <li>Japan</li>
                                            <li>Malaysia</li>
                                          </ul>
                                        </div>
                                        <h3>DafriBank <span>Digital Bank Of Africa</span></h3>
                                        <Link to="dafri-bank-case-study" className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="portfolio-parent">
                                    <div className="portfolio-inner-wrapper">
                                      <img src="asset/images/portfolio-bg02.png" />
                                      <div className="project-content">
                                        <h3>Medu4 <span>Medical Education Platform</span></h3>
                                      </div>
                                      <div className="portfolio-content-parent">
                                        <div className="country-list">
                                          <ul>
                                            <li>Japan</li>
                                            <li>Malaysia</li>
                                          </ul>
                                        </div>
                                        <h3>DafriBank <span>Digital Bank Of Africa</span></h3>
                                        <Link to="medu4-case-study" className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="portfolio-parent">
                                    <div className="portfolio-inner-wrapper">
                                      <img src="asset/images/portfolio-bg03.png" />
                                      <div className="project-content">
                                        <h3>Medu4 <span>Medical Education Platform</span></h3>
                                      </div>
                                      <div className="portfolio-content-parent">
                                        <div className="country-list">
                                          <ul>
                                            <li>Japan</li>
                                            <li>Malaysia</li>
                                          </ul>
                                        </div>
                                        <h3>DafriBank <span>Digital Bank Of Africa</span></h3>
                                        <Link to="medu4-case-study" className="btn btn-default">Case Study</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="portfolio-bottom-btn text-center">
                                <Link to="our-work" className="btn btn-primaryx">View Portfolio</Link>
                              </div>
                            </div>
                          </section>
                          <section className="same-section industries-service-banner">
                            <div className="container">
                              <div className="request-content">
                                <h3>Our Innovative and Foremost Services</h3>
                                <p>We provide custom software development services via our professional developers with vast industry-specific experience. Create, curate, teach. That’s the way we code. </p>
                                <Link to="#" className="btn btn-primaryx">Let’s Have a Glance</Link>
                              </div>
                            </div>
                          </section>
                          <section className="same-section testimonial-section">
                            <div className="container">
                              <div className="same-heading text-center">
                                <span>Testimonial</span>
                                <div className="mobiles_head">
                                  <h4>Customers? Satisfied</h4>
                                  <p>Uptimise IT is all about delivering results that satisfy our clients and deliver success. Here are some words from our happy clients.</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="testimonal-parent">
                                    <h3>Help us improve our productivity</h3>
                                    <div className="testimonal_child">
                                      <div className="testimonal_img">
                                        <Link to="#">
                                          <img src="asset/images/army.png" />
                                        </Link>
                                      </div>
                                    </div>
                                    <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="testimonal-parent">
                                    <h3>Help us improve our productivity</h3>
                                    <div className="testimonal_child">
                                      <div className="testimonal_img">
                                        <Link to="#">
                                          <img src="asset/images/army.png" />
                                        </Link>
                                      </div>
                                    </div>
                                    <p>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section bg-color blank-wrapper-parent">
                            <div className="container">
                              <div className="same-heading text-center">
                                <span>Uptimise IT’s Reviews</span>
                                <div className="mobiles_head">
                                  <h4>Deemed The Best App Developers</h4>
                                  <p>Uptimise IT has been deemed the top mobile app development company by well-known critics and tech reviewers.</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="blank-box">
                                    <img src="asset/images/blank-box-icon01.png" alt="uptimise it" />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="blank-box">
                                    <img src="asset/images/blank-box-icon02.png" alt="uptimise it" />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="blank-box">
                                    <img src="asset/images/blank-box-icon03.png" alt="uptimise it" />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="blank-box">
                                    <img src="asset/images/blank-box-icon04.png" alt="uptimise it" />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="blank-box">
                                    <img src="asset/images/blank-box-icon04.png" alt="uptimise it" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="same-section">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="faq-heading">
                                    <h3>FAQ</h3>
                                    <p>These are answers to some of the most frequently asked questions about on demand app development. </p>
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  <div className="faq-parent">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                      <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            How to create On-demand app?
                                          </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                          <div className="accordion-body">
                                            On demand, mobile app developers follow a five-step development process to deliver amazing solutions. This process is, as mentioned below:
                                            <ul>
                                              <li>Planning and Analysis </li>
                                              <li>UI/UX Design </li>
                                              <li>Development Process</li>
                                              <li>Testing &amp; Deployment </li>
                                              <li>Mobile App maintenance </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            In which industries we can use On-demand app?
                                          </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                          <div className="accordion-body">
                                            Uptimise IT’sOn-demand app solutions cover a lot of industries. Some of these are: 
                                            <ul>
                                              <li>Ewallet</li>
                                              <li>Food delivery </li>
                                              <li>Car wash </li>
                                              <li>Taxi Booking </li>
                                              <li>Healthcare</li>
                                              <li>Fantasy Sports app </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Do you provide documentation for the solution?
                                          </button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                          <div className="accordion-body">Uptimise IT is a professional mobile app development that follows full procedure with all the required documentation. Therefore, we do provide you with documentation for the concerned solutions.</div>
                                        </div>
                                      </div>
                                      <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingfour">
                                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseThree">
                                            How skilled are your developers for on-demand app development?
                                          </button>
                                        </h2>
                                        <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                                          <div className="accordion-body">Our on-demand app developers have years’ worth of high-level experience along with out-of-the-box creativity and all the required sources. This combined with your amazing idea can give you a million-dollar on-demand app.</div>
                                        </div>
                                      </div>
                                      <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingfive">
                                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Can you brief me about your hiring models for on-demand mobile app development?
                                          </button>
                                        </h2>
                                        <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                          <div className="accordion-body">Uptimise IT provides flexible hiring models for the on-demand mobile app development team. You can hire a full time team, part time team, or you can hire them on hourly bases.</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>  
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </section>
      </div>
   
    )
}